/*
* Remix Icon v2.5.0
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
*
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*
* Date: 2020-05-23
*/
@font-face {
  font-family: "remixicon";
  src: url(/_next/static/media/remixicon.f12fd1a0.eot); /* IE9*/
  src: url(/_next/static/media/remixicon.f12fd1a0.eot#iefix) format("embedded-opentype"),
    /* IE6-IE8 */ url(/_next/static/media/remixicon.5400338c.woff2) format("woff2"),
    url(/_next/static/media/remixicon.1e0fb76b.woff) format("woff"),
    url(/_next/static/media/remixicon.e313410c.ttf) format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url(/_next/static/media/remixicon.2b2ee314.svg#remixicon) format("svg"); /* iOS 4.1- */
  font-display: swap;
}

[class^="ri-"],
[class*=" ri-"] {
  font-family: "remixicon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.ri-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -0.075em;
}
.ri-xxs {
  font-size: 0.5em;
}
.ri-xs {
  font-size: 0.75em;
}
.ri-sm {
  font-size: 0.875em;
}
.ri-1x {
  font-size: 1em;
}
.ri-2x {
  font-size: 2em;
}
.ri-3x {
  font-size: 3em;
}
.ri-4x {
  font-size: 4em;
}
.ri-5x {
  font-size: 5em;
}
.ri-6x {
  font-size: 6em;
}
.ri-7x {
  font-size: 7em;
}
.ri-8x {
  font-size: 8em;
}
.ri-9x {
  font-size: 9em;
}
.ri-10x {
  font-size: 10em;
}
.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-24-hours-fill:before {
  content: "\ea01";
}
.ri-24-hours-line:before {
  content: "\ea02";
}
.ri-4k-fill:before {
  content: "\ea03";
}
.ri-4k-line:before {
  content: "\ea04";
}
.ri-a-b:before {
  content: "\ea05";
}

.ri-a-b-line:before {
  content: "\ea05";
}
.ri-a-b-fill:before {
  content: "\ea05";
}

.ri-account-box-fill:before {
  content: "\ea06";
}
.ri-account-box-line:before {
  content: "\ea07";
}
.ri-account-circle-fill:before {
  content: "\ea08";
}
.ri-account-circle-line:before {
  content: "\ea09";
}
.ri-account-pin-box-fill:before {
  content: "\ea0a";
}
.ri-account-pin-box-line:before {
  content: "\ea0b";
}
.ri-account-pin-circle-fill:before {
  content: "\ea0c";
}
.ri-account-pin-circle-line:before {
  content: "\ea0d";
}
.ri-add-box-fill:before {
  content: "\ea0e";
}
.ri-add-box-line:before {
  content: "\ea0f";
}
.ri-add-circle-fill:before {
  content: "\ea10";
}
.ri-add-circle-line:before {
  content: "\ea11";
}
.ri-add-fill:before {
  content: "\ea12";
}
.ri-add-line:before {
  content: "\ea13";
}
.ri-admin-fill:before {
  content: "\ea14";
}
.ri-admin-line:before {
  content: "\ea15";
}
.ri-advertisement-fill:before {
  content: "\ea16";
}
.ri-advertisement-line:before {
  content: "\ea17";
}
.ri-airplay-fill:before {
  content: "\ea18";
}
.ri-airplay-line:before {
  content: "\ea19";
}
.ri-alarm-fill:before {
  content: "\ea1a";
}
.ri-alarm-line:before {
  content: "\ea1b";
}
.ri-alarm-warning-fill:before {
  content: "\ea1c";
}
.ri-alarm-warning-line:before {
  content: "\ea1d";
}
.ri-album-fill:before {
  content: "\ea1e";
}
.ri-album-line:before {
  content: "\ea1f";
}
.ri-alert-fill:before {
  content: "\ea20";
}
.ri-alert-line:before {
  content: "\ea21";
}
.ri-aliens-fill:before {
  content: "\ea22";
}
.ri-aliens-line:before {
  content: "\ea23";
}
.ri-align-bottom-line:before {
  content: "\ea24";
}
.ri-align-center-line:before {
  content: "\ea25";
}
.ri-align-justify-line:before {
  content: "\ea26";
}
.ri-align-left-line:before {
  content: "\ea27";
}
.ri-align-right-line:before {
  content: "\ea28";
}
.ri-align-top-line:before {
  content: "\ea29";
}
.ri-align-vertically-line:before {
  content: "\ea2a";
}
.ri-align-bottom-fill:before {
  content: "\ea24";
}
.ri-align-center-fill:before {
  content: "\ea25";
}
.ri-align-justify-fill:before {
  content: "\ea26";
}
.ri-align-left-fill:before {
  content: "\ea27";
}
.ri-align-right-fill:before {
  content: "\ea28";
}
.ri-align-top-fill:before {
  content: "\ea29";
}
.ri-align-vertically-fill:before {
  content: "\ea2a";
}
.ri-alipay-fill:before {
  content: "\ea2b";
}
.ri-alipay-line:before {
  content: "\ea2c";
}
.ri-amazon-fill:before {
  content: "\ea2d";
}
.ri-amazon-line:before {
  content: "\ea2e";
}
.ri-anchor-fill:before {
  content: "\ea2f";
}
.ri-anchor-line:before {
  content: "\ea30";
}
.ri-ancient-gate-fill:before {
  content: "\ea31";
}
.ri-ancient-gate-line:before {
  content: "\ea32";
}
.ri-ancient-pavilion-fill:before {
  content: "\ea33";
}
.ri-ancient-pavilion-line:before {
  content: "\ea34";
}
.ri-android-fill:before {
  content: "\ea35";
}
.ri-android-line:before {
  content: "\ea36";
}
.ri-angularjs-fill:before {
  content: "\ea37";
}
.ri-angularjs-line:before {
  content: "\ea38";
}
.ri-anticlockwise-2-fill:before {
  content: "\ea39";
}
.ri-anticlockwise-2-line:before {
  content: "\ea3a";
}
.ri-anticlockwise-fill:before {
  content: "\ea3b";
}
.ri-anticlockwise-line:before {
  content: "\ea3c";
}
.ri-app-store-fill:before {
  content: "\ea3d";
}
.ri-app-store-line:before {
  content: "\ea3e";
}
.ri-apple-fill:before {
  content: "\ea3f";
}
.ri-apple-line:before {
  content: "\ea40";
}
.ri-apps-2-fill:before {
  content: "\ea41";
}
.ri-apps-2-line:before {
  content: "\ea42";
}
.ri-apps-fill:before {
  content: "\ea43";
}
.ri-apps-line:before {
  content: "\ea44";
}
.ri-archive-drawer-fill:before {
  content: "\ea45";
}
.ri-archive-drawer-line:before {
  content: "\ea46";
}
.ri-archive-fill:before {
  content: "\ea47";
}
.ri-archive-line:before {
  content: "\ea48";
}
.ri-arrow-down-circle-fill:before {
  content: "\ea49";
}
.ri-arrow-down-circle-line:before {
  content: "\ea4a";
}
.ri-arrow-down-fill:before {
  content: "\ea4b";
}
.ri-arrow-down-line:before {
  content: "\ea4c";
}
.ri-arrow-down-s-fill:before {
  content: "\ea4d";
}
.ri-arrow-down-s-line:before {
  content: "\ea4e";
}
.ri-arrow-drop-down-fill:before {
  content: "\ea4f";
}
.ri-arrow-drop-down-line:before {
  content: "\ea50";
}
.ri-arrow-drop-left-fill:before {
  content: "\ea51";
}
.ri-arrow-drop-left-line:before {
  content: "\ea52";
}
.ri-arrow-drop-right-fill:before {
  content: "\ea53";
}
.ri-arrow-drop-right-line:before {
  content: "\ea54";
}
.ri-arrow-drop-up-fill:before {
  content: "\ea55";
}
.ri-arrow-drop-up-line:before {
  content: "\ea56";
}
.ri-arrow-go-back-fill:before {
  content: "\ea57";
}
.ri-arrow-go-back-line:before {
  content: "\ea58";
}
.ri-arrow-go-forward-fill:before {
  content: "\ea59";
}
.ri-arrow-go-forward-line:before {
  content: "\ea5a";
}
.ri-arrow-left-circle-fill:before {
  content: "\ea5b";
}
.ri-arrow-left-circle-line:before {
  content: "\ea5c";
}
.ri-arrow-left-down-fill:before {
  content: "\ea5d";
}
.ri-arrow-left-down-line:before {
  content: "\ea5e";
}
.ri-arrow-left-fill:before {
  content: "\ea5f";
}
.ri-arrow-left-line:before {
  content: "\ea60";
}
.ri-arrow-left-right-fill:before {
  content: "\ea61";
}
.ri-arrow-left-right-line:before {
  content: "\ea62";
}
.ri-arrow-left-s-fill:before {
  content: "\ea63";
}
.ri-arrow-left-s-line:before {
  content: "\ea64";
}
.ri-arrow-left-up-fill:before {
  content: "\ea65";
}
.ri-arrow-left-up-line:before {
  content: "\ea66";
}
.ri-arrow-right-circle-fill:before {
  content: "\ea67";
}
.ri-arrow-right-circle-line:before {
  content: "\ea68";
}
.ri-arrow-right-down-fill:before {
  content: "\ea69";
}
.ri-arrow-right-down-line:before {
  content: "\ea6a";
}
.ri-arrow-right-fill:before {
  content: "\ea6b";
}
.ri-arrow-right-line:before {
  content: "\ea6c";
}
.ri-arrow-right-s-fill:before {
  content: "\ea6d";
}
.ri-arrow-right-s-line:before {
  content: "\ea6e";
}
.ri-arrow-right-up-fill:before {
  content: "\ea6f";
}
.ri-arrow-right-up-line:before {
  content: "\ea70";
}
.ri-arrow-up-circle-fill:before {
  content: "\ea71";
}
.ri-arrow-up-circle-line:before {
  content: "\ea72";
}
.ri-arrow-up-down-fill:before {
  content: "\ea73";
}
.ri-arrow-up-down-line:before {
  content: "\ea74";
}
.ri-arrow-up-fill:before {
  content: "\ea75";
}
.ri-arrow-up-line:before {
  content: "\ea76";
}
.ri-arrow-up-s-fill:before {
  content: "\ea77";
}
.ri-arrow-up-s-line:before {
  content: "\ea78";
}
.ri-artboard-2-fill:before {
  content: "\ea79";
}
.ri-artboard-2-line:before {
  content: "\ea7a";
}
.ri-artboard-fill:before {
  content: "\ea7b";
}
.ri-artboard-line:before {
  content: "\ea7c";
}
.ri-article-fill:before {
  content: "\ea7d";
}
.ri-article-line:before {
  content: "\ea7e";
}
.ri-aspect-ratio-fill:before {
  content: "\ea7f";
}
.ri-aspect-ratio-line:before {
  content: "\ea80";
}
.ri-asterisk:before {
  content: "\ea81";
}
.ri-asterisk-line:before {
  content: "\ea81";
}
.ri-asterisk-fill:before {
  content: "\ea81";
}

.ri-at-fill:before {
  content: "\ea82";
}
.ri-at-line:before {
  content: "\ea83";
}
.ri-attachment-2:before {
  content: "\ea84";
}
.ri-attachment-2-line:before {
  content: "\ea84";
}
.ri-attachment-2-fill:before {
  content: "\ea84";
}

.ri-attachment-fill:before {
  content: "\ea85";
}
.ri-attachment-line:before {
  content: "\ea86";
}
.ri-auction-fill:before {
  content: "\ea87";
}
.ri-auction-line:before {
  content: "\ea88";
}
.ri-award-fill:before {
  content: "\ea89";
}
.ri-award-line:before {
  content: "\ea8a";
}
.ri-baidu-fill:before {
  content: "\ea8b";
}
.ri-baidu-line:before {
  content: "\ea8c";
}
.ri-ball-pen-fill:before {
  content: "\ea8d";
}
.ri-ball-pen-line:before {
  content: "\ea8e";
}
.ri-bank-card-2-fill:before {
  content: "\ea8f";
}
.ri-bank-card-2-line:before {
  content: "\ea90";
}
.ri-bank-card-fill:before {
  content: "\ea91";
}
.ri-bank-card-line:before {
  content: "\ea92";
}
.ri-bank-fill:before {
  content: "\ea93";
}
.ri-bank-line:before {
  content: "\ea94";
}
.ri-bar-chart-2-fill:before {
  content: "\ea95";
}
.ri-bar-chart-2-line:before {
  content: "\ea96";
}
.ri-bar-chart-box-fill:before {
  content: "\ea97";
}
.ri-bar-chart-box-line:before {
  content: "\ea98";
}
.ri-bar-chart-fill:before {
  content: "\ea99";
}
.ri-bar-chart-grouped-fill:before {
  content: "\ea9a";
}
.ri-bar-chart-grouped-line:before {
  content: "\ea9b";
}
.ri-bar-chart-horizontal-fill:before {
  content: "\ea9c";
}
.ri-bar-chart-horizontal-line:before {
  content: "\ea9d";
}
.ri-bar-chart-line:before {
  content: "\ea9e";
}
.ri-barcode-box-fill:before {
  content: "\ea9f";
}
.ri-barcode-box-line:before {
  content: "\eaa0";
}
.ri-barcode-fill:before {
  content: "\eaa1";
}
.ri-barcode-line:before {
  content: "\eaa2";
}
.ri-barricade-fill:before {
  content: "\eaa3";
}
.ri-barricade-line:before {
  content: "\eaa4";
}
.ri-base-station-fill:before {
  content: "\eaa5";
}
.ri-base-station-line:before {
  content: "\eaa6";
}
.ri-basketball-fill:before {
  content: "\eaa7";
}
.ri-basketball-line:before {
  content: "\eaa8";
}
.ri-battery-2-charge-fill:before {
  content: "\eaa9";
}
.ri-battery-2-charge-line:before {
  content: "\eaaa";
}
.ri-battery-2-fill:before {
  content: "\eaab";
}
.ri-battery-2-line:before {
  content: "\eaac";
}
.ri-battery-charge-fill:before {
  content: "\eaad";
}
.ri-battery-charge-line:before {
  content: "\eaae";
}
.ri-battery-fill:before {
  content: "\eaaf";
}
.ri-battery-line:before {
  content: "\eab0";
}
.ri-battery-low-fill:before {
  content: "\eab1";
}
.ri-battery-low-line:before {
  content: "\eab2";
}
.ri-battery-saver-fill:before {
  content: "\eab3";
}
.ri-battery-saver-line:before {
  content: "\eab4";
}
.ri-battery-share-fill:before {
  content: "\eab5";
}
.ri-battery-share-line:before {
  content: "\eab6";
}
.ri-bear-smile-fill:before {
  content: "\eab7";
}
.ri-bear-smile-line:before {
  content: "\eab8";
}
.ri-behance-fill:before {
  content: "\eab9";
}
.ri-behance-line:before {
  content: "\eaba";
}
.ri-bell-fill:before {
  content: "\eabb";
}
.ri-bell-line:before {
  content: "\eabc";
}
.ri-bike-fill:before {
  content: "\eabd";
}
.ri-bike-line:before {
  content: "\eabe";
}
.ri-bilibili-fill:before {
  content: "\eabf";
}
.ri-bilibili-line:before {
  content: "\eac0";
}
.ri-bill-fill:before {
  content: "\eac1";
}
.ri-bill-line:before {
  content: "\eac2";
}
.ri-billiards-fill:before {
  content: "\eac3";
}
.ri-billiards-line:before {
  content: "\eac4";
}
.ri-bit-coin-fill:before {
  content: "\eac5";
}
.ri-bit-coin-line:before {
  content: "\eac6";
}
.ri-blaze-fill:before {
  content: "\eac7";
}
.ri-blaze-line:before {
  content: "\eac8";
}
.ri-bluetooth-connect-fill:before {
  content: "\eac9";
}
.ri-bluetooth-connect-line:before {
  content: "\eaca";
}
.ri-bluetooth-fill:before {
  content: "\eacb";
}
.ri-bluetooth-line:before {
  content: "\eacc";
}
.ri-blur-off-fill:before {
  content: "\eacd";
}
.ri-blur-off-line:before {
  content: "\eace";
}
.ri-body-scan-fill:before {
  content: "\eacf";
}
.ri-body-scan-line:before {
  content: "\ead0";
}
.ri-bold:before {
  content: "\ead1";
}
.ri-bold-line:before {
  content: "\ead1";
}
.ri-bold-fill:before {
  content: "\ead1";
}

.ri-book-2-fill:before {
  content: "\ead2";
}
.ri-book-2-line:before {
  content: "\ead3";
}
.ri-book-3-fill:before {
  content: "\ead4";
}
.ri-book-3-line:before {
  content: "\ead5";
}
.ri-book-fill:before {
  content: "\ead6";
}
.ri-book-line:before {
  content: "\ead7";
}
.ri-book-mark-fill:before {
  content: "\ead8";
}
.ri-book-mark-line:before {
  content: "\ead9";
}
.ri-book-open-fill:before {
  content: "\eada";
}
.ri-book-open-line:before {
  content: "\eadb";
}
.ri-book-read-fill:before {
  content: "\eadc";
}
.ri-book-read-line:before {
  content: "\eadd";
}
.ri-booklet-fill:before {
  content: "\eade";
}
.ri-booklet-line:before {
  content: "\eadf";
}
.ri-bookmark-2-fill:before {
  content: "\eae0";
}
.ri-bookmark-2-line:before {
  content: "\eae1";
}
.ri-bookmark-3-fill:before {
  content: "\eae2";
}
.ri-bookmark-3-line:before {
  content: "\eae3";
}
.ri-bookmark-fill:before {
  content: "\eae4";
}
.ri-bookmark-line:before {
  content: "\eae5";
}
.ri-boxing-fill:before {
  content: "\eae6";
}
.ri-boxing-line:before {
  content: "\eae7";
}
.ri-braces-fill:before {
  content: "\eae8";
}
.ri-braces-line:before {
  content: "\eae9";
}
.ri-brackets-fill:before {
  content: "\eaea";
}
.ri-brackets-line:before {
  content: "\eaeb";
}
.ri-briefcase-2-fill:before {
  content: "\eaec";
}
.ri-briefcase-2-line:before {
  content: "\eaed";
}
.ri-briefcase-3-fill:before {
  content: "\eaee";
}
.ri-briefcase-3-line:before {
  content: "\eaef";
}
.ri-briefcase-4-fill:before {
  content: "\eaf0";
}
.ri-briefcase-4-line:before {
  content: "\eaf1";
}
.ri-briefcase-5-fill:before {
  content: "\eaf2";
}
.ri-briefcase-5-line:before {
  content: "\eaf3";
}
.ri-briefcase-fill:before {
  content: "\eaf4";
}
.ri-briefcase-line:before {
  content: "\eaf5";
}
.ri-bring-forward:before {
  content: "\eaf6";
}
.ri-bring-forward-line:before {
  content: "\eaf6";
}
.ri-bring-forward-fill:before {
  content: "\eaf6";
}

.ri-bring-to-front:before {
  content: "\eaf7";
}
.ri-bring-to-front-line:before {
  content: "\eaf7";
}
.ri-bring-to-front-fill:before {
  content: "\eaf7";
}

.ri-broadcast-fill:before {
  content: "\eaf8";
}
.ri-broadcast-line:before {
  content: "\eaf9";
}
.ri-brush-2-fill:before {
  content: "\eafa";
}
.ri-brush-2-line:before {
  content: "\eafb";
}
.ri-brush-3-fill:before {
  content: "\eafc";
}
.ri-brush-3-line:before {
  content: "\eafd";
}
.ri-brush-4-fill:before {
  content: "\eafe";
}
.ri-brush-4-line:before {
  content: "\eaff";
}
.ri-brush-fill:before {
  content: "\eb00";
}
.ri-brush-line:before {
  content: "\eb01";
}
.ri-bubble-chart-fill:before {
  content: "\eb02";
}
.ri-bubble-chart-line:before {
  content: "\eb03";
}
.ri-bug-2-fill:before {
  content: "\eb04";
}
.ri-bug-2-line:before {
  content: "\eb05";
}
.ri-bug-fill:before {
  content: "\eb06";
}
.ri-bug-line:before {
  content: "\eb07";
}
.ri-building-2-fill:before {
  content: "\eb08";
}
.ri-building-2-line:before {
  content: "\eb09";
}
.ri-building-3-fill:before {
  content: "\eb0a";
}
.ri-building-3-line:before {
  content: "\eb0b";
}
.ri-building-4-fill:before {
  content: "\eb0c";
}
.ri-building-4-line:before {
  content: "\eb0d";
}
.ri-building-fill:before {
  content: "\eb0e";
}
.ri-building-line:before {
  content: "\eb0f";
}
.ri-bus-2-fill:before {
  content: "\eb10";
}
.ri-bus-2-line:before {
  content: "\eb11";
}
.ri-bus-fill:before {
  content: "\eb12";
}
.ri-bus-line:before {
  content: "\eb13";
}
.ri-bus-wifi-fill:before {
  content: "\eb14";
}
.ri-bus-wifi-line:before {
  content: "\eb15";
}
.ri-cactus-fill:before {
  content: "\eb16";
}
.ri-cactus-line:before {
  content: "\eb17";
}
.ri-cake-2-fill:before {
  content: "\eb18";
}
.ri-cake-2-line:before {
  content: "\eb19";
}
.ri-cake-3-fill:before {
  content: "\eb1a";
}
.ri-cake-3-line:before {
  content: "\eb1b";
}
.ri-cake-fill:before {
  content: "\eb1c";
}
.ri-cake-line:before {
  content: "\eb1d";
}
.ri-calculator-fill:before {
  content: "\eb1e";
}
.ri-calculator-line:before {
  content: "\eb1f";
}
.ri-calendar-2-fill:before {
  content: "\eb20";
}
.ri-calendar-2-line:before {
  content: "\eb21";
}
.ri-calendar-check-fill:before {
  content: "\eb22";
}
.ri-calendar-check-line:before {
  content: "\eb23";
}
.ri-calendar-event-fill:before {
  content: "\eb24";
}
.ri-calendar-event-line:before {
  content: "\eb25";
}
.ri-calendar-fill:before {
  content: "\eb26";
}
.ri-calendar-line:before {
  content: "\eb27";
}
.ri-calendar-todo-fill:before {
  content: "\eb28";
}
.ri-calendar-todo-line:before {
  content: "\eb29";
}
.ri-camera-2-fill:before {
  content: "\eb2a";
}
.ri-camera-2-line:before {
  content: "\eb2b";
}
.ri-camera-3-fill:before {
  content: "\eb2c";
}
.ri-camera-3-line:before {
  content: "\eb2d";
}
.ri-camera-fill:before {
  content: "\eb2e";
}
.ri-camera-lens-fill:before {
  content: "\eb2f";
}
.ri-camera-lens-line:before {
  content: "\eb30";
}
.ri-camera-line:before {
  content: "\eb31";
}
.ri-camera-off-fill:before {
  content: "\eb32";
}
.ri-camera-off-line:before {
  content: "\eb33";
}
.ri-camera-switch-fill:before {
  content: "\eb34";
}
.ri-camera-switch-line:before {
  content: "\eb35";
}
.ri-capsule-fill:before {
  content: "\eb36";
}
.ri-capsule-line:before {
  content: "\eb37";
}
.ri-car-fill:before {
  content: "\eb38";
}
.ri-car-line:before {
  content: "\eb39";
}
.ri-car-washing-fill:before {
  content: "\eb3a";
}
.ri-car-washing-line:before {
  content: "\eb3b";
}
.ri-caravan-fill:before {
  content: "\eb3c";
}
.ri-caravan-line:before {
  content: "\eb3d";
}
.ri-cast-fill:before {
  content: "\eb3e";
}
.ri-cast-line:before {
  content: "\eb3f";
}
.ri-cellphone-fill:before {
  content: "\eb40";
}
.ri-cellphone-line:before {
  content: "\eb41";
}
.ri-celsius-fill:before {
  content: "\eb42";
}
.ri-celsius-line:before {
  content: "\eb43";
}
.ri-centos-fill:before {
  content: "\eb44";
}
.ri-centos-line:before {
  content: "\eb45";
}
.ri-character-recognition-fill:before {
  content: "\eb46";
}
.ri-character-recognition-line:before {
  content: "\eb47";
}
.ri-charging-pile-2-fill:before {
  content: "\eb48";
}
.ri-charging-pile-2-line:before {
  content: "\eb49";
}
.ri-charging-pile-fill:before {
  content: "\eb4a";
}
.ri-charging-pile-line:before {
  content: "\eb4b";
}
.ri-chat-1-fill:before {
  content: "\eb4c";
}
.ri-chat-1-line:before {
  content: "\eb4d";
}
.ri-chat-2-fill:before {
  content: "\eb4e";
}
.ri-chat-2-line:before {
  content: "\eb4f";
}
.ri-chat-3-fill:before {
  content: "\eb50";
}
.ri-chat-3-line:before {
  content: "\eb51";
}
.ri-chat-4-fill:before {
  content: "\eb52";
}
.ri-chat-4-line:before {
  content: "\eb53";
}
.ri-chat-check-fill:before {
  content: "\eb54";
}
.ri-chat-check-line:before {
  content: "\eb55";
}
.ri-chat-delete-fill:before {
  content: "\eb56";
}
.ri-chat-delete-line:before {
  content: "\eb57";
}
.ri-chat-download-fill:before {
  content: "\eb58";
}
.ri-chat-download-line:before {
  content: "\eb59";
}
.ri-chat-follow-up-fill:before {
  content: "\eb5a";
}
.ri-chat-follow-up-line:before {
  content: "\eb5b";
}
.ri-chat-forward-fill:before {
  content: "\eb5c";
}
.ri-chat-forward-line:before {
  content: "\eb5d";
}
.ri-chat-heart-fill:before {
  content: "\eb5e";
}
.ri-chat-heart-line:before {
  content: "\eb5f";
}
.ri-chat-history-fill:before {
  content: "\eb60";
}
.ri-chat-history-line:before {
  content: "\eb61";
}
.ri-chat-new-fill:before {
  content: "\eb62";
}
.ri-chat-new-line:before {
  content: "\eb63";
}
.ri-chat-off-fill:before {
  content: "\eb64";
}
.ri-chat-off-line:before {
  content: "\eb65";
}
.ri-chat-poll-fill:before {
  content: "\eb66";
}
.ri-chat-poll-line:before {
  content: "\eb67";
}
.ri-chat-private-fill:before {
  content: "\eb68";
}
.ri-chat-private-line:before {
  content: "\eb69";
}
.ri-chat-quote-fill:before {
  content: "\eb6a";
}
.ri-chat-quote-line:before {
  content: "\eb6b";
}
.ri-chat-settings-fill:before {
  content: "\eb6c";
}
.ri-chat-settings-line:before {
  content: "\eb6d";
}
.ri-chat-smile-2-fill:before {
  content: "\eb6e";
}
.ri-chat-smile-2-line:before {
  content: "\eb6f";
}
.ri-chat-smile-3-fill:before {
  content: "\eb70";
}
.ri-chat-smile-3-line:before {
  content: "\eb71";
}
.ri-chat-smile-fill:before {
  content: "\eb72";
}
.ri-chat-smile-line:before {
  content: "\eb73";
}
.ri-chat-upload-fill:before {
  content: "\eb74";
}
.ri-chat-upload-line:before {
  content: "\eb75";
}
.ri-chat-voice-fill:before {
  content: "\eb76";
}
.ri-chat-voice-line:before {
  content: "\eb77";
}
.ri-check-double-fill:before {
  content: "\eb78";
}
.ri-check-double-line:before {
  content: "\eb79";
}
.ri-check-fill:before {
  content: "\eb7a";
}
.ri-check-line:before {
  content: "\eb7b";
}
.ri-checkbox-blank-circle-fill:before {
  content: "\eb7c";
}
.ri-checkbox-blank-circle-line:before {
  content: "\eb7d";
}
.ri-checkbox-blank-fill:before {
  content: "\eb7e";
}
.ri-checkbox-blank-line:before {
  content: "\eb7f";
}
.ri-checkbox-circle-fill:before {
  content: "\eb80";
}
.ri-checkbox-circle-line:before {
  content: "\eb81";
}
.ri-checkbox-fill:before {
  content: "\eb82";
}
.ri-checkbox-indeterminate-fill:before {
  content: "\eb83";
}
.ri-checkbox-indeterminate-line:before {
  content: "\eb84";
}
.ri-checkbox-line:before {
  content: "\eb85";
}
.ri-checkbox-multiple-blank-fill:before {
  content: "\eb86";
}
.ri-checkbox-multiple-blank-line:before {
  content: "\eb87";
}
.ri-checkbox-multiple-fill:before {
  content: "\eb88";
}
.ri-checkbox-multiple-line:before {
  content: "\eb89";
}
.ri-china-railway-fill:before {
  content: "\eb8a";
}
.ri-china-railway-line:before {
  content: "\eb8b";
}
.ri-chrome-fill:before {
  content: "\eb8c";
}
.ri-chrome-line:before {
  content: "\eb8d";
}
.ri-clapperboard-fill:before {
  content: "\eb8e";
}
.ri-clapperboard-line:before {
  content: "\eb8f";
}
.ri-clipboard-fill:before {
  content: "\eb90";
}
.ri-clipboard-line:before {
  content: "\eb91";
}
.ri-clockwise-2-fill:before {
  content: "\eb92";
}
.ri-clockwise-2-line:before {
  content: "\eb93";
}
.ri-clockwise-fill:before {
  content: "\eb94";
}
.ri-clockwise-line:before {
  content: "\eb95";
}
.ri-close-circle-fill:before {
  content: "\eb96";
}
.ri-close-circle-line:before {
  content: "\eb97";
}
.ri-close-fill:before {
  content: "\eb98";
}
.ri-close-line:before {
  content: "\eb99";
}
.ri-closed-captioning-fill:before {
  content: "\eb9a";
}
.ri-closed-captioning-line:before {
  content: "\eb9b";
}
.ri-cloud-fill:before {
  content: "\eb9c";
}
.ri-cloud-line:before {
  content: "\eb9d";
}
.ri-cloud-off-fill:before {
  content: "\eb9e";
}
.ri-cloud-off-line:before {
  content: "\eb9f";
}
.ri-cloud-windy-fill:before {
  content: "\eba0";
}
.ri-cloud-windy-line:before {
  content: "\eba1";
}
.ri-cloudy-2-fill:before {
  content: "\eba2";
}
.ri-cloudy-2-line:before {
  content: "\eba3";
}
.ri-cloudy-fill:before {
  content: "\eba4";
}
.ri-cloudy-line:before {
  content: "\eba5";
}
.ri-code-box-fill:before {
  content: "\eba6";
}
.ri-code-box-line:before {
  content: "\eba7";
}
.ri-code-fill:before {
  content: "\eba8";
}
.ri-code-line:before {
  content: "\eba9";
}
.ri-code-s-fill:before {
  content: "\ebaa";
}
.ri-code-s-line:before {
  content: "\ebab";
}
.ri-code-s-slash-fill:before {
  content: "\ebac";
}
.ri-code-s-slash-line:before {
  content: "\ebad";
}
.ri-code-view:before {
  content: "\ebae";
}
.ri-code-view-line:before {
  content: "\ebae";
}
.ri-code-view-fill:before {
  content: "\ebae";
}

.ri-codepen-fill:before {
  content: "\ebaf";
}
.ri-codepen-line:before {
  content: "\ebb0";
}
.ri-coin-fill:before {
  content: "\ebb1";
}
.ri-coin-line:before {
  content: "\ebb2";
}
.ri-coins-fill:before {
  content: "\ebb3";
}
.ri-coins-line:before {
  content: "\ebb4";
}
.ri-collage-fill:before {
  content: "\ebb5";
}
.ri-collage-line:before {
  content: "\ebb6";
}
.ri-command-fill:before {
  content: "\ebb7";
}
.ri-command-line:before {
  content: "\ebb8";
}
.ri-community-fill:before {
  content: "\ebb9";
}
.ri-community-line:before {
  content: "\ebba";
}
.ri-compass-2-fill:before {
  content: "\ebbb";
}
.ri-compass-2-line:before {
  content: "\ebbc";
}
.ri-compass-3-fill:before {
  content: "\ebbd";
}
.ri-compass-3-line:before {
  content: "\ebbe";
}
.ri-compass-4-fill:before {
  content: "\ebbf";
}
.ri-compass-4-line:before {
  content: "\ebc0";
}
.ri-compass-discover-fill:before {
  content: "\ebc1";
}
.ri-compass-discover-line:before {
  content: "\ebc2";
}
.ri-compass-fill:before {
  content: "\ebc3";
}
.ri-compass-line:before {
  content: "\ebc4";
}
.ri-compasses-2-fill:before {
  content: "\ebc5";
}
.ri-compasses-2-line:before {
  content: "\ebc6";
}
.ri-compasses-fill:before {
  content: "\ebc7";
}
.ri-compasses-line:before {
  content: "\ebc8";
}
.ri-computer-fill:before {
  content: "\ebc9";
}
.ri-computer-line:before {
  content: "\ebca";
}
.ri-contacts-book-2-fill:before {
  content: "\ebcb";
}
.ri-contacts-book-2-line:before {
  content: "\ebcc";
}
.ri-contacts-book-fill:before {
  content: "\ebcd";
}
.ri-contacts-book-line:before {
  content: "\ebce";
}
.ri-contacts-book-upload-fill:before {
  content: "\ebcf";
}
.ri-contacts-book-upload-line:before {
  content: "\ebd0";
}
.ri-contacts-fill:before {
  content: "\ebd1";
}
.ri-contacts-line:before {
  content: "\ebd2";
}
.ri-contrast-2-fill:before {
  content: "\ebd3";
}
.ri-contrast-2-line:before {
  content: "\ebd4";
}
.ri-contrast-drop-2-fill:before {
  content: "\ebd5";
}
.ri-contrast-drop-2-line:before {
  content: "\ebd6";
}
.ri-contrast-drop-fill:before {
  content: "\ebd7";
}
.ri-contrast-drop-line:before {
  content: "\ebd8";
}
.ri-contrast-fill:before {
  content: "\ebd9";
}
.ri-contrast-line:before {
  content: "\ebda";
}
.ri-copper-coin-fill:before {
  content: "\ebdb";
}
.ri-copper-coin-line:before {
  content: "\ebdc";
}
.ri-copper-diamond-fill:before {
  content: "\ebdd";
}
.ri-copper-diamond-line:before {
  content: "\ebde";
}
.ri-copyleft-fill:before {
  content: "\ebdf";
}
.ri-copyleft-line:before {
  content: "\ebe0";
}
.ri-copyright-fill:before {
  content: "\ebe1";
}
.ri-copyright-line:before {
  content: "\ebe2";
}
.ri-coreos-fill:before {
  content: "\ebe3";
}
.ri-coreos-line:before {
  content: "\ebe4";
}
.ri-coupon-2-fill:before {
  content: "\ebe5";
}
.ri-coupon-2-line:before {
  content: "\ebe6";
}
.ri-coupon-3-fill:before {
  content: "\ebe7";
}
.ri-coupon-3-line:before {
  content: "\ebe8";
}
.ri-coupon-4-fill:before {
  content: "\ebe9";
}
.ri-coupon-4-line:before {
  content: "\ebea";
}
.ri-coupon-5-fill:before {
  content: "\ebeb";
}
.ri-coupon-5-line:before {
  content: "\ebec";
}
.ri-coupon-fill:before {
  content: "\ebed";
}
.ri-coupon-line:before {
  content: "\ebee";
}
.ri-cpu-fill:before {
  content: "\ebef";
}
.ri-cpu-line:before {
  content: "\ebf0";
}
.ri-creative-commons-by-fill:before {
  content: "\ebf1";
}
.ri-creative-commons-by-line:before {
  content: "\ebf2";
}
.ri-creative-commons-fill:before {
  content: "\ebf3";
}
.ri-creative-commons-line:before {
  content: "\ebf4";
}
.ri-creative-commons-nc-fill:before {
  content: "\ebf5";
}
.ri-creative-commons-nc-line:before {
  content: "\ebf6";
}
.ri-creative-commons-nd-fill:before {
  content: "\ebf7";
}
.ri-creative-commons-nd-line:before {
  content: "\ebf8";
}
.ri-creative-commons-sa-fill:before {
  content: "\ebf9";
}
.ri-creative-commons-sa-line:before {
  content: "\ebfa";
}
.ri-creative-commons-zero-fill:before {
  content: "\ebfb";
}
.ri-creative-commons-zero-line:before {
  content: "\ebfc";
}
.ri-criminal-fill:before {
  content: "\ebfd";
}
.ri-criminal-line:before {
  content: "\ebfe";
}
.ri-crop-2-fill:before {
  content: "\ebff";
}
.ri-crop-2-line:before {
  content: "\ec00";
}
.ri-crop-fill:before {
  content: "\ec01";
}
.ri-crop-line:before {
  content: "\ec02";
}
.ri-css3-fill:before {
  content: "\ec03";
}
.ri-css3-line:before {
  content: "\ec04";
}
.ri-cup-fill:before {
  content: "\ec05";
}
.ri-cup-line:before {
  content: "\ec06";
}
.ri-currency-fill:before {
  content: "\ec07";
}
.ri-currency-line:before {
  content: "\ec08";
}
.ri-cursor-fill:before {
  content: "\ec09";
}
.ri-cursor-line:before {
  content: "\ec0a";
}
.ri-customer-service-2-fill:before {
  content: "\ec0b";
}
.ri-customer-service-2-line:before {
  content: "\ec0c";
}
.ri-customer-service-fill:before {
  content: "\ec0d";
}
.ri-customer-service-line:before {
  content: "\ec0e";
}
.ri-dashboard-2-fill:before {
  content: "\ec0f";
}
.ri-dashboard-2-line:before {
  content: "\ec10";
}
.ri-dashboard-3-fill:before {
  content: "\ec11";
}
.ri-dashboard-3-line:before {
  content: "\ec12";
}
.ri-dashboard-fill:before {
  content: "\ec13";
}
.ri-dashboard-line:before {
  content: "\ec14";
}
.ri-database-2-fill:before {
  content: "\ec15";
}
.ri-database-2-line:before {
  content: "\ec16";
}
.ri-database-fill:before {
  content: "\ec17";
}
.ri-database-line:before {
  content: "\ec18";
}
.ri-delete-back-2-fill:before {
  content: "\ec19";
}
.ri-delete-back-2-line:before {
  content: "\ec1a";
}
.ri-delete-back-fill:before {
  content: "\ec1b";
}
.ri-delete-back-line:before {
  content: "\ec1c";
}
.ri-delete-bin-2-fill:before {
  content: "\ec1d";
}
.ri-delete-bin-2-line:before {
  content: "\ec1e";
}
.ri-delete-bin-3-fill:before {
  content: "\ec1f";
}
.ri-delete-bin-3-line:before {
  content: "\ec20";
}
.ri-delete-bin-4-fill:before {
  content: "\ec21";
}
.ri-delete-bin-4-line:before {
  content: "\ec22";
}
.ri-delete-bin-5-fill:before {
  content: "\ec23";
}
.ri-delete-bin-5-line:before {
  content: "\ec24";
}
.ri-delete-bin-6-fill:before {
  content: "\ec25";
}
.ri-delete-bin-6-line:before {
  content: "\ec26";
}
.ri-delete-bin-7-fill:before {
  content: "\ec27";
}
.ri-delete-bin-7-line:before {
  content: "\ec28";
}
.ri-delete-bin-fill:before {
  content: "\ec29";
}
.ri-delete-bin-line:before {
  content: "\ec2a";
}
.ri-delete-column:before {
  content: "\ec2b";
}
.ri-delete-column-line:before {
  content: "\ec2b";
}
.ri-delete-column-fill:before {
  content: "\ec2b";
}

.ri-delete-row:before {
  content: "\ec2c";
}
.ri-delete-row-line:before {
  content: "\ec2c";
}
.ri-delete-row-fill:before {
  content: "\ec2c";
}

.ri-device-fill:before {
  content: "\ec2d";
}
.ri-device-line:before {
  content: "\ec2e";
}
.ri-device-recover-fill:before {
  content: "\ec2f";
}
.ri-device-recover-line:before {
  content: "\ec30";
}
.ri-dingding-fill:before {
  content: "\ec31";
}
.ri-dingding-line:before {
  content: "\ec32";
}
.ri-direction-fill:before {
  content: "\ec33";
}
.ri-direction-line:before {
  content: "\ec34";
}
.ri-disc-fill:before {
  content: "\ec35";
}
.ri-disc-line:before {
  content: "\ec36";
}
.ri-discord-fill:before {
  content: "\ec37";
}
.ri-discord-line:before {
  content: "\ec38";
}
.ri-discuss-fill:before {
  content: "\ec39";
}
.ri-discuss-line:before {
  content: "\ec3a";
}
.ri-dislike-fill:before {
  content: "\ec3b";
}
.ri-dislike-line:before {
  content: "\ec3c";
}
.ri-disqus-fill:before {
  content: "\ec3d";
}
.ri-disqus-line:before {
  content: "\ec3e";
}
.ri-divide-fill:before {
  content: "\ec3f";
}
.ri-divide-line:before {
  content: "\ec40";
}
.ri-donut-chart-fill:before {
  content: "\ec41";
}
.ri-donut-chart-line:before {
  content: "\ec42";
}
.ri-door-closed-fill:before {
  content: "\ec43";
}
.ri-door-closed-line:before {
  content: "\ec44";
}
.ri-door-fill:before {
  content: "\ec45";
}
.ri-door-line:before {
  content: "\ec46";
}
.ri-door-lock-box-fill:before {
  content: "\ec47";
}
.ri-door-lock-box-line:before {
  content: "\ec48";
}
.ri-door-lock-fill:before {
  content: "\ec49";
}
.ri-door-lock-line:before {
  content: "\ec4a";
}
.ri-door-open-fill:before {
  content: "\ec4b";
}
.ri-door-open-line:before {
  content: "\ec4c";
}
.ri-dossier-fill:before {
  content: "\ec4d";
}
.ri-dossier-line:before {
  content: "\ec4e";
}
.ri-douban-fill:before {
  content: "\ec4f";
}
.ri-douban-line:before {
  content: "\ec50";
}
.ri-double-quotes-l:before {
  content: "\ec51";
}
.ri-double-quotes-l-line:before {
  content: "\ec51";
}
.ri-double-quotes-l-fill:before {
  content: "\ec51";
}

.ri-double-quotes-r:before {
  content: "\ec52";
}
.ri-double-quotes-r-line:before {
  content: "\ec52";
}
.ri-double-quotes-r-fill:before {
  content: "\ec52";
}

.ri-download-2-fill:before {
  content: "\ec53";
}
.ri-download-2-line:before {
  content: "\ec54";
}
.ri-download-cloud-2-fill:before {
  content: "\ec55";
}
.ri-download-cloud-2-line:before {
  content: "\ec56";
}
.ri-download-cloud-fill:before {
  content: "\ec57";
}
.ri-download-cloud-line:before {
  content: "\ec58";
}
.ri-download-fill:before {
  content: "\ec59";
}
.ri-download-line:before {
  content: "\ec5a";
}
.ri-draft-fill:before {
  content: "\ec5b";
}
.ri-draft-line:before {
  content: "\ec5c";
}
.ri-drag-drop-fill:before {
  content: "\ec5d";
}
.ri-drag-drop-line:before {
  content: "\ec5e";
}
.ri-drag-move-2-fill:before {
  content: "\ec5f";
}
.ri-drag-move-2-line:before {
  content: "\ec60";
}
.ri-drag-move-fill:before {
  content: "\ec61";
}
.ri-drag-move-line:before {
  content: "\ec62";
}
.ri-dribbble-fill:before {
  content: "\ec63";
}
.ri-dribbble-line:before {
  content: "\ec64";
}
.ri-drive-fill:before {
  content: "\ec65";
}
.ri-drive-line:before {
  content: "\ec66";
}
.ri-drizzle-fill:before {
  content: "\ec67";
}
.ri-drizzle-line:before {
  content: "\ec68";
}
.ri-drop-fill:before {
  content: "\ec69";
}
.ri-drop-line:before {
  content: "\ec6a";
}
.ri-dropbox-fill:before {
  content: "\ec6b";
}
.ri-dropbox-line:before {
  content: "\ec6c";
}
.ri-dual-sim-1-fill:before {
  content: "\ec6d";
}
.ri-dual-sim-1-line:before {
  content: "\ec6e";
}
.ri-dual-sim-2-fill:before {
  content: "\ec6f";
}
.ri-dual-sim-2-line:before {
  content: "\ec70";
}
.ri-dv-fill:before {
  content: "\ec71";
}
.ri-dv-line:before {
  content: "\ec72";
}
.ri-dvd-fill:before {
  content: "\ec73";
}
.ri-dvd-line:before {
  content: "\ec74";
}
.ri-e-bike-2-fill:before {
  content: "\ec75";
}
.ri-e-bike-2-line:before {
  content: "\ec76";
}
.ri-e-bike-fill:before {
  content: "\ec77";
}
.ri-e-bike-line:before {
  content: "\ec78";
}
.ri-earth-fill:before {
  content: "\ec79";
}
.ri-earth-line:before {
  content: "\ec7a";
}
.ri-earthquake-fill:before {
  content: "\ec7b";
}
.ri-earthquake-line:before {
  content: "\ec7c";
}
.ri-edge-fill:before {
  content: "\ec7d";
}
.ri-edge-line:before {
  content: "\ec7e";
}
.ri-edit-2-fill:before {
  content: "\ec7f";
}
.ri-edit-2-line:before {
  content: "\ec80";
}
.ri-edit-box-fill:before {
  content: "\ec81";
}
.ri-edit-box-line:before {
  content: "\ec82";
}
.ri-edit-circle-fill:before {
  content: "\ec83";
}
.ri-edit-circle-line:before {
  content: "\ec84";
}
.ri-edit-fill:before {
  content: "\ec85";
}
.ri-edit-line:before {
  content: "\ec86";
}
.ri-eject-fill:before {
  content: "\ec87";
}
.ri-eject-line:before {
  content: "\ec88";
}
.ri-emotion-2-fill:before {
  content: "\ec89";
}
.ri-emotion-2-line:before {
  content: "\ec8a";
}
.ri-emotion-fill:before {
  content: "\ec8b";
}
.ri-emotion-happy-fill:before {
  content: "\ec8c";
}
.ri-emotion-happy-line:before {
  content: "\ec8d";
}
.ri-emotion-laugh-fill:before {
  content: "\ec8e";
}
.ri-emotion-laugh-line:before {
  content: "\ec8f";
}
.ri-emotion-line:before {
  content: "\ec90";
}
.ri-emotion-normal-fill:before {
  content: "\ec91";
}
.ri-emotion-normal-line:before {
  content: "\ec92";
}
.ri-emotion-sad-fill:before {
  content: "\ec93";
}
.ri-emotion-sad-line:before {
  content: "\ec94";
}
.ri-emotion-unhappy-fill:before {
  content: "\ec95";
}
.ri-emotion-unhappy-line:before {
  content: "\ec96";
}
.ri-empathize-fill:before {
  content: "\ec97";
}
.ri-empathize-line:before {
  content: "\ec98";
}
.ri-emphasis-cn:before {
  content: "\ec99";
}
.ri-emphasis-cn-line:before {
  content: "\ec99";
}
.ri-emphasis-cn-fill:before {
  content: "\ec99";
}

.ri-emphasis:before {
  content: "\ec9a";
}
.ri-emphasis-line:before {
  content: "\ec9a";
}
.ri-emphasis-fill:before {
  content: "\ec9a";
}

.ri-english-input:before {
  content: "\ec9b";
}
.ri-english-input-line:before {
  content: "\ec9b";
}
.ri-english-input-fill:before {
  content: "\ec9b";
}

.ri-equalizer-fill:before {
  content: "\ec9c";
}
.ri-equalizer-line:before {
  content: "\ec9d";
}
.ri-eraser-fill:before {
  content: "\ec9e";
}
.ri-eraser-line:before {
  content: "\ec9f";
}
.ri-error-warning-fill:before {
  content: "\eca0";
}
.ri-error-warning-line:before {
  content: "\eca1";
}
.ri-evernote-fill:before {
  content: "\eca2";
}
.ri-evernote-line:before {
  content: "\eca3";
}
.ri-exchange-box-fill:before {
  content: "\eca4";
}
.ri-exchange-box-line:before {
  content: "\eca5";
}
.ri-exchange-cny-fill:before {
  content: "\eca6";
}
.ri-exchange-cny-line:before {
  content: "\eca7";
}
.ri-exchange-dollar-fill:before {
  content: "\eca8";
}
.ri-exchange-dollar-line:before {
  content: "\eca9";
}
.ri-exchange-fill:before {
  content: "\ecaa";
}
.ri-exchange-funds-fill:before {
  content: "\ecab";
}
.ri-exchange-funds-line:before {
  content: "\ecac";
}
.ri-exchange-line:before {
  content: "\ecad";
}
.ri-external-link-fill:before {
  content: "\ecae";
}
.ri-external-link-line:before {
  content: "\ecaf";
}
.ri-eye-2-fill:before {
  content: "\ecb0";
}
.ri-eye-2-line:before {
  content: "\ecb1";
}
.ri-eye-close-fill:before {
  content: "\ecb2";
}
.ri-eye-close-line:before {
  content: "\ecb3";
}
.ri-eye-fill:before {
  content: "\ecb4";
}
.ri-eye-line:before {
  content: "\ecb5";
}
.ri-eye-off-fill:before {
  content: "\ecb6";
}
.ri-eye-off-line:before {
  content: "\ecb7";
}
.ri-facebook-box-fill:before {
  content: "\ecb8";
}
.ri-facebook-box-line:before {
  content: "\ecb9";
}
.ri-facebook-circle-fill:before {
  content: "\ecba";
}
.ri-facebook-circle-line:before {
  content: "\ecbb";
}
.ri-facebook-fill:before {
  content: "\ecbc";
}
.ri-facebook-line:before {
  content: "\ecbd";
}
.ri-fahrenheit-fill:before {
  content: "\ecbe";
}
.ri-fahrenheit-line:before {
  content: "\ecbf";
}
.ri-feedback-fill:before {
  content: "\ecc0";
}
.ri-feedback-line:before {
  content: "\ecc1";
}
.ri-file-2-fill:before {
  content: "\ecc2";
}
.ri-file-2-line:before {
  content: "\ecc3";
}
.ri-file-3-fill:before {
  content: "\ecc4";
}
.ri-file-3-line:before {
  content: "\ecc5";
}
.ri-file-4-fill:before {
  content: "\ecc6";
}
.ri-file-4-line:before {
  content: "\ecc7";
}
.ri-file-add-fill:before {
  content: "\ecc8";
}
.ri-file-add-line:before {
  content: "\ecc9";
}
.ri-file-chart-2-fill:before {
  content: "\ecca";
}
.ri-file-chart-2-line:before {
  content: "\eccb";
}
.ri-file-chart-fill:before {
  content: "\eccc";
}
.ri-file-chart-line:before {
  content: "\eccd";
}
.ri-file-cloud-fill:before {
  content: "\ecce";
}
.ri-file-cloud-line:before {
  content: "\eccf";
}
.ri-file-code-fill:before {
  content: "\ecd0";
}
.ri-file-code-line:before {
  content: "\ecd1";
}
.ri-file-copy-2-fill:before {
  content: "\ecd2";
}
.ri-file-copy-2-line:before {
  content: "\ecd3";
}
.ri-file-copy-fill:before {
  content: "\ecd4";
}
.ri-file-copy-line:before {
  content: "\ecd5";
}
.ri-file-damage-fill:before {
  content: "\ecd6";
}
.ri-file-damage-line:before {
  content: "\ecd7";
}
.ri-file-download-fill:before {
  content: "\ecd8";
}
.ri-file-download-line:before {
  content: "\ecd9";
}
.ri-file-edit-fill:before {
  content: "\ecda";
}
.ri-file-edit-line:before {
  content: "\ecdb";
}
.ri-file-excel-2-fill:before {
  content: "\ecdc";
}
.ri-file-excel-2-line:before {
  content: "\ecdd";
}
.ri-file-excel-fill:before {
  content: "\ecde";
}
.ri-file-excel-line:before {
  content: "\ecdf";
}
.ri-file-fill:before {
  content: "\ece0";
}
.ri-file-forbid-fill:before {
  content: "\ece1";
}
.ri-file-forbid-line:before {
  content: "\ece2";
}
.ri-file-gif-fill:before {
  content: "\ece3";
}
.ri-file-gif-line:before {
  content: "\ece4";
}
.ri-file-history-fill:before {
  content: "\ece5";
}
.ri-file-history-line:before {
  content: "\ece6";
}
.ri-file-hwp-fill:before {
  content: "\ece7";
}
.ri-file-hwp-line:before {
  content: "\ece8";
}
.ri-file-info-fill:before {
  content: "\ece9";
}
.ri-file-info-line:before {
  content: "\ecea";
}
.ri-file-line:before {
  content: "\eceb";
}
.ri-file-list-2-fill:before {
  content: "\ecec";
}
.ri-file-list-2-line:before {
  content: "\eced";
}
.ri-file-list-3-fill:before {
  content: "\ecee";
}
.ri-file-list-3-line:before {
  content: "\ecef";
}
.ri-file-list-fill:before {
  content: "\ecf0";
}
.ri-file-list-line:before {
  content: "\ecf1";
}
.ri-file-lock-fill:before {
  content: "\ecf2";
}
.ri-file-lock-line:before {
  content: "\ecf3";
}
.ri-file-mark-fill:before {
  content: "\ecf4";
}
.ri-file-mark-line:before {
  content: "\ecf5";
}
.ri-file-music-fill:before {
  content: "\ecf6";
}
.ri-file-music-line:before {
  content: "\ecf7";
}
.ri-file-paper-2-fill:before {
  content: "\ecf8";
}
.ri-file-paper-2-line:before {
  content: "\ecf9";
}
.ri-file-paper-fill:before {
  content: "\ecfa";
}
.ri-file-paper-line:before {
  content: "\ecfb";
}
.ri-file-pdf-fill:before {
  content: "\ecfc";
}
.ri-file-pdf-line:before {
  content: "\ecfd";
}
.ri-file-ppt-2-fill:before {
  content: "\ecfe";
}
.ri-file-ppt-2-line:before {
  content: "\ecff";
}
.ri-file-ppt-fill:before {
  content: "\ed00";
}
.ri-file-ppt-line:before {
  content: "\ed01";
}
.ri-file-reduce-fill:before {
  content: "\ed02";
}
.ri-file-reduce-line:before {
  content: "\ed03";
}
.ri-file-search-fill:before {
  content: "\ed04";
}
.ri-file-search-line:before {
  content: "\ed05";
}
.ri-file-settings-fill:before {
  content: "\ed06";
}
.ri-file-settings-line:before {
  content: "\ed07";
}
.ri-file-shield-2-fill:before {
  content: "\ed08";
}
.ri-file-shield-2-line:before {
  content: "\ed09";
}
.ri-file-shield-fill:before {
  content: "\ed0a";
}
.ri-file-shield-line:before {
  content: "\ed0b";
}
.ri-file-shred-fill:before {
  content: "\ed0c";
}
.ri-file-shred-line:before {
  content: "\ed0d";
}
.ri-file-text-fill:before {
  content: "\ed0e";
}
.ri-file-text-line:before {
  content: "\ed0f";
}
.ri-file-transfer-fill:before {
  content: "\ed10";
}
.ri-file-transfer-line:before {
  content: "\ed11";
}
.ri-file-unknow-fill:before {
  content: "\ed12";
}
.ri-file-unknow-line:before {
  content: "\ed13";
}
.ri-file-upload-fill:before {
  content: "\ed14";
}
.ri-file-upload-line:before {
  content: "\ed15";
}
.ri-file-user-fill:before {
  content: "\ed16";
}
.ri-file-user-line:before {
  content: "\ed17";
}
.ri-file-warning-fill:before {
  content: "\ed18";
}
.ri-file-warning-line:before {
  content: "\ed19";
}
.ri-file-word-2-fill:before {
  content: "\ed1a";
}
.ri-file-word-2-line:before {
  content: "\ed1b";
}
.ri-file-word-fill:before {
  content: "\ed1c";
}
.ri-file-word-line:before {
  content: "\ed1d";
}
.ri-file-zip-fill:before {
  content: "\ed1e";
}
.ri-file-zip-line:before {
  content: "\ed1f";
}
.ri-film-fill:before {
  content: "\ed20";
}
.ri-film-line:before {
  content: "\ed21";
}
.ri-filter-2-fill:before {
  content: "\ed22";
}
.ri-filter-2-line:before {
  content: "\ed23";
}
.ri-filter-3-fill:before {
  content: "\ed24";
}
.ri-filter-3-line:before {
  content: "\ed25";
}
.ri-filter-fill:before {
  content: "\ed26";
}
.ri-filter-line:before {
  content: "\ed27";
}
.ri-filter-off-fill:before {
  content: "\ed28";
}
.ri-filter-off-line:before {
  content: "\ed29";
}
.ri-find-replace-fill:before {
  content: "\ed2a";
}
.ri-find-replace-line:before {
  content: "\ed2b";
}
.ri-finder-fill:before {
  content: "\ed2c";
}
.ri-finder-line:before {
  content: "\ed2d";
}
.ri-fingerprint-2-fill:before {
  content: "\ed2e";
}
.ri-fingerprint-2-line:before {
  content: "\ed2f";
}
.ri-fingerprint-fill:before {
  content: "\ed30";
}
.ri-fingerprint-line:before {
  content: "\ed31";
}
.ri-fire-fill:before {
  content: "\ed32";
}
.ri-fire-line:before {
  content: "\ed33";
}
.ri-firefox-fill:before {
  content: "\ed34";
}
.ri-firefox-line:before {
  content: "\ed35";
}
.ri-first-aid-kit-fill:before {
  content: "\ed36";
}
.ri-first-aid-kit-line:before {
  content: "\ed37";
}
.ri-flag-2-fill:before {
  content: "\ed38";
}
.ri-flag-2-line:before {
  content: "\ed39";
}
.ri-flag-fill:before {
  content: "\ed3a";
}
.ri-flag-line:before {
  content: "\ed3b";
}
.ri-flashlight-fill:before {
  content: "\ed3c";
}
.ri-flashlight-line:before {
  content: "\ed3d";
}
.ri-flask-fill:before {
  content: "\ed3e";
}
.ri-flask-line:before {
  content: "\ed3f";
}
.ri-flight-land-fill:before {
  content: "\ed40";
}
.ri-flight-land-line:before {
  content: "\ed41";
}
.ri-flight-takeoff-fill:before {
  content: "\ed42";
}
.ri-flight-takeoff-line:before {
  content: "\ed43";
}
.ri-flood-fill:before {
  content: "\ed44";
}
.ri-flood-line:before {
  content: "\ed45";
}
.ri-flow-chart:before {
  content: "\ed46";
}
.ri-flow-chart-line:before {
  content: "\ed46";
}
.ri-flow-chart-fill:before {
  content: "\ed46";
}

.ri-flutter-fill:before {
  content: "\ed47";
}
.ri-flutter-line:before {
  content: "\ed48";
}
.ri-focus-2-fill:before {
  content: "\ed49";
}
.ri-focus-2-line:before {
  content: "\ed4a";
}
.ri-focus-3-fill:before {
  content: "\ed4b";
}
.ri-focus-3-line:before {
  content: "\ed4c";
}
.ri-focus-fill:before {
  content: "\ed4d";
}
.ri-focus-line:before {
  content: "\ed4e";
}
.ri-foggy-fill:before {
  content: "\ed4f";
}
.ri-foggy-line:before {
  content: "\ed50";
}
.ri-folder-2-fill:before {
  content: "\ed51";
}
.ri-folder-2-line:before {
  content: "\ed52";
}
.ri-folder-3-fill:before {
  content: "\ed53";
}
.ri-folder-3-line:before {
  content: "\ed54";
}
.ri-folder-4-fill:before {
  content: "\ed55";
}
.ri-folder-4-line:before {
  content: "\ed56";
}
.ri-folder-5-fill:before {
  content: "\ed57";
}
.ri-folder-5-line:before {
  content: "\ed58";
}
.ri-folder-add-fill:before {
  content: "\ed59";
}
.ri-folder-add-line:before {
  content: "\ed5a";
}
.ri-folder-chart-2-fill:before {
  content: "\ed5b";
}
.ri-folder-chart-2-line:before {
  content: "\ed5c";
}
.ri-folder-chart-fill:before {
  content: "\ed5d";
}
.ri-folder-chart-line:before {
  content: "\ed5e";
}
.ri-folder-download-fill:before {
  content: "\ed5f";
}
.ri-folder-download-line:before {
  content: "\ed60";
}
.ri-folder-fill:before {
  content: "\ed61";
}
.ri-folder-forbid-fill:before {
  content: "\ed62";
}
.ri-folder-forbid-line:before {
  content: "\ed63";
}
.ri-folder-history-fill:before {
  content: "\ed64";
}
.ri-folder-history-line:before {
  content: "\ed65";
}
.ri-folder-info-fill:before {
  content: "\ed66";
}
.ri-folder-info-line:before {
  content: "\ed67";
}
.ri-folder-keyhole-fill:before {
  content: "\ed68";
}
.ri-folder-keyhole-line:before {
  content: "\ed69";
}
.ri-folder-line:before {
  content: "\ed6a";
}
.ri-folder-lock-fill:before {
  content: "\ed6b";
}
.ri-folder-lock-line:before {
  content: "\ed6c";
}
.ri-folder-music-fill:before {
  content: "\ed6d";
}
.ri-folder-music-line:before {
  content: "\ed6e";
}
.ri-folder-open-fill:before {
  content: "\ed6f";
}
.ri-folder-open-line:before {
  content: "\ed70";
}
.ri-folder-received-fill:before {
  content: "\ed71";
}
.ri-folder-received-line:before {
  content: "\ed72";
}
.ri-folder-reduce-fill:before {
  content: "\ed73";
}
.ri-folder-reduce-line:before {
  content: "\ed74";
}
.ri-folder-settings-fill:before {
  content: "\ed75";
}
.ri-folder-settings-line:before {
  content: "\ed76";
}
.ri-folder-shared-fill:before {
  content: "\ed77";
}
.ri-folder-shared-line:before {
  content: "\ed78";
}
.ri-folder-shield-2-fill:before {
  content: "\ed79";
}
.ri-folder-shield-2-line:before {
  content: "\ed7a";
}
.ri-folder-shield-fill:before {
  content: "\ed7b";
}
.ri-folder-shield-line:before {
  content: "\ed7c";
}
.ri-folder-transfer-fill:before {
  content: "\ed7d";
}
.ri-folder-transfer-line:before {
  content: "\ed7e";
}
.ri-folder-unknow-fill:before {
  content: "\ed7f";
}
.ri-folder-unknow-line:before {
  content: "\ed80";
}
.ri-folder-upload-fill:before {
  content: "\ed81";
}
.ri-folder-upload-line:before {
  content: "\ed82";
}
.ri-folder-user-fill:before {
  content: "\ed83";
}
.ri-folder-user-line:before {
  content: "\ed84";
}
.ri-folder-warning-fill:before {
  content: "\ed85";
}
.ri-folder-warning-line:before {
  content: "\ed86";
}
.ri-folder-zip-fill:before {
  content: "\ed87";
}
.ri-folder-zip-line:before {
  content: "\ed88";
}
.ri-folders-fill:before {
  content: "\ed89";
}
.ri-folders-line:before {
  content: "\ed8a";
}
.ri-font-color:before {
  content: "\ed8b";
}
.ri-font-color-line:before {
  content: "\ed8b";
}
.ri-font-color-fill:before {
  content: "\ed8b";
}

.ri-font-size-2:before {
  content: "\ed8c";
}
.ri-font-size-2-line:before {
  content: "\ed8c";
}
.ri-font-size-2-fill:before {
  content: "\ed8c";
}

.ri-font-size:before {
  content: "\ed8d";
}
.ri-font-size-line:before {
  content: "\ed8d";
}
.ri-font-size-fill:before {
  content: "\ed8d";
}

.ri-football-fill:before {
  content: "\ed8e";
}
.ri-football-line:before {
  content: "\ed8f";
}
.ri-footprint-fill:before {
  content: "\ed90";
}
.ri-footprint-line:before {
  content: "\ed91";
}
.ri-forbid-2-fill:before {
  content: "\ed92";
}
.ri-forbid-2-line:before {
  content: "\ed93";
}
.ri-forbid-fill:before {
  content: "\ed94";
}
.ri-forbid-line:before {
  content: "\ed95";
}
.ri-format-clear:before {
  content: "\ed96";
}
.ri-format-clear-line:before {
  content: "\ed96";
}
.ri-format-clear-fill:before {
  content: "\ed96";
}

.ri-fridge-fill:before {
  content: "\ed97";
}
.ri-fridge-line:before {
  content: "\ed98";
}
.ri-fullscreen-exit-fill:before {
  content: "\ed99";
}
.ri-fullscreen-exit-line:before {
  content: "\ed9a";
}
.ri-fullscreen-fill:before {
  content: "\ed9b";
}
.ri-fullscreen-line:before {
  content: "\ed9c";
}
.ri-function-fill:before {
  content: "\ed9d";
}
.ri-function-line:before {
  content: "\ed9e";
}
.ri-functions:before {
  content: "\ed9f";
}
.ri-functions-line:before {
  content: "\ed9f";
}
.ri-functions-fill:before {
  content: "\ed9f";
}

.ri-funds-box-fill:before {
  content: "\eda0";
}
.ri-funds-box-line:before {
  content: "\eda1";
}
.ri-funds-fill:before {
  content: "\eda2";
}
.ri-funds-line:before {
  content: "\eda3";
}
.ri-gallery-fill:before {
  content: "\eda4";
}
.ri-gallery-line:before {
  content: "\eda5";
}
.ri-gallery-upload-fill:before {
  content: "\eda6";
}
.ri-gallery-upload-line:before {
  content: "\eda7";
}
.ri-game-fill:before {
  content: "\eda8";
}
.ri-game-line:before {
  content: "\eda9";
}
.ri-gamepad-fill:before {
  content: "\edaa";
}
.ri-gamepad-line:before {
  content: "\edab";
}
.ri-gas-station-fill:before {
  content: "\edac";
}
.ri-gas-station-line:before {
  content: "\edad";
}
.ri-gatsby-fill:before {
  content: "\edae";
}
.ri-gatsby-line:before {
  content: "\edaf";
}
.ri-genderless-fill:before {
  content: "\edb0";
}
.ri-genderless-line:before {
  content: "\edb1";
}
.ri-ghost-2-fill:before {
  content: "\edb2";
}
.ri-ghost-2-line:before {
  content: "\edb3";
}
.ri-ghost-fill:before {
  content: "\edb4";
}
.ri-ghost-line:before {
  content: "\edb5";
}
.ri-ghost-smile-fill:before {
  content: "\edb6";
}
.ri-ghost-smile-line:before {
  content: "\edb7";
}
.ri-gift-2-fill:before {
  content: "\edb8";
}
.ri-gift-2-line:before {
  content: "\edb9";
}
.ri-gift-fill:before {
  content: "\edba";
}
.ri-gift-line:before {
  content: "\edbb";
}
.ri-git-branch-fill:before {
  content: "\edbc";
}
.ri-git-branch-line:before {
  content: "\edbd";
}
.ri-git-commit-fill:before {
  content: "\edbe";
}
.ri-git-commit-line:before {
  content: "\edbf";
}
.ri-git-merge-fill:before {
  content: "\edc0";
}
.ri-git-merge-line:before {
  content: "\edc1";
}
.ri-git-pull-request-fill:before {
  content: "\edc2";
}
.ri-git-pull-request-line:before {
  content: "\edc3";
}
.ri-git-repository-commits-fill:before {
  content: "\edc4";
}
.ri-git-repository-commits-line:before {
  content: "\edc5";
}
.ri-git-repository-fill:before {
  content: "\edc6";
}
.ri-git-repository-line:before {
  content: "\edc7";
}
.ri-git-repository-private-fill:before {
  content: "\edc8";
}
.ri-git-repository-private-line:before {
  content: "\edc9";
}
.ri-github-fill:before {
  content: "\edca";
}
.ri-github-line:before {
  content: "\edcb";
}
.ri-gitlab-fill:before {
  content: "\edcc";
}
.ri-gitlab-line:before {
  content: "\edcd";
}
.ri-global-fill:before {
  content: "\edce";
}
.ri-global-line:before {
  content: "\edcf";
}
.ri-globe-fill:before {
  content: "\edd0";
}
.ri-globe-line:before {
  content: "\edd1";
}
.ri-goblet-fill:before {
  content: "\edd2";
}
.ri-goblet-line:before {
  content: "\edd3";
}
.ri-google-fill:before {
  content: "\edd4";
}
.ri-google-line:before {
  content: "\edd5";
}
.ri-google-play-fill:before {
  content: "\edd6";
}
.ri-google-play-line:before {
  content: "\edd7";
}
.ri-government-fill:before {
  content: "\edd8";
}
.ri-government-line:before {
  content: "\edd9";
}
.ri-gps-fill:before {
  content: "\edda";
}
.ri-gps-line:before {
  content: "\eddb";
}
.ri-gradienter-fill:before {
  content: "\eddc";
}
.ri-gradienter-line:before {
  content: "\eddd";
}
.ri-grid-fill:before {
  content: "\edde";
}
.ri-grid-line:before {
  content: "\eddf";
}
.ri-group-2-fill:before {
  content: "\ede0";
}
.ri-group-2-line:before {
  content: "\ede1";
}
.ri-group-fill:before {
  content: "\ede2";
}
.ri-group-line:before {
  content: "\ede3";
}
.ri-guide-fill:before {
  content: "\ede4";
}
.ri-guide-line:before {
  content: "\ede5";
}
.ri-h-1:before {
  content: "\ede6";
}
.ri-h-2:before {
  content: "\ede7";
}
.ri-h-3:before {
  content: "\ede8";
}
.ri-h-4:before {
  content: "\ede9";
}
.ri-h-5:before {
  content: "\edea";
}
.ri-h-6:before {
  content: "\edeb";
}
.ri-h-1-line:before {
  content: "\ede6";
}
.ri-h-2-line:before {
  content: "\ede7";
}
.ri-h-3-line:before {
  content: "\ede8";
}
.ri-h-4-line:before {
  content: "\ede9";
}
.ri-h-5-line:before {
  content: "\edea";
}
.ri-h-6-line:before {
  content: "\edeb";
}
.ri-h-1-fill:before {
  content: "\ede6";
}
.ri-h-2-fill:before {
  content: "\ede7";
}
.ri-h-3-fill:before {
  content: "\ede8";
}
.ri-h-4-fill:before {
  content: "\ede9";
}
.ri-h-5-fill:before {
  content: "\edea";
}
.ri-h-6-fill:before {
  content: "\edeb";
}
.ri-hail-fill:before {
  content: "\edec";
}
.ri-hail-line:before {
  content: "\eded";
}
.ri-hammer-fill:before {
  content: "\edee";
}
.ri-hammer-line:before {
  content: "\edef";
}
.ri-hand-coin-fill:before {
  content: "\edf0";
}
.ri-hand-coin-line:before {
  content: "\edf1";
}
.ri-hand-heart-fill:before {
  content: "\edf2";
}
.ri-hand-heart-line:before {
  content: "\edf3";
}
.ri-hand-sanitizer-fill:before {
  content: "\edf4";
}
.ri-hand-sanitizer-line:before {
  content: "\edf5";
}
.ri-handbag-fill:before {
  content: "\edf6";
}
.ri-handbag-line:before {
  content: "\edf7";
}
.ri-hard-drive-2-fill:before {
  content: "\edf8";
}
.ri-hard-drive-2-line:before {
  content: "\edf9";
}
.ri-hard-drive-fill:before {
  content: "\edfa";
}
.ri-hard-drive-line:before {
  content: "\edfb";
}
.ri-hashtag:before {
  content: "\edfc";
}
.ri-hashtag-line:before {
  content: "\edfc";
}
.ri-hashtag-fill:before {
  content: "\edfc";
}

.ri-haze-2-fill:before {
  content: "\edfd";
}
.ri-haze-2-line:before {
  content: "\edfe";
}
.ri-haze-fill:before {
  content: "\edff";
}
.ri-haze-line:before {
  content: "\ee00";
}
.ri-hd-fill:before {
  content: "\ee01";
}
.ri-hd-line:before {
  content: "\ee02";
}
.ri-heading:before {
  content: "\ee03";
}
.ri-heading-line:before {
  content: "\ee03";
}
.ri-heading-fill:before {
  content: "\ee03";
}

.ri-headphone-fill:before {
  content: "\ee04";
}
.ri-headphone-line:before {
  content: "\ee05";
}
.ri-health-book-fill:before {
  content: "\ee06";
}
.ri-health-book-line:before {
  content: "\ee07";
}
.ri-heart-2-fill:before {
  content: "\ee08";
}
.ri-heart-2-line:before {
  content: "\ee09";
}
.ri-heart-3-fill:before {
  content: "\ee0a";
}
.ri-heart-3-line:before {
  content: "\ee0b";
}
.ri-heart-add-fill:before {
  content: "\ee0c";
}
.ri-heart-add-line:before {
  content: "\ee0d";
}
.ri-heart-fill:before {
  content: "\ee0e";
}
.ri-heart-line:before {
  content: "\ee0f";
}
.ri-heart-pulse-fill:before {
  content: "\ee10";
}
.ri-heart-pulse-line:before {
  content: "\ee11";
}
.ri-hearts-fill:before {
  content: "\ee12";
}
.ri-hearts-line:before {
  content: "\ee13";
}
.ri-heavy-showers-fill:before {
  content: "\ee14";
}
.ri-heavy-showers-line:before {
  content: "\ee15";
}
.ri-history-fill:before {
  content: "\ee16";
}
.ri-history-line:before {
  content: "\ee17";
}
.ri-home-2-fill:before {
  content: "\ee18";
}
.ri-home-2-line:before {
  content: "\ee19";
}
.ri-home-3-fill:before {
  content: "\ee1a";
}
.ri-home-3-line:before {
  content: "\ee1b";
}
.ri-home-4-fill:before {
  content: "\ee1c";
}
.ri-home-4-line:before {
  content: "\ee1d";
}
.ri-home-5-fill:before {
  content: "\ee1e";
}
.ri-home-5-line:before {
  content: "\ee1f";
}
.ri-home-6-fill:before {
  content: "\ee20";
}
.ri-home-6-line:before {
  content: "\ee21";
}
.ri-home-7-fill:before {
  content: "\ee22";
}
.ri-home-7-line:before {
  content: "\ee23";
}
.ri-home-8-fill:before {
  content: "\ee24";
}
.ri-home-8-line:before {
  content: "\ee25";
}
.ri-home-fill:before {
  content: "\ee26";
}
.ri-home-gear-fill:before {
  content: "\ee27";
}
.ri-home-gear-line:before {
  content: "\ee28";
}
.ri-home-heart-fill:before {
  content: "\ee29";
}
.ri-home-heart-line:before {
  content: "\ee2a";
}
.ri-home-line:before {
  content: "\ee2b";
}
.ri-home-smile-2-fill:before {
  content: "\ee2c";
}
.ri-home-smile-2-line:before {
  content: "\ee2d";
}
.ri-home-smile-fill:before {
  content: "\ee2e";
}
.ri-home-smile-line:before {
  content: "\ee2f";
}
.ri-home-wifi-fill:before {
  content: "\ee30";
}
.ri-home-wifi-line:before {
  content: "\ee31";
}
.ri-honor-of-kings-fill:before {
  content: "\ee32";
}
.ri-honor-of-kings-line:before {
  content: "\ee33";
}
.ri-honour-fill:before {
  content: "\ee34";
}
.ri-honour-line:before {
  content: "\ee35";
}
.ri-hospital-fill:before {
  content: "\ee36";
}
.ri-hospital-line:before {
  content: "\ee37";
}
.ri-hotel-bed-fill:before {
  content: "\ee38";
}
.ri-hotel-bed-line:before {
  content: "\ee39";
}
.ri-hotel-fill:before {
  content: "\ee3a";
}
.ri-hotel-line:before {
  content: "\ee3b";
}
.ri-hotspot-fill:before {
  content: "\ee3c";
}
.ri-hotspot-line:before {
  content: "\ee3d";
}
.ri-hq-fill:before {
  content: "\ee3e";
}
.ri-hq-line:before {
  content: "\ee3f";
}
.ri-html5-fill:before {
  content: "\ee40";
}
.ri-html5-line:before {
  content: "\ee41";
}
.ri-ie-fill:before {
  content: "\ee42";
}
.ri-ie-line:before {
  content: "\ee43";
}
.ri-image-2-fill:before {
  content: "\ee44";
}
.ri-image-2-line:before {
  content: "\ee45";
}
.ri-image-add-fill:before {
  content: "\ee46";
}
.ri-image-add-line:before {
  content: "\ee47";
}
.ri-image-edit-fill:before {
  content: "\ee48";
}
.ri-image-edit-line:before {
  content: "\ee49";
}
.ri-image-fill:before {
  content: "\ee4a";
}
.ri-image-line:before {
  content: "\ee4b";
}
.ri-inbox-archive-fill:before {
  content: "\ee4c";
}
.ri-inbox-archive-line:before {
  content: "\ee4d";
}
.ri-inbox-fill:before {
  content: "\ee4e";
}
.ri-inbox-line:before {
  content: "\ee4f";
}
.ri-inbox-unarchive-fill:before {
  content: "\ee50";
}
.ri-inbox-unarchive-line:before {
  content: "\ee51";
}
.ri-increase-decrease-fill:before {
  content: "\ee52";
}
.ri-increase-decrease-line:before {
  content: "\ee53";
}
.ri-indent-decrease:before {
  content: "\ee54";
}
.ri-indent-decrease-line:before {
  content: "\ee54";
}
.ri-indent-decrease-fill:before {
  content: "\ee54";
}

.ri-indent-increase:before {
  content: "\ee55";
}
.ri-indent-increase-line:before {
  content: "\ee55";
}
.ri-indent-increase-fill:before {
  content: "\ee55";
}

.ri-indeterminate-circle-fill:before {
  content: "\ee56";
}
.ri-indeterminate-circle-line:before {
  content: "\ee57";
}
.ri-information-fill:before {
  content: "\ee58";
}
.ri-information-line:before {
  content: "\ee59";
}
.ri-infrared-thermometer-fill:before {
  content: "\ee5a";
}
.ri-infrared-thermometer-line:before {
  content: "\ee5b";
}
.ri-ink-bottle-fill:before {
  content: "\ee5c";
}
.ri-ink-bottle-line:before {
  content: "\ee5d";
}
.ri-input-cursor-move:before {
  content: "\ee5e";
}
.ri-input-cursor-move-line:before {
  content: "\ee5e";
}
.ri-input-cursor-move-fill:before {
  content: "\ee5e";
}

.ri-input-method-fill:before {
  content: "\ee5f";
}
.ri-input-method-line:before {
  content: "\ee60";
}
.ri-insert-column-left:before {
  content: "\ee61";
}
.ri-insert-column-left-line:before {
  content: "\ee61";
}
.ri-insert-column-left-fill:before {
  content: "\ee61";
}

.ri-insert-column-right:before {
  content: "\ee62";
}
.ri-insert-column-right-line:before {
  content: "\ee62";
}
.ri-insert-column-right-fill:before {
  content: "\ee62";
}

.ri-insert-row-bottom:before {
  content: "\ee63";
}
.ri-insert-row-bottom-line:before {
  content: "\ee63";
}
.ri-insert-row-bottom-fill:before {
  content: "\ee63";
}

.ri-insert-row-top:before {
  content: "\ee64";
}
.ri-insert-row-top-line:before {
  content: "\ee64";
}
.ri-insert-row-top-fill:before {
  content: "\ee64";
}

.ri-instagram-fill:before {
  content: "\ee65";
}
.ri-instagram-line:before {
  content: "\ee66";
}
.ri-install-fill:before {
  content: "\ee67";
}
.ri-install-line:before {
  content: "\ee68";
}
.ri-invision-fill:before {
  content: "\ee69";
}
.ri-invision-line:before {
  content: "\ee6a";
}
.ri-italic:before {
  content: "\ee6b";
}
.ri-italic-line:before {
  content: "\ee6b";
}
.ri-italic-fill:before {
  content: "\ee6b";
}

.ri-kakao-talk-fill:before {
  content: "\ee6c";
}
.ri-kakao-talk-line:before {
  content: "\ee6d";
}
.ri-key-2-fill:before {
  content: "\ee6e";
}
.ri-key-2-line:before {
  content: "\ee6f";
}
.ri-key-fill:before {
  content: "\ee70";
}
.ri-key-line:before {
  content: "\ee71";
}
.ri-keyboard-box-fill:before {
  content: "\ee72";
}
.ri-keyboard-box-line:before {
  content: "\ee73";
}
.ri-keyboard-fill:before {
  content: "\ee74";
}
.ri-keyboard-line:before {
  content: "\ee75";
}
.ri-keynote-fill:before {
  content: "\ee76";
}
.ri-keynote-line:before {
  content: "\ee77";
}
.ri-knife-blood-fill:before {
  content: "\ee78";
}
.ri-knife-blood-line:before {
  content: "\ee79";
}
.ri-knife-fill:before {
  content: "\ee7a";
}
.ri-knife-line:before {
  content: "\ee7b";
}
.ri-landscape-fill:before {
  content: "\ee7c";
}
.ri-landscape-line:before {
  content: "\ee7d";
}
.ri-layout-2-fill:before {
  content: "\ee7e";
}
.ri-layout-2-line:before {
  content: "\ee7f";
}
.ri-layout-3-fill:before {
  content: "\ee80";
}
.ri-layout-3-line:before {
  content: "\ee81";
}
.ri-layout-4-fill:before {
  content: "\ee82";
}
.ri-layout-4-line:before {
  content: "\ee83";
}
.ri-layout-5-fill:before {
  content: "\ee84";
}
.ri-layout-5-line:before {
  content: "\ee85";
}
.ri-layout-6-fill:before {
  content: "\ee86";
}
.ri-layout-6-line:before {
  content: "\ee87";
}
.ri-layout-bottom-2-fill:before {
  content: "\ee88";
}
.ri-layout-bottom-2-line:before {
  content: "\ee89";
}
.ri-layout-bottom-fill:before {
  content: "\ee8a";
}
.ri-layout-bottom-line:before {
  content: "\ee8b";
}
.ri-layout-column-fill:before {
  content: "\ee8c";
}
.ri-layout-column-line:before {
  content: "\ee8d";
}
.ri-layout-fill:before {
  content: "\ee8e";
}
.ri-layout-grid-fill:before {
  content: "\ee8f";
}
.ri-layout-grid-line:before {
  content: "\ee90";
}
.ri-layout-left-2-fill:before {
  content: "\ee91";
}
.ri-layout-left-2-line:before {
  content: "\ee92";
}
.ri-layout-left-fill:before {
  content: "\ee93";
}
.ri-layout-left-line:before {
  content: "\ee94";
}
.ri-layout-line:before {
  content: "\ee95";
}
.ri-layout-masonry-fill:before {
  content: "\ee96";
}
.ri-layout-masonry-line:before {
  content: "\ee97";
}
.ri-layout-right-2-fill:before {
  content: "\ee98";
}
.ri-layout-right-2-line:before {
  content: "\ee99";
}
.ri-layout-right-fill:before {
  content: "\ee9a";
}
.ri-layout-right-line:before {
  content: "\ee9b";
}
.ri-layout-row-fill:before {
  content: "\ee9c";
}
.ri-layout-row-line:before {
  content: "\ee9d";
}
.ri-layout-top-2-fill:before {
  content: "\ee9e";
}
.ri-layout-top-2-line:before {
  content: "\ee9f";
}
.ri-layout-top-fill:before {
  content: "\eea0";
}
.ri-layout-top-line:before {
  content: "\eea1";
}
.ri-leaf-fill:before {
  content: "\eea2";
}
.ri-leaf-line:before {
  content: "\eea3";
}
.ri-lifebuoy-fill:before {
  content: "\eea4";
}
.ri-lifebuoy-line:before {
  content: "\eea5";
}
.ri-lightbulb-fill:before {
  content: "\eea6";
}
.ri-lightbulb-flash-fill:before {
  content: "\eea7";
}
.ri-lightbulb-flash-line:before {
  content: "\eea8";
}
.ri-lightbulb-line:before {
  content: "\eea9";
}
.ri-line-chart-fill:before {
  content: "\eeaa";
}
.ri-line-chart-line:before {
  content: "\eeab";
}
.ri-line-fill:before {
  content: "\eeac";
}
.ri-line-height:before {
  content: "\eead";
}
.ri-line-height-line:before {
  content: "\eead";
}
.ri-line-height-fill:before {
  content: "\eead";
}
.ri-line-line:before {
  content: "\eeae";
}
.ri-link-m:before {
  content: "\eeaf";
}
.ri-link-m-line:before {
  content: "\eeaf";
}
.ri-link-m-fill:before {
  content: "\eeaf";
}
.ri-link-unlink-m:before {
  content: "\eeb0";
}
.ri-link-unlink-m-line:before {
  content: "\eeb0";
}
.ri-link-unlink-m-fill:before {
  content: "\eeb0";
}
.ri-link-unlink:before {
  content: "\eeb1";
}
.ri-link-unlink-line:before {
  content: "\eeb1";
}
.ri-link-unlink-fill:before {
  content: "\eeb1";
}
.ri-link:before {
  content: "\eeb2";
}
.ri-link-line:before {
  content: "\eeb2";
}
.ri-link-fill:before {
  content: "\eeb2";
}
.ri-linkedin-box-fill:before {
  content: "\eeb3";
}
.ri-linkedin-box-line:before {
  content: "\eeb4";
}
.ri-linkedin-fill:before {
  content: "\eeb5";
}
.ri-linkedin-line:before {
  content: "\eeb6";
}
.ri-links-fill:before {
  content: "\eeb7";
}
.ri-links-line:before {
  content: "\eeb8";
}
.ri-list-check-2:before {
  content: "\eeb9";
}
.ri-list-check-2-line:before {
  content: "\eeb9";
}
.ri-list-check-2-fill:before {
  content: "\eeb9";
}
.ri-list-check:before {
  content: "\eeba";
}
.ri-list-check-line:before {
  content: "\eeba";
}
.ri-list-check-fill:before {
  content: "\eeba";
}
.ri-list-ordered:before {
  content: "\eebb";
}
.ri-list-ordered-line:before {
  content: "\eebb";
}
.ri-list-ordered-fill:before {
  content: "\eebb";
}
.ri-list-settings-fill:before {
  content: "\eebc";
}
.ri-list-settings-line:before {
  content: "\eebd";
}
.ri-list-unordered:before {
  content: "\eebe";
}
.ri-list-unordered-line:before {
  content: "\eebe";
}
.ri-list-unordered-fill:before {
  content: "\eebe";
}
.ri-live-fill:before {
  content: "\eebf";
}
.ri-live-line:before {
  content: "\eec0";
}
.ri-loader-2-fill:before {
  content: "\eec1";
}
.ri-loader-2-line:before {
  content: "\eec2";
}
.ri-loader-3-fill:before {
  content: "\eec3";
}
.ri-loader-3-line:before {
  content: "\eec4";
}
.ri-loader-4-fill:before {
  content: "\eec5";
}
.ri-loader-4-line:before {
  content: "\eec6";
}
.ri-loader-5-fill:before {
  content: "\eec7";
}
.ri-loader-5-line:before {
  content: "\eec8";
}
.ri-loader-fill:before {
  content: "\eec9";
}
.ri-loader-line:before {
  content: "\eeca";
}
.ri-lock-2-fill:before {
  content: "\eecb";
}
.ri-lock-2-line:before {
  content: "\eecc";
}
.ri-lock-fill:before {
  content: "\eecd";
}
.ri-lock-line:before {
  content: "\eece";
}
.ri-lock-password-fill:before {
  content: "\eecf";
}
.ri-lock-password-line:before {
  content: "\eed0";
}
.ri-lock-unlock-fill:before {
  content: "\eed1";
}
.ri-lock-unlock-line:before {
  content: "\eed2";
}
.ri-login-box-fill:before {
  content: "\eed3";
}
.ri-login-box-line:before {
  content: "\eed4";
}
.ri-login-circle-fill:before {
  content: "\eed5";
}
.ri-login-circle-line:before {
  content: "\eed6";
}
.ri-logout-box-fill:before {
  content: "\eed7";
}
.ri-logout-box-line:before {
  content: "\eed8";
}
.ri-logout-box-r-fill:before {
  content: "\eed9";
}
.ri-logout-box-r-line:before {
  content: "\eeda";
}
.ri-logout-circle-fill:before {
  content: "\eedb";
}
.ri-logout-circle-line:before {
  content: "\eedc";
}
.ri-logout-circle-r-fill:before {
  content: "\eedd";
}
.ri-logout-circle-r-line:before {
  content: "\eede";
}
.ri-luggage-cart-fill:before {
  content: "\eedf";
}
.ri-luggage-cart-line:before {
  content: "\eee0";
}
.ri-luggage-deposit-fill:before {
  content: "\eee1";
}
.ri-luggage-deposit-line:before {
  content: "\eee2";
}
.ri-lungs-fill:before {
  content: "\eee3";
}
.ri-lungs-line:before {
  content: "\eee4";
}
.ri-mac-fill:before {
  content: "\eee5";
}
.ri-mac-line:before {
  content: "\eee6";
}
.ri-macbook-fill:before {
  content: "\eee7";
}
.ri-macbook-line:before {
  content: "\eee8";
}
.ri-magic-fill:before {
  content: "\eee9";
}
.ri-magic-line:before {
  content: "\eeea";
}
.ri-mail-add-fill:before {
  content: "\eeeb";
}
.ri-mail-add-line:before {
  content: "\eeec";
}
.ri-mail-check-fill:before {
  content: "\eeed";
}
.ri-mail-check-line:before {
  content: "\eeee";
}
.ri-mail-close-fill:before {
  content: "\eeef";
}
.ri-mail-close-line:before {
  content: "\eef0";
}
.ri-mail-download-fill:before {
  content: "\eef1";
}
.ri-mail-download-line:before {
  content: "\eef2";
}
.ri-mail-fill:before {
  content: "\eef3";
}
.ri-mail-forbid-fill:before {
  content: "\eef4";
}
.ri-mail-forbid-line:before {
  content: "\eef5";
}
.ri-mail-line:before {
  content: "\eef6";
}
.ri-mail-lock-fill:before {
  content: "\eef7";
}
.ri-mail-lock-line:before {
  content: "\eef8";
}
.ri-mail-open-fill:before {
  content: "\eef9";
}
.ri-mail-open-line:before {
  content: "\eefa";
}
.ri-mail-send-fill:before {
  content: "\eefb";
}
.ri-mail-send-line:before {
  content: "\eefc";
}
.ri-mail-settings-fill:before {
  content: "\eefd";
}
.ri-mail-settings-line:before {
  content: "\eefe";
}
.ri-mail-star-fill:before {
  content: "\eeff";
}
.ri-mail-star-line:before {
  content: "\ef00";
}
.ri-mail-unread-fill:before {
  content: "\ef01";
}
.ri-mail-unread-line:before {
  content: "\ef02";
}
.ri-mail-volume-fill:before {
  content: "\ef03";
}
.ri-mail-volume-line:before {
  content: "\ef04";
}
.ri-map-2-fill:before {
  content: "\ef05";
}
.ri-map-2-line:before {
  content: "\ef06";
}
.ri-map-fill:before {
  content: "\ef07";
}
.ri-map-line:before {
  content: "\ef08";
}
.ri-map-pin-2-fill:before {
  content: "\ef09";
}
.ri-map-pin-2-line:before {
  content: "\ef0a";
}
.ri-map-pin-3-fill:before {
  content: "\ef0b";
}
.ri-map-pin-3-line:before {
  content: "\ef0c";
}
.ri-map-pin-4-fill:before {
  content: "\ef0d";
}
.ri-map-pin-4-line:before {
  content: "\ef0e";
}
.ri-map-pin-5-fill:before {
  content: "\ef0f";
}
.ri-map-pin-5-line:before {
  content: "\ef10";
}
.ri-map-pin-add-fill:before {
  content: "\ef11";
}
.ri-map-pin-add-line:before {
  content: "\ef12";
}
.ri-map-pin-fill:before {
  content: "\ef13";
}
.ri-map-pin-line:before {
  content: "\ef14";
}
.ri-map-pin-range-fill:before {
  content: "\ef15";
}
.ri-map-pin-range-line:before {
  content: "\ef16";
}
.ri-map-pin-time-fill:before {
  content: "\ef17";
}
.ri-map-pin-time-line:before {
  content: "\ef18";
}
.ri-map-pin-user-fill:before {
  content: "\ef19";
}
.ri-map-pin-user-line:before {
  content: "\ef1a";
}
.ri-mark-pen-fill:before {
  content: "\ef1b";
}
.ri-mark-pen-line:before {
  content: "\ef1c";
}
.ri-markdown-fill:before {
  content: "\ef1d";
}
.ri-markdown-line:before {
  content: "\ef1e";
}
.ri-markup-fill:before {
  content: "\ef1f";
}
.ri-markup-line:before {
  content: "\ef20";
}
.ri-mastercard-fill:before {
  content: "\ef21";
}
.ri-mastercard-line:before {
  content: "\ef22";
}
.ri-mastodon-fill:before {
  content: "\ef23";
}
.ri-mastodon-line:before {
  content: "\ef24";
}
.ri-medal-2-fill:before {
  content: "\ef25";
}
.ri-medal-2-line:before {
  content: "\ef26";
}
.ri-medal-fill:before {
  content: "\ef27";
}
.ri-medal-line:before {
  content: "\ef28";
}
.ri-medicine-bottle-fill:before {
  content: "\ef29";
}
.ri-medicine-bottle-line:before {
  content: "\ef2a";
}
.ri-medium-fill:before {
  content: "\ef2b";
}
.ri-medium-line:before {
  content: "\ef2c";
}
.ri-men-fill:before {
  content: "\ef2d";
}
.ri-men-line:before {
  content: "\ef2e";
}
.ri-mental-health-fill:before {
  content: "\ef2f";
}
.ri-mental-health-line:before {
  content: "\ef30";
}
.ri-menu-2-fill:before {
  content: "\ef31";
}
.ri-menu-2-line:before {
  content: "\ef32";
}
.ri-menu-3-fill:before {
  content: "\ef33";
}
.ri-menu-3-line:before {
  content: "\ef34";
}
.ri-menu-4-fill:before {
  content: "\ef35";
}
.ri-menu-4-line:before {
  content: "\ef36";
}
.ri-menu-5-fill:before {
  content: "\ef37";
}
.ri-menu-5-line:before {
  content: "\ef38";
}
.ri-menu-add-fill:before {
  content: "\ef39";
}
.ri-menu-add-line:before {
  content: "\ef3a";
}
.ri-menu-fill:before {
  content: "\ef3b";
}
.ri-menu-fold-fill:before {
  content: "\ef3c";
}
.ri-menu-fold-line:before {
  content: "\ef3d";
}
.ri-menu-line:before {
  content: "\ef3e";
}
.ri-menu-unfold-fill:before {
  content: "\ef3f";
}
.ri-menu-unfold-line:before {
  content: "\ef40";
}
.ri-merge-cells-horizontal:before {
  content: "\ef41";
}
.ri-merge-cells-horizontal-line:before {
  content: "\ef41";
}
.ri-merge-cells-horizontal-fill:before {
  content: "\ef41";
}
.ri-merge-cells-vertical:before {
  content: "\ef42";
}
.ri-merge-cells-vertical-line:before {
  content: "\ef42";
}
.ri-merge-cells-vertical-fill:before {
  content: "\ef42";
}
.ri-message-2-fill:before {
  content: "\ef43";
}
.ri-message-2-line:before {
  content: "\ef44";
}
.ri-message-3-fill:before {
  content: "\ef45";
}
.ri-message-3-line:before {
  content: "\ef46";
}
.ri-message-fill:before {
  content: "\ef47";
}
.ri-message-line:before {
  content: "\ef48";
}
.ri-messenger-fill:before {
  content: "\ef49";
}
.ri-messenger-line:before {
  content: "\ef4a";
}
.ri-meteor-fill:before {
  content: "\ef4b";
}
.ri-meteor-line:before {
  content: "\ef4c";
}
.ri-mic-2-fill:before {
  content: "\ef4d";
}
.ri-mic-2-line:before {
  content: "\ef4e";
}
.ri-mic-fill:before {
  content: "\ef4f";
}
.ri-mic-line:before {
  content: "\ef50";
}
.ri-mic-off-fill:before {
  content: "\ef51";
}
.ri-mic-off-line:before {
  content: "\ef52";
}
.ri-mickey-fill:before {
  content: "\ef53";
}
.ri-mickey-line:before {
  content: "\ef54";
}
.ri-microscope-fill:before {
  content: "\ef55";
}
.ri-microscope-line:before {
  content: "\ef56";
}
.ri-microsoft-fill:before {
  content: "\ef57";
}
.ri-microsoft-line:before {
  content: "\ef58";
}
.ri-mind-map:before {
  content: "\ef59";
}
.ri-mind-map-line:before {
  content: "\ef59";
}
.ri-mind-map-fill:before {
  content: "\ef59";
}
.ri-mini-program-fill:before {
  content: "\ef5a";
}
.ri-mini-program-line:before {
  content: "\ef5b";
}
.ri-mist-fill:before {
  content: "\ef5c";
}
.ri-mist-line:before {
  content: "\ef5d";
}
.ri-money-cny-box-fill:before {
  content: "\ef5e";
}
.ri-money-cny-box-line:before {
  content: "\ef5f";
}
.ri-money-cny-circle-fill:before {
  content: "\ef60";
}
.ri-money-cny-circle-line:before {
  content: "\ef61";
}
.ri-money-dollar-box-fill:before {
  content: "\ef62";
}
.ri-money-dollar-box-line:before {
  content: "\ef63";
}
.ri-money-dollar-circle-fill:before {
  content: "\ef64";
}
.ri-money-dollar-circle-line:before {
  content: "\ef65";
}
.ri-money-euro-box-fill:before {
  content: "\ef66";
}
.ri-money-euro-box-line:before {
  content: "\ef67";
}
.ri-money-euro-circle-fill:before {
  content: "\ef68";
}
.ri-money-euro-circle-line:before {
  content: "\ef69";
}
.ri-money-pound-box-fill:before {
  content: "\ef6a";
}
.ri-money-pound-box-line:before {
  content: "\ef6b";
}
.ri-money-pound-circle-fill:before {
  content: "\ef6c";
}
.ri-money-pound-circle-line:before {
  content: "\ef6d";
}
.ri-moon-clear-fill:before {
  content: "\ef6e";
}
.ri-moon-clear-line:before {
  content: "\ef6f";
}
.ri-moon-cloudy-fill:before {
  content: "\ef70";
}
.ri-moon-cloudy-line:before {
  content: "\ef71";
}
.ri-moon-fill:before {
  content: "\ef72";
}
.ri-moon-foggy-fill:before {
  content: "\ef73";
}
.ri-moon-foggy-line:before {
  content: "\ef74";
}
.ri-moon-line:before {
  content: "\ef75";
}
.ri-more-2-fill:before {
  content: "\ef76";
}
.ri-more-2-line:before {
  content: "\ef77";
}
.ri-more-fill:before {
  content: "\ef78";
}
.ri-more-line:before {
  content: "\ef79";
}
.ri-motorbike-fill:before {
  content: "\ef7a";
}
.ri-motorbike-line:before {
  content: "\ef7b";
}
.ri-mouse-fill:before {
  content: "\ef7c";
}
.ri-mouse-line:before {
  content: "\ef7d";
}
.ri-movie-2-fill:before {
  content: "\ef7e";
}
.ri-movie-2-line:before {
  content: "\ef7f";
}
.ri-movie-fill:before {
  content: "\ef80";
}
.ri-movie-line:before {
  content: "\ef81";
}
.ri-music-2-fill:before {
  content: "\ef82";
}
.ri-music-2-line:before {
  content: "\ef83";
}
.ri-music-fill:before {
  content: "\ef84";
}
.ri-music-line:before {
  content: "\ef85";
}
.ri-mv-fill:before {
  content: "\ef86";
}
.ri-mv-line:before {
  content: "\ef87";
}
.ri-navigation-fill:before {
  content: "\ef88";
}
.ri-navigation-line:before {
  content: "\ef89";
}
.ri-netease-cloud-music-fill:before {
  content: "\ef8a";
}
.ri-netease-cloud-music-line:before {
  content: "\ef8b";
}
.ri-netflix-fill:before {
  content: "\ef8c";
}
.ri-netflix-line:before {
  content: "\ef8d";
}
.ri-newspaper-fill:before {
  content: "\ef8e";
}
.ri-newspaper-line:before {
  content: "\ef8f";
}
.ri-node-tree:before {
  content: "\ef90";
}
.ri-node-tree-line:before {
  content: "\ef90";
}
.ri-node-tree-fill:before {
  content: "\ef90";
}
.ri-notification-2-fill:before {
  content: "\ef91";
}
.ri-notification-2-line:before {
  content: "\ef92";
}
.ri-notification-3-fill:before {
  content: "\ef93";
}
.ri-notification-3-line:before {
  content: "\ef94";
}
.ri-notification-4-fill:before {
  content: "\ef95";
}
.ri-notification-4-line:before {
  content: "\ef96";
}
.ri-notification-badge-fill:before {
  content: "\ef97";
}
.ri-notification-badge-line:before {
  content: "\ef98";
}
.ri-notification-fill:before {
  content: "\ef99";
}
.ri-notification-line:before {
  content: "\ef9a";
}
.ri-notification-off-fill:before {
  content: "\ef9b";
}
.ri-notification-off-line:before {
  content: "\ef9c";
}
.ri-npmjs-fill:before {
  content: "\ef9d";
}
.ri-npmjs-line:before {
  content: "\ef9e";
}
.ri-number-0:before {
  content: "\ef9f";
}
.ri-number-0-line:before {
  content: "\ef9f";
}
.ri-number-0-fill:before {
  content: "\ef9f";
}
.ri-number-1:before {
  content: "\efa0";
}
.ri-number-2:before {
  content: "\efa1";
}
.ri-number-3:before {
  content: "\efa2";
}
.ri-number-4:before {
  content: "\efa3";
}
.ri-number-5:before {
  content: "\efa4";
}
.ri-number-6:before {
  content: "\efa5";
}
.ri-number-7:before {
  content: "\efa6";
}
.ri-number-8:before {
  content: "\efa7";
}
.ri-number-9:before {
  content: "\efa8";
}
.ri-number-1-line:before {
  content: "\efa0";
}
.ri-number-2-line:before {
  content: "\efa1";
}
.ri-number-3-line:before {
  content: "\efa2";
}
.ri-number-4-line:before {
  content: "\efa3";
}
.ri-number-5-line:before {
  content: "\efa4";
}
.ri-number-6-line:before {
  content: "\efa5";
}
.ri-number-7-line:before {
  content: "\efa6";
}
.ri-number-8-line:before {
  content: "\efa7";
}
.ri-number-9-line:before {
  content: "\efa8";
}
.ri-number-1-fill:before {
  content: "\efa0";
}
.ri-number-2-fill:before {
  content: "\efa1";
}
.ri-number-3-fill:before {
  content: "\efa2";
}
.ri-number-4-fill:before {
  content: "\efa3";
}
.ri-number-5-fill:before {
  content: "\efa4";
}
.ri-number-6-line:before {
  content: "\efa5";
}
.ri-number-7-fill:before {
  content: "\efa6";
}
.ri-number-8-fill:before {
  content: "\efa7";
}
.ri-number-9-fill:before {
  content: "\efa8";
}
.ri-numbers-fill:before {
  content: "\efa9";
}
.ri-numbers-line:before {
  content: "\efaa";
}
.ri-nurse-fill:before {
  content: "\efab";
}
.ri-nurse-line:before {
  content: "\efac";
}
.ri-oil-fill:before {
  content: "\efad";
}
.ri-oil-line:before {
  content: "\efae";
}
.ri-omega-line:before {
  content: "\efaf";
}
.ri-omega-fill:before {
  content: "\efaf";
}
.ri-open-arm-fill:before {
  content: "\efb0";
}
.ri-open-arm-line:before {
  content: "\efb1";
}
.ri-open-source-fill:before {
  content: "\efb2";
}
.ri-open-source-line:before {
  content: "\efb3";
}
.ri-opera-fill:before {
  content: "\efb4";
}
.ri-opera-line:before {
  content: "\efb5";
}
.ri-order-play-fill:before {
  content: "\efb6";
}
.ri-order-play-line:before {
  content: "\efb7";
}
.ri-organization-chart:before {
  content: "\efb8";
}
.ri-organization-chart-line:before {
  content: "\efb8";
}
.ri-organization-chart-fill:before {
  content: "\efb8";
}

.ri-outlet-2-fill:before {
  content: "\efb9";
}
.ri-outlet-2-line:before {
  content: "\efba";
}
.ri-outlet-fill:before {
  content: "\efbb";
}
.ri-outlet-line:before {
  content: "\efbc";
}
.ri-page-separator:before {
  content: "\efbd";
}
.ri-page-separator-line:before {
  content: "\efbd";
}
.ri-page-separator-fill:before {
  content: "\efbd";
}

.ri-pages-fill:before {
  content: "\efbe";
}
.ri-pages-line:before {
  content: "\efbf";
}
.ri-paint-brush-fill:before {
  content: "\efc0";
}
.ri-paint-brush-line:before {
  content: "\efc1";
}
.ri-paint-fill:before {
  content: "\efc2";
}
.ri-paint-line:before {
  content: "\efc3";
}
.ri-palette-fill:before {
  content: "\efc4";
}
.ri-palette-line:before {
  content: "\efc5";
}
.ri-pantone-fill:before {
  content: "\efc6";
}
.ri-pantone-line:before {
  content: "\efc7";
}
.ri-paragraph:before {
  content: "\efc8";
}
.ri-paragraph-line:before {
  content: "\efc8";
}
.ri-paragraph-fill:before {
  content: "\efc8";
}
.ri-parent-fill:before {
  content: "\efc9";
}
.ri-parent-line:before {
  content: "\efca";
}
.ri-parentheses-fill:before {
  content: "\efcb";
}
.ri-parentheses-line:before {
  content: "\efcc";
}
.ri-parking-box-fill:before {
  content: "\efcd";
}
.ri-parking-box-line:before {
  content: "\efce";
}
.ri-parking-fill:before {
  content: "\efcf";
}
.ri-parking-line:before {
  content: "\efd0";
}
.ri-passport-fill:before {
  content: "\efd1";
}
.ri-passport-line:before {
  content: "\efd2";
}
.ri-patreon-fill:before {
  content: "\efd3";
}
.ri-patreon-line:before {
  content: "\efd4";
}
.ri-pause-circle-fill:before {
  content: "\efd5";
}
.ri-pause-circle-line:before {
  content: "\efd6";
}
.ri-pause-fill:before {
  content: "\efd7";
}
.ri-pause-line:before {
  content: "\efd8";
}
.ri-pause-mini-fill:before {
  content: "\efd9";
}
.ri-pause-mini-line:before {
  content: "\efda";
}
.ri-paypal-fill:before {
  content: "\efdb";
}
.ri-paypal-line:before {
  content: "\efdc";
}
.ri-pen-nib-fill:before {
  content: "\efdd";
}
.ri-pen-nib-line:before {
  content: "\efde";
}
.ri-pencil-fill:before {
  content: "\efdf";
}
.ri-pencil-line:before {
  content: "\efe0";
}
.ri-pencil-ruler-2-fill:before {
  content: "\efe1";
}
.ri-pencil-ruler-2-line:before {
  content: "\efe2";
}
.ri-pencil-ruler-fill:before {
  content: "\efe3";
}
.ri-pencil-ruler-line:before {
  content: "\efe4";
}
.ri-percent-fill:before {
  content: "\efe5";
}
.ri-percent-line:before {
  content: "\efe6";
}
.ri-phone-camera-fill:before {
  content: "\efe7";
}
.ri-phone-camera-line:before {
  content: "\efe8";
}
.ri-phone-fill:before {
  content: "\efe9";
}
.ri-phone-find-fill:before {
  content: "\efea";
}
.ri-phone-find-line:before {
  content: "\efeb";
}
.ri-phone-line:before {
  content: "\efec";
}
.ri-phone-lock-fill:before {
  content: "\efed";
}
.ri-phone-lock-line:before {
  content: "\efee";
}
.ri-picture-in-picture-2-fill:before {
  content: "\efef";
}
.ri-picture-in-picture-2-line:before {
  content: "\eff0";
}
.ri-picture-in-picture-exit-fill:before {
  content: "\eff1";
}
.ri-picture-in-picture-exit-line:before {
  content: "\eff2";
}
.ri-picture-in-picture-fill:before {
  content: "\eff3";
}
.ri-picture-in-picture-line:before {
  content: "\eff4";
}
.ri-pie-chart-2-fill:before {
  content: "\eff5";
}
.ri-pie-chart-2-line:before {
  content: "\eff6";
}
.ri-pie-chart-box-fill:before {
  content: "\eff7";
}
.ri-pie-chart-box-line:before {
  content: "\eff8";
}
.ri-pie-chart-fill:before {
  content: "\eff9";
}
.ri-pie-chart-line:before {
  content: "\effa";
}
.ri-pin-distance-fill:before {
  content: "\effb";
}
.ri-pin-distance-line:before {
  content: "\effc";
}
.ri-ping-pong-fill:before {
  content: "\effd";
}
.ri-ping-pong-line:before {
  content: "\effe";
}
.ri-pinterest-fill:before {
  content: "\efff";
}
.ri-pinterest-line:before {
  content: "\f000";
}
.ri-pinyin-input:before {
  content: "\f001";
}
.ri-pinyin-input-line:before {
  content: "\f001";
}
.ri-pinyin-input-fill:before {
  content: "\f001";
}

.ri-pixelfed-fill:before {
  content: "\f002";
}
.ri-pixelfed-line:before {
  content: "\f003";
}
.ri-plane-fill:before {
  content: "\f004";
}
.ri-plane-line:before {
  content: "\f005";
}
.ri-plant-fill:before {
  content: "\f006";
}
.ri-plant-line:before {
  content: "\f007";
}
.ri-play-circle-fill:before {
  content: "\f008";
}
.ri-play-circle-line:before {
  content: "\f009";
}
.ri-play-fill:before {
  content: "\f00a";
}
.ri-play-line:before {
  content: "\f00b";
}
.ri-play-list-2-fill:before {
  content: "\f00c";
}
.ri-play-list-2-line:before {
  content: "\f00d";
}
.ri-play-list-add-fill:before {
  content: "\f00e";
}
.ri-play-list-add-line:before {
  content: "\f00f";
}
.ri-play-list-fill:before {
  content: "\f010";
}
.ri-play-list-line:before {
  content: "\f011";
}
.ri-play-mini-fill:before {
  content: "\f012";
}
.ri-play-mini-line:before {
  content: "\f013";
}
.ri-playstation-fill:before {
  content: "\f014";
}
.ri-playstation-line:before {
  content: "\f015";
}
.ri-plug-2-fill:before {
  content: "\f016";
}
.ri-plug-2-line:before {
  content: "\f017";
}
.ri-plug-fill:before {
  content: "\f018";
}
.ri-plug-line:before {
  content: "\f019";
}
.ri-polaroid-2-fill:before {
  content: "\f01a";
}
.ri-polaroid-2-line:before {
  content: "\f01b";
}
.ri-polaroid-fill:before {
  content: "\f01c";
}
.ri-polaroid-line:before {
  content: "\f01d";
}
.ri-police-car-fill:before {
  content: "\f01e";
}
.ri-police-car-line:before {
  content: "\f01f";
}
.ri-price-tag-2-fill:before {
  content: "\f020";
}
.ri-price-tag-2-line:before {
  content: "\f021";
}
.ri-price-tag-3-fill:before {
  content: "\f022";
}
.ri-price-tag-3-line:before {
  content: "\f023";
}
.ri-price-tag-fill:before {
  content: "\f024";
}
.ri-price-tag-line:before {
  content: "\f025";
}
.ri-printer-cloud-fill:before {
  content: "\f026";
}
.ri-printer-cloud-line:before {
  content: "\f027";
}
.ri-printer-fill:before {
  content: "\f028";
}
.ri-printer-line:before {
  content: "\f029";
}
.ri-product-hunt-fill:before {
  content: "\f02a";
}
.ri-product-hunt-line:before {
  content: "\f02b";
}
.ri-profile-fill:before {
  content: "\f02c";
}
.ri-profile-line:before {
  content: "\f02d";
}
.ri-projector-2-fill:before {
  content: "\f02e";
}
.ri-projector-2-line:before {
  content: "\f02f";
}
.ri-projector-fill:before {
  content: "\f030";
}
.ri-projector-line:before {
  content: "\f031";
}
.ri-psychotherapy-fill:before {
  content: "\f032";
}
.ri-psychotherapy-line:before {
  content: "\f033";
}
.ri-pulse-fill:before {
  content: "\f034";
}
.ri-pulse-line:before {
  content: "\f035";
}
.ri-pushpin-2-fill:before {
  content: "\f036";
}
.ri-pushpin-2-line:before {
  content: "\f037";
}
.ri-pushpin-fill:before {
  content: "\f038";
}
.ri-pushpin-line:before {
  content: "\f039";
}
.ri-qq-fill:before {
  content: "\f03a";
}
.ri-qq-line:before {
  content: "\f03b";
}
.ri-qr-code-fill:before {
  content: "\f03c";
}
.ri-qr-code-line:before {
  content: "\f03d";
}
.ri-qr-scan-2-fill:before {
  content: "\f03e";
}
.ri-qr-scan-2-line:before {
  content: "\f03f";
}
.ri-qr-scan-fill:before {
  content: "\f040";
}
.ri-qr-scan-line:before {
  content: "\f041";
}
.ri-question-answer-fill:before {
  content: "\f042";
}
.ri-question-answer-line:before {
  content: "\f043";
}
.ri-question-fill:before {
  content: "\f044";
}
.ri-question-line:before {
  content: "\f045";
}
.ri-question-mark:before {
  content: "\f046";
}
.ri-question-mark-line:before {
  content: "\f046";
}
.ri-question-mark-fill:before {
  content: "\f046";
}

.ri-questionnaire-fill:before {
  content: "\f047";
}
.ri-questionnaire-line:before {
  content: "\f048";
}
.ri-quill-pen-fill:before {
  content: "\f049";
}
.ri-quill-pen-line:before {
  content: "\f04a";
}
.ri-radar-fill:before {
  content: "\f04b";
}
.ri-radar-line:before {
  content: "\f04c";
}
.ri-radio-2-fill:before {
  content: "\f04d";
}
.ri-radio-2-line:before {
  content: "\f04e";
}
.ri-radio-button-fill:before {
  content: "\f04f";
}
.ri-radio-button-line:before {
  content: "\f050";
}
.ri-radio-fill:before {
  content: "\f051";
}
.ri-radio-line:before {
  content: "\f052";
}
.ri-rainbow-fill:before {
  content: "\f053";
}
.ri-rainbow-line:before {
  content: "\f054";
}
.ri-rainy-fill:before {
  content: "\f055";
}
.ri-rainy-line:before {
  content: "\f056";
}
.ri-reactjs-fill:before {
  content: "\f057";
}
.ri-reactjs-line:before {
  content: "\f058";
}
.ri-record-circle-fill:before {
  content: "\f059";
}
.ri-record-circle-line:before {
  content: "\f05a";
}
.ri-record-mail-fill:before {
  content: "\f05b";
}
.ri-record-mail-line:before {
  content: "\f05c";
}
.ri-recycle-fill:before {
  content: "\f05d";
}
.ri-recycle-line:before {
  content: "\f05e";
}
.ri-red-packet-fill:before {
  content: "\f05f";
}
.ri-red-packet-line:before {
  content: "\f060";
}
.ri-reddit-fill:before {
  content: "\f061";
}
.ri-reddit-line:before {
  content: "\f062";
}
.ri-refresh-fill:before {
  content: "\f063";
}
.ri-refresh-line:before {
  content: "\f064";
}
.ri-refund-2-fill:before {
  content: "\f065";
}
.ri-refund-2-line:before {
  content: "\f066";
}
.ri-refund-fill:before {
  content: "\f067";
}
.ri-refund-line:before {
  content: "\f068";
}
.ri-registered-fill:before {
  content: "\f069";
}
.ri-registered-line:before {
  content: "\f06a";
}
.ri-remixicon-fill:before {
  content: "\f06b";
}
.ri-remixicon-line:before {
  content: "\f06c";
}
.ri-remote-control-2-fill:before {
  content: "\f06d";
}
.ri-remote-control-2-line:before {
  content: "\f06e";
}
.ri-remote-control-fill:before {
  content: "\f06f";
}
.ri-remote-control-line:before {
  content: "\f070";
}
.ri-repeat-2-fill:before {
  content: "\f071";
}
.ri-repeat-2-line:before {
  content: "\f072";
}
.ri-repeat-fill:before {
  content: "\f073";
}
.ri-repeat-line:before {
  content: "\f074";
}
.ri-repeat-one-fill:before {
  content: "\f075";
}
.ri-repeat-one-line:before {
  content: "\f076";
}
.ri-reply-all-fill:before {
  content: "\f077";
}
.ri-reply-all-line:before {
  content: "\f078";
}
.ri-reply-fill:before {
  content: "\f079";
}
.ri-reply-line:before {
  content: "\f07a";
}
.ri-reserved-fill:before {
  content: "\f07b";
}
.ri-reserved-line:before {
  content: "\f07c";
}
.ri-rest-time-fill:before {
  content: "\f07d";
}
.ri-rest-time-line:before {
  content: "\f07e";
}
.ri-restart-fill:before {
  content: "\f07f";
}
.ri-restart-line:before {
  content: "\f080";
}
.ri-restaurant-2-fill:before {
  content: "\f081";
}
.ri-restaurant-2-line:before {
  content: "\f082";
}
.ri-restaurant-fill:before {
  content: "\f083";
}
.ri-restaurant-line:before {
  content: "\f084";
}
.ri-rewind-fill:before {
  content: "\f085";
}
.ri-rewind-line:before {
  content: "\f086";
}
.ri-rewind-mini-fill:before {
  content: "\f087";
}
.ri-rewind-mini-line:before {
  content: "\f088";
}
.ri-rhythm-fill:before {
  content: "\f089";
}
.ri-rhythm-line:before {
  content: "\f08a";
}
.ri-riding-fill:before {
  content: "\f08b";
}
.ri-riding-line:before {
  content: "\f08c";
}
.ri-road-map-fill:before {
  content: "\f08d";
}
.ri-road-map-line:before {
  content: "\f08e";
}
.ri-roadster-fill:before {
  content: "\f08f";
}
.ri-roadster-line:before {
  content: "\f090";
}
.ri-robot-fill:before {
  content: "\f091";
}
.ri-robot-line:before {
  content: "\f092";
}
.ri-rocket-2-fill:before {
  content: "\f093";
}
.ri-rocket-2-line:before {
  content: "\f094";
}
.ri-rocket-fill:before {
  content: "\f095";
}
.ri-rocket-line:before {
  content: "\f096";
}
.ri-rotate-lock-fill:before {
  content: "\f097";
}
.ri-rotate-lock-line:before {
  content: "\f098";
}
.ri-rounded-corner:before {
  content: "\f099";
}
.ri-rounded-corner-line:before {
  content: "\f099";
}
.ri-rounded-corner-fill:before {
  content: "\f099";
}

.ri-route-fill:before {
  content: "\f09a";
}
.ri-route-line:before {
  content: "\f09b";
}
.ri-router-fill:before {
  content: "\f09c";
}
.ri-router-line:before {
  content: "\f09d";
}
.ri-rss-fill:before {
  content: "\f09e";
}
.ri-rss-line:before {
  content: "\f09f";
}
.ri-ruler-2-fill:before {
  content: "\f0a0";
}
.ri-ruler-2-line:before {
  content: "\f0a1";
}
.ri-ruler-fill:before {
  content: "\f0a2";
}
.ri-ruler-line:before {
  content: "\f0a3";
}
.ri-run-fill:before {
  content: "\f0a4";
}
.ri-run-line:before {
  content: "\f0a5";
}
.ri-safari-fill:before {
  content: "\f0a6";
}
.ri-safari-line:before {
  content: "\f0a7";
}
.ri-safe-2-fill:before {
  content: "\f0a8";
}
.ri-safe-2-line:before {
  content: "\f0a9";
}
.ri-safe-fill:before {
  content: "\f0aa";
}
.ri-safe-line:before {
  content: "\f0ab";
}
.ri-sailboat-fill:before {
  content: "\f0ac";
}
.ri-sailboat-line:before {
  content: "\f0ad";
}
.ri-save-2-fill:before {
  content: "\f0ae";
}
.ri-save-2-line:before {
  content: "\f0af";
}
.ri-save-3-fill:before {
  content: "\f0b0";
}
.ri-save-3-line:before {
  content: "\f0b1";
}
.ri-save-fill:before {
  content: "\f0b2";
}
.ri-save-line:before {
  content: "\f0b3";
}
.ri-scales-2-fill:before {
  content: "\f0b4";
}
.ri-scales-2-line:before {
  content: "\f0b5";
}
.ri-scales-3-fill:before {
  content: "\f0b6";
}
.ri-scales-3-line:before {
  content: "\f0b7";
}
.ri-scales-fill:before {
  content: "\f0b8";
}
.ri-scales-line:before {
  content: "\f0b9";
}
.ri-scan-2-fill:before {
  content: "\f0ba";
}
.ri-scan-2-line:before {
  content: "\f0bb";
}
.ri-scan-fill:before {
  content: "\f0bc";
}
.ri-scan-line:before {
  content: "\f0bd";
}
.ri-scissors-2-fill:before {
  content: "\f0be";
}
.ri-scissors-2-line:before {
  content: "\f0bf";
}
.ri-scissors-cut-fill:before {
  content: "\f0c0";
}
.ri-scissors-cut-line:before {
  content: "\f0c1";
}
.ri-scissors-fill:before {
  content: "\f0c2";
}
.ri-scissors-line:before {
  content: "\f0c3";
}
.ri-screenshot-2-fill:before {
  content: "\f0c4";
}
.ri-screenshot-2-line:before {
  content: "\f0c5";
}
.ri-screenshot-fill:before {
  content: "\f0c6";
}
.ri-screenshot-line:before {
  content: "\f0c7";
}
.ri-sd-card-fill:before {
  content: "\f0c8";
}
.ri-sd-card-line:before {
  content: "\f0c9";
}
.ri-sd-card-mini-fill:before {
  content: "\f0ca";
}
.ri-sd-card-mini-line:before {
  content: "\f0cb";
}
.ri-search-2-fill:before {
  content: "\f0cc";
}
.ri-search-2-line:before {
  content: "\f0cd";
}
.ri-search-eye-fill:before {
  content: "\f0ce";
}
.ri-search-eye-line:before {
  content: "\f0cf";
}
.ri-search-fill:before {
  content: "\f0d0";
}
.ri-search-line:before {
  content: "\f0d1";
}
.ri-secure-payment-fill:before {
  content: "\f0d2";
}
.ri-secure-payment-line:before {
  content: "\f0d3";
}
.ri-seedling-fill:before {
  content: "\f0d4";
}
.ri-seedling-line:before {
  content: "\f0d5";
}
.ri-send-backward:before {
  content: "\f0d6";
}
.ri-send-backward-line:before {
  content: "\f0d6";
}
.ri-send-backward-fill:before {
  content: "\f0d6";
}
.ri-send-plane-2-fill:before {
  content: "\f0d7";
}
.ri-send-plane-2-line:before {
  content: "\f0d8";
}
.ri-send-plane-fill:before {
  content: "\f0d9";
}
.ri-send-plane-line:before {
  content: "\f0da";
}
.ri-send-to-back:before {
  content: "\f0db";
}
.ri-send-to-back-line:before {
  content: "\f0db";
}
.ri-send-to-back-fill:before {
  content: "\f0db";
}
.ri-sensor-fill:before {
  content: "\f0dc";
}
.ri-sensor-line:before {
  content: "\f0dd";
}
.ri-separator:before {
  content: "\f0de";
}
.ri-separator-line:before {
  content: "\f0de";
}
.ri-separator-fill:before {
  content: "\f0de";
}
.ri-server-fill:before {
  content: "\f0df";
}
.ri-server-line:before {
  content: "\f0e0";
}
.ri-service-fill:before {
  content: "\f0e1";
}
.ri-service-line:before {
  content: "\f0e2";
}
.ri-settings-2-fill:before {
  content: "\f0e3";
}
.ri-settings-2-line:before {
  content: "\f0e4";
}
.ri-settings-3-fill:before {
  content: "\f0e5";
}
.ri-settings-3-line:before {
  content: "\f0e6";
}
.ri-settings-4-fill:before {
  content: "\f0e7";
}
.ri-settings-4-line:before {
  content: "\f0e8";
}
.ri-settings-5-fill:before {
  content: "\f0e9";
}
.ri-settings-5-line:before {
  content: "\f0ea";
}
.ri-settings-6-fill:before {
  content: "\f0eb";
}
.ri-settings-6-line:before {
  content: "\f0ec";
}
.ri-settings-fill:before {
  content: "\f0ed";
}
.ri-settings-line:before {
  content: "\f0ee";
}
.ri-shape-2-fill:before {
  content: "\f0ef";
}
.ri-shape-2-line:before {
  content: "\f0f0";
}
.ri-shape-fill:before {
  content: "\f0f1";
}
.ri-shape-line:before {
  content: "\f0f2";
}
.ri-share-box-fill:before {
  content: "\f0f3";
}
.ri-share-box-line:before {
  content: "\f0f4";
}
.ri-share-circle-fill:before {
  content: "\f0f5";
}
.ri-share-circle-line:before {
  content: "\f0f6";
}
.ri-share-fill:before {
  content: "\f0f7";
}
.ri-share-forward-2-fill:before {
  content: "\f0f8";
}
.ri-share-forward-2-line:before {
  content: "\f0f9";
}
.ri-share-forward-box-fill:before {
  content: "\f0fa";
}
.ri-share-forward-box-line:before {
  content: "\f0fb";
}
.ri-share-forward-fill:before {
  content: "\f0fc";
}
.ri-share-forward-line:before {
  content: "\f0fd";
}
.ri-share-line:before {
  content: "\f0fe";
}
.ri-shield-check-fill:before {
  content: "\f0ff";
}
.ri-shield-check-line:before {
  content: "\f100";
}
.ri-shield-cross-fill:before {
  content: "\f101";
}
.ri-shield-cross-line:before {
  content: "\f102";
}
.ri-shield-fill:before {
  content: "\f103";
}
.ri-shield-flash-fill:before {
  content: "\f104";
}
.ri-shield-flash-line:before {
  content: "\f105";
}
.ri-shield-keyhole-fill:before {
  content: "\f106";
}
.ri-shield-keyhole-line:before {
  content: "\f107";
}
.ri-shield-line:before {
  content: "\f108";
}
.ri-shield-star-fill:before {
  content: "\f109";
}
.ri-shield-star-line:before {
  content: "\f10a";
}
.ri-shield-user-fill:before {
  content: "\f10b";
}
.ri-shield-user-line:before {
  content: "\f10c";
}
.ri-ship-2-fill:before {
  content: "\f10d";
}
.ri-ship-2-line:before {
  content: "\f10e";
}
.ri-ship-fill:before {
  content: "\f10f";
}
.ri-ship-line:before {
  content: "\f110";
}
.ri-shirt-fill:before {
  content: "\f111";
}
.ri-shirt-line:before {
  content: "\f112";
}
.ri-shopping-bag-2-fill:before {
  content: "\f113";
}
.ri-shopping-bag-2-line:before {
  content: "\f114";
}
.ri-shopping-bag-3-fill:before {
  content: "\f115";
}
.ri-shopping-bag-3-line:before {
  content: "\f116";
}
.ri-shopping-bag-fill:before {
  content: "\f117";
}
.ri-shopping-bag-line:before {
  content: "\f118";
}
.ri-shopping-basket-2-fill:before {
  content: "\f119";
}
.ri-shopping-basket-2-line:before {
  content: "\f11a";
}
.ri-shopping-basket-fill:before {
  content: "\f11b";
}
.ri-shopping-basket-line:before {
  content: "\f11c";
}
.ri-shopping-cart-2-fill:before {
  content: "\f11d";
}
.ri-shopping-cart-2-line:before {
  content: "\f11e";
}
.ri-shopping-cart-fill:before {
  content: "\f11f";
}
.ri-shopping-cart-line:before {
  content: "\f120";
}
.ri-showers-fill:before {
  content: "\f121";
}
.ri-showers-line:before {
  content: "\f122";
}
.ri-shuffle-fill:before {
  content: "\f123";
}
.ri-shuffle-line:before {
  content: "\f124";
}
.ri-shut-down-fill:before {
  content: "\f125";
}
.ri-shut-down-line:before {
  content: "\f126";
}
.ri-side-bar-fill:before {
  content: "\f127";
}
.ri-side-bar-line:before {
  content: "\f128";
}
.ri-signal-tower-fill:before {
  content: "\f129";
}
.ri-signal-tower-line:before {
  content: "\f12a";
}
.ri-signal-wifi-1-fill:before {
  content: "\f12b";
}
.ri-signal-wifi-1-line:before {
  content: "\f12c";
}
.ri-signal-wifi-2-fill:before {
  content: "\f12d";
}
.ri-signal-wifi-2-line:before {
  content: "\f12e";
}
.ri-signal-wifi-3-fill:before {
  content: "\f12f";
}
.ri-signal-wifi-3-line:before {
  content: "\f130";
}
.ri-signal-wifi-error-fill:before {
  content: "\f131";
}
.ri-signal-wifi-error-line:before {
  content: "\f132";
}
.ri-signal-wifi-fill:before {
  content: "\f133";
}
.ri-signal-wifi-line:before {
  content: "\f134";
}
.ri-signal-wifi-off-fill:before {
  content: "\f135";
}
.ri-signal-wifi-off-line:before {
  content: "\f136";
}
.ri-sim-card-2-fill:before {
  content: "\f137";
}
.ri-sim-card-2-line:before {
  content: "\f138";
}
.ri-sim-card-fill:before {
  content: "\f139";
}
.ri-sim-card-line:before {
  content: "\f13a";
}
.ri-single-quotes-l:before {
  content: "\f13b";
}
.ri-single-quotes-l-line:before {
  content: "\f13b";
}
.ri-single-quotes-l-fill:before {
  content: "\f13b";
}
.ri-single-quotes-r:before {
  content: "\f13c";
}
.ri-single-quotes-r-line:before {
  content: "\f13c";
}
.ri-single-quotes-r-fill:before {
  content: "\f13c";
}
.ri-sip-fill:before {
  content: "\f13d";
}
.ri-sip-line:before {
  content: "\f13e";
}
.ri-skip-back-fill:before {
  content: "\f13f";
}
.ri-skip-back-line:before {
  content: "\f140";
}
.ri-skip-back-mini-fill:before {
  content: "\f141";
}
.ri-skip-back-mini-line:before {
  content: "\f142";
}
.ri-skip-forward-fill:before {
  content: "\f143";
}
.ri-skip-forward-line:before {
  content: "\f144";
}
.ri-skip-forward-mini-fill:before {
  content: "\f145";
}
.ri-skip-forward-mini-line:before {
  content: "\f146";
}
.ri-skull-2-fill:before {
  content: "\f147";
}
.ri-skull-2-line:before {
  content: "\f148";
}
.ri-skull-fill:before {
  content: "\f149";
}
.ri-skull-line:before {
  content: "\f14a";
}
.ri-skype-fill:before {
  content: "\f14b";
}
.ri-skype-line:before {
  content: "\f14c";
}
.ri-slack-fill:before {
  content: "\f14d";
}
.ri-slack-line:before {
  content: "\f14e";
}
.ri-slice-fill:before {
  content: "\f14f";
}
.ri-slice-line:before {
  content: "\f150";
}
.ri-slideshow-2-fill:before {
  content: "\f151";
}
.ri-slideshow-2-line:before {
  content: "\f152";
}
.ri-slideshow-3-fill:before {
  content: "\f153";
}
.ri-slideshow-3-line:before {
  content: "\f154";
}
.ri-slideshow-4-fill:before {
  content: "\f155";
}
.ri-slideshow-4-line:before {
  content: "\f156";
}
.ri-slideshow-fill:before {
  content: "\f157";
}
.ri-slideshow-line:before {
  content: "\f158";
}
.ri-smartphone-fill:before {
  content: "\f159";
}
.ri-smartphone-line:before {
  content: "\f15a";
}
.ri-snapchat-fill:before {
  content: "\f15b";
}
.ri-snapchat-line:before {
  content: "\f15c";
}
.ri-snowy-fill:before {
  content: "\f15d";
}
.ri-snowy-line:before {
  content: "\f15e";
}
.ri-sort-asc:before {
  content: "\f15f";
}
.ri-sort-asc-line:before {
  content: "\f15f";
}
.ri-sort-asc-fill:before {
  content: "\f15f";
}

.ri-sort-desc:before {
  content: "\f160";
}
.ri-sort-desc-line:before {
  content: "\f160";
}
.ri-sort-desc-fill:before {
  content: "\f160";
}
.ri-sound-module-fill:before {
  content: "\f161";
}
.ri-sound-module-line:before {
  content: "\f162";
}
.ri-soundcloud-fill:before {
  content: "\f163";
}
.ri-soundcloud-line:before {
  content: "\f164";
}
.ri-space-ship-fill:before {
  content: "\f165";
}
.ri-space-ship-line:before {
  content: "\f166";
}
.ri-space:before {
  content: "\f167";
}
.ri-space-line:before {
  content: "\f167";
}
.ri-space-fill:before {
  content: "\f167";
}

.ri-spam-2-fill:before {
  content: "\f168";
}
.ri-spam-2-line:before {
  content: "\f169";
}
.ri-spam-3-fill:before {
  content: "\f16a";
}
.ri-spam-3-line:before {
  content: "\f16b";
}
.ri-spam-fill:before {
  content: "\f16c";
}
.ri-spam-line:before {
  content: "\f16d";
}
.ri-speaker-2-fill:before {
  content: "\f16e";
}
.ri-speaker-2-line:before {
  content: "\f16f";
}
.ri-speaker-3-fill:before {
  content: "\f170";
}
.ri-speaker-3-line:before {
  content: "\f171";
}
.ri-speaker-fill:before {
  content: "\f172";
}
.ri-speaker-line:before {
  content: "\f173";
}
.ri-spectrum-fill:before {
  content: "\f174";
}
.ri-spectrum-line:before {
  content: "\f175";
}
.ri-speed-fill:before {
  content: "\f176";
}
.ri-speed-line:before {
  content: "\f177";
}
.ri-speed-mini-fill:before {
  content: "\f178";
}
.ri-speed-mini-line:before {
  content: "\f179";
}
.ri-split-cells-horizontal:before {
  content: "\f17a";
}
.ri-split-cells-horizontal-line:before {
  content: "\f17a";
}
.ri-split-cells-horizontal-fill:before {
  content: "\f17a";
}

.ri-split-cells-vertical:before {
  content: "\f17b";
}
.ri-split-cells-vertical-line:before {
  content: "\f17b";
}
.ri-split-cells-vertical-fill:before {
  content: "\f17b";
}

.ri-spotify-fill:before {
  content: "\f17c";
}
.ri-spotify-line:before {
  content: "\f17d";
}
.ri-spy-fill:before {
  content: "\f17e";
}
.ri-spy-line:before {
  content: "\f17f";
}
.ri-stack-fill:before {
  content: "\f180";
}
.ri-stack-line:before {
  content: "\f181";
}
.ri-stack-overflow-fill:before {
  content: "\f182";
}
.ri-stack-overflow-line:before {
  content: "\f183";
}
.ri-stackshare-fill:before {
  content: "\f184";
}
.ri-stackshare-line:before {
  content: "\f185";
}
.ri-star-fill:before {
  content: "\f186";
}
.ri-star-half-fill:before {
  content: "\f187";
}
.ri-star-half-line:before {
  content: "\f188";
}
.ri-star-half-s-fill:before {
  content: "\f189";
}
.ri-star-half-s-line:before {
  content: "\f18a";
}
.ri-star-line:before {
  content: "\f18b";
}
.ri-star-s-fill:before {
  content: "\f18c";
}
.ri-star-s-line:before {
  content: "\f18d";
}
.ri-star-smile-fill:before {
  content: "\f18e";
}
.ri-star-smile-line:before {
  content: "\f18f";
}
.ri-steam-fill:before {
  content: "\f190";
}
.ri-steam-line:before {
  content: "\f191";
}
.ri-steering-2-fill:before {
  content: "\f192";
}
.ri-steering-2-line:before {
  content: "\f193";
}
.ri-steering-fill:before {
  content: "\f194";
}
.ri-steering-line:before {
  content: "\f195";
}
.ri-stethoscope-fill:before {
  content: "\f196";
}
.ri-stethoscope-line:before {
  content: "\f197";
}
.ri-sticky-note-2-fill:before {
  content: "\f198";
}
.ri-sticky-note-2-line:before {
  content: "\f199";
}
.ri-sticky-note-fill:before {
  content: "\f19a";
}
.ri-sticky-note-line:before {
  content: "\f19b";
}
.ri-stock-fill:before {
  content: "\f19c";
}
.ri-stock-line:before {
  content: "\f19d";
}
.ri-stop-circle-fill:before {
  content: "\f19e";
}
.ri-stop-circle-line:before {
  content: "\f19f";
}
.ri-stop-fill:before {
  content: "\f1a0";
}
.ri-stop-line:before {
  content: "\f1a1";
}
.ri-stop-mini-fill:before {
  content: "\f1a2";
}
.ri-stop-mini-line:before {
  content: "\f1a3";
}
.ri-store-2-fill:before {
  content: "\f1a4";
}
.ri-store-2-line:before {
  content: "\f1a5";
}
.ri-store-3-fill:before {
  content: "\f1a6";
}
.ri-store-3-line:before {
  content: "\f1a7";
}
.ri-store-fill:before {
  content: "\f1a8";
}
.ri-store-line:before {
  content: "\f1a9";
}
.ri-strikethrough-2:before {
  content: "\f1aa";
}
.ri-strikethrough-2-line:before {
  content: "\f1aa";
}
.ri-strikethrough-2-fill:before {
  content: "\f1aa";
}

.ri-strikethrough:before {
  content: "\f1ab";
}
.ri-strikethrough-line:before {
  content: "\f1ab";
}
.ri-strikethrough-fill:before {
  content: "\f1ab";
}

.ri-subscript-2:before {
  content: "\f1ac";
}
.ri-subscript:before {
  content: "\f1ad";
}
.ri-subscript-2-line:before {
  content: "\f1ac";
}
.ri-subscript-2-fill:before {
  content: "\f1ac";
}
.ri-subscript-line:before {
  content: "\f1ad";
}
.ri-subscript-fill:before {
  content: "\f1ad";
}
.ri-subtract-fill:before {
  content: "\f1ae";
}
.ri-subtract-line:before {
  content: "\f1af";
}
.ri-subway-fill:before {
  content: "\f1b0";
}
.ri-subway-line:before {
  content: "\f1b1";
}
.ri-subway-wifi-fill:before {
  content: "\f1b2";
}
.ri-subway-wifi-line:before {
  content: "\f1b3";
}
.ri-suitcase-2-fill:before {
  content: "\f1b4";
}
.ri-suitcase-2-line:before {
  content: "\f1b5";
}
.ri-suitcase-3-fill:before {
  content: "\f1b6";
}
.ri-suitcase-3-line:before {
  content: "\f1b7";
}
.ri-suitcase-fill:before {
  content: "\f1b8";
}
.ri-suitcase-line:before {
  content: "\f1b9";
}
.ri-sun-cloudy-fill:before {
  content: "\f1ba";
}
.ri-sun-cloudy-line:before {
  content: "\f1bb";
}
.ri-sun-fill:before {
  content: "\f1bc";
}
.ri-sun-foggy-fill:before {
  content: "\f1bd";
}
.ri-sun-foggy-line:before {
  content: "\f1be";
}
.ri-sun-line:before {
  content: "\f1bf";
}
.ri-superscript-2:before {
  content: "\f1c0";
}
.ri-superscript-2-line:before {
  content: "\f1c0";
}
.ri-superscript-2-fill:before {
  content: "\f1c0";
}
.ri-superscript:before {
  content: "\f1c1";
}
.ri-superscript-line:before {
  content: "\f1c1";
}
.ri-superscript-fill:before {
  content: "\f1c1";
}
.ri-surgical-mask-fill:before {
  content: "\f1c2";
}
.ri-surgical-mask-line:before {
  content: "\f1c3";
}
.ri-surround-sound-fill:before {
  content: "\f1c4";
}
.ri-surround-sound-line:before {
  content: "\f1c5";
}
.ri-survey-fill:before {
  content: "\f1c6";
}
.ri-survey-line:before {
  content: "\f1c7";
}
.ri-swap-box-fill:before {
  content: "\f1c8";
}
.ri-swap-box-line:before {
  content: "\f1c9";
}
.ri-swap-fill:before {
  content: "\f1ca";
}
.ri-swap-line:before {
  content: "\f1cb";
}
.ri-switch-fill:before {
  content: "\f1cc";
}
.ri-switch-line:before {
  content: "\f1cd";
}
.ri-sword-fill:before {
  content: "\f1ce";
}
.ri-sword-line:before {
  content: "\f1cf";
}
.ri-syringe-fill:before {
  content: "\f1d0";
}
.ri-syringe-line:before {
  content: "\f1d1";
}
.ri-t-box-fill:before {
  content: "\f1d2";
}
.ri-t-box-line:before {
  content: "\f1d3";
}
.ri-t-shirt-2-fill:before {
  content: "\f1d4";
}
.ri-t-shirt-2-line:before {
  content: "\f1d5";
}
.ri-t-shirt-air-fill:before {
  content: "\f1d6";
}
.ri-t-shirt-air-line:before {
  content: "\f1d7";
}
.ri-t-shirt-fill:before {
  content: "\f1d8";
}
.ri-t-shirt-line:before {
  content: "\f1d9";
}
.ri-table-2:before {
  content: "\f1da";
}
.ri-table-2-line:before {
  content: "\f1da";
}
.ri-table-2-fill:before {
  content: "\f1da";
}
.ri-table-alt-fill:before {
  content: "\f1db";
}
.ri-table-alt-line:before {
  content: "\f1dc";
}
.ri-table-fill:before {
  content: "\f1dd";
}
.ri-table-line:before {
  content: "\f1de";
}
.ri-tablet-fill:before {
  content: "\f1df";
}
.ri-tablet-line:before {
  content: "\f1e0";
}
.ri-takeaway-fill:before {
  content: "\f1e1";
}
.ri-takeaway-line:before {
  content: "\f1e2";
}
.ri-taobao-fill:before {
  content: "\f1e3";
}
.ri-taobao-line:before {
  content: "\f1e4";
}
.ri-tape-fill:before {
  content: "\f1e5";
}
.ri-tape-line:before {
  content: "\f1e6";
}
.ri-task-fill:before {
  content: "\f1e7";
}
.ri-task-line:before {
  content: "\f1e8";
}
.ri-taxi-fill:before {
  content: "\f1e9";
}
.ri-taxi-line:before {
  content: "\f1ea";
}
.ri-taxi-wifi-fill:before {
  content: "\f1eb";
}
.ri-taxi-wifi-line:before {
  content: "\f1ec";
}
.ri-team-fill:before {
  content: "\f1ed";
}
.ri-team-line:before {
  content: "\f1ee";
}
.ri-telegram-fill:before {
  content: "\f1ef";
}
.ri-telegram-line:before {
  content: "\f1f0";
}
.ri-temp-cold-fill:before {
  content: "\f1f1";
}
.ri-temp-cold-line:before {
  content: "\f1f2";
}
.ri-temp-hot-fill:before {
  content: "\f1f3";
}
.ri-temp-hot-line:before {
  content: "\f1f4";
}
.ri-terminal-box-fill:before {
  content: "\f1f5";
}
.ri-terminal-box-line:before {
  content: "\f1f6";
}
.ri-terminal-fill:before {
  content: "\f1f7";
}
.ri-terminal-line:before {
  content: "\f1f8";
}
.ri-terminal-window-fill:before {
  content: "\f1f9";
}
.ri-terminal-window-line:before {
  content: "\f1fa";
}
.ri-test-tube-fill:before {
  content: "\f1fb";
}
.ri-test-tube-line:before {
  content: "\f1fc";
}
.ri-text-direction-l:before {
  content: "\f1fd";
}
.ri-text-direction-l-line:before {
  content: "\f1fd";
}
.ri-text-direction-l-fill:before {
  content: "\f1fd";
}
.ri-text-direction-r:before {
  content: "\f1fe";
}
.ri-text-direction-r-line:before {
  content: "\f1fe";
}
.ri-text-direction-r-fill:before {
  content: "\f1fe";
}
.ri-text-spacing:before {
  content: "\f1ff";
}
.ri-text-spacing-line:before {
  content: "\f1ff";
}
.ri-text-spacing-fill:before {
  content: "\f1ff";
}
.ri-text-wrap:before {
  content: "\f200";
}
.ri-text-wrap-line:before {
  content: "\f200";
}
.ri-text-wrap-fill:before {
  content: "\f200";
}
.ri-text:before {
  content: "\f201";
}
.ri-text-line:before {
  content: "\f201";
}
.ri-text-fill:before {
  content: "\f201";
}
.ri-thermometer-fill:before {
  content: "\f202";
}
.ri-thermometer-line:before {
  content: "\f203";
}
.ri-thumb-down-fill:before {
  content: "\f204";
}
.ri-thumb-down-line:before {
  content: "\f205";
}
.ri-thumb-up-fill:before {
  content: "\f206";
}
.ri-thumb-up-line:before {
  content: "\f207";
}
.ri-thunderstorms-fill:before {
  content: "\f208";
}
.ri-thunderstorms-line:before {
  content: "\f209";
}
.ri-ticket-2-fill:before {
  content: "\f20a";
}
.ri-ticket-2-line:before {
  content: "\f20b";
}
.ri-ticket-fill:before {
  content: "\f20c";
}
.ri-ticket-line:before {
  content: "\f20d";
}
.ri-time-fill:before {
  content: "\f20e";
}
.ri-time-line:before {
  content: "\f20f";
}
.ri-timer-2-fill:before {
  content: "\f210";
}
.ri-timer-2-line:before {
  content: "\f211";
}
.ri-timer-fill:before {
  content: "\f212";
}
.ri-timer-flash-fill:before {
  content: "\f213";
}
.ri-timer-flash-line:before {
  content: "\f214";
}
.ri-timer-line:before {
  content: "\f215";
}
.ri-todo-fill:before {
  content: "\f216";
}
.ri-todo-line:before {
  content: "\f217";
}
.ri-toggle-fill:before {
  content: "\f218";
}
.ri-toggle-line:before {
  content: "\f219";
}
.ri-tools-fill:before {
  content: "\f21a";
}
.ri-tools-line:before {
  content: "\f21b";
}
.ri-tornado-fill:before {
  content: "\f21c";
}
.ri-tornado-line:before {
  content: "\f21d";
}
.ri-trademark-fill:before {
  content: "\f21e";
}
.ri-trademark-line:before {
  content: "\f21f";
}
.ri-traffic-light-fill:before {
  content: "\f220";
}
.ri-traffic-light-line:before {
  content: "\f221";
}
.ri-train-fill:before {
  content: "\f222";
}
.ri-train-line:before {
  content: "\f223";
}
.ri-train-wifi-fill:before {
  content: "\f224";
}
.ri-train-wifi-line:before {
  content: "\f225";
}
.ri-translate-2:before {
  content: "\f226";
}
.ri-translate-2-line:before {
  content: "\f226";
}
.ri-translate-2-fill:before {
  content: "\f226";
}
.ri-translate:before {
  content: "\f227";
}
.ri-translate-line:before {
  content: "\f227";
}
.ri-translate-fill:before {
  content: "\f227";
}
.ri-travesti-fill:before {
  content: "\f228";
}
.ri-travesti-line:before {
  content: "\f229";
}
.ri-treasure-map-fill:before {
  content: "\f22a";
}
.ri-treasure-map-line:before {
  content: "\f22b";
}
.ri-trello-fill:before {
  content: "\f22c";
}
.ri-trello-line:before {
  content: "\f22d";
}
.ri-trophy-fill:before {
  content: "\f22e";
}
.ri-trophy-line:before {
  content: "\f22f";
}
.ri-truck-fill:before {
  content: "\f230";
}
.ri-truck-line:before {
  content: "\f231";
}
.ri-tumblr-fill:before {
  content: "\f232";
}
.ri-tumblr-line:before {
  content: "\f233";
}
.ri-tv-2-fill:before {
  content: "\f234";
}
.ri-tv-2-line:before {
  content: "\f235";
}
.ri-tv-fill:before {
  content: "\f236";
}
.ri-tv-line:before {
  content: "\f237";
}
.ri-twitch-fill:before {
  content: "\f238";
}
.ri-twitch-line:before {
  content: "\f239";
}
.ri-twitter-fill:before {
  content: "\f23a";
}
.ri-twitter-line:before {
  content: "\f23b";
}
.ri-typhoon-fill:before {
  content: "\f23c";
}
.ri-typhoon-line:before {
  content: "\f23d";
}
.ri-u-disk-fill:before {
  content: "\f23e";
}
.ri-u-disk-line:before {
  content: "\f23f";
}
.ri-ubuntu-fill:before {
  content: "\f240";
}
.ri-ubuntu-line:before {
  content: "\f241";
}
.ri-umbrella-fill:before {
  content: "\f242";
}
.ri-umbrella-line:before {
  content: "\f243";
}
.ri-underline:before {
  content: "\f244";
}
.ri-underline-line:before {
  content: "\f244";
}
.ri-underline-fill:before {
  content: "\f244";
}
.ri-uninstall-fill:before {
  content: "\f245";
}
.ri-uninstall-line:before {
  content: "\f246";
}
.ri-unsplash-fill:before {
  content: "\f247";
}
.ri-unsplash-line:before {
  content: "\f248";
}
.ri-upload-2-fill:before {
  content: "\f249";
}
.ri-upload-2-line:before {
  content: "\f24a";
}
.ri-upload-cloud-2-fill:before {
  content: "\f24b";
}
.ri-upload-cloud-2-line:before {
  content: "\f24c";
}
.ri-upload-cloud-fill:before {
  content: "\f24d";
}
.ri-upload-cloud-line:before {
  content: "\f24e";
}
.ri-upload-fill:before {
  content: "\f24f";
}
.ri-upload-line:before {
  content: "\f250";
}
.ri-usb-fill:before {
  content: "\f251";
}
.ri-usb-line:before {
  content: "\f252";
}
.ri-user-2-fill:before {
  content: "\f253";
}
.ri-user-2-line:before {
  content: "\f254";
}
.ri-user-3-fill:before {
  content: "\f255";
}
.ri-user-3-line:before {
  content: "\f256";
}
.ri-user-4-fill:before {
  content: "\f257";
}
.ri-user-4-line:before {
  content: "\f258";
}
.ri-user-5-fill:before {
  content: "\f259";
}
.ri-user-5-line:before {
  content: "\f25a";
}
.ri-user-6-fill:before {
  content: "\f25b";
}
.ri-user-6-line:before {
  content: "\f25c";
}
.ri-user-add-fill:before {
  content: "\f25d";
}
.ri-user-add-line:before {
  content: "\f25e";
}
.ri-user-fill:before {
  content: "\f25f";
}
.ri-user-follow-fill:before {
  content: "\f260";
}
.ri-user-follow-line:before {
  content: "\f261";
}
.ri-user-heart-fill:before {
  content: "\f262";
}
.ri-user-heart-line:before {
  content: "\f263";
}
.ri-user-line:before {
  content: "\f264";
}
.ri-user-location-fill:before {
  content: "\f265";
}
.ri-user-location-line:before {
  content: "\f266";
}
.ri-user-received-2-fill:before {
  content: "\f267";
}
.ri-user-received-2-line:before {
  content: "\f268";
}
.ri-user-received-fill:before {
  content: "\f269";
}
.ri-user-received-line:before {
  content: "\f26a";
}
.ri-user-search-fill:before {
  content: "\f26b";
}
.ri-user-search-line:before {
  content: "\f26c";
}
.ri-user-settings-fill:before {
  content: "\f26d";
}
.ri-user-settings-line:before {
  content: "\f26e";
}
.ri-user-shared-2-fill:before {
  content: "\f26f";
}
.ri-user-shared-2-line:before {
  content: "\f270";
}
.ri-user-shared-fill:before {
  content: "\f271";
}
.ri-user-shared-line:before {
  content: "\f272";
}
.ri-user-smile-fill:before {
  content: "\f273";
}
.ri-user-smile-line:before {
  content: "\f274";
}
.ri-user-star-fill:before {
  content: "\f275";
}
.ri-user-star-line:before {
  content: "\f276";
}
.ri-user-unfollow-fill:before {
  content: "\f277";
}
.ri-user-unfollow-line:before {
  content: "\f278";
}
.ri-user-voice-fill:before {
  content: "\f279";
}
.ri-user-voice-line:before {
  content: "\f27a";
}
.ri-video-add-fill:before {
  content: "\f27b";
}
.ri-video-add-line:before {
  content: "\f27c";
}
.ri-video-chat-fill:before {
  content: "\f27d";
}
.ri-video-chat-line:before {
  content: "\f27e";
}
.ri-video-download-fill:before {
  content: "\f27f";
}
.ri-video-download-line:before {
  content: "\f280";
}
.ri-video-fill:before {
  content: "\f281";
}
.ri-video-line:before {
  content: "\f282";
}
.ri-video-upload-fill:before {
  content: "\f283";
}
.ri-video-upload-line:before {
  content: "\f284";
}
.ri-vidicon-2-fill:before {
  content: "\f285";
}
.ri-vidicon-2-line:before {
  content: "\f286";
}
.ri-vidicon-fill:before {
  content: "\f287";
}
.ri-vidicon-line:before {
  content: "\f288";
}
.ri-vimeo-fill:before {
  content: "\f289";
}
.ri-vimeo-line:before {
  content: "\f28a";
}
.ri-vip-crown-2-fill:before {
  content: "\f28b";
}
.ri-vip-crown-2-line:before {
  content: "\f28c";
}
.ri-vip-crown-fill:before {
  content: "\f28d";
}
.ri-vip-crown-line:before {
  content: "\f28e";
}
.ri-vip-diamond-fill:before {
  content: "\f28f";
}
.ri-vip-diamond-line:before {
  content: "\f290";
}
.ri-vip-fill:before {
  content: "\f291";
}
.ri-vip-line:before {
  content: "\f292";
}
.ri-virus-fill:before {
  content: "\f293";
}
.ri-virus-line:before {
  content: "\f294";
}
.ri-visa-fill:before {
  content: "\f295";
}
.ri-visa-line:before {
  content: "\f296";
}
.ri-voice-recognition-fill:before {
  content: "\f297";
}
.ri-voice-recognition-line:before {
  content: "\f298";
}
.ri-voiceprint-fill:before {
  content: "\f299";
}
.ri-voiceprint-line:before {
  content: "\f29a";
}
.ri-volume-down-fill:before {
  content: "\f29b";
}
.ri-volume-down-line:before {
  content: "\f29c";
}
.ri-volume-mute-fill:before {
  content: "\f29d";
}
.ri-volume-mute-line:before {
  content: "\f29e";
}
.ri-volume-off-vibrate-fill:before {
  content: "\f29f";
}
.ri-volume-off-vibrate-line:before {
  content: "\f2a0";
}
.ri-volume-up-fill:before {
  content: "\f2a1";
}
.ri-volume-up-line:before {
  content: "\f2a2";
}
.ri-volume-vibrate-fill:before {
  content: "\f2a3";
}
.ri-volume-vibrate-line:before {
  content: "\f2a4";
}
.ri-vuejs-fill:before {
  content: "\f2a5";
}
.ri-vuejs-line:before {
  content: "\f2a6";
}
.ri-walk-fill:before {
  content: "\f2a7";
}
.ri-walk-line:before {
  content: "\f2a8";
}
.ri-wallet-2-fill:before {
  content: "\f2a9";
}
.ri-wallet-2-line:before {
  content: "\f2aa";
}
.ri-wallet-3-fill:before {
  content: "\f2ab";
}
.ri-wallet-3-line:before {
  content: "\f2ac";
}
.ri-wallet-fill:before {
  content: "\f2ad";
}
.ri-wallet-line:before {
  content: "\f2ae";
}
.ri-water-flash-fill:before {
  content: "\f2af";
}
.ri-water-flash-line:before {
  content: "\f2b0";
}
.ri-webcam-fill:before {
  content: "\f2b1";
}
.ri-webcam-line:before {
  content: "\f2b2";
}
.ri-wechat-2-fill:before {
  content: "\f2b3";
}
.ri-wechat-2-line:before {
  content: "\f2b4";
}
.ri-wechat-fill:before {
  content: "\f2b5";
}
.ri-wechat-line:before {
  content: "\f2b6";
}
.ri-wechat-pay-fill:before {
  content: "\f2b7";
}
.ri-wechat-pay-line:before {
  content: "\f2b8";
}
.ri-weibo-fill:before {
  content: "\f2b9";
}
.ri-weibo-line:before {
  content: "\f2ba";
}
.ri-whatsapp-fill:before {
  content: "\f2bb";
}
.ri-whatsapp-line:before {
  content: "\f2bc";
}
.ri-wheelchair-fill:before {
  content: "\f2bd";
}
.ri-wheelchair-line:before {
  content: "\f2be";
}
.ri-wifi-fill:before {
  content: "\f2bf";
}
.ri-wifi-line:before {
  content: "\f2c0";
}
.ri-wifi-off-fill:before {
  content: "\f2c1";
}
.ri-wifi-off-line:before {
  content: "\f2c2";
}
.ri-window-2-fill:before {
  content: "\f2c3";
}
.ri-window-2-line:before {
  content: "\f2c4";
}
.ri-window-fill:before {
  content: "\f2c5";
}
.ri-window-line:before {
  content: "\f2c6";
}
.ri-windows-fill:before {
  content: "\f2c7";
}
.ri-windows-line:before {
  content: "\f2c8";
}
.ri-windy-fill:before {
  content: "\f2c9";
}
.ri-windy-line:before {
  content: "\f2ca";
}
.ri-wireless-charging-fill:before {
  content: "\f2cb";
}
.ri-wireless-charging-line:before {
  content: "\f2cc";
}
.ri-women-fill:before {
  content: "\f2cd";
}
.ri-women-line:before {
  content: "\f2ce";
}
.ri-wubi-input:before {
  content: "\f2cf";
}
.ri-wubi-input-line:before {
  content: "\f2cf";
}
.ri-wubi-input-fill:before {
  content: "\f2cf";
}
.ri-xbox-fill:before {
  content: "\f2d0";
}
.ri-xbox-line:before {
  content: "\f2d1";
}
.ri-xing-fill:before {
  content: "\f2d2";
}
.ri-xing-line:before {
  content: "\f2d3";
}
.ri-youtube-fill:before {
  content: "\f2d4";
}
.ri-youtube-line:before {
  content: "\f2d5";
}
.ri-zcool-fill:before {
  content: "\f2d6";
}
.ri-zcool-line:before {
  content: "\f2d7";
}
.ri-zhihu-fill:before {
  content: "\f2d8";
}
.ri-zhihu-line:before {
  content: "\f2d9";
}
.ri-zoom-in-fill:before {
  content: "\f2da";
}
.ri-zoom-in-line:before {
  content: "\f2db";
}
.ri-zoom-out-fill:before {
  content: "\f2dc";
}
.ri-zoom-out-line:before {
  content: "\f2dd";
}
.ri-zzz-fill:before {
  content: "\f2de";
}
.ri-zzz-line:before {
  content: "\f2df";
}

@charset 'UTF-8';
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: #2d3436;
  font-size: 14px;
  font-family: "Dosis", Arial, Helvetica, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #2d3436;
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #f27052;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #ee502c;
}
a:active {
  color: #ee502c;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: #b2bec3;
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background: #f27052;
}
::selection {
  color: #fff;
  background: #f27052;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.remix-icon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.remix-icon > * {
  line-height: 1;
}
.remix-icon svg {
  display: inline-block;
}
.remix-icon::before {
  display: none;
}
.remix-icon .remix-icon-icon {
  display: block;
}
.remix-icon[tabindex] {
  cursor: pointer;
}
.remix-icon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.remix-icon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.ant-fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #f27052;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #f27052;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #f27052;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #f27052;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 7px;
}
.ant-alert-content {
  flex: 1 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #eafff8;
  border: 1px solid #eafff8;
}
.ant-alert-success .ant-alert-icon {
  color: #00f7bf;
}
.ant-alert-info {
  background-color: #eafcff;
  border: 1px solid #eafcff;
}
.ant-alert-info .ant-alert-icon {
  color: #1be7ff;
}
.ant-alert-warning {
  background-color: #fff9e9;
  border: 1px solid #fff9e9;
}
.ant-alert-warning .ant-alert-icon {
  color: #ffc212;
}
.ant-alert-error {
  background-color: #ffe7ea;
  border: 1px solid #ffe7ea;
}
.ant-alert-error .ant-alert-icon {
  color: #ff0022;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .remix-icon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .remix-icon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  align-items: flex-start;
  padding: 20px 15px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 23px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 23px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: #2d3436;
  font-size: 16px;
}
.ant-alert-message {
  color: #2d3436;
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description {
  padding-right: 24px;
  padding-left: 23px;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 23px;
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: transparent;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #f27052;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor-fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: #2d3436;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #f27052;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ant-anchor-rtl {
  direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
  padding: 7px 16px 7px 0;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #f27052;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #f0e3e0;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar .ant-image-img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .remix-icon {
  margin: 0;
}
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .remix-icon {
  margin: 0;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .remix-icon {
  margin: 0;
}
.ant-avatar-square {
  border-radius: 7px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: #2d3436;
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #f27052;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff0022;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-count.remix-icon-spin,
.ant-badge-dot.remix-icon-spin,
.ant-badge .ant-scroll-number-custom-component.remix-icon-spin {
  -webkit-animation: antBadgeLoadingCircle 1s infinite linear;
          animation: antBadgeLoadingCircle 1s infinite linear;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #00f7bf;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #f27052;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f27052;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
          animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #636e72;
}
.ant-badge-status-error {
  background-color: #ff0022;
}
.ant-badge-status-warning {
  background-color: #ffc212;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: #2d3436;
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
          animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  -webkit-animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  -webkit-animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
          animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
@-webkit-keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
  direction: ltr;
}
.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@-webkit-keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@-webkit-keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@-webkit-keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@-webkit-keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%;
  }
}
@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%;
  }
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #f27052;
  border-radius: 4px;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentColor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-badge-rtl {
  direction: rtl;
}
.ant-badge-rtl .ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-count,
.ant-badge-rtl .ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-dot,
.ant-badge-rtl .ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor;
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  color: #f27052;
  font-size: 14px;
}
.ant-breadcrumb .remix-icon {
  font-size: 14px;
}
.ant-breadcrumb a {
  color: #f27052;
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #ee502c;
}
.ant-breadcrumb > span:last-child {
  color: #636e72;
}
.ant-breadcrumb > span:last-child a {
  color: #636e72;
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: #b2bec3;
}
.ant-breadcrumb-link > .remix-icon + span,
.ant-breadcrumb-link > .remix-icon + a {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .remix-icon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .remix-icon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .remix-icon + a {
  margin-right: 4px;
  margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .remix-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-btn {
  line-height: 16px;
  position: relative;
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 40px;
  padding: 8px 24px;
  font-size: 14px;
  border-radius: 7px;
  color: #2d3436;
  border-color: #dfe6e9;
  background: #fff;
}
.ant-btn > .remix-icon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 48px;
  padding: 10.4px 24px;
  font-size: 16px;
  border-radius: 7px;
}
.ant-btn-sm {
  height: 32px;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 7px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #ff9b80;
  border-color: #ff9b80;
  background: #fff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #cc513b;
  border-color: #cc513b;
  background: #fff;
}
.ant-btn:active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  border-color: #f27052;
  background: #f27052;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff9b80;
  background: #ff9b80;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  border-color: #cc513b;
  background: #cc513b;
}
.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #ee502c;
  border-left-color: #ee502c;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #dfe6e9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #ee502c;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #dfe6e9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #ee502c;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #dfe6e9;
}
.ant-btn-ghost {
  color: #2d3436;
  border-color: #dfe6e9;
  background: transparent;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #ff9b80;
  border-color: #ff9b80;
  background: transparent;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #cc513b;
  border-color: #cc513b;
  background: transparent;
}
.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: #2d3436;
  border-color: #dfe6e9;
  background: #fff;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #ff9b80;
  border-color: #ff9b80;
  background: #fff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #cc513b;
  border-color: #cc513b;
  background: #fff;
}
.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  border-color: #ff0022;
  background: #ff0022;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #ff293e;
  background: #ff293e;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  border-color: #d90024;
  background: #d90024;
}
.ant-btn-danger:active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #f27052;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #ff9b80;
  border-color: #ff9b80;
  background: transparent;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #cc513b;
  border-color: #cc513b;
  background: transparent;
}
.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: #b2bec3;
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: #2d3436;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #ff9b80;
  border-color: #ff9b80;
  background: transparent;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentColor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #cc513b;
  border-color: #cc513b;
  background: transparent;
}
.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #2d3436;
  background: #f7fafc;
  border-color: transparent;
}
.ant-btn-text:active {
  color: #2d3436;
  background: #f7fafc;
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: #b2bec3;
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #ff0022;
  border-color: #ff0022;
  background: #fff;
}
.ant-btn-dangerous > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff293e;
  border-color: #ff293e;
  background: #fff;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #d90024;
  border-color: #d90024;
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #ff0022;
  background: #ff0022;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff293e;
  background: #ff293e;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #d90024;
  background: #d90024;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #ff0022;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff9b80;
  border-color: #ff9b80;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #cc513b;
  border-color: #cc513b;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff293e;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d90024;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: #b2bec3;
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #ff0022;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff9b80;
  border-color: #ff9b80;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #cc513b;
  border-color: #cc513b;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff293e;
  border-color: transparent;
  background: #f7fafc;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d90024;
  border-color: transparent;
  background: #f7fafc;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: #b2bec3;
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding: 6.4px 0;
  font-size: 16px;
  border-radius: 7px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 48px;
  height: 48px;
  padding: 8.9px 0;
  font-size: 18px;
  border-radius: 7px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 32px;
  height: 32px;
  padding: 4px 0;
  font-size: 14px;
  border-radius: 7px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .remix-icon {
  display: flex;
  justify-content: center;
}
a.ant-btn-icon-only {
  vertical-align: -1px;
}
a.ant-btn-icon-only > .remix-icon {
  display: inline;
}
.ant-btn-round {
  height: 40px;
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-lg {
  height: 48px;
  padding: 10.4px 24px;
  font-size: 16px;
  border-radius: 48px;
}
.ant-btn-round.ant-btn-sm {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 40px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 48px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 32px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .remix-icon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .remix-icon.remix-icon-plus > svg,
.ant-btn .remix-icon.remix-icon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .remix-icon {
  padding-right: 8px;
  -webkit-animation: none;
          animation: none;
}
.ant-btn > .ant-btn-loading-icon .remix-icon svg {
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.ant-btn > .ant-btn-loading-icon:only-child .remix-icon {
  padding-right: 0;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 48px;
  padding: 10.4px 24px;
  font-size: 16px;
  border-radius: 0;
}
.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
  width: 48px;
  height: 48px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 32px;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 0;
}
.ant-btn-group-sm > .ant-btn > .remix-icon,
.ant-btn-group-sm > span > .ant-btn > .remix-icon {
  font-size: 14px;
}
.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 7px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 7px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 7px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 7px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 7px 7px 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 7px 0 0 7px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 7px 7px 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 7px 0 0 7px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .remix-icon + span,
.ant-btn > span + .remix-icon {
  margin-left: 8px;
}
.ant-btn.ant-btn-background-ghost {
  color: #f27052 !important;
  border-color: #f27052 !important;
}
.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #ff9b80;
  border-color: #ff9b80;
}
.ant-btn.ant-btn-background-ghost:active {
  color: #cc513b;
  border-color: #cc513b;
}
.ant-btn.ant-btn-background-ghost[disabled] {
  color: #b2bec3;
  background: transparent;
  border-color: #dfe6e9;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #f27052;
  border-color: #f27052;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #ff9b80;
  border-color: #ff9b80;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #cc513b;
  border-color: #cc513b;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff0022;
  border-color: #ff0022;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff293e;
  border-color: #ff293e;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d90024;
  border-color: #d90024;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff0022;
  border-color: #ff0022;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff293e;
  border-color: #ff293e;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d90024;
  border-color: #d90024;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff0022;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff293e;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d90024;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: #b2bec3;
  border-color: #dfe6e9;
  background: #dfe6e9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.remix-icon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 38px;
}
a.ant-btn-lg {
  line-height: 46px;
}
a.ant-btn-sm {
  line-height: 30px;
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #ee502c;
  border-left-color: #dfe6e9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #dfe6e9;
  border-left-color: #ee502c;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .remix-icon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn > .ant-btn-loading-icon:only-child .remix-icon {
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-rtl.ant-btn > .remix-icon + span,
.ant-btn-rtl.ant-btn > span + .remix-icon {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 7px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 7px 7px;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #f0e3e0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #f27052;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: #2d3436;
  line-height: 1.5715;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #f27052;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #2d3436;
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 7px;
}
.ant-card-rtl {
  direction: rtl;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #f0f0f0;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #2d3436;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 7px 7px 0 0;
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card-head .ant-tabs-top {
  clear: both;
  margin-bottom: -17px;
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-top-bar {
  border-bottom: 1px solid #f0f0f0;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
}
.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-card-body {
  padding: 24px;
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.ant-card-rtl .ant-card-grid {
  float: right;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 7px 7px 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-rtl .ant-card-actions > li {
  float: right;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #f27052;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .remix-icon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .remix-icon:hover {
  color: #f27052;
}
.ant-card-actions > li > span > .remix-icon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: #2d3436;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 7px;
  -webkit-animation: card-loading 1.4s ease infinite;
          animation: card-loading 1.4s ease infinite;
}
@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 2px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
@-webkit-keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-cascader-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
  border-color: #f27052;
}
.ant-cascader-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f27052;
  border-radius: 7px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-cascader-checkbox:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox::after {
  visibility: visible;
}
.ant-cascader-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 2px solid #dfe6e9;
  border-radius: 7px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-cascader-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
  background-color: #f27052;
  border-color: #f27052;
}
.ant-cascader-checkbox-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  border-color: #b2bec3;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-input {
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
  background-color: #dfe6e9;
  border-color: #dfe6e9 !important;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  border-color: #dfe6e9;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-cascader-checkbox-disabled + span {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-disabled::after {
  visibility: hidden;
}
.ant-cascader-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-wrapper + .ant-cascader-checkbox-wrapper {
  margin-left: 8px;
}
.ant-cascader-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-cascader-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-cascader-checkbox-group-item {
  margin-right: 8px;
}
.ant-cascader-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 0;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
  background-color: #fff;
  border-color: #dfe6e9;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #f27052;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  background-color: #b2bec3;
  border-color: #b2bec3;
}
.ant-cascader-checkbox-rtl {
  direction: rtl;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 8px;
}
.ant-cascader {
  width: 184px;
}
.ant-cascader-checkbox {
  top: 0;
  margin-right: 8px;
}
.ant-cascader-menus {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.ant-cascader-menus.ant-cascader-menu-empty .ant-cascader-menu {
  width: 100%;
  height: auto;
}
.ant-cascader-menu {
  min-width: 111px;
  height: 180px;
  margin: 0;
  margin: -8px 0;
  padding: 8px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 12px 16px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: #b2bec3;
  cursor: default;
  pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 400;
  background-color: #f0e3e0;
}
.ant-cascader-menu-item-content {
  flex: auto;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: #b2bec3;
}
.ant-cascader-menu-item-keyword {
  color: #ff0022;
}
.ant-cascader-rtl .ant-cascader-menu-item-expand-icon,
.ant-cascader-rtl .ant-cascader-menu-item-loading-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-cascader-rtl .ant-cascader-checkbox {
  top: 0;
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f27052;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f27052;
  border-radius: 7px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 2px solid #dfe6e9;
  border-radius: 7px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f27052;
  border-color: #f27052;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #b2bec3;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #dfe6e9;
  border-color: #dfe6e9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #dfe6e9;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled + span {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #dfe6e9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #f27052;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: #b2bec3;
  border-color: #b2bec3;
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background-color: #ffffff;
  border: 1px solid #dfe6e9;
  border-bottom: 0;
  border-radius: 7px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #dfe6e9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 7px 7px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 38px !important;
  color: #2d3436;
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 16px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 38px !important;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 24px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-content {
  color: #2d3436;
  background-color: #ffffff;
  border-top: 1px solid #dfe6e9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px 38px !important;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 7px 7px;
}
.ant-collapse-borderless {
  background-color: #ffffff;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #dfe6e9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 38px !important;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 16px;
  padding-left: 0;
}
.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 24px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  padding: 8px 15px 8px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker.ant-picker-disabled {
  background: #dfe6e9;
  border-color: #dfe6e9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #b2bec3;
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 15px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::-moz-placeholder {
  opacity: 1;
}
.ant-picker-input > input:-ms-input-placeholder {
  color: #b2bec3;
  -ms-user-select: none;
      user-select: none;
}
.ant-picker-input > input::placeholder {
  color: #b2bec3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input-disabled {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-picker-input > input[disabled] {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 10.5px 15px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 4px 7px;
}
.ant-picker-input > input-rtl {
  direction: rtl;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #b2bec3;
}
.ant-picker-large {
  padding: 10.5px 15px 10.5px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 4px 7px 4px;
}
.ant-picker-suffix {
  align-self: center;
  margin-left: 4px;
  color: #b2bec3;
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: #b2bec3;
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: #b2bec3;
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 15px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 15px;
  background: #f27052;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 1.66666667px;
  display: block;
  transform: rotate(-45deg);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 1.66666667px;
  display: block;
  transform: rotate(135deg);
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 6.66666667px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 16px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #f27052;
  background: #f27052;
  border-color: #ecaa9b;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 22.5px;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  transition: left 0.3s ease-out;
}
.ant-picker-range-arrow::after {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 10px;
  height: 10px;
  border: 5px solid #f0f0f0;
  border-color: #fff #fff transparent transparent;
  content: '';
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #f27052;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 316px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: #2d3436;
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: #b2bec3;
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: #2d3436;
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #f27052;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: #2d3436;
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: #b2bec3;
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: #2d3436;
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 7px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #f27052;
  border-radius: 7px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #f0e3e0;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #f27052;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #f0e3e0;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #f9beb1;
  border-bottom: 1px dashed #f9beb1;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #ecaa9b;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 7px 0 0 7px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 7px 7px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #ecaa9b;
  transition: all 0.3s;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #f9beb1;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #f9beb1;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.ant-picker-cell-disabled {
  color: #b2bec3;
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #b2bec3;
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
  padding: 0 16px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #f27052;
}
.ant-picker-today-btn:hover {
  color: #ee502c;
}
.ant-picker-today-btn:active {
  color: #ee502c;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 20px;
  border-left: 1px dashed #f9beb1;
  border-radius: 7px 0 0 7px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 20px;
  border-right: 1px dashed #f9beb1;
  border-radius: 0 7px 7px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 20px;
  border-right: 1px dashed #f9beb1;
  border-radius: 0 7px 7px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 20px;
  border-left: 1px dashed #f9beb1;
  border-radius: 7px 0 0 7px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 16px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #f27052;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 16px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 280px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 40px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 40px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 184px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 186px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
  background: rgba(240, 227, 224, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0 0 0 6px;
  color: #2d3436;
  line-height: 40px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #f0e3e0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: #b2bec3;
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable-next-line selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 15px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 15px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 7px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 7px 7px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 7px 0 0 7px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #f9beb1;
  border-left: none;
  border-radius: 0 7px 7px 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #f9beb1;
  border-radius: 7px 0 0 7px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #f9beb1;
  border-left: 1px dashed #f9beb1;
  border-radius: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: #2d3436;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: #2d3436;
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  border-radius: 7px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 24px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}
.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1 1;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 24px 38px;
  border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 16px 38px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 24px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: #2d3436;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::before {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::after {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left .ant-divider-inner-text {
  padding-left: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::before {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::after {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right .ant-divider-inner-text {
  padding-right: 0;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: width 0s ease 0.3s, height 0s ease 0.3s;
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-drawer-left {
  left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          animation: antdDrawerFadeIn 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  flex: 1 1;
  margin: 0;
  color: #2d3436;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  color: #2d3436;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 7px 7px 0 0;
}
.ant-drawer-header-title {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
}
.ant-drawer-header-close-only {
  padding-bottom: 0;
  border: none;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 24px 24px;
  border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(45, 52, 54, 0.4);
  opacity: 0;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff0022;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff0022;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .remix-icon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .remix-icon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .remix-icon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 10px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 10px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 6.2px;
  border-color: transparent #fff #fff transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-color: #fff transparent transparent #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 8px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 7px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 16px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-title-content {
  flex: auto;
}
.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 12px 16px;
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #f27052;
  background-color: #f0e3e0;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: #b2bec3;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 28px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: #b2bec3;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #f27052;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .remix-icon.remix-icon-down,
.ant-dropdown-link > .remix-icon.remix-icon-down,
.ant-dropdown-button > .remix-icon.remix-icon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading,
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
  display: block;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .remix-icon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .remix-icon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .remix-icon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #f27052;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .remix-icon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .remix-icon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .remix-icon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .remix-icon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 16px;
  padding-left: 28px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: #b2bec3;
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: #b2bec3;
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}
.ant-form-item .ant-upload {
  background: transparent;
}
.ant-form-item .ant-upload.ant-upload-drag {
  background: #fafafa;
}
.ant-form-item input[type='radio'],
.ant-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-form-item .ant-radio-inline,
.ant-form-item .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
.ant-form-item .ant-radio-inline:first-child,
.ant-form-item .ant-checkbox-inline:first-child {
  margin-left: 0;
}
.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
  display: block;
}
.ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-item .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: 100%;
}
.ant-form-item .ant-picker-calendar-year-select,
.ant-form-item .ant-picker-calendar-month-select,
.ant-form-item .ant-input-group .ant-select,
.ant-form-item .ant-input-group .ant-cascader-picker,
.ant-form-item .ant-input-number-group .ant-select,
.ant-form-item .ant-input-number-group .ant-cascader-picker {
  width: auto;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
}
.ant-form-horizontal .ant-form-item-control:not(.ant-col) {
  min-width: 0;
}
.ant-form-vertical .ant-form-item {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain-error {
  color: #ff0022;
}
.ant-form-item-explain-warning {
  color: #ffc212;
}
.ant-form-item-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}
.ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  right: 32px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
.ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 19px;
}
.ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: 32px;
}
.ant-form-item-has-feedback .ant-picker {
  padding-right: 33.2px;
}
.ant-form-item-has-feedback .ant-picker-large {
  padding-right: 33.2px;
}
.ant-form-item-has-feedback .ant-picker-small {
  padding-right: 25.2px;
}
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 40px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #00f7bf;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #ffc212;
}
.ant-form-item-has-warning :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-warning :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-form-item-has-warning :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-warning :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background-color: #fff;
  border-color: #ffc212;
}
.ant-form-item-has-warning :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-form-item-has-warning :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-form-item-has-warning :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused,
.ant-form-item-has-warning :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffd43b;
  box-shadow: 0 0 0 2px rgba(255, 194, 18, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffd43b;
  box-shadow: 0 0 0 2px rgba(255, 194, 18, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-warning .ant-input-prefix,
.ant-form-item-has-warning .ant-input-number-prefix {
  color: #ffc212;
}
.ant-form-item-has-warning .ant-input-group-addon,
.ant-form-item-has-warning .ant-input-number-group-addon {
  color: #ffc212;
  border-color: #ffc212;
}
.ant-form-item-has-warning .has-feedback {
  color: #ffc212;
}
.ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #ffc212;
  -webkit-animation-name: diffZoomIn3 !important;
          animation-name: diffZoomIn3 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ffc212 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ffd43b;
  box-shadow: 0 0 0 2px rgba(255, 194, 18, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
  background-color: #fff;
  border-color: #ffc212;
}
.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker:focus {
  border-color: #ffd43b;
  box-shadow: 0 0 0 2px rgba(255, 194, 18, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ffc212;
}
.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffd43b;
  box-shadow: 0 0 0 2px rgba(255, 194, 18, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-error .ant-form-item-split {
  color: #ff0022;
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background-color: #fff;
  border-color: #ff0022;
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff293e;
  box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff293e;
  box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-error .ant-input-prefix,
.ant-form-item-has-error .ant-input-number-prefix {
  color: #ff0022;
}
.ant-form-item-has-error .ant-input-group-addon,
.ant-form-item-has-error .ant-input-number-group-addon {
  color: #ff0022;
  border-color: #ff0022;
}
.ant-form-item-has-error .has-feedback {
  color: #ff0022;
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #ff0022;
  -webkit-animation-name: diffZoomIn2 !important;
          animation-name: diffZoomIn2 !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ff0022 !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ff293e;
  box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-form-item-has-error .ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 0;
  box-shadow: none;
}
.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff0022;
}
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: #fff;
  border-color: #ff0022;
}
.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus {
  border-color: #ff293e;
  box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff0022;
}
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff0022;
}
.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff293e;
  box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-error .ant-cascader-picker:hover .ant-cascader-picker-label:hover + .ant-cascader-input.ant-input {
  border-color: #ff0022;
}
.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
  background-color: #fff;
  border-color: #ff293e;
  box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-has-error .ant-transfer-list {
  border-color: #ff0022;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #dfe6e9;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper {
  border-color: #ff0022 !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #ff0022;
}
.ant-form-item-has-error .ant-mentions {
  border-color: #ff0022 !important;
}
.ant-form-item-has-error .ant-mentions-focused,
.ant-form-item-has-error .ant-mentions:focus {
  border-color: #ff293e;
  box-shadow: 0 0 0 2px rgba(255, 0, 34, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #f27052;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #dfe6e9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 32px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 32px;
}
.ant-form-large .ant-form-item-label > label {
  height: 48px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 48px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
  transition: margin-bottom 0.3s 0.017s linear;
}
.ant-form-item-with-help {
  margin-bottom: 0;
  transition: none;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 40px;
  color: #2d3436;
  font-size: 14px;
}
.ant-form-item-label > label > .remix-icon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff0022;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  -ms-writing-mode: lr-tb;
      writing-mode: horizontal-tb;
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 40px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-item-explain-connected {
  height: 0;
  min-height: 0;
  opacity: 0;
}
.ant-form-item-extra {
  min-height: 24px;
}
.ant-form-item .ant-input-textarea-show-count::after {
  margin-bottom: -22px;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  min-height: 24px;
  opacity: 1;
}
.ant-show-help {
  transition: height 0.3s linear, min-height 0.3s linear, margin-bottom 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-leave {
  min-height: 24px;
}
.ant-show-help-leave-active {
  min-height: 0;
}
.ant-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-appear,
.ant-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.ant-show-help-item-appear-active,
.ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}
@-webkit-keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 15px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 15px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 15px;
  padding-left: 33.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 15px;
  padding-left: 33.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  display: block;
  width: 100%;
  height: auto;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-image-mask-info .remix-icon {
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.ant-zoom-enter,
.ant-image-preview.antzoom-appear {
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(45, 52, 54, 0.4);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: -webkit-grab;
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  color: #636e72;
  list-style: none;
  background: rgba(45, 52, 54, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 16px;
  padding: 16px;
  cursor: pointer;
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(99, 110, 114, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  color: #636e72;
  background: rgba(45, 52, 54, 0.1);
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled {
  color: rgba(99, 110, 114, 0.25);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .remix-icon,
.ant-image-preview-switch-right-disabled > .remix-icon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .remix-icon,
.ant-image-preview-switch-right > .remix-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 10px;
}
.ant-image-preview-switch-right {
  right: 10px;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 15px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  display: inline-flex;
}
.ant-input-affix-wrapper::-moz-placeholder {
  opacity: 1;
}
.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #b2bec3;
  -ms-user-select: none;
      user-select: none;
}
.ant-input-affix-wrapper::placeholder {
  color: #b2bec3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper[disabled] {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 10.5px 15px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 4px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none !important;
}
.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-show-count-suffix {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-show-count-has-suffix {
  margin-right: 2px;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.remix-icon.ant-input-clear-icon {
  margin: 0;
  color: #b2bec3;
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.remix-icon.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.remix-icon.ant-input-clear-icon:active {
  color: #2d3436;
}
.remix-icon.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.remix-icon.ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}
.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 15px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
}
.ant-input::-moz-placeholder {
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #b2bec3;
  -ms-user-select: none;
      user-select: none;
}
.ant-input::placeholder {
  color: #b2bec3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-input[disabled] {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 10.5px 15px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 4px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 15px;
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #f0f3f5;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -9px -15px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #f27052;
}
.ant-input-group-addon .ant-cascader-picker {
  margin: -9px -16px;
  background-color: transparent;
}
.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 10.5px 15px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 4px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 48px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 32px;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 7px 0 0 7px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 7px 7px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #dfe6e9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #dfe6e9;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 7px 0 0 7px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 7px 0 0 7px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 7px 7px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 7px 7px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 7px 0 0 7px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 7px 7px 0;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: #ee502c;
}
.ant-input[type='color'] {
  height: 40px;
}
.ant-input[type='color'].ant-input-lg {
  height: 48px;
}
.ant-input[type='color'].ant-input-sm {
  height: 32px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}
.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #ee502c;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #ee502c;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 7px 7px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 40px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 48px;
}
.ant-input-search-small .ant-input-search-button {
  height: 32px;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #ee502c;
  border-left-color: #dfe6e9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #ee502c;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 7px 0 0 7px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 40px;
  }
  .ant-input-lg {
    height: 48px;
  }
  .ant-input-sm {
    height: 32px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}
.ant-input-number-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 15px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  position: static;
  display: inline-flex;
  width: 90px;
  padding: 0;
  -webkit-padding-start: 15px;
          padding-inline-start: 15px;
}
.ant-input-number-affix-wrapper::-moz-placeholder {
  opacity: 1;
}
.ant-input-number-affix-wrapper:-ms-input-placeholder {
  color: #b2bec3;
  -ms-user-select: none;
      user-select: none;
}
.ant-input-number-affix-wrapper::placeholder {
  color: #b2bec3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input-number-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-input-number-affix-wrapper:focus,
.ant-input-rtl .ant-input-number-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-disabled {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper-disabled:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-input-number-affix-wrapper[disabled] {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-input-number-affix-wrapper-borderless,
.ant-input-number-affix-wrapper-borderless:hover,
.ant-input-number-affix-wrapper-borderless:focus,
.ant-input-number-affix-wrapper-borderless-focused,
.ant-input-number-affix-wrapper-borderless-disabled,
.ant-input-number-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-affix-wrapper-lg {
  padding: 10.5px 15px;
  font-size: 16px;
}
.ant-input-number-affix-wrapper-sm {
  padding: 4px 7px;
}
.ant-input-number-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
  z-index: 1;
}
.ant-input-rtl .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
  background: transparent;
}
.ant-input-number-affix-wrapper > div.ant-input-number {
  width: 100%;
  border: none;
  outline: none;
}
.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}
.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 0;
}
.ant-input-number-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-number-prefix {
  display: flex;
  flex: none;
  align-items: center;
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
}
.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 8px 15px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
}
.ant-input-number::-moz-placeholder {
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #b2bec3;
  -ms-user-select: none;
      user-select: none;
}
.ant-input-number::placeholder {
  color: #b2bec3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  padding: 10.5px 15px;
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 4px 7px;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-number-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-number-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-number-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap,
.ant-input-number-group > .ant-input-number {
  display: table-cell;
}
.ant-input-number-group-addon:not(:first-child):not(:last-child),
.ant-input-number-group-wrap:not(:first-child):not(:last-child),
.ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-number-group-wrap > * {
  display: block !important;
}
.ant-input-number-group .ant-input-number {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-number-group .ant-input-number:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}
.ant-input-number-group-addon {
  position: relative;
  padding: 0 15px;
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #f0f3f5;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s;
}
.ant-input-number-group-addon .ant-select {
  margin: -9px -15px;
}
.ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #f27052;
}
.ant-input-number-group-addon .ant-cascader-picker {
  margin: -9px -16px;
  background-color: transparent;
}
.ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-number-group > .ant-input-number:first-child,
.ant-input-number-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group-addon:first-child {
  border-right: 0;
}
.ant-input-number-group-addon:last-child {
  border-left: 0;
}
.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group-lg .ant-input-number,
.ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 10.5px 15px;
  font-size: 16px;
}
.ant-input-number-group-sm .ant-input-number,
.ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 4px 7px;
}
.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 48px;
}
.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 32px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 7px 0 0 7px;
}
.ant-input-number-group > .ant-input-number-rtl:first-child,
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-radius: 0 7px 7px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #dfe6e9;
  border-left: 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #dfe6e9;
}
.ant-input-number-group-rtl.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-rtl.ant-input-number-group-addon:last-child {
  border-radius: 7px 0 0 7px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-radius: 7px 0 0 7px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-radius: 0 7px 7px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 7px 7px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 7px 0 0 7px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 7px 7px 0;
}
.ant-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  border-left: 1px solid #dfe6e9;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #ffffff;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #ee502c;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 38px;
  padding: 0 15px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 7px;
  outline: 0;
  transition: all 0.3s linear;
  -webkit-appearance: textfield !important;
     -moz-appearance: textfield !important;
          appearance: textfield !important;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
}
.ant-input-number-input::-moz-placeholder {
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #b2bec3;
  -ms-user-select: none;
      user-select: none;
}
.ant-input-number-input::placeholder {
  color: #b2bec3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 46px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 30px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 7px 7px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap,
.ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 7px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #dfe6e9;
  border-bottom-right-radius: 7px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: #b2bec3;
}
.ant-input-number-borderless {
  box-shadow: none;
}
.ant-input-number-out-of-range input {
  color: #ff0022;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler {
  border-right: 1px solid #dfe6e9;
  border-left: 0;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
}
.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}
.ant-input-number-rtl .ant-input-number-handler-up {
  border-top-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 24px;
  color: #2d3436;
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: #2d3436;
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 7px 7px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 7px 0 0 7px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: #2d3436;
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: #2d3436;
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 24px;
  color: #b2bec3;
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  color: #2d3436;
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 24px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: #2d3436;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-meta-title > a {
  color: #2d3436;
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #f27052;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-list-empty {
  padding: 24px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 24px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 24px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 16px;
  color: #2d3436;
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 24px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 24px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #dfe6e9;
  border-radius: 7px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 38px;
  padding-left: 38px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 38px;
  padding-left: 38px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 38px;
  padding-left: 38px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 24px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 24px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 24px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  width: 100%;
  min-width: 0;
  padding: 8px 15px;
  color: #2d3436;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::-moz-placeholder {
  opacity: 1;
}
.ant-mentions:-ms-input-placeholder {
  color: #b2bec3;
  -ms-user-select: none;
      user-select: none;
}
.ant-mentions::placeholder {
  color: #b2bec3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-disabled {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-mentions[disabled] {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  padding: 10.5px 15px;
  font-size: 16px;
}
.ant-mentions-sm {
  padding: 4px 7px;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-mentions-disabled > textarea {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-mentions-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 38px;
  margin: 0;
  padding: 8px 15px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
    -o-tab-size: inherit;
       tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
}
.ant-mentions > textarea::-moz-placeholder {
  opacity: 1;
}
.ant-mentions > textarea:-ms-input-placeholder {
  color: #b2bec3;
  -ms-user-select: none;
      user-select: none;
}
.ant-mentions > textarea::placeholder {
  color: #b2bec3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 7px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 16px;
  overflow: hidden;
  color: #2d3436;
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 7px 7px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 7px 7px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: #b2bec3;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: #2d3436;
  font-weight: 400;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-menu-item-danger.ant-menu-item {
  color: #ff0022;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff0022;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #ff0022;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff0022;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff0022;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #ff0022;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff0022;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff0022;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff0022;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: #636e72;
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #f27052;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-overflow {
  display: flex;
}
.ant-menu-overflow-item {
  flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: #636e72;
  font-size: 12px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #f27052;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #f0e3e0;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
  transition: color 0.3s;
}
.ant-menu-item a {
  color: #636e72;
}
.ant-menu-item a:hover {
  color: #f27052;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: #636e72;
}
.ant-menu-item > .ant-badge a:hover {
  color: #f27052;
}
.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
}
.ant-menu-item-divider-dashed {
  border-style: dashed;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #f27052;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #f27052;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f0e3e0;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .remix-icon,
.ant-menu-submenu-title .remix-icon {
  min-width: 14px;
  font-size: 24px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .remix-icon + span,
.ant-menu-submenu-title .remix-icon + span {
  margin-left: 8px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .remix-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .remix-icon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu-item:focus-visible,
.ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #f27052;
}
.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 7px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 7px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #636e72;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentColor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #f27052;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #f27052;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #f27052;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #f27052;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: #636e72;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #f27052;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #f27052;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 2px solid #f27052;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 48px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 48px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .remix-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .remix-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .remix-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .remix-icon {
  margin: 0;
  font-size: 16px;
  line-height: 48px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .remix-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .remix-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .remix-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .remix-icon + span {
  display: inline-block;
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .remix-icon {
  display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .remix-icon {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: #636e72;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #ffffff;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: #b2bec3 !important;
  background: none;
  cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: #b2bec3 !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: #b2bec3 !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: #b2bec3 !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #f27052;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #f27052;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f27052;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .remix-icon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .remix-icon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #f27052;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .remix-icon,
.ant-menu-rtl .ant-menu-submenu-title .remix-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .remix-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .remix-icon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .remix-icon {
  color: #00f7bf;
}
.ant-message-error .remix-icon {
  color: #ff0022;
}
.ant-message-warning .remix-icon {
  color: #ffc212;
}
.ant-message-info .remix-icon,
.ant-message-loading .remix-icon {
  color: #1be7ff;
}
.ant-message .remix-icon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
          animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .remix-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.antzoom-appear {
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(45, 52, 54, 0.4);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: #2d3436;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 7px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: #2d3436;
  background: #fff;
  border-bottom: 1px solid #dfe6e9;
  border-radius: 7px 7px 0 0;
}
.ant-modal-body {
  padding: 48px 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 24px 24px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #dfe6e9;
  border-radius: 0 0 7px 7px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #2d3436;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: #2d3436;
  font-size: 14px;
}
.ant-modal-confirm-body > .remix-icon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .remix-icon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .remix-icon {
  color: #ff0022;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .remix-icon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .remix-icon {
  color: #ffc212;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .remix-icon {
  color: #1be7ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .remix-icon {
  color: #00f7bf;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: auto;
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .remix-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .remix-icon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
          animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder {
  position: relative;
}
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: #2d3436;
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.remix-icon.ant-notification-notice-icon-success {
  color: #00f7bf;
}
.remix-icon.ant-notification-notice-icon-info {
  color: #1be7ff;
}
.remix-icon.ant-notification-notice-icon-warning {
  color: #ffc212;
}
.remix-icon.ant-notification-notice-icon-error {
  color: #ff0022;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
          animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
          animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 24px 38px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 16px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #f27052;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #ee502c;
}
.ant-page-header-back-button:active {
  color: #ee502c;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: #2d3436;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  margin-left: 12px;
  white-space: unset;
}
.ant-page-header-heading-extra > *:first-child {
  margin-left: 0;
}
.ant-page-header-content {
  padding-top: 16px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 40px;
  margin-right: 8px;
  line-height: 38px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 40px;
  height: 40px;
  margin-right: 8px;
  font-family: "Dosis", Arial, Helvetica, sans-serif;
  line-height: 38px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #2d3436;
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #f27052;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #f27052;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #f27052;
}
.ant-pagination-item-active a {
  color: #f27052;
}
.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
  border-color: #ee502c;
}
.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a {
  color: #ee502c;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #f27052;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: #b2bec3;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 40px;
  height: 40px;
  color: #2d3436;
  font-family: "Dosis", Arial, Helvetica, sans-serif;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: #2d3436;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #ee502c;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #f27052;
  border-color: #f27052;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: #b2bec3;
  border-color: #dfe6e9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 40px;
  margin-left: 8px;
  line-height: 40px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 15px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  width: 50px;
  height: 40px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #b2bec3;
  -ms-user-select: none;
      user-select: none;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #b2bec3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #dfe6e9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 10.5px 15px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 4px 7px;
}
.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #f27052;
}
.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: #b2bec3;
  background: #dfe6e9;
  border-color: #dfe6e9;
  cursor: not-allowed;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 4px 7px;
  width: 44px;
  height: 32px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #dfe6e9;
  border-color: #dfe6e9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: #b2bec3;
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #b2bec3;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: #b2bec3;
  background: #dfe6e9;
  border-color: #dfe6e9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: #b2bec3;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.ant-popconfirm {
  z-index: 1060;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 7px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 24px 4px;
  color: #2d3436;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-popover-message > .remix-icon {
  position: absolute;
  top: 8.0005px;
  color: #ffc212;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 6px;
  height: 6px;
  margin: auto;
  background-color: #fff;
  content: '';
  pointer-events: auto;
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 1.51471863px;
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-4.24264069px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 1.51471863px;
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(4.24264069px) rotate(45deg);
}
.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 1.51471863px;
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(4.24264069px) rotate(45deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 1.51471863px;
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-4.24264069px) rotate(45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
  background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
  background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
  background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
  background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
  background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
  background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
  background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
  background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
  background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
  background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
  background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 24px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #f27052;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .remix-icon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #dfe6e9;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #dfe6e9;
}
.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
          animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #f27052;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #f27052;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00f7bf;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: #636e72;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .remix-icon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
          animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff0022;
}
.ant-progress-status-exception .ant-progress-text {
  color: #ff0022;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff0022;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #00f7bf;
}
.ant-progress-status-success .ant-progress-text {
  color: #00f7bf;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #00f7bf;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #636e72;
  font-size: 14px;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .remix-icon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff0022;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #00f7bf;
}
@-webkit-keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
@keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  font-size: 0;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #f27052;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #f27052;
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f27052;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #dfe6e9;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #f27052;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #f27052;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #dfe6e9;
  border-color: #dfe6e9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: #b2bec3;
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 40px;
  margin: 0;
  padding: 0 23px;
  color: #f27052;
  font-size: 14px;
  line-height: 38px;
  background: #fff;
  border: 1px solid #dfe6e9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: #f27052;
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 48px;
  font-size: 16px;
  line-height: 46px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 32px;
  padding: 0 7px;
  line-height: 30px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #dfe6e9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #dfe6e9;
  border-radius: 7px 0 0 7px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 7px 7px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 7px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #f27052;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px #f27052;
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #f27052;
  background: #f0e3e0;
  border-color: #f27052;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #f27052;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #f27052;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #ee502c;
  border-color: #ee502c;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #ee502c;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #cc513b;
  border-color: #cc513b;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #cc513b;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #f27052;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #f27052;
  border-color: #f27052;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #ee502c;
  border-color: #ee502c;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #cc513b;
  border-color: #cc513b;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #f27052;
}
.ant-radio-button-wrapper-disabled {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: #b2bec3;
  background-color: #dfe6e9;
  border-color: #dfe6e9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #dfe6e9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #dfe6e9;
  background-color: #b2bec3;
  border-color: #dfe6e9;
  box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #dfe6e9;
  border-radius: 0 7px 7px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #ee502c;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 7px 0 0 7px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #dfe6e9;
}
.ant-rate {
  box-sizing: border-box;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #ffc212;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div {
  transition: all 0.3s, outline 0s;
}
.ant-rate-star > div:hover,
.ant-rate-star > div:focus-visible {
  transform: scale(1.1);
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:focus-visible {
  outline: 1px dashed #ffc212;
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #dfe6e9;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-rate-star-first .remix-icon,
.ant-rate-star-second .remix-icon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .remix-icon {
  color: #00f7bf;
}
.ant-result-error .ant-result-icon > .remix-icon {
  color: #ff0022;
}
.ant-result-info .ant-result-icon > .remix-icon {
  color: #1be7ff;
}
.ant-result-warning .ant-result-icon > .remix-icon {
  color: #ffc212;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .remix-icon {
  font-size: 72px;
}
.ant-result-title {
  color: #2d3436;
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  left: 15px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 38px;
  transition: all 0.3s;
}
@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 29px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #b2bec3;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 40px;
  padding: 0 15px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 38px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 15px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 30px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 30px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 15px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 3px 8px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #dfe6e9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 4px 0;
  line-height: 24px;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 28px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
  -webkit-padding-start: 8px;
          padding-inline-start: 8px;
  -webkit-padding-end: 4px;
          padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #dfe6e9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .remix-icon {
  vertical-align: -0.2em;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-margin-start: 7px;
          margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: "Dosis", Arial, Helvetica, sans-serif;
  line-height: 24px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 15px;
  left: 15px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  -webkit-margin-start: 3px;
          margin-inline-start: 3px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #ff9b80;
  box-shadow: 0 0 0 2px rgba(242, 112, 82, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #b2bec3;
  background: #dfe6e9;
  cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #dfe6e9;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ee502c;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-selection-item {
  flex: 1 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #b2bec3;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 15px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #b2bec3;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .remix-icon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .remix-icon > svg {
  vertical-align: top;
}
.ant-select-arrow .remix-icon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 15px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #b2bec3;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 8px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 7px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: #b2bec3;
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 12px 16px;
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #b2bec3;
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 12px 16px;
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #f27052;
  font-weight: 400;
  background-color: #f0e3e0;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #f27052;
}
.ant-select-item-option-disabled {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #dfe6e9;
}
.ant-select-item-option-grouped {
  padding-left: 32px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: auto;
  right: initial;
  left: 15px;
}
.ant-select-rtl .ant-select-clear {
  right: auto;
  right: initial;
  left: 15px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 32px;
  padding-left: 16px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 8px;
  padding-left: 28px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 15px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 15px;
  left: 29px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 24px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f0f3f5;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f0f3f5;
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 16px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f0f3f5;
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 40px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #f0f3f5 25%, #e4e7e9 37%, #f0f3f5 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, #f0f3f5 25%, #e4e7e9 37%, #f0f3f5 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  background: linear-gradient(90deg, #f0f3f5 25%, #e4e7e9 37%, #f0f3f5 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  background: linear-gradient(90deg, #f0f3f5 25%, #e4e7e9 37%, #f0f3f5 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: linear-gradient(90deg, #f0f3f5 25%, #e4e7e9 37%, #f0f3f5 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: #f0f3f5;
  border-radius: 7px;
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 96px;
  min-width: 96px;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 48px;
  min-width: 48px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 48px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f0f3f5;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: #f0f3f5;
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 100%;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 100%;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: #f0f3f5;
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  -webkit-animation-name: ant-skeleton-loading-rtl;
          animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  -webkit-animation-name: ant-skeleton-loading-rtl;
          animation-name: ant-skeleton-loading-rtl;
}
@-webkit-keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 0px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #dfe6e9;
  border-radius: 7px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #f27052;
  border-radius: 7px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #f27052;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #f58d75;
  box-shadow: 0 0 0 5px rgba(242, 112, 82, 0.12);
}
.ant-slider-handle:focus {
  border-color: #f58d75;
  outline: none;
  box-shadow: 0 0 0 5px rgba(242, 112, 82, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #f27052;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #ee502c;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #ee502c;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-slider-mark-text-active {
  color: #2d3436;
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #f9b8a9;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-rail {
  background-color: #dfe6e9 !important;
}
.ant-slider-disabled .ant-slider-track {
  background-color: #b2bec3 !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: #b2bec3 !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-slider-rtl .ant-slider-dot {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:first-child {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:last-child {
  margin-right: -4px;
  margin-left: 0;
}
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #f27052;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  opacity: 0.5;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #f27052;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
          animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
          animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  transform: rotate(-45deg);
  -webkit-animation-name: antRotateRtl;
          animation-name: antRotateRtl;
}
@-webkit-keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-content {
  color: #2d3436;
  font-size: 24px;
  font-family: "Dosis", Arial, Helvetica, sans-serif;
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: left;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: "Dosis", Arial, Helvetica, sans-serif;
  line-height: 32px;
  text-align: center;
  border: 1px solid #b2bec3;
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #f27052;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: #2d3436;
  font-size: 16px;
  line-height: 40px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 20px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: #b2bec3;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #b2bec3;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #b2bec3;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f27052;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #f27052;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #f27052;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #2d3436;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #2d3436;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #f27052;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f27052;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #f27052;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #f27052;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #2d3436;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f27052;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f27052;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff0022;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff0022;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff0022;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff0022;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff0022;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff0022;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #f27052;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #f27052;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #f27052;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #f27052;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 13px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 6.5px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
  left: -1px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #f27052;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
  padding-right: 4px;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
  top: 4px !important;
}
.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}
.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  background-color: #b2bec3;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(178, 190, 195, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #f27052;
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #f27052;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 8px 0 27px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 27px 0 8px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 20px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon.remix-icon {
  position: relative;
  top: 3px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #f27052;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 27px 0 8px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 8px 0 27px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 20px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 16px 16px;
}
.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -8px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -16px -16px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 33px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 16px;
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -8px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -16px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -16px -8px 33px;
}
.ant-table-small .ant-table-thead > tr > th {
  background-color: #f7fafc;
}
.ant-table-small .ant-table-selection-column {
  width: 46px;
  min-width: 46px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #dfe6e9;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #dfe6e9;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #dfe6e9;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #dfe6e9;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
  background-color: transparent !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #dfe6e9;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #dfe6e9;
  content: '';
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #dfe6e9;
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -17px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #dfe6e9;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar {
  box-shadow: 0 1px 0 1px #f7fafc;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 7px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 7px 7px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: #2d3436;
  background: #fafafa;
}
.ant-table-thead > tr > th {
  position: relative;
  color: #2d3436;
  font-weight: 500;
  text-align: left;
  background: #f7fafc;
  border-bottom: 1px solid #dfe6e9;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #dfe6e9;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 33px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #f0e3e0;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #edddd9;
}
.ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
div.ant-table-summary {
  box-shadow: 0 -1px 0 #dfe6e9;
}
.ant-table-summary > tr > th,
.ant-table-summary > tr > td {
  border-bottom: 1px solid #dfe6e9;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 8px;
  row-gap: 8px;
}
.ant-table-pagination > * {
  flex: none;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort::before {
  background-color: transparent !important;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1 1;
}
.ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.ant-table-column-sorters::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.ant-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #f27052;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}
.ant-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: #bfbfbf;
  font-size: 12px;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-filter-trigger.active {
  color: #f27052;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: #b2bec3;
  font-size: 12px;
  text-align: center;
  content: 'Not Found';
}
.ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #f0e3e0;
}
.ant-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px #f0f0f0 solid;
}
.ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.ant-table-filter-dropdown-search-input .remix-icon {
  color: #b2bec3;
}
.ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #dfe6e9;
}
.ant-table-selection-col {
  width: 32px;
}
.ant-table-bordered .ant-table-selection-col {
  width: 50px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}
table tr th.ant-table-selection-column::after {
  background-color: transparent !important;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-margin-start: 100%;
          margin-inline-start: 100%;
  -webkit-padding-start: 4px;
          padding-inline-start: 4px;
}
.ant-table-selection-extra .remix-icon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .remix-icon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #f27052;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  outline: none;
  transform: scale(0.94117647);
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #ee502c;
}
.ant-table-row-expand-icon:active {
  color: #ee502c;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentColor;
  transition: transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: #b2bec3;
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-holder {
  position: -webkit-sticky;
  position: sticky;
  z-index: calc(2 + 1);
  background: #fff;
}
.ant-table-sticky-scroll {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #dfe6e9;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 7px 7px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 7px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 7px;
}
.ant-table-footer {
  border-radius: 0 0 7px 7px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  right: auto;
  left: 0;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 4px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 24px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 24px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 24px 6px;
}
.ant-tabs-rtl {
  direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .remix-icon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .remix-icon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-dropdown-rtl {
  direction: rtl;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 38px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 38px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 38px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 8px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 7px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
  display: flex;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 12px 16px;
  overflow: hidden;
  color: #2d3436;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item > span {
  flex: 1 1;
  white-space: nowrap;
}
.ant-tabs-dropdown-menu-item-remove {
  flex: none;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.ant-tabs-dropdown-menu-item-remove:hover {
  color: #ee502c;
}
.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: #b2bec3;
  background: transparent;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 24px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #f27052;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 7px 7px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 7px 7px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 7px 0 0 7px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 7px 7px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  overflow: hidden;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 24px;
  background: transparent;
  border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  margin-left: 2px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 7px 7px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #ee502c;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #cc513b;
}
.ant-tabs-extra-content {
  flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #f27052;
  pointer-events: none;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 16px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #cc513b;
}
.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-tab-remove:hover {
  color: #2d3436;
}
.ant-tabs-tab:hover {
  color: #ee502c;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f27052;
  text-shadow: 0 0 0.25px currentColor;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: #b2bec3;
}
.ant-tabs-tab .ant-tabs-tab-remove .remix-icon {
  margin: 0;
}
.ant-tabs-tab .remix-icon {
  margin-right: 12px;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-content {
  display: flex;
  width: 100%;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs-content-animated {
  transition: margin 0.3s;
}
.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #f0f3f5;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: #636e72;
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: #2d3436;
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .remix-icon-close,
.ant-tag-has-color .remix-icon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #f27052;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #f27052;
}
.ant-tag-checkable:active {
  background-color: #cc513b;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #00f7bf;
  background: #e6fff5;
  border-color: #7affd3;
}
.ant-tag-processing {
  color: #1be7ff;
  background: #e8fffd;
  border-color: #96ffff;
}
.ant-tag-error {
  color: #ff0022;
  background: #ffe6e6;
  border-color: #ff7a7f;
}
.ant-tag-warning {
  color: #ffc212;
  background: #fffde6;
  border-color: #ffee8c;
}
.ant-tag > .remix-icon + span,
.ant-tag > span + .remix-icon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .remix-icon + span,
.ant-tag-rtl.ant-tag > span + .remix-icon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-timeline {
  box-sizing: border-box;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #f27052;
  border-color: #f27052;
}
.ant-timeline-item-head-red {
  color: #ff0022;
  border-color: #ff0022;
}
.ant-timeline-item-head-green {
  color: #00f7bf;
  border-color: #00f7bf;
}
.ant-timeline-item-head-gray {
  color: #b2bec3;
  border-color: #b2bec3;
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline-rtl {
  direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #f27052;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #f0e3e0 !important;
  border-radius: 7px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: #f0e3e0 !important;
  content: '';
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content {
  background-color: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content {
  background-color: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content {
  background-color: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content {
  background-color: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content {
  background-color: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content {
  background-color: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content {
  background-color: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content {
  background-color: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content {
  background-color: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content {
  background-color: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content {
  background-color: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
  width: 40px;
  min-width: 40px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #f7fafc;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #dfe6e9;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search .anticon-search {
  color: #b2bec3;
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 16px 9px;
  color: #2d3436;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 7px 7px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 16px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 6px 16px;
  line-height: 28px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  color: #f27052;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  color: #dfe6e9;
}
.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
  color: #ee502c;
}
.ant-transfer-list-content-item-remove:active {
  color: #ee502c;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.ant-transfer-list-content-item-remove:hover {
  color: #ee502c;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #edddd9;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #f0e3e0;
}
.ant-transfer-list-content-item-disabled {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: #b2bec3;
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .remix-icon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
@-webkit-keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #f0e3e0;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #f27052;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f27052;
  border-radius: 7px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 2px solid #dfe6e9;
  border-radius: 7px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #f27052;
  border-color: #f27052;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #b2bec3;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #dfe6e9;
  border-color: #dfe6e9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #dfe6e9;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #dfe6e9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #f27052;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: #b2bec3;
  border-color: #b2bec3;
}
.ant-tree-checkbox-rtl {
  direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 7px;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #f27052;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
  position: relative;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #f27052;
  opacity: 0;
  -webkit-animation: ant-tree-node-fx-do-not-use 0.3s;
          animation: ant-tree-node-fx-do-not-use 0.3s;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-tree-treenode:hover .ant-tree-draggable-icon {
  opacity: 0.45;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #f27052;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #636e72;
  content: ' ';
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #636e72;
  content: ' ';
}
.ant-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #f0e3e0;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper {
  line-height: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #f27052;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #f27052;
  border-radius: 50%;
  content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #f27052;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #dfe6e9;
  content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #dfe6e9;
}
.ant-tree-rtl.ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #f27052;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f27052;
  border-radius: 7px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 2px solid #dfe6e9;
  border-radius: 7px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #f27052;
  border-color: #f27052;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: #b2bec3;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #dfe6e9;
  border-color: #dfe6e9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #dfe6e9;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #dfe6e9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #f27052;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: #b2bec3;
  border-color: #b2bec3;
}
.ant-select-tree-checkbox-rtl {
  direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree-select-dropdown {
  padding: 8px 4px;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 7px;
  transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #f27052;
}
.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging {
  position: relative;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #f27052;
  opacity: 0;
  -webkit-animation: ant-tree-node-fx-do-not-use 0.3s;
          animation: ant-tree-node-fx-do-not-use 0.3s;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: #b2bec3;
  cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-select-tree-treenode:hover .ant-select-tree-draggable-icon {
  opacity: 0.45;
}
.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
  color: #f27052;
}
.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #636e72;
  content: ' ';
}
.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #636e72;
  content: ' ';
}
.ant-select-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #f0e3e0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-select-tree-node-content-wrapper {
  line-height: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #f27052;
  border-radius: 1px;
  pointer-events: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #f27052;
  border-radius: 50%;
  content: '';
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #f27052;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #dfe6e9;
  content: '';
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-select-tree .ant-select-tree-treenode-leaf-last .ant-select-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.ant-typography {
  color: #2d3436;
  overflow-wrap: break-word;
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-success {
  color: #00f7bf;
}
.ant-typography.ant-typography-warning {
  color: #ffc212;
}
.ant-typography.ant-typography-danger {
  color: #ff0022;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus,
a.ant-typography.ant-typography-danger:hover {
  color: #ff293e;
}
.ant-typography.ant-typography-disabled {
  color: #b2bec3;
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: #2d3436;
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: #2d3436;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: #2d3436;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: #2d3436;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.ant-typography,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: #2d3436;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
  max-width: 100%;
}
a.ant-typography,
.ant-typography a {
  color: #f27052;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #ee502c;
}
a.ant-typography:active,
.ant-typography a:active {
  color: #ee502c;
}
a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none;
}
a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
  color: #b2bec3;
  cursor: not-allowed;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
  color: #b2bec3;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #f27052;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #ee502c;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #ee502c;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #00f7bf;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -16px;
  margin-top: -9px;
  margin-bottom: calc(1em - 8px - 1px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul {
  list-style-type: circle;
}
.ant-typography ul ul {
  list-style-type: disc;
}
.ant-typography ol {
  list-style-type: decimal;
}
.ant-typography pre,
.ant-typography blockquote {
  margin: 1em 0;
}
.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.ant-typography-single-line {
  white-space: nowrap;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.ant-typography-rtl {
  direction: rtl;
}
.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
  float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
  right: -16px;
  left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #dfe6e9;
  border-radius: 7px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #f27052;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #dfe6e9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #dfe6e9;
  border-radius: 7px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 24px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #cc513b;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #ee502c;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .remix-icon {
  color: #ee502c;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: #2d3436;
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .remix-icon-plus {
  color: #b2bec3;
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .remix-icon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .remix-icon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .remix-icon {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .remix-icon-loading .remix-icon,
.ant-upload-list-item-info .ant-upload-text-icon .remix-icon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .remix-icon-close {
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-item .remix-icon-close:hover {
  color: #2d3436;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .remix-icon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .remix-icon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff0022;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .remix-icon {
  color: #ff0022;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff0022;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .remix-icon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .remix-icon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .remix-icon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .remix-icon svg path[fill='#e6f7ff'] {
  fill: #ffe6e6;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .remix-icon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .remix-icon svg path[fill='#1890ff'] {
  fill: #ff0022;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .remix-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .remix-icon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .remix-icon-close,
.ant-upload-list-picture-card .remix-icon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .remix-icon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .remix-icon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .remix-icon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: #636e72;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .remix-icon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .remix-icon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .remix-icon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .remix-icon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .remix-icon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .remix-icon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
          animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
          animation-name: uploadAnimateInlineOut;
}
@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .remix-icon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item .remix-icon-close {
  right: auto;
  left: 4px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .remix-icon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .remix-icon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .remix-icon-close {
  right: auto;
  left: 8px;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
.ant-layout-header {
  background: none;
  margin: 16px 32px 32px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  z-index: 3;
  padding: 0;
}
.ant-layout-header > .ant-row {
  height: 100%;
  align-items: center;
}
.ant-layout-header > .ant-row > .ant-col {
  background: #ffffff;
  border-radius: 7px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.ant-layout-header.da-header-horizontal {
  margin: 16px 32px 32px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  z-index: 3;
  padding: 0;
}
.ant-layout-header.da-header-horizontal .ant-menu.ant-menu-root {
  position: relative;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
  vertical-align: middle;
  top: 0;
}
.ant-layout-header.da-header-horizontal .ant-menu.ant-menu-root .ant-menu-submenu-selected .menu-item {
  background: #f27052 !important;
  color: #ffffff !important;
}
.ant-layout-header.da-header-horizontal .ant-menu.ant-menu-root .ant-menu-submenu-selected .menu-item svg path {
  fill: #ffffff !important;
}
.ant-layout-header.da-header-horizontal .ant-menu.ant-menu-root > li {
  line-height: normal;
}
.ant-layout-header.da-header-horizontal .ant-menu.ant-menu-root > li:after {
  visibility: hidden;
}
@media screen and (max-width: 1199px) {
  .ant-layout-header.da-header-horizontal .ant-menu.ant-menu-root {
    display: none;
  }
}
.ant-layout-header.da-header-horizontal.da-header-full,
.ant-layout-header.da-header-horizontal.da-header-bg {
  padding: 16px 32px;
  margin: 0 0 32px;
  background-color: #ffffff;
  border-radius: 0px;
}
.ant-layout-header.da-header-horizontal.da-header-full > .ant-row > .ant-col,
.ant-layout-header.da-header-horizontal.da-header-bg > .ant-row > .ant-col {
  padding: 0;
}
.ant-layout-header .da-header-search {
  opacity: 0;
  visibility: hidden;
  transform: translate(-30px, 0);
  transition: 0.4s;
}
.ant-layout-header .da-header-search.da-header-search-active {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
.ant-layout-header .da-header-search-result {
  position: absolute;
  top: 100%;
  left: 0;
  right: 10px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  line-height: normal;
}
.ant-layout-header .da-header-search-result ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-layout-header .da-header-search-result ul li {
  height: auto;
  transition: 0.4s;
  font-size: 12px;
  line-height: normal;
}
.ant-layout-header .da-header-search-result ul li a {
  display: block;
  padding: 10px 40px;
  color: #2d3436;
}
.ant-layout-header .da-header-search-result ul li:hover {
  background-color: #f0e3e0;
}
.ant-layout-header .da-header-search-result ul li:hover a {
  color: #f27052;
}
@media screen and (max-width: 991px) {
  .ant-layout-header .da-header-search {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -70px;
    top: auto;
  }
  .ant-layout-header .da-header-search .da-header-search-result {
    top: calc(100% - 10px);
    border-radius: 16px;
    right: 0;
  }
}
.ant-layout-header .da-update-icon {
  min-width: 18px;
  border-radius: 9px;
}
.ant-layout-header .da-mobile-sidebar-button {
  display: none;
}
.ant-layout-header .da-header-left-text-item {
  line-height: 32px;
}
.ant-layout-header .da-header-left-text-item .remix-icon {
  vertical-align: text-bottom;
}
@media screen and (max-width: 1380px) {
  .ant-layout-header .da-header-left-text-item {
    line-height: normal;
  }
}
@media screen and (max-width: 1199px) {
  .ant-layout-header .da-mobile-sidebar-button {
    display: block;
  }
  .ant-layout-header .da-header-left-text {
    display: none !important;
  }
  .ant-layout-header.da-header-horizontal .da-header-logo {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .ant-layout-header {
    margin: 16px 16px 32px;
  }
  .ant-layout-header > .ant-row > .ant-col {
    padding: 0 16px 0 8px;
  }
  .ant-layout-header.da-header-horizontal {
    margin: 16px 16px 32px;
  }
  .ant-layout-header.da-header-horizontal.da-header-full,
  .ant-layout-header.da-header-horizontal.da-header-bg {
    padding: 16px 16px;
  }
}
.da-basket-dropdown-button:hover .da-basket-dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.da-basket-dropdown {
  position: absolute;
  min-width: 330px;
  padding: 35px 16px 8px;
  top: 30px;
  right: -10px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s;
}
.da-basket-dropdown.remove-cart {
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}
.da-basket-dropdown-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 358px;
}
.da-basket-dropdown-list::-webkit-scrollbar {
  width: 0;
}
.da-basket-dropdown:before {
  content: '';
  position: absolute;
  top: 29px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  border: 1px solid #dfe6e9;
  border-radius: 8px;
}
.da-basket-dropdown > div {
  position: relative;
}
@media screen and (max-width: 767px) {
  .da-basket-dropdown {
    right: 0;
    left: 10%;
    transform: translate(-50%, 0px);
    min-width: 300px;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .ant-layout-header > .ant-row > .ant-col {
    padding: 0 8px 0 16px;
  }
}
html[dir='rtl'] .da-basket-dropdown {
  left: -10px;
  right: auto;
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-basket-dropdown {
    transform: translate(-35%, 0px);
  }
}
.ant-layout-footer {
  padding: 24px 32px;
}
@media screen and (max-width: 767px) {
  .ant-layout-footer {
    padding: 18px 16px;
    text-align: center;
  }
}
html[dir='rtl'] .ant-layout-footer .da-text-right {
  text-align: left;
}
.da-logo {
  height: 40px;
}
.da-mobile-sidebar {
  display: none;
}
.da-mobile-sidebar .ant-drawer-header {
  border: none;
}
.da-sidebar .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.da-sidebar .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.da-sidebar .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.da-sidebar .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 calc(50% - 24px / 2) !important;
}
.da-sidebar .ant-menu-inline-collapsed .ant-menu-item-group-title {
  text-align: center;
}
.da-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .remix-icon {
  margin: 0;
  font-size: 24px;
}
.da-sidebar .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  font-size: 24px;
}
.da-sidebar.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-row:first-child {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.da-sidebar.ant-layout-sider-collapsed .ant-layout-sider-children > .ant-menu {
  margin-top: 14px;
}
.da-sidebar.ant-layout-sider-collapsed .ant-menu-item .ant-menu-item-icon,
.da-sidebar.ant-layout-sider-collapsed .ant-menu-submenu-title .ant-menu-item-icon,
.da-sidebar.ant-layout-sider-collapsed .ant-menu-item .remix-icon,
.da-sidebar.ant-layout-sider-collapsed .ant-menu-submenu-title .remix-icon {
  min-width: 24px;
}
.da-sidebar .ant-menu-item-group-title {
  font-weight: 500;
  padding: 12px 24px;
}
.da-sidebar .ant-layout-sider-children {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.da-sidebar .ant-layout-sider-children .ant-row:nth-child(1) .ant-btn {
  padding: 0;
  overflow: hidden;
}
.da-sidebar .ant-layout-sider-children .ant-row:nth-child(1) .ant-btn > svg {
  width: 100%;
  height: 100%;
}
.da-sidebar .ant-layout-sider-children .ant-row:nth-child(1) .ant-btn > svg > g {
  transform: scale(0.4);
  transform-origin: center;
}
.da-sidebar .ant-layout-sider-children .ant-menu-root {
  position: relative;
  min-height: calc(100vh - 179px);
  max-height: calc(100vh - 179px);
  flex: 1 1;
  padding-bottom: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 24px;
}
.da-sidebar .ant-layout-sider-children .ant-menu-root::-webkit-scrollbar {
  width: 0;
}
.da-sidebar .ant-layout-sider-children .ant-menu-root.ant-menu-inline-collapsed {
  min-height: calc(100vh - 189px);
  max-height: calc(100vh - 189px);
}
.da-sidebar .ant-menu-inline .ant-menu-item,
.da-sidebar .ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% - 20px);
  margin-left: 10px;
  border-radius: 7px;
}
.da-sidebar .ant-menu-vertical .ant-menu-item::after,
.da-sidebar .ant-menu-vertical-left .ant-menu-item::after,
.da-sidebar .ant-menu-vertical-right .ant-menu-item::after,
.da-sidebar .ant-menu-inline .ant-menu-item::after {
  top: 8px;
  bottom: 8px;
  right: 5px;
  border-radius: 2px;
  padding: 8px 0;
}
.da-sidebar .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  margin-left: -10px !important;
  width: calc(100% - -10px) !important;
}
.da-sidebar .ant-menu-item-selected-in-active {
  background: #ffffff !important;
  color: inherit !important;
}
.da-sidebar .ant-menu-item-selected-in-active a {
  color: inherit !important;
}
.da-sidebar .ant-menu-item-selected-in-active:after {
  transform: scaleY(0.0001) !important;
  opacity: 0 !important;
}
.da-sidebar .ant-menu-submenu-title {
  margin-left: 0 !important;
}
.da-sidebar li.ant-menu-submenu.ant-menu-submenu-inline {
  padding-right: 0px;
}
.da-sidebar .ant-menu-submenu,
.da-sidebar .ant-menu-submenu-inline .ant-menu-submenu-title {
  padding-right: 0;
  width: 100% !important;
  padding-left: 12px !important;
}
.da-sidebar .ant-menu-item {
  padding-left: 14px !important;
}
.da-sidebar .ant-menu-submenu-arrow {
  right: 11px !important;
}
.da-sidebar .ant-menu-inline-collapsed .ant-menu-submenu {
  padding-left: 0 !important;
}
.da-sidebar .ant-menu-inline-collapsed .ant-menu-submenu,
.da-sidebar .ant-menu-inline-collapsed .ant-menu-submenu-inline .ant-menu-submenu-title {
  width: auto !important;
}
.da-sidebar .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.da-sidebar .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 16px !important;
  margin-left: 0;
  width: calc(100% - 10px);
}
.da-sidebar .ant-menu-sub > .ant-menu-submenu > .ant-menu-sub .ant-menu-item {
  padding-left: 16px !important;
  margin-left: -10px !important;
  width: 100%;
}
.da-sidebar .ant-menu-sub > .ant-menu-submenu {
  padding-left: 10px !important;
}
.ant-menu-horizontal li.ant-menu-overflow-item {
  width: auto !important;
}
.ant-menu-submenu-popup {
  width: auto !important;
  padding-left: 0 !important;
}
.ant-menu-submenu-popup .ant-menu-submenu {
  padding-left: 0px !important;
}
.ant-menu-submenu-popup .ant-menu-submenu-title {
  padding-left: 12px !important;
}
.ant-menu-submenu-popup .ant-menu-submenu-arrow {
  right: 0 !important;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item {
  padding-left: 12px;
}
@media screen and (max-width: 1199px) {
  .da-mobile-sidebar {
    display: block;
  }
  .da-sidebar.ant-layout-sider {
    display: none;
  }
  .da-mobile-sidebar .ant-menu.ant-menu-root.ant-menu-inline {
    margin-top: 0;
    position: relative;
    padding-bottom: 24px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .da-mobile-sidebar .ant-drawer-body {
    padding: 18px 4px 0;
    display: flex;
    flex-direction: column;
  }
}
html[dir='rtl'] .da-sidebar .ant-layout-sider-children .ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu-title {
  padding: 0 calc(50% - 24px / 2) !important;
}
html[dir='rtl'] .da-sidebar .ant-layout-sider-children .ant-menu-root .ant-menu-sub .ant-menu-item {
  padding-right: 24px !important;
}
html[dir='rtl'] .da-sidebar .ant-layout-sider-children .ant-menu-root .ant-menu-submenu-title {
  width: 100%;
  padding-right: 24px !important;
}
.da-content-main {
  margin: 0 32px;
  min-height: calc(100vh - 173px);
}
@media screen and (max-width: 767px) {
  .da-content-main {
    margin: 24px 16px 0;
    min-height: calc(100vh - 144px);
    margin: 0 16px;
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 69, 94, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(255, 69, 94, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 69, 94, 0);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 69, 94, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(255, 69, 94, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 69, 94, 0);
  }
}
.da-theme-customise {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 4;
  transform: translate(100%, 0);
  transition: 0.4s;
}
.da-theme-customise.active {
  transform: translate(0%, 0) !important;
  box-shadow: -13px 0px 20px rgba(0, 0, 0, 0.05);
}
.da-theme-customise.active .da-theme-customise-button-bg svg path {
  fill: #ffffff;
}
.da-theme-customise.active .da-theme-customise-button-icon svg path {
  fill: #f27052;
}
.da-theme-customise-button {
  height: 120px;
  width: 50px;
  position: absolute;
  top: 140px;
  transform: translate(-100%, 0);
  cursor: pointer;
}
.da-theme-customise-button-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.da-theme-customise-button-bg svg path {
  fill: #f27052;
  transition: 0.4s;
}
.da-theme-customise-button-icon {
  position: absolute;
  top: 51px;
  right: 14px;
}
.da-theme-customise-button-icon svg path {
  fill: #ffffff;
  transition: 0.4s;
}
.da-theme-customise-button-icon > span {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #ff0022;
  transform: scale(1);
  box-shadow: 0 0 0 0 #ff455e;
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}
.da-theme-customise-container {
  width: 396px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.da-theme-customise-container-header button {
  margin-right: -24px;
  margin-top: -6px;
}
.da-theme-customise-container-body {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1;
}
.da-theme-customise-container-body::-webkit-scrollbar {
  width: 0;
}
.da-theme-customise-container-body-item + .da-theme-customise-container-body-item {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px dashed #dfe6e9;
}
.da-theme-customise-container-body-item-svg {
  position: relative;
  height: 101px;
  border: 2px solid #dfe6e9;
  transition: 0.4s;
  cursor: pointer;
}
.da-theme-customise-container-body-item-svg svg {
  width: 100%;
  height: 100%;
}
.da-theme-customise-container-body-item-svg-check {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%) scale(0);
  transition: 0.4s;
}
.da-theme-customise-container-body-item-svg-check svg {
  width: 20px;
  height: 20px;
}
.da-theme-customise-container-body-item-svg-other {
  cursor: pointer;
  border: 2px solid #dfe6e9;
  height: 33px;
  transition: 0.4s;
}
.da-theme-customise-container-body-item-svg-other svg {
  width: 100%;
  height: 100%;
}
.da-theme-customise-container-body-item-svg-other.active {
  border-color: #2d3436;
}
.da-theme-customise-container-body-item-svg.active {
  border-color: #2d3436;
}
.da-theme-customise-container-body-item-svg.active .da-theme-customise-container-body-item-svg-check {
  transform: translate(40%, -30%) scale(1);
}
@media screen and (max-width: 767px) {
  .da-theme-customise-container {
    width: 270px;
  }
  .da-theme-customise-container-body-item-svg {
    height: 138px;
  }
  .da-theme-customise-container-body-item-svg-other {
    height: 45px;
  }
  .da-theme-customise-button {
    transform: translate(-100%, -50%) scale(0.9);
    transform-origin: right;
  }
}
html[dir='rtl'] .da-theme-customise {
  left: 0;
  right: auto;
  transform: translate(-100%, 0);
}
html[dir='rtl'] .da-theme-customise.active {
  box-shadow: 13px 0px 20px rgba(0, 0, 0, 0.05);
}
html[dir='rtl'] .da-theme-customise.active-container-header button {
  margin-right: -24px;
  margin-top: -6px;
}
html[dir='rtl'] .da-theme-customise-button {
  transform: translate(100%, 0%) scale(-1, 1);
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-theme-customise-button {
    transform: translate(0%, -50%) scale(-0.9, 0.9);
  }
}
html[dir='rtl'] .da-theme-customise-container-header button {
  margin-left: -24px;
  margin-right: 0;
}
/* vietnamese */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/dosis/v22/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Manrope';
  src: url('/vendor-theme/fonts/Manrope-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/vendor-theme/fonts/Manrope-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/vendor-theme/fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/vendor-theme/fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/vendor-theme/fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/vendor-theme/fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/vendor-theme/fonts/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #2d3436;
  font-weight: 500;
}
h1,
.h1 {
  font-size: 42px;
  line-height: 48px;
}
@media screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 24px;
    line-height: 28px;
  }
}
h2,
.h2 {
  font-size: 28px;
  line-height: 42px;
}
h3,
.h3 {
  font-size: 24px;
  line-height: 28px;
}
h4,
.h4 {
  font-size: 20px;
  line-height: 24px;
}
h5,
.h5 {
  font-size: 16px;
  line-height: 24px;
}
.da-caption {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
.da-p1-body {
  font-size: 14px;
  line-height: 21px;
  color: #636e72;
  font-weight: 400;
}
.da-p2-body {
  font-size: 14px;
  line-height: 21px;
  color: #636e72;
  font-weight: 200;
}
.da-button {
  font-size: 14px;
  line-height: 16px;
  color: #636e72;
  font-weight: 500;
}
.da-placeholder {
  font-size: 14px;
  line-height: 16px;
  color: #636e72;
  font-weight: 300;
}
.da-input-description {
  font-size: 10px;
  line-height: 12px;
  color: #636e72;
  font-weight: 500;
}
.da-input-label {
  font-size: 14px;
  line-height: 16px;
  color: #636e72;
  font-weight: 500;
}
.da-badge-text {
  font-size: 12px;
  line-height: 12px;
  color: #636e72;
  font-weight: 500;
}
.da-text-center {
  text-align: center;
}
.da-text-lowercase {
  text-transform: lowercase;
}
.da-text-uppercase {
  text-transform: uppercase;
}
.da-text-capitalize {
  text-transform: capitalize;
}
.da-text-line-through {
  text-decoration: line-through;
}
.da-text-underline {
  text-decoration: underline;
}
.da-font-weight-200 {
  font-weight: 200;
}
.da-font-weight-300 {
  font-weight: 300;
}
.da-font-weight-400 {
  font-weight: 400;
}
.da-font-weight-500 {
  font-weight: 500;
}
.da-font-weight-600 {
  font-weight: 600;
}
.da-font-weight-700 {
  font-weight: 700;
}
.da-font-weight-800 {
  font-weight: 800;
}
.da-m-auto {
  margin: auto !important;
}
.da-m-0 {
  margin: 0px !important;
}
.da-mt-0 {
  margin-top: 0px !important;
}
.da-mb-0 {
  margin-bottom: 0px !important;
}
.da-my-0 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.da-mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
html[dir='rtl'] .da-ml-0 {
  margin-right: 0px !important;
}
html[dir='rtl'] .da-mr-0 {
  margin-left: 0px !important;
}
html[dir='ltr'] .da-ml-0 {
  margin-left: 0px !important;
}
html[dir='ltr'] .da-mr-0 {
  margin-right: 0px !important;
}
.da-m-4 {
  margin: 4px !important;
}
.da-mt-4 {
  margin-top: 4px !important;
}
.da-mb-4 {
  margin-bottom: 4px !important;
}
.da-my-4 {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}
.da-mx-4 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}
html[dir='rtl'] .da-ml-4 {
  margin-right: 4px !important;
}
html[dir='rtl'] .da-mr-4 {
  margin-left: 4px !important;
}
html[dir='ltr'] .da-ml-4 {
  margin-left: 4px !important;
}
html[dir='ltr'] .da-mr-4 {
  margin-right: 4px !important;
}
.da-m-6 {
  margin: 6px !important;
}
.da-mt-6 {
  margin-top: 6px !important;
}
.da-mb-6 {
  margin-bottom: 6px !important;
}
.da-my-6 {
  margin-bottom: 6px !important;
  margin-top: 6px !important;
}
.da-mx-6 {
  margin-right: 6px !important;
  margin-left: 6px !important;
}
html[dir='rtl'] .da-ml-6 {
  margin-right: 6px !important;
}
html[dir='rtl'] .da-mr-6 {
  margin-left: 6px !important;
}
html[dir='ltr'] .da-ml-6 {
  margin-left: 6px !important;
}
html[dir='ltr'] .da-mr-6 {
  margin-right: 6px !important;
}
.da-m-8 {
  margin: 8px !important;
}
.da-mt-8 {
  margin-top: 8px !important;
}
.da-mb-8 {
  margin-bottom: 8px !important;
}
.da-my-8 {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}
.da-mx-8 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}
html[dir='rtl'] .da-ml-8 {
  margin-right: 8px !important;
}
html[dir='rtl'] .da-mr-8 {
  margin-left: 8px !important;
}
html[dir='ltr'] .da-ml-8 {
  margin-left: 8px !important;
}
html[dir='ltr'] .da-mr-8 {
  margin-right: 8px !important;
}
.da-m-10 {
  margin: 10px !important;
}
.da-mt-10 {
  margin-top: 10px !important;
}
.da-mb-10 {
  margin-bottom: 10px !important;
}
.da-my-10 {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.da-mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
html[dir='rtl'] .da-ml-10 {
  margin-right: 10px !important;
}
html[dir='rtl'] .da-mr-10 {
  margin-left: 10px !important;
}
html[dir='ltr'] .da-ml-10 {
  margin-left: 10px !important;
}
html[dir='ltr'] .da-mr-10 {
  margin-right: 10px !important;
}
.da-m-12 {
  margin: 12px !important;
}
.da-mt-12 {
  margin-top: 12px !important;
}
.da-mb-12 {
  margin-bottom: 12px !important;
}
.da-my-12 {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}
.da-mx-12 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}
html[dir='rtl'] .da-ml-12 {
  margin-right: 12px !important;
}
html[dir='rtl'] .da-mr-12 {
  margin-left: 12px !important;
}
html[dir='ltr'] .da-ml-12 {
  margin-left: 12px !important;
}
html[dir='ltr'] .da-mr-12 {
  margin-right: 12px !important;
}
.da-m-16 {
  margin: 16px !important;
}
.da-mt-16 {
  margin-top: 16px !important;
}
.da-mb-16 {
  margin-bottom: 16px !important;
}
.da-my-16 {
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}
.da-mx-16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}
html[dir='rtl'] .da-ml-16 {
  margin-right: 16px !important;
}
html[dir='rtl'] .da-mr-16 {
  margin-left: 16px !important;
}
html[dir='ltr'] .da-ml-16 {
  margin-left: 16px !important;
}
html[dir='ltr'] .da-mr-16 {
  margin-right: 16px !important;
}
.da-m-18 {
  margin: 18px !important;
}
.da-mt-18 {
  margin-top: 18px !important;
}
.da-mb-18 {
  margin-bottom: 18px !important;
}
.da-my-18 {
  margin-bottom: 18px !important;
  margin-top: 18px !important;
}
.da-mx-18 {
  margin-right: 18px !important;
  margin-left: 18px !important;
}
html[dir='rtl'] .da-ml-18 {
  margin-right: 18px !important;
}
html[dir='rtl'] .da-mr-18 {
  margin-left: 18px !important;
}
html[dir='ltr'] .da-ml-18 {
  margin-left: 18px !important;
}
html[dir='ltr'] .da-mr-18 {
  margin-right: 18px !important;
}
.da-m-24 {
  margin: 24px !important;
}
.da-mt-24 {
  margin-top: 24px !important;
}
.da-mb-24 {
  margin-bottom: 24px !important;
}
.da-my-24 {
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}
.da-mx-24 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}
html[dir='rtl'] .da-ml-24 {
  margin-right: 24px !important;
}
html[dir='rtl'] .da-mr-24 {
  margin-left: 24px !important;
}
html[dir='ltr'] .da-ml-24 {
  margin-left: 24px !important;
}
html[dir='ltr'] .da-mr-24 {
  margin-right: 24px !important;
}
.da-m-32 {
  margin: 32px !important;
}
.da-mt-32 {
  margin-top: 32px !important;
}
.da-mb-32 {
  margin-bottom: 32px !important;
}
.da-my-32 {
  margin-bottom: 32px !important;
  margin-top: 32px !important;
}
.da-mx-32 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}
html[dir='rtl'] .da-ml-32 {
  margin-right: 32px !important;
}
html[dir='rtl'] .da-mr-32 {
  margin-left: 32px !important;
}
html[dir='ltr'] .da-ml-32 {
  margin-left: 32px !important;
}
html[dir='ltr'] .da-mr-32 {
  margin-right: 32px !important;
}
.da-m-36 {
  margin: 36px !important;
}
.da-mt-36 {
  margin-top: 36px !important;
}
.da-mb-36 {
  margin-bottom: 36px !important;
}
.da-my-36 {
  margin-bottom: 36px !important;
  margin-top: 36px !important;
}
.da-mx-36 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}
html[dir='rtl'] .da-ml-36 {
  margin-right: 36px !important;
}
html[dir='rtl'] .da-mr-36 {
  margin-left: 36px !important;
}
html[dir='ltr'] .da-ml-36 {
  margin-left: 36px !important;
}
html[dir='ltr'] .da-mr-36 {
  margin-right: 36px !important;
}
.da-m-42 {
  margin: 42px !important;
}
.da-mt-42 {
  margin-top: 42px !important;
}
.da-mb-42 {
  margin-bottom: 42px !important;
}
.da-my-42 {
  margin-bottom: 42px !important;
  margin-top: 42px !important;
}
.da-mx-42 {
  margin-right: 42px !important;
  margin-left: 42px !important;
}
html[dir='rtl'] .da-ml-42 {
  margin-right: 42px !important;
}
html[dir='rtl'] .da-mr-42 {
  margin-left: 42px !important;
}
html[dir='ltr'] .da-ml-42 {
  margin-left: 42px !important;
}
html[dir='ltr'] .da-mr-42 {
  margin-right: 42px !important;
}
.da-m-48 {
  margin: 48px !important;
}
.da-mt-48 {
  margin-top: 48px !important;
}
.da-mb-48 {
  margin-bottom: 48px !important;
}
.da-my-48 {
  margin-bottom: 48px !important;
  margin-top: 48px !important;
}
.da-mx-48 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}
html[dir='rtl'] .da-ml-48 {
  margin-right: 48px !important;
}
html[dir='rtl'] .da-mr-48 {
  margin-left: 48px !important;
}
html[dir='ltr'] .da-ml-48 {
  margin-left: 48px !important;
}
html[dir='ltr'] .da-mr-48 {
  margin-right: 48px !important;
}
.da-m-64 {
  margin: 64px !important;
}
.da-mt-64 {
  margin-top: 64px !important;
}
.da-mb-64 {
  margin-bottom: 64px !important;
}
.da-my-64 {
  margin-bottom: 64px !important;
  margin-top: 64px !important;
}
.da-mx-64 {
  margin-right: 64px !important;
  margin-left: 64px !important;
}
html[dir='rtl'] .da-ml-64 {
  margin-right: 64px !important;
}
html[dir='rtl'] .da-mr-64 {
  margin-left: 64px !important;
}
html[dir='ltr'] .da-ml-64 {
  margin-left: 64px !important;
}
html[dir='ltr'] .da-mr-64 {
  margin-right: 64px !important;
}
.da-m-96 {
  margin: 96px !important;
}
.da-mt-96 {
  margin-top: 96px !important;
}
.da-mb-96 {
  margin-bottom: 96px !important;
}
.da-my-96 {
  margin-bottom: 96px !important;
  margin-top: 96px !important;
}
.da-mx-96 {
  margin-right: 96px !important;
  margin-left: 96px !important;
}
html[dir='rtl'] .da-ml-96 {
  margin-right: 96px !important;
}
html[dir='rtl'] .da-mr-96 {
  margin-left: 96px !important;
}
html[dir='ltr'] .da-ml-96 {
  margin-left: 96px !important;
}
html[dir='ltr'] .da-mr-96 {
  margin-right: 96px !important;
}
.da-m-120 {
  margin: 120px !important;
}
.da-mt-120 {
  margin-top: 120px !important;
}
.da-mb-120 {
  margin-bottom: 120px !important;
}
.da-my-120 {
  margin-bottom: 120px !important;
  margin-top: 120px !important;
}
.da-mx-120 {
  margin-right: 120px !important;
  margin-left: 120px !important;
}
html[dir='rtl'] .da-ml-120 {
  margin-right: 120px !important;
}
html[dir='rtl'] .da-mr-120 {
  margin-left: 120px !important;
}
html[dir='ltr'] .da-ml-120 {
  margin-left: 120px !important;
}
html[dir='ltr'] .da-mr-120 {
  margin-right: 120px !important;
}
@media screen and (max-width: 1599px) {
  .da-m-xl-0 {
    margin: 0px !important;
  }
  .da-mt-xl-0 {
    margin-top: 0px !important;
  }
  .da-mb-xl-0 {
    margin-bottom: 0px !important;
  }
  .da-my-xl-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .da-mx-xl-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-0 {
    margin-right: 0px !important;
  }
  html[dir='rtl'] .da-mr-xl-0 {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-0 {
    margin-left: 0px !important;
  }
  html[dir='ltr'] .da-mr-xl-0 {
    margin-right: 0px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-4 {
    margin: 4px !important;
  }
  .da-mt-xl-4 {
    margin-top: 4px !important;
  }
  .da-mb-xl-4 {
    margin-bottom: 4px !important;
  }
  .da-my-xl-4 {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .da-mx-xl-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-4 {
    margin-right: 4px !important;
  }
  html[dir='rtl'] .da-mr-xl-4 {
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-4 {
    margin-left: 4px !important;
  }
  html[dir='ltr'] .da-mr-xl-4 {
    margin-right: 4px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-6 {
    margin: 6px !important;
  }
  .da-mt-xl-6 {
    margin-top: 6px !important;
  }
  .da-mb-xl-6 {
    margin-bottom: 6px !important;
  }
  .da-my-xl-6 {
    margin-bottom: 6px !important;
    margin-top: 6px !important;
  }
  .da-mx-xl-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-6 {
    margin-right: 6px !important;
  }
  html[dir='rtl'] .da-mr-xl-6 {
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-6 {
    margin-left: 6px !important;
  }
  html[dir='ltr'] .da-mr-xl-6 {
    margin-right: 6px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-8 {
    margin: 8px !important;
  }
  .da-mt-xl-8 {
    margin-top: 8px !important;
  }
  .da-mb-xl-8 {
    margin-bottom: 8px !important;
  }
  .da-my-xl-8 {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .da-mx-xl-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-8 {
    margin-right: 8px !important;
  }
  html[dir='rtl'] .da-mr-xl-8 {
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-8 {
    margin-left: 8px !important;
  }
  html[dir='ltr'] .da-mr-xl-8 {
    margin-right: 8px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-10 {
    margin: 10px !important;
  }
  .da-mt-xl-10 {
    margin-top: 10px !important;
  }
  .da-mb-xl-10 {
    margin-bottom: 10px !important;
  }
  .da-my-xl-10 {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .da-mx-xl-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-10 {
    margin-right: 10px !important;
  }
  html[dir='rtl'] .da-mr-xl-10 {
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-10 {
    margin-left: 10px !important;
  }
  html[dir='ltr'] .da-mr-xl-10 {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-12 {
    margin: 12px !important;
  }
  .da-mt-xl-12 {
    margin-top: 12px !important;
  }
  .da-mb-xl-12 {
    margin-bottom: 12px !important;
  }
  .da-my-xl-12 {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }
  .da-mx-xl-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-12 {
    margin-right: 12px !important;
  }
  html[dir='rtl'] .da-mr-xl-12 {
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-12 {
    margin-left: 12px !important;
  }
  html[dir='ltr'] .da-mr-xl-12 {
    margin-right: 12px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-16 {
    margin: 16px !important;
  }
  .da-mt-xl-16 {
    margin-top: 16px !important;
  }
  .da-mb-xl-16 {
    margin-bottom: 16px !important;
  }
  .da-my-xl-16 {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .da-mx-xl-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-16 {
    margin-right: 16px !important;
  }
  html[dir='rtl'] .da-mr-xl-16 {
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-16 {
    margin-left: 16px !important;
  }
  html[dir='ltr'] .da-mr-xl-16 {
    margin-right: 16px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-18 {
    margin: 18px !important;
  }
  .da-mt-xl-18 {
    margin-top: 18px !important;
  }
  .da-mb-xl-18 {
    margin-bottom: 18px !important;
  }
  .da-my-xl-18 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
  .da-mx-xl-18 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-18 {
    margin-right: 18px !important;
  }
  html[dir='rtl'] .da-mr-xl-18 {
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-18 {
    margin-left: 18px !important;
  }
  html[dir='ltr'] .da-mr-xl-18 {
    margin-right: 18px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-24 {
    margin: 24px !important;
  }
  .da-mt-xl-24 {
    margin-top: 24px !important;
  }
  .da-mb-xl-24 {
    margin-bottom: 24px !important;
  }
  .da-my-xl-24 {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .da-mx-xl-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-24 {
    margin-right: 24px !important;
  }
  html[dir='rtl'] .da-mr-xl-24 {
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-24 {
    margin-left: 24px !important;
  }
  html[dir='ltr'] .da-mr-xl-24 {
    margin-right: 24px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-32 {
    margin: 32px !important;
  }
  .da-mt-xl-32 {
    margin-top: 32px !important;
  }
  .da-mb-xl-32 {
    margin-bottom: 32px !important;
  }
  .da-my-xl-32 {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .da-mx-xl-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-32 {
    margin-right: 32px !important;
  }
  html[dir='rtl'] .da-mr-xl-32 {
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-32 {
    margin-left: 32px !important;
  }
  html[dir='ltr'] .da-mr-xl-32 {
    margin-right: 32px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-36 {
    margin: 36px !important;
  }
  .da-mt-xl-36 {
    margin-top: 36px !important;
  }
  .da-mb-xl-36 {
    margin-bottom: 36px !important;
  }
  .da-my-xl-36 {
    margin-bottom: 36px !important;
    margin-top: 36px !important;
  }
  .da-mx-xl-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-36 {
    margin-right: 36px !important;
  }
  html[dir='rtl'] .da-mr-xl-36 {
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-36 {
    margin-left: 36px !important;
  }
  html[dir='ltr'] .da-mr-xl-36 {
    margin-right: 36px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-42 {
    margin: 42px !important;
  }
  .da-mt-xl-42 {
    margin-top: 42px !important;
  }
  .da-mb-xl-42 {
    margin-bottom: 42px !important;
  }
  .da-my-xl-42 {
    margin-bottom: 42px !important;
    margin-top: 42px !important;
  }
  .da-mx-xl-42 {
    margin-right: 42px !important;
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-42 {
    margin-right: 42px !important;
  }
  html[dir='rtl'] .da-mr-xl-42 {
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-42 {
    margin-left: 42px !important;
  }
  html[dir='ltr'] .da-mr-xl-42 {
    margin-right: 42px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-48 {
    margin: 48px !important;
  }
  .da-mt-xl-48 {
    margin-top: 48px !important;
  }
  .da-mb-xl-48 {
    margin-bottom: 48px !important;
  }
  .da-my-xl-48 {
    margin-bottom: 48px !important;
    margin-top: 48px !important;
  }
  .da-mx-xl-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-48 {
    margin-right: 48px !important;
  }
  html[dir='rtl'] .da-mr-xl-48 {
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-48 {
    margin-left: 48px !important;
  }
  html[dir='ltr'] .da-mr-xl-48 {
    margin-right: 48px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-64 {
    margin: 64px !important;
  }
  .da-mt-xl-64 {
    margin-top: 64px !important;
  }
  .da-mb-xl-64 {
    margin-bottom: 64px !important;
  }
  .da-my-xl-64 {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .da-mx-xl-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-64 {
    margin-right: 64px !important;
  }
  html[dir='rtl'] .da-mr-xl-64 {
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-64 {
    margin-left: 64px !important;
  }
  html[dir='ltr'] .da-mr-xl-64 {
    margin-right: 64px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-96 {
    margin: 96px !important;
  }
  .da-mt-xl-96 {
    margin-top: 96px !important;
  }
  .da-mb-xl-96 {
    margin-bottom: 96px !important;
  }
  .da-my-xl-96 {
    margin-bottom: 96px !important;
    margin-top: 96px !important;
  }
  .da-mx-xl-96 {
    margin-right: 96px !important;
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-96 {
    margin-right: 96px !important;
  }
  html[dir='rtl'] .da-mr-xl-96 {
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-96 {
    margin-left: 96px !important;
  }
  html[dir='ltr'] .da-mr-xl-96 {
    margin-right: 96px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-m-xl-120 {
    margin: 120px !important;
  }
  .da-mt-xl-120 {
    margin-top: 120px !important;
  }
  .da-mb-xl-120 {
    margin-bottom: 120px !important;
  }
  .da-my-xl-120 {
    margin-bottom: 120px !important;
    margin-top: 120px !important;
  }
  .da-mx-xl-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-ml-xl-120 {
    margin-right: 120px !important;
  }
  html[dir='rtl'] .da-mr-xl-120 {
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-ml-xl-120 {
    margin-left: 120px !important;
  }
  html[dir='ltr'] .da-mr-xl-120 {
    margin-right: 120px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-0 {
    margin: 0px !important;
  }
  .da-mt-lg-0 {
    margin-top: 0px !important;
  }
  .da-mb-lg-0 {
    margin-bottom: 0px !important;
  }
  .da-my-lg-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .da-mx-lg-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-0 {
    margin-right: 0px !important;
  }
  html[dir='rtl'] .da-mr-lg-0 {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-0 {
    margin-left: 0px !important;
  }
  html[dir='ltr'] .da-mr-lg-0 {
    margin-right: 0px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-4 {
    margin: 4px !important;
  }
  .da-mt-lg-4 {
    margin-top: 4px !important;
  }
  .da-mb-lg-4 {
    margin-bottom: 4px !important;
  }
  .da-my-lg-4 {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .da-mx-lg-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-4 {
    margin-right: 4px !important;
  }
  html[dir='rtl'] .da-mr-lg-4 {
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-4 {
    margin-left: 4px !important;
  }
  html[dir='ltr'] .da-mr-lg-4 {
    margin-right: 4px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-6 {
    margin: 6px !important;
  }
  .da-mt-lg-6 {
    margin-top: 6px !important;
  }
  .da-mb-lg-6 {
    margin-bottom: 6px !important;
  }
  .da-my-lg-6 {
    margin-bottom: 6px !important;
    margin-top: 6px !important;
  }
  .da-mx-lg-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-6 {
    margin-right: 6px !important;
  }
  html[dir='rtl'] .da-mr-lg-6 {
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-6 {
    margin-left: 6px !important;
  }
  html[dir='ltr'] .da-mr-lg-6 {
    margin-right: 6px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-8 {
    margin: 8px !important;
  }
  .da-mt-lg-8 {
    margin-top: 8px !important;
  }
  .da-mb-lg-8 {
    margin-bottom: 8px !important;
  }
  .da-my-lg-8 {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .da-mx-lg-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-8 {
    margin-right: 8px !important;
  }
  html[dir='rtl'] .da-mr-lg-8 {
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-8 {
    margin-left: 8px !important;
  }
  html[dir='ltr'] .da-mr-lg-8 {
    margin-right: 8px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-10 {
    margin: 10px !important;
  }
  .da-mt-lg-10 {
    margin-top: 10px !important;
  }
  .da-mb-lg-10 {
    margin-bottom: 10px !important;
  }
  .da-my-lg-10 {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .da-mx-lg-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-10 {
    margin-right: 10px !important;
  }
  html[dir='rtl'] .da-mr-lg-10 {
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-10 {
    margin-left: 10px !important;
  }
  html[dir='ltr'] .da-mr-lg-10 {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-12 {
    margin: 12px !important;
  }
  .da-mt-lg-12 {
    margin-top: 12px !important;
  }
  .da-mb-lg-12 {
    margin-bottom: 12px !important;
  }
  .da-my-lg-12 {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }
  .da-mx-lg-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-12 {
    margin-right: 12px !important;
  }
  html[dir='rtl'] .da-mr-lg-12 {
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-12 {
    margin-left: 12px !important;
  }
  html[dir='ltr'] .da-mr-lg-12 {
    margin-right: 12px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-16 {
    margin: 16px !important;
  }
  .da-mt-lg-16 {
    margin-top: 16px !important;
  }
  .da-mb-lg-16 {
    margin-bottom: 16px !important;
  }
  .da-my-lg-16 {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .da-mx-lg-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-16 {
    margin-right: 16px !important;
  }
  html[dir='rtl'] .da-mr-lg-16 {
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-16 {
    margin-left: 16px !important;
  }
  html[dir='ltr'] .da-mr-lg-16 {
    margin-right: 16px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-18 {
    margin: 18px !important;
  }
  .da-mt-lg-18 {
    margin-top: 18px !important;
  }
  .da-mb-lg-18 {
    margin-bottom: 18px !important;
  }
  .da-my-lg-18 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
  .da-mx-lg-18 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-18 {
    margin-right: 18px !important;
  }
  html[dir='rtl'] .da-mr-lg-18 {
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-18 {
    margin-left: 18px !important;
  }
  html[dir='ltr'] .da-mr-lg-18 {
    margin-right: 18px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-24 {
    margin: 24px !important;
  }
  .da-mt-lg-24 {
    margin-top: 24px !important;
  }
  .da-mb-lg-24 {
    margin-bottom: 24px !important;
  }
  .da-my-lg-24 {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .da-mx-lg-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-24 {
    margin-right: 24px !important;
  }
  html[dir='rtl'] .da-mr-lg-24 {
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-24 {
    margin-left: 24px !important;
  }
  html[dir='ltr'] .da-mr-lg-24 {
    margin-right: 24px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-32 {
    margin: 32px !important;
  }
  .da-mt-lg-32 {
    margin-top: 32px !important;
  }
  .da-mb-lg-32 {
    margin-bottom: 32px !important;
  }
  .da-my-lg-32 {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .da-mx-lg-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-32 {
    margin-right: 32px !important;
  }
  html[dir='rtl'] .da-mr-lg-32 {
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-32 {
    margin-left: 32px !important;
  }
  html[dir='ltr'] .da-mr-lg-32 {
    margin-right: 32px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-36 {
    margin: 36px !important;
  }
  .da-mt-lg-36 {
    margin-top: 36px !important;
  }
  .da-mb-lg-36 {
    margin-bottom: 36px !important;
  }
  .da-my-lg-36 {
    margin-bottom: 36px !important;
    margin-top: 36px !important;
  }
  .da-mx-lg-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-36 {
    margin-right: 36px !important;
  }
  html[dir='rtl'] .da-mr-lg-36 {
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-36 {
    margin-left: 36px !important;
  }
  html[dir='ltr'] .da-mr-lg-36 {
    margin-right: 36px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-42 {
    margin: 42px !important;
  }
  .da-mt-lg-42 {
    margin-top: 42px !important;
  }
  .da-mb-lg-42 {
    margin-bottom: 42px !important;
  }
  .da-my-lg-42 {
    margin-bottom: 42px !important;
    margin-top: 42px !important;
  }
  .da-mx-lg-42 {
    margin-right: 42px !important;
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-42 {
    margin-right: 42px !important;
  }
  html[dir='rtl'] .da-mr-lg-42 {
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-42 {
    margin-left: 42px !important;
  }
  html[dir='ltr'] .da-mr-lg-42 {
    margin-right: 42px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-48 {
    margin: 48px !important;
  }
  .da-mt-lg-48 {
    margin-top: 48px !important;
  }
  .da-mb-lg-48 {
    margin-bottom: 48px !important;
  }
  .da-my-lg-48 {
    margin-bottom: 48px !important;
    margin-top: 48px !important;
  }
  .da-mx-lg-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-48 {
    margin-right: 48px !important;
  }
  html[dir='rtl'] .da-mr-lg-48 {
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-48 {
    margin-left: 48px !important;
  }
  html[dir='ltr'] .da-mr-lg-48 {
    margin-right: 48px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-64 {
    margin: 64px !important;
  }
  .da-mt-lg-64 {
    margin-top: 64px !important;
  }
  .da-mb-lg-64 {
    margin-bottom: 64px !important;
  }
  .da-my-lg-64 {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .da-mx-lg-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-64 {
    margin-right: 64px !important;
  }
  html[dir='rtl'] .da-mr-lg-64 {
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-64 {
    margin-left: 64px !important;
  }
  html[dir='ltr'] .da-mr-lg-64 {
    margin-right: 64px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-96 {
    margin: 96px !important;
  }
  .da-mt-lg-96 {
    margin-top: 96px !important;
  }
  .da-mb-lg-96 {
    margin-bottom: 96px !important;
  }
  .da-my-lg-96 {
    margin-bottom: 96px !important;
    margin-top: 96px !important;
  }
  .da-mx-lg-96 {
    margin-right: 96px !important;
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-96 {
    margin-right: 96px !important;
  }
  html[dir='rtl'] .da-mr-lg-96 {
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-96 {
    margin-left: 96px !important;
  }
  html[dir='ltr'] .da-mr-lg-96 {
    margin-right: 96px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-m-lg-120 {
    margin: 120px !important;
  }
  .da-mt-lg-120 {
    margin-top: 120px !important;
  }
  .da-mb-lg-120 {
    margin-bottom: 120px !important;
  }
  .da-my-lg-120 {
    margin-bottom: 120px !important;
    margin-top: 120px !important;
  }
  .da-mx-lg-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-ml-lg-120 {
    margin-right: 120px !important;
  }
  html[dir='rtl'] .da-mr-lg-120 {
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-ml-lg-120 {
    margin-left: 120px !important;
  }
  html[dir='ltr'] .da-mr-lg-120 {
    margin-right: 120px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-0 {
    margin: 0px !important;
  }
  .da-mt-md-0 {
    margin-top: 0px !important;
  }
  .da-mb-md-0 {
    margin-bottom: 0px !important;
  }
  .da-my-md-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .da-mx-md-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-0 {
    margin-right: 0px !important;
  }
  html[dir='rtl'] .da-mr-md-0 {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-0 {
    margin-left: 0px !important;
  }
  html[dir='ltr'] .da-mr-md-0 {
    margin-right: 0px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-4 {
    margin: 4px !important;
  }
  .da-mt-md-4 {
    margin-top: 4px !important;
  }
  .da-mb-md-4 {
    margin-bottom: 4px !important;
  }
  .da-my-md-4 {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .da-mx-md-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-4 {
    margin-right: 4px !important;
  }
  html[dir='rtl'] .da-mr-md-4 {
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-4 {
    margin-left: 4px !important;
  }
  html[dir='ltr'] .da-mr-md-4 {
    margin-right: 4px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-6 {
    margin: 6px !important;
  }
  .da-mt-md-6 {
    margin-top: 6px !important;
  }
  .da-mb-md-6 {
    margin-bottom: 6px !important;
  }
  .da-my-md-6 {
    margin-bottom: 6px !important;
    margin-top: 6px !important;
  }
  .da-mx-md-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-6 {
    margin-right: 6px !important;
  }
  html[dir='rtl'] .da-mr-md-6 {
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-6 {
    margin-left: 6px !important;
  }
  html[dir='ltr'] .da-mr-md-6 {
    margin-right: 6px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-8 {
    margin: 8px !important;
  }
  .da-mt-md-8 {
    margin-top: 8px !important;
  }
  .da-mb-md-8 {
    margin-bottom: 8px !important;
  }
  .da-my-md-8 {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .da-mx-md-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-8 {
    margin-right: 8px !important;
  }
  html[dir='rtl'] .da-mr-md-8 {
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-8 {
    margin-left: 8px !important;
  }
  html[dir='ltr'] .da-mr-md-8 {
    margin-right: 8px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-10 {
    margin: 10px !important;
  }
  .da-mt-md-10 {
    margin-top: 10px !important;
  }
  .da-mb-md-10 {
    margin-bottom: 10px !important;
  }
  .da-my-md-10 {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .da-mx-md-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-10 {
    margin-right: 10px !important;
  }
  html[dir='rtl'] .da-mr-md-10 {
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-10 {
    margin-left: 10px !important;
  }
  html[dir='ltr'] .da-mr-md-10 {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-12 {
    margin: 12px !important;
  }
  .da-mt-md-12 {
    margin-top: 12px !important;
  }
  .da-mb-md-12 {
    margin-bottom: 12px !important;
  }
  .da-my-md-12 {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }
  .da-mx-md-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-12 {
    margin-right: 12px !important;
  }
  html[dir='rtl'] .da-mr-md-12 {
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-12 {
    margin-left: 12px !important;
  }
  html[dir='ltr'] .da-mr-md-12 {
    margin-right: 12px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-16 {
    margin: 16px !important;
  }
  .da-mt-md-16 {
    margin-top: 16px !important;
  }
  .da-mb-md-16 {
    margin-bottom: 16px !important;
  }
  .da-my-md-16 {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .da-mx-md-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-16 {
    margin-right: 16px !important;
  }
  html[dir='rtl'] .da-mr-md-16 {
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-16 {
    margin-left: 16px !important;
  }
  html[dir='ltr'] .da-mr-md-16 {
    margin-right: 16px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-18 {
    margin: 18px !important;
  }
  .da-mt-md-18 {
    margin-top: 18px !important;
  }
  .da-mb-md-18 {
    margin-bottom: 18px !important;
  }
  .da-my-md-18 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
  .da-mx-md-18 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-18 {
    margin-right: 18px !important;
  }
  html[dir='rtl'] .da-mr-md-18 {
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-18 {
    margin-left: 18px !important;
  }
  html[dir='ltr'] .da-mr-md-18 {
    margin-right: 18px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-24 {
    margin: 24px !important;
  }
  .da-mt-md-24 {
    margin-top: 24px !important;
  }
  .da-mb-md-24 {
    margin-bottom: 24px !important;
  }
  .da-my-md-24 {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .da-mx-md-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-24 {
    margin-right: 24px !important;
  }
  html[dir='rtl'] .da-mr-md-24 {
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-24 {
    margin-left: 24px !important;
  }
  html[dir='ltr'] .da-mr-md-24 {
    margin-right: 24px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-32 {
    margin: 32px !important;
  }
  .da-mt-md-32 {
    margin-top: 32px !important;
  }
  .da-mb-md-32 {
    margin-bottom: 32px !important;
  }
  .da-my-md-32 {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .da-mx-md-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-32 {
    margin-right: 32px !important;
  }
  html[dir='rtl'] .da-mr-md-32 {
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-32 {
    margin-left: 32px !important;
  }
  html[dir='ltr'] .da-mr-md-32 {
    margin-right: 32px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-36 {
    margin: 36px !important;
  }
  .da-mt-md-36 {
    margin-top: 36px !important;
  }
  .da-mb-md-36 {
    margin-bottom: 36px !important;
  }
  .da-my-md-36 {
    margin-bottom: 36px !important;
    margin-top: 36px !important;
  }
  .da-mx-md-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-36 {
    margin-right: 36px !important;
  }
  html[dir='rtl'] .da-mr-md-36 {
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-36 {
    margin-left: 36px !important;
  }
  html[dir='ltr'] .da-mr-md-36 {
    margin-right: 36px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-42 {
    margin: 42px !important;
  }
  .da-mt-md-42 {
    margin-top: 42px !important;
  }
  .da-mb-md-42 {
    margin-bottom: 42px !important;
  }
  .da-my-md-42 {
    margin-bottom: 42px !important;
    margin-top: 42px !important;
  }
  .da-mx-md-42 {
    margin-right: 42px !important;
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-42 {
    margin-right: 42px !important;
  }
  html[dir='rtl'] .da-mr-md-42 {
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-42 {
    margin-left: 42px !important;
  }
  html[dir='ltr'] .da-mr-md-42 {
    margin-right: 42px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-48 {
    margin: 48px !important;
  }
  .da-mt-md-48 {
    margin-top: 48px !important;
  }
  .da-mb-md-48 {
    margin-bottom: 48px !important;
  }
  .da-my-md-48 {
    margin-bottom: 48px !important;
    margin-top: 48px !important;
  }
  .da-mx-md-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-48 {
    margin-right: 48px !important;
  }
  html[dir='rtl'] .da-mr-md-48 {
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-48 {
    margin-left: 48px !important;
  }
  html[dir='ltr'] .da-mr-md-48 {
    margin-right: 48px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-64 {
    margin: 64px !important;
  }
  .da-mt-md-64 {
    margin-top: 64px !important;
  }
  .da-mb-md-64 {
    margin-bottom: 64px !important;
  }
  .da-my-md-64 {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .da-mx-md-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-64 {
    margin-right: 64px !important;
  }
  html[dir='rtl'] .da-mr-md-64 {
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-64 {
    margin-left: 64px !important;
  }
  html[dir='ltr'] .da-mr-md-64 {
    margin-right: 64px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-96 {
    margin: 96px !important;
  }
  .da-mt-md-96 {
    margin-top: 96px !important;
  }
  .da-mb-md-96 {
    margin-bottom: 96px !important;
  }
  .da-my-md-96 {
    margin-bottom: 96px !important;
    margin-top: 96px !important;
  }
  .da-mx-md-96 {
    margin-right: 96px !important;
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-96 {
    margin-right: 96px !important;
  }
  html[dir='rtl'] .da-mr-md-96 {
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-96 {
    margin-left: 96px !important;
  }
  html[dir='ltr'] .da-mr-md-96 {
    margin-right: 96px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-m-md-120 {
    margin: 120px !important;
  }
  .da-mt-md-120 {
    margin-top: 120px !important;
  }
  .da-mb-md-120 {
    margin-bottom: 120px !important;
  }
  .da-my-md-120 {
    margin-bottom: 120px !important;
    margin-top: 120px !important;
  }
  .da-mx-md-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-ml-md-120 {
    margin-right: 120px !important;
  }
  html[dir='rtl'] .da-mr-md-120 {
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-ml-md-120 {
    margin-left: 120px !important;
  }
  html[dir='ltr'] .da-mr-md-120 {
    margin-right: 120px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-0 {
    margin: 0px !important;
  }
  .da-mt-sm-0 {
    margin-top: 0px !important;
  }
  .da-mb-sm-0 {
    margin-bottom: 0px !important;
  }
  .da-my-sm-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .da-mx-sm-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-0 {
    margin-right: 0px !important;
  }
  html[dir='rtl'] .da-mr-sm-0 {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-0 {
    margin-left: 0px !important;
  }
  html[dir='ltr'] .da-mr-sm-0 {
    margin-right: 0px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-4 {
    margin: 4px !important;
  }
  .da-mt-sm-4 {
    margin-top: 4px !important;
  }
  .da-mb-sm-4 {
    margin-bottom: 4px !important;
  }
  .da-my-sm-4 {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .da-mx-sm-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-4 {
    margin-right: 4px !important;
  }
  html[dir='rtl'] .da-mr-sm-4 {
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-4 {
    margin-left: 4px !important;
  }
  html[dir='ltr'] .da-mr-sm-4 {
    margin-right: 4px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-6 {
    margin: 6px !important;
  }
  .da-mt-sm-6 {
    margin-top: 6px !important;
  }
  .da-mb-sm-6 {
    margin-bottom: 6px !important;
  }
  .da-my-sm-6 {
    margin-bottom: 6px !important;
    margin-top: 6px !important;
  }
  .da-mx-sm-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-6 {
    margin-right: 6px !important;
  }
  html[dir='rtl'] .da-mr-sm-6 {
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-6 {
    margin-left: 6px !important;
  }
  html[dir='ltr'] .da-mr-sm-6 {
    margin-right: 6px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-8 {
    margin: 8px !important;
  }
  .da-mt-sm-8 {
    margin-top: 8px !important;
  }
  .da-mb-sm-8 {
    margin-bottom: 8px !important;
  }
  .da-my-sm-8 {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .da-mx-sm-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-8 {
    margin-right: 8px !important;
  }
  html[dir='rtl'] .da-mr-sm-8 {
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-8 {
    margin-left: 8px !important;
  }
  html[dir='ltr'] .da-mr-sm-8 {
    margin-right: 8px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-10 {
    margin: 10px !important;
  }
  .da-mt-sm-10 {
    margin-top: 10px !important;
  }
  .da-mb-sm-10 {
    margin-bottom: 10px !important;
  }
  .da-my-sm-10 {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .da-mx-sm-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-10 {
    margin-right: 10px !important;
  }
  html[dir='rtl'] .da-mr-sm-10 {
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-10 {
    margin-left: 10px !important;
  }
  html[dir='ltr'] .da-mr-sm-10 {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-12 {
    margin: 12px !important;
  }
  .da-mt-sm-12 {
    margin-top: 12px !important;
  }
  .da-mb-sm-12 {
    margin-bottom: 12px !important;
  }
  .da-my-sm-12 {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }
  .da-mx-sm-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-12 {
    margin-right: 12px !important;
  }
  html[dir='rtl'] .da-mr-sm-12 {
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-12 {
    margin-left: 12px !important;
  }
  html[dir='ltr'] .da-mr-sm-12 {
    margin-right: 12px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-16 {
    margin: 16px !important;
  }
  .da-mt-sm-16 {
    margin-top: 16px !important;
  }
  .da-mb-sm-16 {
    margin-bottom: 16px !important;
  }
  .da-my-sm-16 {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .da-mx-sm-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-16 {
    margin-right: 16px !important;
  }
  html[dir='rtl'] .da-mr-sm-16 {
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-16 {
    margin-left: 16px !important;
  }
  html[dir='ltr'] .da-mr-sm-16 {
    margin-right: 16px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-18 {
    margin: 18px !important;
  }
  .da-mt-sm-18 {
    margin-top: 18px !important;
  }
  .da-mb-sm-18 {
    margin-bottom: 18px !important;
  }
  .da-my-sm-18 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
  .da-mx-sm-18 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-18 {
    margin-right: 18px !important;
  }
  html[dir='rtl'] .da-mr-sm-18 {
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-18 {
    margin-left: 18px !important;
  }
  html[dir='ltr'] .da-mr-sm-18 {
    margin-right: 18px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-24 {
    margin: 24px !important;
  }
  .da-mt-sm-24 {
    margin-top: 24px !important;
  }
  .da-mb-sm-24 {
    margin-bottom: 24px !important;
  }
  .da-my-sm-24 {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .da-mx-sm-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-24 {
    margin-right: 24px !important;
  }
  html[dir='rtl'] .da-mr-sm-24 {
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-24 {
    margin-left: 24px !important;
  }
  html[dir='ltr'] .da-mr-sm-24 {
    margin-right: 24px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-32 {
    margin: 32px !important;
  }
  .da-mt-sm-32 {
    margin-top: 32px !important;
  }
  .da-mb-sm-32 {
    margin-bottom: 32px !important;
  }
  .da-my-sm-32 {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .da-mx-sm-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-32 {
    margin-right: 32px !important;
  }
  html[dir='rtl'] .da-mr-sm-32 {
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-32 {
    margin-left: 32px !important;
  }
  html[dir='ltr'] .da-mr-sm-32 {
    margin-right: 32px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-36 {
    margin: 36px !important;
  }
  .da-mt-sm-36 {
    margin-top: 36px !important;
  }
  .da-mb-sm-36 {
    margin-bottom: 36px !important;
  }
  .da-my-sm-36 {
    margin-bottom: 36px !important;
    margin-top: 36px !important;
  }
  .da-mx-sm-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-36 {
    margin-right: 36px !important;
  }
  html[dir='rtl'] .da-mr-sm-36 {
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-36 {
    margin-left: 36px !important;
  }
  html[dir='ltr'] .da-mr-sm-36 {
    margin-right: 36px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-42 {
    margin: 42px !important;
  }
  .da-mt-sm-42 {
    margin-top: 42px !important;
  }
  .da-mb-sm-42 {
    margin-bottom: 42px !important;
  }
  .da-my-sm-42 {
    margin-bottom: 42px !important;
    margin-top: 42px !important;
  }
  .da-mx-sm-42 {
    margin-right: 42px !important;
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-42 {
    margin-right: 42px !important;
  }
  html[dir='rtl'] .da-mr-sm-42 {
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-42 {
    margin-left: 42px !important;
  }
  html[dir='ltr'] .da-mr-sm-42 {
    margin-right: 42px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-48 {
    margin: 48px !important;
  }
  .da-mt-sm-48 {
    margin-top: 48px !important;
  }
  .da-mb-sm-48 {
    margin-bottom: 48px !important;
  }
  .da-my-sm-48 {
    margin-bottom: 48px !important;
    margin-top: 48px !important;
  }
  .da-mx-sm-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-48 {
    margin-right: 48px !important;
  }
  html[dir='rtl'] .da-mr-sm-48 {
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-48 {
    margin-left: 48px !important;
  }
  html[dir='ltr'] .da-mr-sm-48 {
    margin-right: 48px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-64 {
    margin: 64px !important;
  }
  .da-mt-sm-64 {
    margin-top: 64px !important;
  }
  .da-mb-sm-64 {
    margin-bottom: 64px !important;
  }
  .da-my-sm-64 {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .da-mx-sm-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-64 {
    margin-right: 64px !important;
  }
  html[dir='rtl'] .da-mr-sm-64 {
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-64 {
    margin-left: 64px !important;
  }
  html[dir='ltr'] .da-mr-sm-64 {
    margin-right: 64px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-96 {
    margin: 96px !important;
  }
  .da-mt-sm-96 {
    margin-top: 96px !important;
  }
  .da-mb-sm-96 {
    margin-bottom: 96px !important;
  }
  .da-my-sm-96 {
    margin-bottom: 96px !important;
    margin-top: 96px !important;
  }
  .da-mx-sm-96 {
    margin-right: 96px !important;
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-96 {
    margin-right: 96px !important;
  }
  html[dir='rtl'] .da-mr-sm-96 {
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-96 {
    margin-left: 96px !important;
  }
  html[dir='ltr'] .da-mr-sm-96 {
    margin-right: 96px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-m-sm-120 {
    margin: 120px !important;
  }
  .da-mt-sm-120 {
    margin-top: 120px !important;
  }
  .da-mb-sm-120 {
    margin-bottom: 120px !important;
  }
  .da-my-sm-120 {
    margin-bottom: 120px !important;
    margin-top: 120px !important;
  }
  .da-mx-sm-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-ml-sm-120 {
    margin-right: 120px !important;
  }
  html[dir='rtl'] .da-mr-sm-120 {
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-ml-sm-120 {
    margin-left: 120px !important;
  }
  html[dir='ltr'] .da-mr-sm-120 {
    margin-right: 120px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-0 {
    margin: 0px !important;
  }
  .da-mt-xs-0 {
    margin-top: 0px !important;
  }
  .da-mb-xs-0 {
    margin-bottom: 0px !important;
  }
  .da-my-xs-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .da-mx-sm-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-0 {
    margin-right: 0px !important;
  }
  html[dir='rtl'] .da-mr-xs-0 {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-0 {
    margin-left: 0px !important;
  }
  html[dir='ltr'] .da-mr-xs-0 {
    margin-right: 0px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-4 {
    margin: 4px !important;
  }
  .da-mt-xs-4 {
    margin-top: 4px !important;
  }
  .da-mb-xs-4 {
    margin-bottom: 4px !important;
  }
  .da-my-xs-4 {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .da-mx-sm-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-4 {
    margin-right: 4px !important;
  }
  html[dir='rtl'] .da-mr-xs-4 {
    margin-left: 4px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-4 {
    margin-left: 4px !important;
  }
  html[dir='ltr'] .da-mr-xs-4 {
    margin-right: 4px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-6 {
    margin: 6px !important;
  }
  .da-mt-xs-6 {
    margin-top: 6px !important;
  }
  .da-mb-xs-6 {
    margin-bottom: 6px !important;
  }
  .da-my-xs-6 {
    margin-bottom: 6px !important;
    margin-top: 6px !important;
  }
  .da-mx-sm-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-6 {
    margin-right: 6px !important;
  }
  html[dir='rtl'] .da-mr-xs-6 {
    margin-left: 6px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-6 {
    margin-left: 6px !important;
  }
  html[dir='ltr'] .da-mr-xs-6 {
    margin-right: 6px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-8 {
    margin: 8px !important;
  }
  .da-mt-xs-8 {
    margin-top: 8px !important;
  }
  .da-mb-xs-8 {
    margin-bottom: 8px !important;
  }
  .da-my-xs-8 {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .da-mx-sm-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-8 {
    margin-right: 8px !important;
  }
  html[dir='rtl'] .da-mr-xs-8 {
    margin-left: 8px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-8 {
    margin-left: 8px !important;
  }
  html[dir='ltr'] .da-mr-xs-8 {
    margin-right: 8px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-10 {
    margin: 10px !important;
  }
  .da-mt-xs-10 {
    margin-top: 10px !important;
  }
  .da-mb-xs-10 {
    margin-bottom: 10px !important;
  }
  .da-my-xs-10 {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .da-mx-sm-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-10 {
    margin-right: 10px !important;
  }
  html[dir='rtl'] .da-mr-xs-10 {
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-10 {
    margin-left: 10px !important;
  }
  html[dir='ltr'] .da-mr-xs-10 {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-12 {
    margin: 12px !important;
  }
  .da-mt-xs-12 {
    margin-top: 12px !important;
  }
  .da-mb-xs-12 {
    margin-bottom: 12px !important;
  }
  .da-my-xs-12 {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }
  .da-mx-sm-12 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-12 {
    margin-right: 12px !important;
  }
  html[dir='rtl'] .da-mr-xs-12 {
    margin-left: 12px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-12 {
    margin-left: 12px !important;
  }
  html[dir='ltr'] .da-mr-xs-12 {
    margin-right: 12px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-16 {
    margin: 16px !important;
  }
  .da-mt-xs-16 {
    margin-top: 16px !important;
  }
  .da-mb-xs-16 {
    margin-bottom: 16px !important;
  }
  .da-my-xs-16 {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .da-mx-sm-16 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-16 {
    margin-right: 16px !important;
  }
  html[dir='rtl'] .da-mr-xs-16 {
    margin-left: 16px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-16 {
    margin-left: 16px !important;
  }
  html[dir='ltr'] .da-mr-xs-16 {
    margin-right: 16px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-18 {
    margin: 18px !important;
  }
  .da-mt-xs-18 {
    margin-top: 18px !important;
  }
  .da-mb-xs-18 {
    margin-bottom: 18px !important;
  }
  .da-my-xs-18 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
  .da-mx-sm-18 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-18 {
    margin-right: 18px !important;
  }
  html[dir='rtl'] .da-mr-xs-18 {
    margin-left: 18px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-18 {
    margin-left: 18px !important;
  }
  html[dir='ltr'] .da-mr-xs-18 {
    margin-right: 18px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-24 {
    margin: 24px !important;
  }
  .da-mt-xs-24 {
    margin-top: 24px !important;
  }
  .da-mb-xs-24 {
    margin-bottom: 24px !important;
  }
  .da-my-xs-24 {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .da-mx-sm-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-24 {
    margin-right: 24px !important;
  }
  html[dir='rtl'] .da-mr-xs-24 {
    margin-left: 24px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-24 {
    margin-left: 24px !important;
  }
  html[dir='ltr'] .da-mr-xs-24 {
    margin-right: 24px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-32 {
    margin: 32px !important;
  }
  .da-mt-xs-32 {
    margin-top: 32px !important;
  }
  .da-mb-xs-32 {
    margin-bottom: 32px !important;
  }
  .da-my-xs-32 {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .da-mx-sm-32 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-32 {
    margin-right: 32px !important;
  }
  html[dir='rtl'] .da-mr-xs-32 {
    margin-left: 32px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-32 {
    margin-left: 32px !important;
  }
  html[dir='ltr'] .da-mr-xs-32 {
    margin-right: 32px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-36 {
    margin: 36px !important;
  }
  .da-mt-xs-36 {
    margin-top: 36px !important;
  }
  .da-mb-xs-36 {
    margin-bottom: 36px !important;
  }
  .da-my-xs-36 {
    margin-bottom: 36px !important;
    margin-top: 36px !important;
  }
  .da-mx-sm-36 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-36 {
    margin-right: 36px !important;
  }
  html[dir='rtl'] .da-mr-xs-36 {
    margin-left: 36px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-36 {
    margin-left: 36px !important;
  }
  html[dir='ltr'] .da-mr-xs-36 {
    margin-right: 36px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-42 {
    margin: 42px !important;
  }
  .da-mt-xs-42 {
    margin-top: 42px !important;
  }
  .da-mb-xs-42 {
    margin-bottom: 42px !important;
  }
  .da-my-xs-42 {
    margin-bottom: 42px !important;
    margin-top: 42px !important;
  }
  .da-mx-sm-42 {
    margin-right: 42px !important;
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-42 {
    margin-right: 42px !important;
  }
  html[dir='rtl'] .da-mr-xs-42 {
    margin-left: 42px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-42 {
    margin-left: 42px !important;
  }
  html[dir='ltr'] .da-mr-xs-42 {
    margin-right: 42px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-48 {
    margin: 48px !important;
  }
  .da-mt-xs-48 {
    margin-top: 48px !important;
  }
  .da-mb-xs-48 {
    margin-bottom: 48px !important;
  }
  .da-my-xs-48 {
    margin-bottom: 48px !important;
    margin-top: 48px !important;
  }
  .da-mx-sm-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-48 {
    margin-right: 48px !important;
  }
  html[dir='rtl'] .da-mr-xs-48 {
    margin-left: 48px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-48 {
    margin-left: 48px !important;
  }
  html[dir='ltr'] .da-mr-xs-48 {
    margin-right: 48px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-64 {
    margin: 64px !important;
  }
  .da-mt-xs-64 {
    margin-top: 64px !important;
  }
  .da-mb-xs-64 {
    margin-bottom: 64px !important;
  }
  .da-my-xs-64 {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .da-mx-sm-64 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-64 {
    margin-right: 64px !important;
  }
  html[dir='rtl'] .da-mr-xs-64 {
    margin-left: 64px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-64 {
    margin-left: 64px !important;
  }
  html[dir='ltr'] .da-mr-xs-64 {
    margin-right: 64px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-96 {
    margin: 96px !important;
  }
  .da-mt-xs-96 {
    margin-top: 96px !important;
  }
  .da-mb-xs-96 {
    margin-bottom: 96px !important;
  }
  .da-my-xs-96 {
    margin-bottom: 96px !important;
    margin-top: 96px !important;
  }
  .da-mx-sm-96 {
    margin-right: 96px !important;
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-96 {
    margin-right: 96px !important;
  }
  html[dir='rtl'] .da-mr-xs-96 {
    margin-left: 96px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-96 {
    margin-left: 96px !important;
  }
  html[dir='ltr'] .da-mr-xs-96 {
    margin-right: 96px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-m-xs-120 {
    margin: 120px !important;
  }
  .da-mt-xs-120 {
    margin-top: 120px !important;
  }
  .da-mb-xs-120 {
    margin-bottom: 120px !important;
  }
  .da-my-xs-120 {
    margin-bottom: 120px !important;
    margin-top: 120px !important;
  }
  .da-mx-sm-120 {
    margin-right: 120px !important;
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-ml-xs-120 {
    margin-right: 120px !important;
  }
  html[dir='rtl'] .da-mr-xs-120 {
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-ml-xs-120 {
    margin-left: 120px !important;
  }
  html[dir='ltr'] .da-mr-xs-120 {
    margin-right: 120px !important;
  }
}
.da-p-0 {
  padding: 0px !important;
}
.da-pt-0 {
  padding-top: 0px !important;
}
.da-pb-0 {
  padding-bottom: 0px !important;
}
.da-py-0 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.da-px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
html[dir='rtl'] .da-pl-0 {
  padding-right: 0px !important;
}
html[dir='rtl'] .da-pr-0 {
  padding-left: 0px !important;
}
html[dir='ltr'] .da-pl-0 {
  padding-left: 0px !important;
}
html[dir='ltr'] .da-pr-0 {
  padding-right: 0px !important;
}
.da-p-4 {
  padding: 4px !important;
}
.da-pt-4 {
  padding-top: 4px !important;
}
.da-pb-4 {
  padding-bottom: 4px !important;
}
.da-py-4 {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
.da-px-4 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}
html[dir='rtl'] .da-pl-4 {
  padding-right: 4px !important;
}
html[dir='rtl'] .da-pr-4 {
  padding-left: 4px !important;
}
html[dir='ltr'] .da-pl-4 {
  padding-left: 4px !important;
}
html[dir='ltr'] .da-pr-4 {
  padding-right: 4px !important;
}
.da-p-6 {
  padding: 6px !important;
}
.da-pt-6 {
  padding-top: 6px !important;
}
.da-pb-6 {
  padding-bottom: 6px !important;
}
.da-py-6 {
  padding-bottom: 6px !important;
  padding-top: 6px !important;
}
.da-px-6 {
  padding-right: 6px !important;
  padding-left: 6px !important;
}
html[dir='rtl'] .da-pl-6 {
  padding-right: 6px !important;
}
html[dir='rtl'] .da-pr-6 {
  padding-left: 6px !important;
}
html[dir='ltr'] .da-pl-6 {
  padding-left: 6px !important;
}
html[dir='ltr'] .da-pr-6 {
  padding-right: 6px !important;
}
.da-p-8 {
  padding: 8px !important;
}
.da-pt-8 {
  padding-top: 8px !important;
}
.da-pb-8 {
  padding-bottom: 8px !important;
}
.da-py-8 {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}
.da-px-8 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}
html[dir='rtl'] .da-pl-8 {
  padding-right: 8px !important;
}
html[dir='rtl'] .da-pr-8 {
  padding-left: 8px !important;
}
html[dir='ltr'] .da-pl-8 {
  padding-left: 8px !important;
}
html[dir='ltr'] .da-pr-8 {
  padding-right: 8px !important;
}
.da-p-10 {
  padding: 10px !important;
}
.da-pt-10 {
  padding-top: 10px !important;
}
.da-pb-10 {
  padding-bottom: 10px !important;
}
.da-py-10 {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}
.da-px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
html[dir='rtl'] .da-pl-10 {
  padding-right: 10px !important;
}
html[dir='rtl'] .da-pr-10 {
  padding-left: 10px !important;
}
html[dir='ltr'] .da-pl-10 {
  padding-left: 10px !important;
}
html[dir='ltr'] .da-pr-10 {
  padding-right: 10px !important;
}
.da-p-12 {
  padding: 12px !important;
}
.da-pt-12 {
  padding-top: 12px !important;
}
.da-pb-12 {
  padding-bottom: 12px !important;
}
.da-py-12 {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}
.da-px-12 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}
html[dir='rtl'] .da-pl-12 {
  padding-right: 12px !important;
}
html[dir='rtl'] .da-pr-12 {
  padding-left: 12px !important;
}
html[dir='ltr'] .da-pl-12 {
  padding-left: 12px !important;
}
html[dir='ltr'] .da-pr-12 {
  padding-right: 12px !important;
}
.da-p-16 {
  padding: 16px !important;
}
.da-pt-16 {
  padding-top: 16px !important;
}
.da-pb-16 {
  padding-bottom: 16px !important;
}
.da-py-16 {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}
.da-px-16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}
html[dir='rtl'] .da-pl-16 {
  padding-right: 16px !important;
}
html[dir='rtl'] .da-pr-16 {
  padding-left: 16px !important;
}
html[dir='ltr'] .da-pl-16 {
  padding-left: 16px !important;
}
html[dir='ltr'] .da-pr-16 {
  padding-right: 16px !important;
}
.da-p-18 {
  padding: 18px !important;
}
.da-pt-18 {
  padding-top: 18px !important;
}
.da-pb-18 {
  padding-bottom: 18px !important;
}
.da-py-18 {
  padding-bottom: 18px !important;
  padding-top: 18px !important;
}
.da-px-18 {
  padding-right: 18px !important;
  padding-left: 18px !important;
}
html[dir='rtl'] .da-pl-18 {
  padding-right: 18px !important;
}
html[dir='rtl'] .da-pr-18 {
  padding-left: 18px !important;
}
html[dir='ltr'] .da-pl-18 {
  padding-left: 18px !important;
}
html[dir='ltr'] .da-pr-18 {
  padding-right: 18px !important;
}
.da-p-24 {
  padding: 24px !important;
}
.da-pt-24 {
  padding-top: 24px !important;
}
.da-pb-24 {
  padding-bottom: 24px !important;
}
.da-py-24 {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}
.da-px-24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}
html[dir='rtl'] .da-pl-24 {
  padding-right: 24px !important;
}
html[dir='rtl'] .da-pr-24 {
  padding-left: 24px !important;
}
html[dir='ltr'] .da-pl-24 {
  padding-left: 24px !important;
}
html[dir='ltr'] .da-pr-24 {
  padding-right: 24px !important;
}
.da-p-32 {
  padding: 32px !important;
}
.da-pt-32 {
  padding-top: 32px !important;
}
.da-pb-32 {
  padding-bottom: 32px !important;
}
.da-py-32 {
  padding-bottom: 32px !important;
  padding-top: 32px !important;
}
.da-px-32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}
html[dir='rtl'] .da-pl-32 {
  padding-right: 32px !important;
}
html[dir='rtl'] .da-pr-32 {
  padding-left: 32px !important;
}
html[dir='ltr'] .da-pl-32 {
  padding-left: 32px !important;
}
html[dir='ltr'] .da-pr-32 {
  padding-right: 32px !important;
}
.da-p-36 {
  padding: 36px !important;
}
.da-pt-36 {
  padding-top: 36px !important;
}
.da-pb-36 {
  padding-bottom: 36px !important;
}
.da-py-36 {
  padding-bottom: 36px !important;
  padding-top: 36px !important;
}
.da-px-36 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}
html[dir='rtl'] .da-pl-36 {
  padding-right: 36px !important;
}
html[dir='rtl'] .da-pr-36 {
  padding-left: 36px !important;
}
html[dir='ltr'] .da-pl-36 {
  padding-left: 36px !important;
}
html[dir='ltr'] .da-pr-36 {
  padding-right: 36px !important;
}
.da-p-42 {
  padding: 42px !important;
}
.da-pt-42 {
  padding-top: 42px !important;
}
.da-pb-42 {
  padding-bottom: 42px !important;
}
.da-py-42 {
  padding-bottom: 42px !important;
  padding-top: 42px !important;
}
.da-px-42 {
  padding-right: 42px !important;
  padding-left: 42px !important;
}
html[dir='rtl'] .da-pl-42 {
  padding-right: 42px !important;
}
html[dir='rtl'] .da-pr-42 {
  padding-left: 42px !important;
}
html[dir='ltr'] .da-pl-42 {
  padding-left: 42px !important;
}
html[dir='ltr'] .da-pr-42 {
  padding-right: 42px !important;
}
.da-p-48 {
  padding: 48px !important;
}
.da-pt-48 {
  padding-top: 48px !important;
}
.da-pb-48 {
  padding-bottom: 48px !important;
}
.da-py-48 {
  padding-bottom: 48px !important;
  padding-top: 48px !important;
}
.da-px-48 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}
html[dir='rtl'] .da-pl-48 {
  padding-right: 48px !important;
}
html[dir='rtl'] .da-pr-48 {
  padding-left: 48px !important;
}
html[dir='ltr'] .da-pl-48 {
  padding-left: 48px !important;
}
html[dir='ltr'] .da-pr-48 {
  padding-right: 48px !important;
}
.da-p-64 {
  padding: 64px !important;
}
.da-pt-64 {
  padding-top: 64px !important;
}
.da-pb-64 {
  padding-bottom: 64px !important;
}
.da-py-64 {
  padding-bottom: 64px !important;
  padding-top: 64px !important;
}
.da-px-64 {
  padding-right: 64px !important;
  padding-left: 64px !important;
}
html[dir='rtl'] .da-pl-64 {
  padding-right: 64px !important;
}
html[dir='rtl'] .da-pr-64 {
  padding-left: 64px !important;
}
html[dir='ltr'] .da-pl-64 {
  padding-left: 64px !important;
}
html[dir='ltr'] .da-pr-64 {
  padding-right: 64px !important;
}
.da-p-96 {
  padding: 96px !important;
}
.da-pt-96 {
  padding-top: 96px !important;
}
.da-pb-96 {
  padding-bottom: 96px !important;
}
.da-py-96 {
  padding-bottom: 96px !important;
  padding-top: 96px !important;
}
.da-px-96 {
  padding-right: 96px !important;
  padding-left: 96px !important;
}
html[dir='rtl'] .da-pl-96 {
  padding-right: 96px !important;
}
html[dir='rtl'] .da-pr-96 {
  padding-left: 96px !important;
}
html[dir='ltr'] .da-pl-96 {
  padding-left: 96px !important;
}
html[dir='ltr'] .da-pr-96 {
  padding-right: 96px !important;
}
.da-p-120 {
  padding: 120px !important;
}
.da-pt-120 {
  padding-top: 120px !important;
}
.da-pb-120 {
  padding-bottom: 120px !important;
}
.da-py-120 {
  padding-bottom: 120px !important;
  padding-top: 120px !important;
}
.da-px-120 {
  padding-right: 120px !important;
  padding-left: 120px !important;
}
html[dir='rtl'] .da-pl-120 {
  padding-right: 120px !important;
}
html[dir='rtl'] .da-pr-120 {
  padding-left: 120px !important;
}
html[dir='ltr'] .da-pl-120 {
  padding-left: 120px !important;
}
html[dir='ltr'] .da-pr-120 {
  padding-right: 120px !important;
}
@media screen and (max-width: 1599px) {
  .da-p-xl-0 {
    padding: 0px !important;
  }
  .da-pt-xl-0 {
    padding-top: 0px !important;
  }
  .da-pb-xl-0 {
    padding-bottom: 0px !important;
  }
  .da-py-xl-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .da-px-xl-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-0 {
    padding-right: 0px !important;
  }
  html[dir='rtl'] .da-pr-xl-0 {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-0 {
    padding-left: 0px !important;
  }
  html[dir='ltr'] .da-pr-xl-0 {
    padding-right: 0px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-4 {
    padding: 4px !important;
  }
  .da-pt-xl-4 {
    padding-top: 4px !important;
  }
  .da-pb-xl-4 {
    padding-bottom: 4px !important;
  }
  .da-py-xl-4 {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .da-px-xl-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-4 {
    padding-right: 4px !important;
  }
  html[dir='rtl'] .da-pr-xl-4 {
    padding-left: 4px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-4 {
    padding-left: 4px !important;
  }
  html[dir='ltr'] .da-pr-xl-4 {
    padding-right: 4px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-6 {
    padding: 6px !important;
  }
  .da-pt-xl-6 {
    padding-top: 6px !important;
  }
  .da-pb-xl-6 {
    padding-bottom: 6px !important;
  }
  .da-py-xl-6 {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }
  .da-px-xl-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-6 {
    padding-right: 6px !important;
  }
  html[dir='rtl'] .da-pr-xl-6 {
    padding-left: 6px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-6 {
    padding-left: 6px !important;
  }
  html[dir='ltr'] .da-pr-xl-6 {
    padding-right: 6px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-8 {
    padding: 8px !important;
  }
  .da-pt-xl-8 {
    padding-top: 8px !important;
  }
  .da-pb-xl-8 {
    padding-bottom: 8px !important;
  }
  .da-py-xl-8 {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .da-px-xl-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-8 {
    padding-right: 8px !important;
  }
  html[dir='rtl'] .da-pr-xl-8 {
    padding-left: 8px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-8 {
    padding-left: 8px !important;
  }
  html[dir='ltr'] .da-pr-xl-8 {
    padding-right: 8px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-10 {
    padding: 10px !important;
  }
  .da-pt-xl-10 {
    padding-top: 10px !important;
  }
  .da-pb-xl-10 {
    padding-bottom: 10px !important;
  }
  .da-py-xl-10 {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .da-px-xl-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-10 {
    padding-right: 10px !important;
  }
  html[dir='rtl'] .da-pr-xl-10 {
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-10 {
    padding-left: 10px !important;
  }
  html[dir='ltr'] .da-pr-xl-10 {
    padding-right: 10px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-12 {
    padding: 12px !important;
  }
  .da-pt-xl-12 {
    padding-top: 12px !important;
  }
  .da-pb-xl-12 {
    padding-bottom: 12px !important;
  }
  .da-py-xl-12 {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }
  .da-px-xl-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-12 {
    padding-right: 12px !important;
  }
  html[dir='rtl'] .da-pr-xl-12 {
    padding-left: 12px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-12 {
    padding-left: 12px !important;
  }
  html[dir='ltr'] .da-pr-xl-12 {
    padding-right: 12px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-16 {
    padding: 16px !important;
  }
  .da-pt-xl-16 {
    padding-top: 16px !important;
  }
  .da-pb-xl-16 {
    padding-bottom: 16px !important;
  }
  .da-py-xl-16 {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .da-px-xl-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-16 {
    padding-right: 16px !important;
  }
  html[dir='rtl'] .da-pr-xl-16 {
    padding-left: 16px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-16 {
    padding-left: 16px !important;
  }
  html[dir='ltr'] .da-pr-xl-16 {
    padding-right: 16px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-18 {
    padding: 18px !important;
  }
  .da-pt-xl-18 {
    padding-top: 18px !important;
  }
  .da-pb-xl-18 {
    padding-bottom: 18px !important;
  }
  .da-py-xl-18 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
  .da-px-xl-18 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-18 {
    padding-right: 18px !important;
  }
  html[dir='rtl'] .da-pr-xl-18 {
    padding-left: 18px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-18 {
    padding-left: 18px !important;
  }
  html[dir='ltr'] .da-pr-xl-18 {
    padding-right: 18px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-24 {
    padding: 24px !important;
  }
  .da-pt-xl-24 {
    padding-top: 24px !important;
  }
  .da-pb-xl-24 {
    padding-bottom: 24px !important;
  }
  .da-py-xl-24 {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .da-px-xl-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-24 {
    padding-right: 24px !important;
  }
  html[dir='rtl'] .da-pr-xl-24 {
    padding-left: 24px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-24 {
    padding-left: 24px !important;
  }
  html[dir='ltr'] .da-pr-xl-24 {
    padding-right: 24px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-32 {
    padding: 32px !important;
  }
  .da-pt-xl-32 {
    padding-top: 32px !important;
  }
  .da-pb-xl-32 {
    padding-bottom: 32px !important;
  }
  .da-py-xl-32 {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .da-px-xl-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-32 {
    padding-right: 32px !important;
  }
  html[dir='rtl'] .da-pr-xl-32 {
    padding-left: 32px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-32 {
    padding-left: 32px !important;
  }
  html[dir='ltr'] .da-pr-xl-32 {
    padding-right: 32px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-36 {
    padding: 36px !important;
  }
  .da-pt-xl-36 {
    padding-top: 36px !important;
  }
  .da-pb-xl-36 {
    padding-bottom: 36px !important;
  }
  .da-py-xl-36 {
    padding-bottom: 36px !important;
    padding-top: 36px !important;
  }
  .da-px-xl-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-36 {
    padding-right: 36px !important;
  }
  html[dir='rtl'] .da-pr-xl-36 {
    padding-left: 36px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-36 {
    padding-left: 36px !important;
  }
  html[dir='ltr'] .da-pr-xl-36 {
    padding-right: 36px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-42 {
    padding: 42px !important;
  }
  .da-pt-xl-42 {
    padding-top: 42px !important;
  }
  .da-pb-xl-42 {
    padding-bottom: 42px !important;
  }
  .da-py-xl-42 {
    padding-bottom: 42px !important;
    padding-top: 42px !important;
  }
  .da-px-xl-42 {
    padding-right: 42px !important;
    padding-left: 42px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-42 {
    padding-right: 42px !important;
  }
  html[dir='rtl'] .da-pr-xl-42 {
    padding-left: 42px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-42 {
    padding-left: 42px !important;
  }
  html[dir='ltr'] .da-pr-xl-42 {
    padding-right: 42px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-48 {
    padding: 48px !important;
  }
  .da-pt-xl-48 {
    padding-top: 48px !important;
  }
  .da-pb-xl-48 {
    padding-bottom: 48px !important;
  }
  .da-py-xl-48 {
    padding-bottom: 48px !important;
    padding-top: 48px !important;
  }
  .da-px-xl-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-48 {
    padding-right: 48px !important;
  }
  html[dir='rtl'] .da-pr-xl-48 {
    padding-left: 48px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-48 {
    padding-left: 48px !important;
  }
  html[dir='ltr'] .da-pr-xl-48 {
    padding-right: 48px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-64 {
    padding: 64px !important;
  }
  .da-pt-xl-64 {
    padding-top: 64px !important;
  }
  .da-pb-xl-64 {
    padding-bottom: 64px !important;
  }
  .da-py-xl-64 {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .da-px-xl-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-64 {
    padding-right: 64px !important;
  }
  html[dir='rtl'] .da-pr-xl-64 {
    padding-left: 64px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-64 {
    padding-left: 64px !important;
  }
  html[dir='ltr'] .da-pr-xl-64 {
    padding-right: 64px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-96 {
    padding: 96px !important;
  }
  .da-pt-xl-96 {
    padding-top: 96px !important;
  }
  .da-pb-xl-96 {
    padding-bottom: 96px !important;
  }
  .da-py-xl-96 {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }
  .da-px-xl-96 {
    padding-right: 96px !important;
    padding-left: 96px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-96 {
    padding-right: 96px !important;
  }
  html[dir='rtl'] .da-pr-xl-96 {
    padding-left: 96px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-96 {
    padding-left: 96px !important;
  }
  html[dir='ltr'] .da-pr-xl-96 {
    padding-right: 96px !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-p-xl-120 {
    padding: 120px !important;
  }
  .da-pt-xl-120 {
    padding-top: 120px !important;
  }
  .da-pb-xl-120 {
    padding-bottom: 120px !important;
  }
  .da-py-xl-120 {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }
  .da-px-xl-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='rtl'] .da-pl-xl-120 {
    padding-right: 120px !important;
  }
  html[dir='rtl'] .da-pr-xl-120 {
    padding-left: 120px !important;
  }
}
@media screen and (max-width: 1599px) {
  html[dir='ltr'] .da-pl-xl-120 {
    padding-left: 120px !important;
  }
  html[dir='ltr'] .da-pr-xl-120 {
    padding-right: 120px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-0 {
    padding: 0px !important;
  }
  .da-pt-lg-0 {
    padding-top: 0px !important;
  }
  .da-pb-lg-0 {
    padding-bottom: 0px !important;
  }
  .da-py-lg-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .da-px-lg-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-0 {
    padding-right: 0px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-0 {
    padding-left: 0px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-0 {
    padding-left: 0px !important;
  }
  html[dir='ltr'] .da-pr-lg-0 {
    padding-right: 0px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-4 {
    padding: 4px !important;
  }
  .da-pt-lg-4 {
    padding-top: 4px !important;
  }
  .da-pb-lg-4 {
    padding-bottom: 4px !important;
  }
  .da-py-lg-4 {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .da-px-lg-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-4 {
    padding-right: 4px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-4 {
    padding-left: 4px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-4 {
    padding-left: 4px !important;
  }
  html[dir='ltr'] .da-pr-lg-4 {
    padding-right: 4px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-6 {
    padding: 6px !important;
  }
  .da-pt-lg-6 {
    padding-top: 6px !important;
  }
  .da-pb-lg-6 {
    padding-bottom: 6px !important;
  }
  .da-py-lg-6 {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }
  .da-px-lg-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-6 {
    padding-right: 6px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-6 {
    padding-left: 6px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-6 {
    padding-left: 6px !important;
  }
  html[dir='ltr'] .da-pr-lg-6 {
    padding-right: 6px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-8 {
    padding: 8px !important;
  }
  .da-pt-lg-8 {
    padding-top: 8px !important;
  }
  .da-pb-lg-8 {
    padding-bottom: 8px !important;
  }
  .da-py-lg-8 {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .da-px-lg-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-8 {
    padding-right: 8px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-8 {
    padding-left: 8px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-8 {
    padding-left: 8px !important;
  }
  html[dir='ltr'] .da-pr-lg-8 {
    padding-right: 8px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-10 {
    padding: 10px !important;
  }
  .da-pt-lg-10 {
    padding-top: 10px !important;
  }
  .da-pb-lg-10 {
    padding-bottom: 10px !important;
  }
  .da-py-lg-10 {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .da-px-lg-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-10 {
    padding-right: 10px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-10 {
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-10 {
    padding-left: 10px !important;
  }
  html[dir='ltr'] .da-pr-lg-10 {
    padding-right: 10px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-12 {
    padding: 12px !important;
  }
  .da-pt-lg-12 {
    padding-top: 12px !important;
  }
  .da-pb-lg-12 {
    padding-bottom: 12px !important;
  }
  .da-py-lg-12 {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }
  .da-px-lg-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-12 {
    padding-right: 12px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-12 {
    padding-left: 12px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-12 {
    padding-left: 12px !important;
  }
  html[dir='ltr'] .da-pr-lg-12 {
    padding-right: 12px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-16 {
    padding: 16px !important;
  }
  .da-pt-lg-16 {
    padding-top: 16px !important;
  }
  .da-pb-lg-16 {
    padding-bottom: 16px !important;
  }
  .da-py-lg-16 {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .da-px-lg-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-16 {
    padding-right: 16px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-16 {
    padding-left: 16px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-16 {
    padding-left: 16px !important;
  }
  html[dir='ltr'] .da-pr-lg-16 {
    padding-right: 16px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-18 {
    padding: 18px !important;
  }
  .da-pt-lg-18 {
    padding-top: 18px !important;
  }
  .da-pb-lg-18 {
    padding-bottom: 18px !important;
  }
  .da-py-lg-18 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
  .da-px-lg-18 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-18 {
    padding-right: 18px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-18 {
    padding-left: 18px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-18 {
    padding-left: 18px !important;
  }
  html[dir='ltr'] .da-pr-lg-18 {
    padding-right: 18px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-24 {
    padding: 24px !important;
  }
  .da-pt-lg-24 {
    padding-top: 24px !important;
  }
  .da-pb-lg-24 {
    padding-bottom: 24px !important;
  }
  .da-py-lg-24 {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .da-px-lg-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-24 {
    padding-right: 24px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-24 {
    padding-left: 24px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-24 {
    padding-left: 24px !important;
  }
  html[dir='ltr'] .da-pr-lg-24 {
    padding-right: 24px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-32 {
    padding: 32px !important;
  }
  .da-pt-lg-32 {
    padding-top: 32px !important;
  }
  .da-pb-lg-32 {
    padding-bottom: 32px !important;
  }
  .da-py-lg-32 {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .da-px-lg-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-32 {
    padding-right: 32px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-32 {
    padding-left: 32px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-32 {
    padding-left: 32px !important;
  }
  html[dir='ltr'] .da-pr-lg-32 {
    padding-right: 32px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-36 {
    padding: 36px !important;
  }
  .da-pt-lg-36 {
    padding-top: 36px !important;
  }
  .da-pb-lg-36 {
    padding-bottom: 36px !important;
  }
  .da-py-lg-36 {
    padding-bottom: 36px !important;
    padding-top: 36px !important;
  }
  .da-px-lg-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-36 {
    padding-right: 36px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-36 {
    padding-left: 36px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-36 {
    padding-left: 36px !important;
  }
  html[dir='ltr'] .da-pr-lg-36 {
    padding-right: 36px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-42 {
    padding: 42px !important;
  }
  .da-pt-lg-42 {
    padding-top: 42px !important;
  }
  .da-pb-lg-42 {
    padding-bottom: 42px !important;
  }
  .da-py-lg-42 {
    padding-bottom: 42px !important;
    padding-top: 42px !important;
  }
  .da-px-lg-42 {
    padding-right: 42px !important;
    padding-left: 42px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-42 {
    padding-right: 42px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-42 {
    padding-left: 42px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-42 {
    padding-left: 42px !important;
  }
  html[dir='ltr'] .da-pr-lg-42 {
    padding-right: 42px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-48 {
    padding: 48px !important;
  }
  .da-pt-lg-48 {
    padding-top: 48px !important;
  }
  .da-pb-lg-48 {
    padding-bottom: 48px !important;
  }
  .da-py-lg-48 {
    padding-bottom: 48px !important;
    padding-top: 48px !important;
  }
  .da-px-lg-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-48 {
    padding-right: 48px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-48 {
    padding-left: 48px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-48 {
    padding-left: 48px !important;
  }
  html[dir='ltr'] .da-pr-lg-48 {
    padding-right: 48px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-64 {
    padding: 64px !important;
  }
  .da-pt-lg-64 {
    padding-top: 64px !important;
  }
  .da-pb-lg-64 {
    padding-bottom: 64px !important;
  }
  .da-py-lg-64 {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .da-px-lg-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-64 {
    padding-right: 64px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-64 {
    padding-left: 64px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-64 {
    padding-left: 64px !important;
  }
  html[dir='ltr'] .da-pr-lg-64 {
    padding-right: 64px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-96 {
    padding: 96px !important;
  }
  .da-pt-lg-96 {
    padding-top: 96px !important;
  }
  .da-pb-lg-96 {
    padding-bottom: 96px !important;
  }
  .da-py-lg-96 {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }
  .da-px-lg-96 {
    padding-right: 96px !important;
    padding-left: 96px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-96 {
    padding-right: 96px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-96 {
    padding-left: 96px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-96 {
    padding-left: 96px !important;
  }
  html[dir='ltr'] .da-pr-lg-96 {
    padding-right: 96px !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-p-lg-120 {
    padding: 120px !important;
  }
  .da-pt-lg-120 {
    padding-top: 120px !important;
  }
  .da-pb-lg-120 {
    padding-bottom: 120px !important;
  }
  .da-py-lg-120 {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }
  .da-px-lg-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='rtl'] .da-pl-lg-120 {
    padding-right: 120px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-lg-120 {
    padding-left: 120px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1199px) {
  html[dir='ltr'] .da-pl-lg-120 {
    padding-left: 120px !important;
  }
  html[dir='ltr'] .da-pr-lg-120 {
    padding-right: 120px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-0 {
    padding: 0px !important;
  }
  .da-pt-md-0 {
    padding-top: 0px !important;
  }
  .da-pb-md-0 {
    padding-bottom: 0px !important;
  }
  .da-py-md-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .da-px-md-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-0 {
    padding-right: 0px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-0 {
    padding-left: 0px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-0 {
    padding-left: 0px !important;
  }
  html[dir='ltr'] .da-pr-md-0 {
    padding-right: 0px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-4 {
    padding: 4px !important;
  }
  .da-pt-md-4 {
    padding-top: 4px !important;
  }
  .da-pb-md-4 {
    padding-bottom: 4px !important;
  }
  .da-py-md-4 {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .da-px-md-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-4 {
    padding-right: 4px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-4 {
    padding-left: 4px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-4 {
    padding-left: 4px !important;
  }
  html[dir='ltr'] .da-pr-md-4 {
    padding-right: 4px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-6 {
    padding: 6px !important;
  }
  .da-pt-md-6 {
    padding-top: 6px !important;
  }
  .da-pb-md-6 {
    padding-bottom: 6px !important;
  }
  .da-py-md-6 {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }
  .da-px-md-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-6 {
    padding-right: 6px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-6 {
    padding-left: 6px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-6 {
    padding-left: 6px !important;
  }
  html[dir='ltr'] .da-pr-md-6 {
    padding-right: 6px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-8 {
    padding: 8px !important;
  }
  .da-pt-md-8 {
    padding-top: 8px !important;
  }
  .da-pb-md-8 {
    padding-bottom: 8px !important;
  }
  .da-py-md-8 {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .da-px-md-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-8 {
    padding-right: 8px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-8 {
    padding-left: 8px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-8 {
    padding-left: 8px !important;
  }
  html[dir='ltr'] .da-pr-md-8 {
    padding-right: 8px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-10 {
    padding: 10px !important;
  }
  .da-pt-md-10 {
    padding-top: 10px !important;
  }
  .da-pb-md-10 {
    padding-bottom: 10px !important;
  }
  .da-py-md-10 {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .da-px-md-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-10 {
    padding-right: 10px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-10 {
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-10 {
    padding-left: 10px !important;
  }
  html[dir='ltr'] .da-pr-md-10 {
    padding-right: 10px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-12 {
    padding: 12px !important;
  }
  .da-pt-md-12 {
    padding-top: 12px !important;
  }
  .da-pb-md-12 {
    padding-bottom: 12px !important;
  }
  .da-py-md-12 {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }
  .da-px-md-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-12 {
    padding-right: 12px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-12 {
    padding-left: 12px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-12 {
    padding-left: 12px !important;
  }
  html[dir='ltr'] .da-pr-md-12 {
    padding-right: 12px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-16 {
    padding: 16px !important;
  }
  .da-pt-md-16 {
    padding-top: 16px !important;
  }
  .da-pb-md-16 {
    padding-bottom: 16px !important;
  }
  .da-py-md-16 {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .da-px-md-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-16 {
    padding-right: 16px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-16 {
    padding-left: 16px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-16 {
    padding-left: 16px !important;
  }
  html[dir='ltr'] .da-pr-md-16 {
    padding-right: 16px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-18 {
    padding: 18px !important;
  }
  .da-pt-md-18 {
    padding-top: 18px !important;
  }
  .da-pb-md-18 {
    padding-bottom: 18px !important;
  }
  .da-py-md-18 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
  .da-px-md-18 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-18 {
    padding-right: 18px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-18 {
    padding-left: 18px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-18 {
    padding-left: 18px !important;
  }
  html[dir='ltr'] .da-pr-md-18 {
    padding-right: 18px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-24 {
    padding: 24px !important;
  }
  .da-pt-md-24 {
    padding-top: 24px !important;
  }
  .da-pb-md-24 {
    padding-bottom: 24px !important;
  }
  .da-py-md-24 {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .da-px-md-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-24 {
    padding-right: 24px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-24 {
    padding-left: 24px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-24 {
    padding-left: 24px !important;
  }
  html[dir='ltr'] .da-pr-md-24 {
    padding-right: 24px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-32 {
    padding: 32px !important;
  }
  .da-pt-md-32 {
    padding-top: 32px !important;
  }
  .da-pb-md-32 {
    padding-bottom: 32px !important;
  }
  .da-py-md-32 {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .da-px-md-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-32 {
    padding-right: 32px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-32 {
    padding-left: 32px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-32 {
    padding-left: 32px !important;
  }
  html[dir='ltr'] .da-pr-md-32 {
    padding-right: 32px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-36 {
    padding: 36px !important;
  }
  .da-pt-md-36 {
    padding-top: 36px !important;
  }
  .da-pb-md-36 {
    padding-bottom: 36px !important;
  }
  .da-py-md-36 {
    padding-bottom: 36px !important;
    padding-top: 36px !important;
  }
  .da-px-md-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-36 {
    padding-right: 36px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-36 {
    padding-left: 36px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-36 {
    padding-left: 36px !important;
  }
  html[dir='ltr'] .da-pr-md-36 {
    padding-right: 36px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-42 {
    padding: 42px !important;
  }
  .da-pt-md-42 {
    padding-top: 42px !important;
  }
  .da-pb-md-42 {
    padding-bottom: 42px !important;
  }
  .da-py-md-42 {
    padding-bottom: 42px !important;
    padding-top: 42px !important;
  }
  .da-px-md-42 {
    padding-right: 42px !important;
    padding-left: 42px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-42 {
    padding-right: 42px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-42 {
    padding-left: 42px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-42 {
    padding-left: 42px !important;
  }
  html[dir='ltr'] .da-pr-md-42 {
    padding-right: 42px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-48 {
    padding: 48px !important;
  }
  .da-pt-md-48 {
    padding-top: 48px !important;
  }
  .da-pb-md-48 {
    padding-bottom: 48px !important;
  }
  .da-py-md-48 {
    padding-bottom: 48px !important;
    padding-top: 48px !important;
  }
  .da-px-md-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-48 {
    padding-right: 48px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-48 {
    padding-left: 48px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-48 {
    padding-left: 48px !important;
  }
  html[dir='ltr'] .da-pr-md-48 {
    padding-right: 48px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-64 {
    padding: 64px !important;
  }
  .da-pt-md-64 {
    padding-top: 64px !important;
  }
  .da-pb-md-64 {
    padding-bottom: 64px !important;
  }
  .da-py-md-64 {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .da-px-md-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-64 {
    padding-right: 64px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-64 {
    padding-left: 64px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-64 {
    padding-left: 64px !important;
  }
  html[dir='ltr'] .da-pr-md-64 {
    padding-right: 64px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-96 {
    padding: 96px !important;
  }
  .da-pt-md-96 {
    padding-top: 96px !important;
  }
  .da-pb-md-96 {
    padding-bottom: 96px !important;
  }
  .da-py-md-96 {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }
  .da-px-md-96 {
    padding-right: 96px !important;
    padding-left: 96px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-96 {
    padding-right: 96px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-96 {
    padding-left: 96px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-96 {
    padding-left: 96px !important;
  }
  html[dir='ltr'] .da-pr-md-96 {
    padding-right: 96px !important;
  }
}
@media screen and (max-width: 991px) {
  .da-p-md-120 {
    padding: 120px !important;
  }
  .da-pt-md-120 {
    padding-top: 120px !important;
  }
  .da-pb-md-120 {
    padding-bottom: 120px !important;
  }
  .da-py-md-120 {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }
  .da-px-md-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-pl-md-120 {
    padding-right: 120px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-md-120 {
    padding-left: 120px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  html[dir='ltr'] .da-pl-md-120 {
    padding-left: 120px !important;
  }
  html[dir='ltr'] .da-pr-md-120 {
    padding-right: 120px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-0 {
    padding: 0px !important;
  }
  .da-pt-sm-0 {
    padding-top: 0px !important;
  }
  .da-pb-sm-0 {
    padding-bottom: 0px !important;
  }
  .da-py-sm-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .da-px-sm-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-0 {
    padding-right: 0px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-0 {
    padding-left: 0px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-0 {
    padding-left: 0px !important;
  }
  html[dir='ltr'] .da-pr-sm-0 {
    padding-right: 0px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-4 {
    padding: 4px !important;
  }
  .da-pt-sm-4 {
    padding-top: 4px !important;
  }
  .da-pb-sm-4 {
    padding-bottom: 4px !important;
  }
  .da-py-sm-4 {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .da-px-sm-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-4 {
    padding-right: 4px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-4 {
    padding-left: 4px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-4 {
    padding-left: 4px !important;
  }
  html[dir='ltr'] .da-pr-sm-4 {
    padding-right: 4px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-6 {
    padding: 6px !important;
  }
  .da-pt-sm-6 {
    padding-top: 6px !important;
  }
  .da-pb-sm-6 {
    padding-bottom: 6px !important;
  }
  .da-py-sm-6 {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }
  .da-px-sm-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-6 {
    padding-right: 6px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-6 {
    padding-left: 6px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-6 {
    padding-left: 6px !important;
  }
  html[dir='ltr'] .da-pr-sm-6 {
    padding-right: 6px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-8 {
    padding: 8px !important;
  }
  .da-pt-sm-8 {
    padding-top: 8px !important;
  }
  .da-pb-sm-8 {
    padding-bottom: 8px !important;
  }
  .da-py-sm-8 {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .da-px-sm-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-8 {
    padding-right: 8px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-8 {
    padding-left: 8px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-8 {
    padding-left: 8px !important;
  }
  html[dir='ltr'] .da-pr-sm-8 {
    padding-right: 8px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-10 {
    padding: 10px !important;
  }
  .da-pt-sm-10 {
    padding-top: 10px !important;
  }
  .da-pb-sm-10 {
    padding-bottom: 10px !important;
  }
  .da-py-sm-10 {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .da-px-sm-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-10 {
    padding-right: 10px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-10 {
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-10 {
    padding-left: 10px !important;
  }
  html[dir='ltr'] .da-pr-sm-10 {
    padding-right: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-12 {
    padding: 12px !important;
  }
  .da-pt-sm-12 {
    padding-top: 12px !important;
  }
  .da-pb-sm-12 {
    padding-bottom: 12px !important;
  }
  .da-py-sm-12 {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }
  .da-px-sm-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-12 {
    padding-right: 12px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-12 {
    padding-left: 12px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-12 {
    padding-left: 12px !important;
  }
  html[dir='ltr'] .da-pr-sm-12 {
    padding-right: 12px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-16 {
    padding: 16px !important;
  }
  .da-pt-sm-16 {
    padding-top: 16px !important;
  }
  .da-pb-sm-16 {
    padding-bottom: 16px !important;
  }
  .da-py-sm-16 {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .da-px-sm-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-16 {
    padding-right: 16px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-16 {
    padding-left: 16px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-16 {
    padding-left: 16px !important;
  }
  html[dir='ltr'] .da-pr-sm-16 {
    padding-right: 16px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-18 {
    padding: 18px !important;
  }
  .da-pt-sm-18 {
    padding-top: 18px !important;
  }
  .da-pb-sm-18 {
    padding-bottom: 18px !important;
  }
  .da-py-sm-18 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
  .da-px-sm-18 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-18 {
    padding-right: 18px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-18 {
    padding-left: 18px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-18 {
    padding-left: 18px !important;
  }
  html[dir='ltr'] .da-pr-sm-18 {
    padding-right: 18px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-24 {
    padding: 24px !important;
  }
  .da-pt-sm-24 {
    padding-top: 24px !important;
  }
  .da-pb-sm-24 {
    padding-bottom: 24px !important;
  }
  .da-py-sm-24 {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .da-px-sm-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-24 {
    padding-right: 24px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-24 {
    padding-left: 24px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-24 {
    padding-left: 24px !important;
  }
  html[dir='ltr'] .da-pr-sm-24 {
    padding-right: 24px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-32 {
    padding: 32px !important;
  }
  .da-pt-sm-32 {
    padding-top: 32px !important;
  }
  .da-pb-sm-32 {
    padding-bottom: 32px !important;
  }
  .da-py-sm-32 {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .da-px-sm-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-32 {
    padding-right: 32px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-32 {
    padding-left: 32px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-32 {
    padding-left: 32px !important;
  }
  html[dir='ltr'] .da-pr-sm-32 {
    padding-right: 32px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-36 {
    padding: 36px !important;
  }
  .da-pt-sm-36 {
    padding-top: 36px !important;
  }
  .da-pb-sm-36 {
    padding-bottom: 36px !important;
  }
  .da-py-sm-36 {
    padding-bottom: 36px !important;
    padding-top: 36px !important;
  }
  .da-px-sm-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-36 {
    padding-right: 36px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-36 {
    padding-left: 36px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-36 {
    padding-left: 36px !important;
  }
  html[dir='ltr'] .da-pr-sm-36 {
    padding-right: 36px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-42 {
    padding: 42px !important;
  }
  .da-pt-sm-42 {
    padding-top: 42px !important;
  }
  .da-pb-sm-42 {
    padding-bottom: 42px !important;
  }
  .da-py-sm-42 {
    padding-bottom: 42px !important;
    padding-top: 42px !important;
  }
  .da-px-sm-42 {
    padding-right: 42px !important;
    padding-left: 42px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-42 {
    padding-right: 42px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-42 {
    padding-left: 42px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-42 {
    padding-left: 42px !important;
  }
  html[dir='ltr'] .da-pr-sm-42 {
    padding-right: 42px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-48 {
    padding: 48px !important;
  }
  .da-pt-sm-48 {
    padding-top: 48px !important;
  }
  .da-pb-sm-48 {
    padding-bottom: 48px !important;
  }
  .da-py-sm-48 {
    padding-bottom: 48px !important;
    padding-top: 48px !important;
  }
  .da-px-sm-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-48 {
    padding-right: 48px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-48 {
    padding-left: 48px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-48 {
    padding-left: 48px !important;
  }
  html[dir='ltr'] .da-pr-sm-48 {
    padding-right: 48px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-64 {
    padding: 64px !important;
  }
  .da-pt-sm-64 {
    padding-top: 64px !important;
  }
  .da-pb-sm-64 {
    padding-bottom: 64px !important;
  }
  .da-py-sm-64 {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .da-px-sm-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-64 {
    padding-right: 64px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-64 {
    padding-left: 64px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-64 {
    padding-left: 64px !important;
  }
  html[dir='ltr'] .da-pr-sm-64 {
    padding-right: 64px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-96 {
    padding: 96px !important;
  }
  .da-pt-sm-96 {
    padding-top: 96px !important;
  }
  .da-pb-sm-96 {
    padding-bottom: 96px !important;
  }
  .da-py-sm-96 {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }
  .da-px-sm-96 {
    padding-right: 96px !important;
    padding-left: 96px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-96 {
    padding-right: 96px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-96 {
    padding-left: 96px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-96 {
    padding-left: 96px !important;
  }
  html[dir='ltr'] .da-pr-sm-96 {
    padding-right: 96px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-p-sm-120 {
    padding: 120px !important;
  }
  .da-pt-sm-120 {
    padding-top: 120px !important;
  }
  .da-pb-sm-120 {
    padding-bottom: 120px !important;
  }
  .da-py-sm-120 {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }
  .da-px-sm-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-pl-sm-120 {
    padding-right: 120px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-sm-120 {
    padding-left: 120px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  html[dir='ltr'] .da-pl-sm-120 {
    padding-left: 120px !important;
  }
  html[dir='ltr'] .da-pr-sm-120 {
    padding-right: 120px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-0 {
    padding: 0px !important;
  }
  .da-pt-xs-0 {
    padding-top: 0px !important;
  }
  .da-pb-xs-0 {
    padding-bottom: 0px !important;
  }
  .da-py-xs-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .da-px-xs-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-0 {
    padding-right: 0px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-0 {
    padding-left: 0px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-0 {
    padding-left: 0px !important;
  }
  html[dir='ltr'] .da-pr-xs-0 {
    padding-right: 0px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-4 {
    padding: 4px !important;
  }
  .da-pt-xs-4 {
    padding-top: 4px !important;
  }
  .da-pb-xs-4 {
    padding-bottom: 4px !important;
  }
  .da-py-xs-4 {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .da-px-xs-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-4 {
    padding-right: 4px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-4 {
    padding-left: 4px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-4 {
    padding-left: 4px !important;
  }
  html[dir='ltr'] .da-pr-xs-4 {
    padding-right: 4px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-6 {
    padding: 6px !important;
  }
  .da-pt-xs-6 {
    padding-top: 6px !important;
  }
  .da-pb-xs-6 {
    padding-bottom: 6px !important;
  }
  .da-py-xs-6 {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }
  .da-px-xs-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-6 {
    padding-right: 6px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-6 {
    padding-left: 6px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-6 {
    padding-left: 6px !important;
  }
  html[dir='ltr'] .da-pr-xs-6 {
    padding-right: 6px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-8 {
    padding: 8px !important;
  }
  .da-pt-xs-8 {
    padding-top: 8px !important;
  }
  .da-pb-xs-8 {
    padding-bottom: 8px !important;
  }
  .da-py-xs-8 {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .da-px-xs-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-8 {
    padding-right: 8px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-8 {
    padding-left: 8px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-8 {
    padding-left: 8px !important;
  }
  html[dir='ltr'] .da-pr-xs-8 {
    padding-right: 8px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-10 {
    padding: 10px !important;
  }
  .da-pt-xs-10 {
    padding-top: 10px !important;
  }
  .da-pb-xs-10 {
    padding-bottom: 10px !important;
  }
  .da-py-xs-10 {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .da-px-xs-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-10 {
    padding-right: 10px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-10 {
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-10 {
    padding-left: 10px !important;
  }
  html[dir='ltr'] .da-pr-xs-10 {
    padding-right: 10px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-12 {
    padding: 12px !important;
  }
  .da-pt-xs-12 {
    padding-top: 12px !important;
  }
  .da-pb-xs-12 {
    padding-bottom: 12px !important;
  }
  .da-py-xs-12 {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }
  .da-px-xs-12 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-12 {
    padding-right: 12px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-12 {
    padding-left: 12px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-12 {
    padding-left: 12px !important;
  }
  html[dir='ltr'] .da-pr-xs-12 {
    padding-right: 12px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-16 {
    padding: 16px !important;
  }
  .da-pt-xs-16 {
    padding-top: 16px !important;
  }
  .da-pb-xs-16 {
    padding-bottom: 16px !important;
  }
  .da-py-xs-16 {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .da-px-xs-16 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-16 {
    padding-right: 16px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-16 {
    padding-left: 16px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-16 {
    padding-left: 16px !important;
  }
  html[dir='ltr'] .da-pr-xs-16 {
    padding-right: 16px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-18 {
    padding: 18px !important;
  }
  .da-pt-xs-18 {
    padding-top: 18px !important;
  }
  .da-pb-xs-18 {
    padding-bottom: 18px !important;
  }
  .da-py-xs-18 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
  .da-px-xs-18 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-18 {
    padding-right: 18px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-18 {
    padding-left: 18px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-18 {
    padding-left: 18px !important;
  }
  html[dir='ltr'] .da-pr-xs-18 {
    padding-right: 18px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-24 {
    padding: 24px !important;
  }
  .da-pt-xs-24 {
    padding-top: 24px !important;
  }
  .da-pb-xs-24 {
    padding-bottom: 24px !important;
  }
  .da-py-xs-24 {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .da-px-xs-24 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-24 {
    padding-right: 24px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-24 {
    padding-left: 24px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-24 {
    padding-left: 24px !important;
  }
  html[dir='ltr'] .da-pr-xs-24 {
    padding-right: 24px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-32 {
    padding: 32px !important;
  }
  .da-pt-xs-32 {
    padding-top: 32px !important;
  }
  .da-pb-xs-32 {
    padding-bottom: 32px !important;
  }
  .da-py-xs-32 {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .da-px-xs-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-32 {
    padding-right: 32px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-32 {
    padding-left: 32px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-32 {
    padding-left: 32px !important;
  }
  html[dir='ltr'] .da-pr-xs-32 {
    padding-right: 32px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-36 {
    padding: 36px !important;
  }
  .da-pt-xs-36 {
    padding-top: 36px !important;
  }
  .da-pb-xs-36 {
    padding-bottom: 36px !important;
  }
  .da-py-xs-36 {
    padding-bottom: 36px !important;
    padding-top: 36px !important;
  }
  .da-px-xs-36 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-36 {
    padding-right: 36px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-36 {
    padding-left: 36px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-36 {
    padding-left: 36px !important;
  }
  html[dir='ltr'] .da-pr-xs-36 {
    padding-right: 36px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-42 {
    padding: 42px !important;
  }
  .da-pt-xs-42 {
    padding-top: 42px !important;
  }
  .da-pb-xs-42 {
    padding-bottom: 42px !important;
  }
  .da-py-xs-42 {
    padding-bottom: 42px !important;
    padding-top: 42px !important;
  }
  .da-px-xs-42 {
    padding-right: 42px !important;
    padding-left: 42px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-42 {
    padding-right: 42px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-42 {
    padding-left: 42px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-42 {
    padding-left: 42px !important;
  }
  html[dir='ltr'] .da-pr-xs-42 {
    padding-right: 42px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-48 {
    padding: 48px !important;
  }
  .da-pt-xs-48 {
    padding-top: 48px !important;
  }
  .da-pb-xs-48 {
    padding-bottom: 48px !important;
  }
  .da-py-xs-48 {
    padding-bottom: 48px !important;
    padding-top: 48px !important;
  }
  .da-px-xs-48 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-48 {
    padding-right: 48px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-48 {
    padding-left: 48px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-48 {
    padding-left: 48px !important;
  }
  html[dir='ltr'] .da-pr-xs-48 {
    padding-right: 48px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-64 {
    padding: 64px !important;
  }
  .da-pt-xs-64 {
    padding-top: 64px !important;
  }
  .da-pb-xs-64 {
    padding-bottom: 64px !important;
  }
  .da-py-xs-64 {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .da-px-xs-64 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-64 {
    padding-right: 64px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-64 {
    padding-left: 64px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-64 {
    padding-left: 64px !important;
  }
  html[dir='ltr'] .da-pr-xs-64 {
    padding-right: 64px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-96 {
    padding: 96px !important;
  }
  .da-pt-xs-96 {
    padding-top: 96px !important;
  }
  .da-pb-xs-96 {
    padding-bottom: 96px !important;
  }
  .da-py-xs-96 {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }
  .da-px-xs-96 {
    padding-right: 96px !important;
    padding-left: 96px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-96 {
    padding-right: 96px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-96 {
    padding-left: 96px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-96 {
    padding-left: 96px !important;
  }
  html[dir='ltr'] .da-pr-xs-96 {
    padding-right: 96px !important;
  }
}
@media screen and (max-width: 575px) {
  .da-p-xs-120 {
    padding: 120px !important;
  }
  .da-pt-xs-120 {
    padding-top: 120px !important;
  }
  .da-pb-xs-120 {
    padding-bottom: 120px !important;
  }
  .da-py-xs-120 {
    padding-bottom: 120px !important;
    padding-top: 120px !important;
  }
  .da-px-xs-120 {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='rtl'] .da-pl-xs-120 {
    padding-right: 120px !important;
    padding-left: 0 !important;
  }
  html[dir='rtl'] .da-pr-xs-120 {
    padding-left: 120px !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  html[dir='ltr'] .da-pl-xs-120 {
    padding-left: 120px !important;
  }
  html[dir='ltr'] .da-pr-xs-120 {
    padding-right: 120px !important;
  }
}
.da-hover-text-color-primary-1:hover {
  color: #f27052 !important;
}
.da-hover-text-color-primary-2:hover {
  color: #ee502c !important;
}
.da-hover-text-color-primary-3:hover {
  color: #ecaa9b !important;
}
.da-hover-text-color-primary-4:hover {
  color: #f0e3e0 !important;
}
.da-hover-text-color-secondary-1:hover {
  color: #c903ff !important;
}
.da-hover-text-color-secondary-2:hover {
  color: #e26bf5 !important;
}
.da-hover-text-color-secondary-3:hover {
  color: #f7c2ff !important;
}
.da-hover-text-color-secondary-4:hover {
  color: #fdeffc !important;
}
.da-hover-text-color-warning-1:hover {
  color: #ffc212 !important;
}
.da-hover-text-color-warning-2:hover {
  color: #ffd252 !important;
}
.da-hover-text-color-warning-3:hover {
  color: #ffe393 !important;
}
.da-hover-text-color-warning-4:hover {
  color: #fff9e9 !important;
}
.da-hover-text-color-success-1:hover {
  color: #00f7bf !important;
}
.da-hover-text-color-success-2:hover {
  color: #5bffce !important;
}
.da-hover-text-color-success-3:hover {
  color: #98ffe0 !important;
}
.da-hover-text-color-success-4:hover {
  color: #eafff8 !important;
}
.da-hover-text-color-danger-1:hover {
  color: #ff0022 !important;
}
.da-hover-text-color-danger-2:hover {
  color: #ff455e !important;
}
.da-hover-text-color-danger-3:hover {
  color: #ff8b9a !important;
}
.da-hover-text-color-danger-4:hover {
  color: #ffe7ea !important;
}
.da-hover-text-color-info-1:hover {
  color: #1be7ff !important;
}
.da-hover-text-color-info-2:hover {
  color: #59edff !important;
}
.da-hover-text-color-info-3:hover {
  color: #97f4ff !important;
}
.da-hover-text-color-info-4:hover {
  color: #eafcff !important;
}
.da-hover-text-color-black-0:hover {
  color: #ffffff !important;
}
.da-hover-text-color-black-10:hover {
  color: #f7fafc !important;
}
.da-hover-text-color-black-20:hover {
  color: #f0f3f5 !important;
}
.da-hover-text-color-black-40:hover {
  color: #dfe6e9 !important;
}
.da-hover-text-color-black-60:hover {
  color: #b2bec3 !important;
}
.da-hover-text-color-black-80:hover {
  color: #636e72 !important;
}
.da-hover-text-color-black-100:hover {
  color: #2d3436 !important;
}
.da-hover-text-color-black-bg:hover {
  color: #111314 !important;
}
.da-hover-text-color-primary-gradient:hover {
  color: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-hover-text-color-secondary-gradient:hover {
  color: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-hover-text-color-warning-gradient:hover {
  color: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-hover-text-color-success-gradient:hover {
  color: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-hover-text-color-danger-gradient:hover {
  color: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-hover-text-color-info-gradient:hover {
  color: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-text-color-primary-1 {
  color: #f27052 !important;
}
.da-text-color-primary-2 {
  color: #ee502c !important;
}
.da-text-color-primary-3 {
  color: #ecaa9b !important;
}
.da-text-color-primary-4 {
  color: #f0e3e0 !important;
}
.da-text-color-secondary-1 {
  color: #c903ff !important;
}
.da-text-color-secondary-2 {
  color: #e26bf5 !important;
}
.da-text-color-secondary-3 {
  color: #f7c2ff !important;
}
.da-text-color-secondary-4 {
  color: #fdeffc !important;
}
.da-text-color-warning-1 {
  color: #ffc212 !important;
}
.da-text-color-warning-2 {
  color: #ffd252 !important;
}
.da-text-color-warning-3 {
  color: #ffe393 !important;
}
.da-text-color-warning-4 {
  color: #fff9e9 !important;
}
.da-text-color-success-1 {
  color: #00f7bf !important;
}
.da-text-color-success-2 {
  color: #5bffce !important;
}
.da-text-color-success-3 {
  color: #98ffe0 !important;
}
.da-text-color-success-4 {
  color: #eafff8 !important;
}
.da-text-color-danger-1 {
  color: #ff0022 !important;
}
.da-text-color-danger-2 {
  color: #ff455e !important;
}
.da-text-color-danger-3 {
  color: #ff8b9a !important;
}
.da-text-color-danger-4 {
  color: #ffe7ea !important;
}
.da-text-color-info-1 {
  color: #1be7ff !important;
}
.da-text-color-info-2 {
  color: #59edff !important;
}
.da-text-color-info-3 {
  color: #97f4ff !important;
}
.da-text-color-info-4 {
  color: #eafcff !important;
}
.da-text-color-black-0 {
  color: #ffffff !important;
}
.da-text-color-black-10 {
  color: #f7fafc !important;
}
.da-text-color-black-20 {
  color: #f0f3f5 !important;
}
.da-text-color-black-40 {
  color: #dfe6e9 !important;
}
.da-text-color-black-60 {
  color: #b2bec3 !important;
}
.da-text-color-black-80 {
  color: #636e72 !important;
}
.da-text-color-black-100 {
  color: #2d3436 !important;
}
.da-text-color-black-bg {
  color: #111314 !important;
}
.da-text-color-primary-gradient {
  color: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-text-color-secondary-gradient {
  color: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-text-color-warning-gradient {
  color: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-text-color-success-gradient {
  color: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-text-color-danger-gradient {
  color: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-text-color-info-gradient {
  color: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-hover-bg-color-primary-1:hover {
  background-color: #f27052 !important;
}
.da-hover-bg-color-primary-2:hover {
  background-color: #ee502c !important;
}
.da-hover-bg-color-primary-3:hover {
  background-color: #ecaa9b !important;
}
.da-hover-bg-color-primary-4:hover {
  background-color: #f0e3e0 !important;
}
.da-hover-bg-color-secondary-1:hover {
  background-color: #c903ff !important;
}
.da-hover-bg-color-secondary-2:hover {
  background-color: #e26bf5 !important;
}
.da-hover-bg-color-secondary-3:hover {
  background-color: #f7c2ff !important;
}
.da-hover-bg-color-secondary-4:hover {
  background-color: #fdeffc !important;
}
.da-hover-bg-color-warning-1:hover {
  background-color: #ffc212 !important;
}
.da-hover-bg-color-warning-2:hover {
  background-color: #ffd252 !important;
}
.da-hover-bg-color-warning-3:hover {
  background-color: #ffe393 !important;
}
.da-hover-bg-color-warning-4:hover {
  background-color: #fff9e9 !important;
}
.da-hover-bg-color-success-1:hover {
  background-color: #00f7bf !important;
}
.da-hover-bg-color-success-2:hover {
  background-color: #5bffce !important;
}
.da-hover-bg-color-success-3:hover {
  background-color: #98ffe0 !important;
}
.da-hover-bg-color-success-4:hover {
  background-color: #eafff8 !important;
}
.da-hover-bg-color-danger-1:hover {
  background-color: #ff0022 !important;
}
.da-hover-bg-color-danger-2:hover {
  background-color: #ff455e !important;
}
.da-hover-bg-color-danger-3:hover {
  background-color: #ff8b9a !important;
}
.da-hover-bg-color-danger-4:hover {
  background-color: #ffe7ea !important;
}
.da-hover-bg-color-info-1:hover {
  background-color: #1be7ff !important;
}
.da-hover-bg-color-info-2:hover {
  background-color: #59edff !important;
}
.da-hover-bg-color-info-3:hover {
  background-color: #97f4ff !important;
}
.da-hover-bg-color-info-4:hover {
  background-color: #eafcff !important;
}
.da-hover-bg-color-black-0:hover {
  background-color: #ffffff !important;
}
.da-hover-bg-color-black-10:hover {
  background-color: #f7fafc !important;
}
.da-hover-bg-color-black-20:hover {
  background-color: #f0f3f5 !important;
}
.da-hover-bg-color-black-40:hover {
  background-color: #dfe6e9 !important;
}
.da-hover-bg-color-black-60:hover {
  background-color: #b2bec3 !important;
}
.da-hover-bg-color-black-80:hover {
  background-color: #636e72 !important;
}
.da-hover-bg-color-black-100:hover {
  background-color: #2d3436 !important;
}
.da-hover-bg-color-black-bg:hover {
  background-color: #111314 !important;
}
.da-hover-bg-color-primary-gradient:hover {
  background-color: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-hover-bg-color-secondary-gradient:hover {
  background-color: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-hover-bg-color-warning-gradient:hover {
  background-color: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-hover-bg-color-success-gradient:hover {
  background-color: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-hover-bg-color-danger-gradient:hover {
  background-color: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-hover-bg-color-info-gradient:hover {
  background-color: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-bg-color-primary-1 {
  background-color: #f27052 !important;
}
.da-bg-color-primary-2 {
  background-color: #ee502c !important;
}
.da-bg-color-primary-3 {
  background-color: #ecaa9b !important;
}
.da-bg-color-primary-4 {
  background-color: #f0e3e0 !important;
}
.da-bg-color-secondary-1 {
  background-color: #c903ff !important;
}
.da-bg-color-secondary-2 {
  background-color: #e26bf5 !important;
}
.da-bg-color-secondary-3 {
  background-color: #f7c2ff !important;
}
.da-bg-color-secondary-4 {
  background-color: #fdeffc !important;
}
.da-bg-color-warning-1 {
  background-color: #ffc212 !important;
}
.da-bg-color-warning-2 {
  background-color: #ffd252 !important;
}
.da-bg-color-warning-3 {
  background-color: #ffe393 !important;
}
.da-bg-color-warning-4 {
  background-color: #fff9e9 !important;
}
.da-bg-color-success-1 {
  background-color: #00f7bf !important;
}
.da-bg-color-success-2 {
  background-color: #5bffce !important;
}
.da-bg-color-success-3 {
  background-color: #98ffe0 !important;
}
.da-bg-color-success-4 {
  background-color: #eafff8 !important;
}
.da-bg-color-danger-1 {
  background-color: #ff0022 !important;
}
.da-bg-color-danger-2 {
  background-color: #ff455e !important;
}
.da-bg-color-danger-3 {
  background-color: #ff8b9a !important;
}
.da-bg-color-danger-4 {
  background-color: #ffe7ea !important;
}
.da-bg-color-info-1 {
  background-color: #1be7ff !important;
}
.da-bg-color-info-2 {
  background-color: #59edff !important;
}
.da-bg-color-info-3 {
  background-color: #97f4ff !important;
}
.da-bg-color-info-4 {
  background-color: #eafcff !important;
}
.da-bg-color-black-0 {
  background-color: #ffffff !important;
}
.da-bg-color-black-10 {
  background-color: #f7fafc !important;
}
.da-bg-color-black-20 {
  background-color: #f0f3f5 !important;
}
.da-bg-color-black-40 {
  background-color: #dfe6e9 !important;
}
.da-bg-color-black-60 {
  background-color: #b2bec3 !important;
}
.da-bg-color-black-80 {
  background-color: #636e72 !important;
}
.da-bg-color-black-100 {
  background-color: #2d3436 !important;
}
.da-bg-color-black-bg {
  background-color: #111314 !important;
}
.da-bg-color-primary-gradient {
  background-color: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-bg-color-secondary-gradient {
  background-color: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-bg-color-warning-gradient {
  background-color: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-bg-color-success-gradient {
  background-color: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-bg-color-danger-gradient {
  background-color: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-bg-color-info-gradient {
  background-color: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-hover-bg-primary-1:hover {
  background: #f27052 !important;
}
.da-hover-bg-primary-2:hover {
  background: #ee502c !important;
}
.da-hover-bg-primary-3:hover {
  background: #ecaa9b !important;
}
.da-hover-bg-primary-4:hover {
  background: #f0e3e0 !important;
}
.da-hover-bg-secondary-1:hover {
  background: #c903ff !important;
}
.da-hover-bg-secondary-2:hover {
  background: #e26bf5 !important;
}
.da-hover-bg-secondary-3:hover {
  background: #f7c2ff !important;
}
.da-hover-bg-secondary-4:hover {
  background: #fdeffc !important;
}
.da-hover-bg-warning-1:hover {
  background: #ffc212 !important;
}
.da-hover-bg-warning-2:hover {
  background: #ffd252 !important;
}
.da-hover-bg-warning-3:hover {
  background: #ffe393 !important;
}
.da-hover-bg-warning-4:hover {
  background: #fff9e9 !important;
}
.da-hover-bg-success-1:hover {
  background: #00f7bf !important;
}
.da-hover-bg-success-2:hover {
  background: #5bffce !important;
}
.da-hover-bg-success-3:hover {
  background: #98ffe0 !important;
}
.da-hover-bg-success-4:hover {
  background: #eafff8 !important;
}
.da-hover-bg-danger-1:hover {
  background: #ff0022 !important;
}
.da-hover-bg-danger-2:hover {
  background: #ff455e !important;
}
.da-hover-bg-danger-3:hover {
  background: #ff8b9a !important;
}
.da-hover-bg-danger-4:hover {
  background: #ffe7ea !important;
}
.da-hover-bg-info-1:hover {
  background: #1be7ff !important;
}
.da-hover-bg-info-2:hover {
  background: #59edff !important;
}
.da-hover-bg-info-3:hover {
  background: #97f4ff !important;
}
.da-hover-bg-info-4:hover {
  background: #eafcff !important;
}
.da-hover-bg-black-0:hover {
  background: #ffffff !important;
}
.da-hover-bg-black-10:hover {
  background: #f7fafc !important;
}
.da-hover-bg-black-20:hover {
  background: #f0f3f5 !important;
}
.da-hover-bg-black-40:hover {
  background: #dfe6e9 !important;
}
.da-hover-bg-black-60:hover {
  background: #b2bec3 !important;
}
.da-hover-bg-black-80:hover {
  background: #636e72 !important;
}
.da-hover-bg-black-100:hover {
  background: #2d3436 !important;
}
.da-hover-bg-black-bg:hover {
  background: #111314 !important;
}
.da-hover-bg-primary-gradient:hover {
  background: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-hover-bg-secondary-gradient:hover {
  background: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-hover-bg-warning-gradient:hover {
  background: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-hover-bg-success-gradient:hover {
  background: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-hover-bg-danger-gradient:hover {
  background: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-hover-bg-info-gradient:hover {
  background: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-bg-primary-1 {
  background: #f27052 !important;
}
.da-bg-primary-2 {
  background: #ee502c !important;
}
.da-bg-primary-3 {
  background: #ecaa9b !important;
}
.da-bg-primary-4 {
  background: #f0e3e0 !important;
}
.da-bg-secondary-1 {
  background: #c903ff !important;
}
.da-bg-secondary-2 {
  background: #e26bf5 !important;
}
.da-bg-secondary-3 {
  background: #f7c2ff !important;
}
.da-bg-secondary-4 {
  background: #fdeffc !important;
}
.da-bg-warning-1 {
  background: #ffc212 !important;
}
.da-bg-warning-2 {
  background: #ffd252 !important;
}
.da-bg-warning-3 {
  background: #ffe393 !important;
}
.da-bg-warning-4 {
  background: #fff9e9 !important;
}
.da-bg-success-1 {
  background: #00f7bf !important;
}
.da-bg-success-2 {
  background: #5bffce !important;
}
.da-bg-success-3 {
  background: #98ffe0 !important;
}
.da-bg-success-4 {
  background: #eafff8 !important;
}
.da-bg-danger-1 {
  background: #ff0022 !important;
}
.da-bg-danger-2 {
  background: #ff455e !important;
}
.da-bg-danger-3 {
  background: #ff8b9a !important;
}
.da-bg-danger-4 {
  background: #ffe7ea !important;
}
.da-bg-info-1 {
  background: #1be7ff !important;
}
.da-bg-info-2 {
  background: #59edff !important;
}
.da-bg-info-3 {
  background: #97f4ff !important;
}
.da-bg-info-4 {
  background: #eafcff !important;
}
.da-bg-black-0 {
  background: #ffffff !important;
}
.da-bg-black-10 {
  background: #f7fafc !important;
}
.da-bg-black-20 {
  background: #f0f3f5 !important;
}
.da-bg-black-40 {
  background: #dfe6e9 !important;
}
.da-bg-black-60 {
  background: #b2bec3 !important;
}
.da-bg-black-80 {
  background: #636e72 !important;
}
.da-bg-black-100 {
  background: #2d3436 !important;
}
.da-bg-black-bg {
  background: #111314 !important;
}
.da-bg-primary-gradient {
  background: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-bg-secondary-gradient {
  background: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-bg-warning-gradient {
  background: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-bg-success-gradient {
  background: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-bg-danger-gradient {
  background: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-bg-info-gradient {
  background: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-hover-border-color-primary-1:hover {
  border-color: #f27052 !important;
}
.da-hover-border-color-primary-2:hover {
  border-color: #ee502c !important;
}
.da-hover-border-color-primary-3:hover {
  border-color: #ecaa9b !important;
}
.da-hover-border-color-primary-4:hover {
  border-color: #f0e3e0 !important;
}
.da-hover-border-color-secondary-1:hover {
  border-color: #c903ff !important;
}
.da-hover-border-color-secondary-2:hover {
  border-color: #e26bf5 !important;
}
.da-hover-border-color-secondary-3:hover {
  border-color: #f7c2ff !important;
}
.da-hover-border-color-secondary-4:hover {
  border-color: #fdeffc !important;
}
.da-hover-border-color-warning-1:hover {
  border-color: #ffc212 !important;
}
.da-hover-border-color-warning-2:hover {
  border-color: #ffd252 !important;
}
.da-hover-border-color-warning-3:hover {
  border-color: #ffe393 !important;
}
.da-hover-border-color-warning-4:hover {
  border-color: #fff9e9 !important;
}
.da-hover-border-color-success-1:hover {
  border-color: #00f7bf !important;
}
.da-hover-border-color-success-2:hover {
  border-color: #5bffce !important;
}
.da-hover-border-color-success-3:hover {
  border-color: #98ffe0 !important;
}
.da-hover-border-color-success-4:hover {
  border-color: #eafff8 !important;
}
.da-hover-border-color-danger-1:hover {
  border-color: #ff0022 !important;
}
.da-hover-border-color-danger-2:hover {
  border-color: #ff455e !important;
}
.da-hover-border-color-danger-3:hover {
  border-color: #ff8b9a !important;
}
.da-hover-border-color-danger-4:hover {
  border-color: #ffe7ea !important;
}
.da-hover-border-color-info-1:hover {
  border-color: #1be7ff !important;
}
.da-hover-border-color-info-2:hover {
  border-color: #59edff !important;
}
.da-hover-border-color-info-3:hover {
  border-color: #97f4ff !important;
}
.da-hover-border-color-info-4:hover {
  border-color: #eafcff !important;
}
.da-hover-border-color-black-0:hover {
  border-color: #ffffff !important;
}
.da-hover-border-color-black-10:hover {
  border-color: #f7fafc !important;
}
.da-hover-border-color-black-20:hover {
  border-color: #f0f3f5 !important;
}
.da-hover-border-color-black-40:hover {
  border-color: #dfe6e9 !important;
}
.da-hover-border-color-black-60:hover {
  border-color: #b2bec3 !important;
}
.da-hover-border-color-black-80:hover {
  border-color: #636e72 !important;
}
.da-hover-border-color-black-100:hover {
  border-color: #2d3436 !important;
}
.da-hover-border-color-black-bg:hover {
  border-color: #111314 !important;
}
.da-hover-border-color-primary-gradient:hover {
  border-color: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-hover-border-color-secondary-gradient:hover {
  border-color: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-hover-border-color-warning-gradient:hover {
  border-color: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-hover-border-color-success-gradient:hover {
  border-color: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-hover-border-color-danger-gradient:hover {
  border-color: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-hover-border-color-info-gradient:hover {
  border-color: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-border-color-primary-1 {
  border-color: #f27052 !important;
}
.da-border-color-primary-2 {
  border-color: #ee502c !important;
}
.da-border-color-primary-3 {
  border-color: #ecaa9b !important;
}
.da-border-color-primary-4 {
  border-color: #f0e3e0 !important;
}
.da-border-color-secondary-1 {
  border-color: #c903ff !important;
}
.da-border-color-secondary-2 {
  border-color: #e26bf5 !important;
}
.da-border-color-secondary-3 {
  border-color: #f7c2ff !important;
}
.da-border-color-secondary-4 {
  border-color: #fdeffc !important;
}
.da-border-color-warning-1 {
  border-color: #ffc212 !important;
}
.da-border-color-warning-2 {
  border-color: #ffd252 !important;
}
.da-border-color-warning-3 {
  border-color: #ffe393 !important;
}
.da-border-color-warning-4 {
  border-color: #fff9e9 !important;
}
.da-border-color-success-1 {
  border-color: #00f7bf !important;
}
.da-border-color-success-2 {
  border-color: #5bffce !important;
}
.da-border-color-success-3 {
  border-color: #98ffe0 !important;
}
.da-border-color-success-4 {
  border-color: #eafff8 !important;
}
.da-border-color-danger-1 {
  border-color: #ff0022 !important;
}
.da-border-color-danger-2 {
  border-color: #ff455e !important;
}
.da-border-color-danger-3 {
  border-color: #ff8b9a !important;
}
.da-border-color-danger-4 {
  border-color: #ffe7ea !important;
}
.da-border-color-info-1 {
  border-color: #1be7ff !important;
}
.da-border-color-info-2 {
  border-color: #59edff !important;
}
.da-border-color-info-3 {
  border-color: #97f4ff !important;
}
.da-border-color-info-4 {
  border-color: #eafcff !important;
}
.da-border-color-black-0 {
  border-color: #ffffff !important;
}
.da-border-color-black-10 {
  border-color: #f7fafc !important;
}
.da-border-color-black-20 {
  border-color: #f0f3f5 !important;
}
.da-border-color-black-40 {
  border-color: #dfe6e9 !important;
}
.da-border-color-black-60 {
  border-color: #b2bec3 !important;
}
.da-border-color-black-80 {
  border-color: #636e72 !important;
}
.da-border-color-black-100 {
  border-color: #2d3436 !important;
}
.da-border-color-black-bg {
  border-color: #111314 !important;
}
.da-border-color-primary-gradient {
  border-color: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-border-color-secondary-gradient {
  border-color: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-border-color-warning-gradient {
  border-color: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-border-color-success-gradient {
  border-color: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-border-color-danger-gradient {
  border-color: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-border-color-info-gradient {
  border-color: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-fill-primary-1 path {
  fill: #f27052 !important;
}
.da-fill-primary-2 path {
  fill: #ee502c !important;
}
.da-fill-primary-3 path {
  fill: #ecaa9b !important;
}
.da-fill-primary-4 path {
  fill: #f0e3e0 !important;
}
.da-fill-secondary-1 path {
  fill: #c903ff !important;
}
.da-fill-secondary-2 path {
  fill: #e26bf5 !important;
}
.da-fill-secondary-3 path {
  fill: #f7c2ff !important;
}
.da-fill-secondary-4 path {
  fill: #fdeffc !important;
}
.da-fill-warning-1 path {
  fill: #ffc212 !important;
}
.da-fill-warning-2 path {
  fill: #ffd252 !important;
}
.da-fill-warning-3 path {
  fill: #ffe393 !important;
}
.da-fill-warning-4 path {
  fill: #fff9e9 !important;
}
.da-fill-success-1 path {
  fill: #00f7bf !important;
}
.da-fill-success-2 path {
  fill: #5bffce !important;
}
.da-fill-success-3 path {
  fill: #98ffe0 !important;
}
.da-fill-success-4 path {
  fill: #eafff8 !important;
}
.da-fill-danger-1 path {
  fill: #ff0022 !important;
}
.da-fill-danger-2 path {
  fill: #ff455e !important;
}
.da-fill-danger-3 path {
  fill: #ff8b9a !important;
}
.da-fill-danger-4 path {
  fill: #ffe7ea !important;
}
.da-fill-info-1 path {
  fill: #1be7ff !important;
}
.da-fill-info-2 path {
  fill: #59edff !important;
}
.da-fill-info-3 path {
  fill: #97f4ff !important;
}
.da-fill-info-4 path {
  fill: #eafcff !important;
}
.da-fill-black-0 path {
  fill: #ffffff !important;
}
.da-fill-black-10 path {
  fill: #f7fafc !important;
}
.da-fill-black-20 path {
  fill: #f0f3f5 !important;
}
.da-fill-black-40 path {
  fill: #dfe6e9 !important;
}
.da-fill-black-60 path {
  fill: #b2bec3 !important;
}
.da-fill-black-80 path {
  fill: #636e72 !important;
}
.da-fill-black-100 path {
  fill: #2d3436 !important;
}
.da-fill-black-bg path {
  fill: #111314 !important;
}
.da-fill-primary-gradient path {
  fill: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-fill-secondary-gradient path {
  fill: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-fill-warning-gradient path {
  fill: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-fill-success-gradient path {
  fill: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-fill-danger-gradient path {
  fill: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-fill-info-gradient path {
  fill: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-hover-fill-primary-1:hover path {
  fill: #f27052 !important;
}
.da-hover-fill-primary-2:hover path {
  fill: #ee502c !important;
}
.da-hover-fill-primary-3:hover path {
  fill: #ecaa9b !important;
}
.da-hover-fill-primary-4:hover path {
  fill: #f0e3e0 !important;
}
.da-hover-fill-secondary-1:hover path {
  fill: #c903ff !important;
}
.da-hover-fill-secondary-2:hover path {
  fill: #e26bf5 !important;
}
.da-hover-fill-secondary-3:hover path {
  fill: #f7c2ff !important;
}
.da-hover-fill-secondary-4:hover path {
  fill: #fdeffc !important;
}
.da-hover-fill-warning-1:hover path {
  fill: #ffc212 !important;
}
.da-hover-fill-warning-2:hover path {
  fill: #ffd252 !important;
}
.da-hover-fill-warning-3:hover path {
  fill: #ffe393 !important;
}
.da-hover-fill-warning-4:hover path {
  fill: #fff9e9 !important;
}
.da-hover-fill-success-1:hover path {
  fill: #00f7bf !important;
}
.da-hover-fill-success-2:hover path {
  fill: #5bffce !important;
}
.da-hover-fill-success-3:hover path {
  fill: #98ffe0 !important;
}
.da-hover-fill-success-4:hover path {
  fill: #eafff8 !important;
}
.da-hover-fill-danger-1:hover path {
  fill: #ff0022 !important;
}
.da-hover-fill-danger-2:hover path {
  fill: #ff455e !important;
}
.da-hover-fill-danger-3:hover path {
  fill: #ff8b9a !important;
}
.da-hover-fill-danger-4:hover path {
  fill: #ffe7ea !important;
}
.da-hover-fill-info-1:hover path {
  fill: #1be7ff !important;
}
.da-hover-fill-info-2:hover path {
  fill: #59edff !important;
}
.da-hover-fill-info-3:hover path {
  fill: #97f4ff !important;
}
.da-hover-fill-info-4:hover path {
  fill: #eafcff !important;
}
.da-hover-fill-black-0:hover path {
  fill: #ffffff !important;
}
.da-hover-fill-black-10:hover path {
  fill: #f7fafc !important;
}
.da-hover-fill-black-20:hover path {
  fill: #f0f3f5 !important;
}
.da-hover-fill-black-40:hover path {
  fill: #dfe6e9 !important;
}
.da-hover-fill-black-60:hover path {
  fill: #b2bec3 !important;
}
.da-hover-fill-black-80:hover path {
  fill: #636e72 !important;
}
.da-hover-fill-black-100:hover path {
  fill: #2d3436 !important;
}
.da-hover-fill-black-bg:hover path {
  fill: #111314 !important;
}
.da-hover-fill-primary-gradient:hover path {
  fill: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-hover-fill-secondary-gradient:hover path {
  fill: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-hover-fill-warning-gradient:hover path {
  fill: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-hover-fill-success-gradient:hover path {
  fill: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-hover-fill-danger-gradient:hover path {
  fill: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-hover-fill-info-gradient:hover path {
  fill: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-stroke-primary-1 path {
  stroke: #f27052 !important;
}
.da-stroke-primary-2 path {
  stroke: #ee502c !important;
}
.da-stroke-primary-3 path {
  stroke: #ecaa9b !important;
}
.da-stroke-primary-4 path {
  stroke: #f0e3e0 !important;
}
.da-stroke-secondary-1 path {
  stroke: #c903ff !important;
}
.da-stroke-secondary-2 path {
  stroke: #e26bf5 !important;
}
.da-stroke-secondary-3 path {
  stroke: #f7c2ff !important;
}
.da-stroke-secondary-4 path {
  stroke: #fdeffc !important;
}
.da-stroke-warning-1 path {
  stroke: #ffc212 !important;
}
.da-stroke-warning-2 path {
  stroke: #ffd252 !important;
}
.da-stroke-warning-3 path {
  stroke: #ffe393 !important;
}
.da-stroke-warning-4 path {
  stroke: #fff9e9 !important;
}
.da-stroke-success-1 path {
  stroke: #00f7bf !important;
}
.da-stroke-success-2 path {
  stroke: #5bffce !important;
}
.da-stroke-success-3 path {
  stroke: #98ffe0 !important;
}
.da-stroke-success-4 path {
  stroke: #eafff8 !important;
}
.da-stroke-danger-1 path {
  stroke: #ff0022 !important;
}
.da-stroke-danger-2 path {
  stroke: #ff455e !important;
}
.da-stroke-danger-3 path {
  stroke: #ff8b9a !important;
}
.da-stroke-danger-4 path {
  stroke: #ffe7ea !important;
}
.da-stroke-info-1 path {
  stroke: #1be7ff !important;
}
.da-stroke-info-2 path {
  stroke: #59edff !important;
}
.da-stroke-info-3 path {
  stroke: #97f4ff !important;
}
.da-stroke-info-4 path {
  stroke: #eafcff !important;
}
.da-stroke-black-0 path {
  stroke: #ffffff !important;
}
.da-stroke-black-10 path {
  stroke: #f7fafc !important;
}
.da-stroke-black-20 path {
  stroke: #f0f3f5 !important;
}
.da-stroke-black-40 path {
  stroke: #dfe6e9 !important;
}
.da-stroke-black-60 path {
  stroke: #b2bec3 !important;
}
.da-stroke-black-80 path {
  stroke: #636e72 !important;
}
.da-stroke-black-100 path {
  stroke: #2d3436 !important;
}
.da-stroke-black-bg path {
  stroke: #111314 !important;
}
.da-stroke-primary-gradient path {
  stroke: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-stroke-secondary-gradient path {
  stroke: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-stroke-warning-gradient path {
  stroke: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-stroke-success-gradient path {
  stroke: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-stroke-danger-gradient path {
  stroke: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-stroke-info-gradient path {
  stroke: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
.da-hover-stroke-primary-1:hover path {
  stroke: #f27052 !important;
}
.da-hover-stroke-primary-2:hover path {
  stroke: #ee502c !important;
}
.da-hover-stroke-primary-3:hover path {
  stroke: #ecaa9b !important;
}
.da-hover-stroke-primary-4:hover path {
  stroke: #f0e3e0 !important;
}
.da-hover-stroke-secondary-1:hover path {
  stroke: #c903ff !important;
}
.da-hover-stroke-secondary-2:hover path {
  stroke: #e26bf5 !important;
}
.da-hover-stroke-secondary-3:hover path {
  stroke: #f7c2ff !important;
}
.da-hover-stroke-secondary-4:hover path {
  stroke: #fdeffc !important;
}
.da-hover-stroke-warning-1:hover path {
  stroke: #ffc212 !important;
}
.da-hover-stroke-warning-2:hover path {
  stroke: #ffd252 !important;
}
.da-hover-stroke-warning-3:hover path {
  stroke: #ffe393 !important;
}
.da-hover-stroke-warning-4:hover path {
  stroke: #fff9e9 !important;
}
.da-hover-stroke-success-1:hover path {
  stroke: #00f7bf !important;
}
.da-hover-stroke-success-2:hover path {
  stroke: #5bffce !important;
}
.da-hover-stroke-success-3:hover path {
  stroke: #98ffe0 !important;
}
.da-hover-stroke-success-4:hover path {
  stroke: #eafff8 !important;
}
.da-hover-stroke-danger-1:hover path {
  stroke: #ff0022 !important;
}
.da-hover-stroke-danger-2:hover path {
  stroke: #ff455e !important;
}
.da-hover-stroke-danger-3:hover path {
  stroke: #ff8b9a !important;
}
.da-hover-stroke-danger-4:hover path {
  stroke: #ffe7ea !important;
}
.da-hover-stroke-info-1:hover path {
  stroke: #1be7ff !important;
}
.da-hover-stroke-info-2:hover path {
  stroke: #59edff !important;
}
.da-hover-stroke-info-3:hover path {
  stroke: #97f4ff !important;
}
.da-hover-stroke-info-4:hover path {
  stroke: #eafcff !important;
}
.da-hover-stroke-black-0:hover path {
  stroke: #ffffff !important;
}
.da-hover-stroke-black-10:hover path {
  stroke: #f7fafc !important;
}
.da-hover-stroke-black-20:hover path {
  stroke: #f0f3f5 !important;
}
.da-hover-stroke-black-40:hover path {
  stroke: #dfe6e9 !important;
}
.da-hover-stroke-black-60:hover path {
  stroke: #b2bec3 !important;
}
.da-hover-stroke-black-80:hover path {
  stroke: #636e72 !important;
}
.da-hover-stroke-black-100:hover path {
  stroke: #2d3436 !important;
}
.da-hover-stroke-black-bg:hover path {
  stroke: #111314 !important;
}
.da-hover-stroke-primary-gradient:hover path {
  stroke: linear-gradient(90deg, #ee502c 0%, #ecaa9b 100%) !important;
}
.da-hover-stroke-secondary-gradient:hover path {
  stroke: linear-gradient(90deg, #a210ba 0%, #ff00c7 100%) !important;
}
.da-hover-stroke-warning-gradient:hover path {
  stroke: linear-gradient(90deg, #ffc212 0%, #faff00 100%) !important;
}
.da-hover-stroke-success-gradient:hover path {
  stroke: linear-gradient(90deg, #00f7bf 0%, #98ffa2 100%) !important;
}
.da-hover-stroke-danger-gradient:hover path {
  stroke: linear-gradient(90deg, #ff0022 0%, #ff5c00 100%) !important;
}
.da-hover-stroke-info-gradient:hover path {
  stroke: linear-gradient(90deg, #1be7ff 0%, #c2f4ff 100%) !important;
}
body.dark .da-hover-text-color-dark-0:hover {
  color: #ffffff !important;
}
body.dark .da-hover-text-color-dark-5:hover {
  color: #f5f5f5 !important;
}
body.dark .da-hover-text-color-dark-10:hover {
  color: #f0f0f0 !important;
}
body.dark .da-hover-text-color-dark-15:hover {
  color: #e1e1e1 !important;
}
body.dark .da-hover-text-color-dark-20:hover {
  color: #d7d7d7 !important;
}
body.dark .da-hover-text-color-dark-30:hover {
  color: #c2c2c2 !important;
}
body.dark .da-hover-text-color-dark-35:hover {
  color: #adadad !important;
}
body.dark .da-hover-text-color-dark-40:hover {
  color: #999999 !important;
}
body.dark .da-hover-text-color-dark-45:hover {
  color: #858585 !important;
}
body.dark .da-hover-text-color-dark-50:hover {
  color: #707070 !important;
}
body.dark .da-hover-text-color-dark-60:hover {
  color: #5c5c5c !important;
}
body.dark .da-hover-text-color-dark-70:hover {
  color: #474747 !important;
}
body.dark .da-hover-text-color-dark-80:hover {
  color: #333333 !important;
}
body.dark .da-hover-text-color-dark-85:hover {
  color: #292929 !important;
}
body.dark .da-hover-text-color-dark-90:hover {
  color: #1f1f1f !important;
}
body.dark .da-hover-text-color-dark-100:hover {
  color: #141414 !important;
}
body.dark .da-hover-text-color-dark-primary-2:hover {
  color: #ee502c !important;
}
body.dark .da-hover-text-color-dark-primary-1:hover {
  color: #f27052 !important;
}
body.dark .da-hover-text-color-dark-primary:hover {
  color: #050342 !important;
}
body.dark .da-hover-text-color-dark-danger:hover {
  color: #2e1619 !important;
}
body.dark .da-hover-text-color-dark-error:hover {
  color: #2e1619 !important;
}
body.dark .da-hover-text-color-dark-warning:hover {
  color: #3b3117 !important;
}
body.dark .da-hover-text-color-dark-success:hover {
  color: #18342a !important;
}
body.dark .da-hover-text-color-dark-secondary:hover {
  color: #290034 !important;
}
body.dark .da-hover-text-color-dark-info:hover {
  color: #1e383d !important;
}
body.dark .da-text-color-dark-0 {
  color: #ffffff !important;
}
body.dark .da-text-color-dark-5 {
  color: #f5f5f5 !important;
}
body.dark .da-text-color-dark-10 {
  color: #f0f0f0 !important;
}
body.dark .da-text-color-dark-15 {
  color: #e1e1e1 !important;
}
body.dark .da-text-color-dark-20 {
  color: #d7d7d7 !important;
}
body.dark .da-text-color-dark-30 {
  color: #c2c2c2 !important;
}
body.dark .da-text-color-dark-35 {
  color: #adadad !important;
}
body.dark .da-text-color-dark-40 {
  color: #999999 !important;
}
body.dark .da-text-color-dark-45 {
  color: #858585 !important;
}
body.dark .da-text-color-dark-50 {
  color: #707070 !important;
}
body.dark .da-text-color-dark-60 {
  color: #5c5c5c !important;
}
body.dark .da-text-color-dark-70 {
  color: #474747 !important;
}
body.dark .da-text-color-dark-80 {
  color: #333333 !important;
}
body.dark .da-text-color-dark-85 {
  color: #292929 !important;
}
body.dark .da-text-color-dark-90 {
  color: #1f1f1f !important;
}
body.dark .da-text-color-dark-100 {
  color: #141414 !important;
}
body.dark .da-text-color-dark-primary-2 {
  color: #ee502c !important;
}
body.dark .da-text-color-dark-primary-1 {
  color: #f27052 !important;
}
body.dark .da-text-color-dark-primary {
  color: #050342 !important;
}
body.dark .da-text-color-dark-danger {
  color: #2e1619 !important;
}
body.dark .da-text-color-dark-error {
  color: #2e1619 !important;
}
body.dark .da-text-color-dark-warning {
  color: #3b3117 !important;
}
body.dark .da-text-color-dark-success {
  color: #18342a !important;
}
body.dark .da-text-color-dark-secondary {
  color: #290034 !important;
}
body.dark .da-text-color-dark-info {
  color: #1e383d !important;
}
body.dark .da-hover-bg-color-dark-0:hover {
  background-color: #ffffff !important;
}
body.dark .da-hover-bg-color-dark-5:hover {
  background-color: #f5f5f5 !important;
}
body.dark .da-hover-bg-color-dark-10:hover {
  background-color: #f0f0f0 !important;
}
body.dark .da-hover-bg-color-dark-15:hover {
  background-color: #e1e1e1 !important;
}
body.dark .da-hover-bg-color-dark-20:hover {
  background-color: #d7d7d7 !important;
}
body.dark .da-hover-bg-color-dark-30:hover {
  background-color: #c2c2c2 !important;
}
body.dark .da-hover-bg-color-dark-35:hover {
  background-color: #adadad !important;
}
body.dark .da-hover-bg-color-dark-40:hover {
  background-color: #999999 !important;
}
body.dark .da-hover-bg-color-dark-45:hover {
  background-color: #858585 !important;
}
body.dark .da-hover-bg-color-dark-50:hover {
  background-color: #707070 !important;
}
body.dark .da-hover-bg-color-dark-60:hover {
  background-color: #5c5c5c !important;
}
body.dark .da-hover-bg-color-dark-70:hover {
  background-color: #474747 !important;
}
body.dark .da-hover-bg-color-dark-80:hover {
  background-color: #333333 !important;
}
body.dark .da-hover-bg-color-dark-85:hover {
  background-color: #292929 !important;
}
body.dark .da-hover-bg-color-dark-90:hover {
  background-color: #1f1f1f !important;
}
body.dark .da-hover-bg-color-dark-100:hover {
  background-color: #141414 !important;
}
body.dark .da-hover-bg-color-dark-primary-2:hover {
  background-color: #ee502c !important;
}
body.dark .da-hover-bg-color-dark-primary-1:hover {
  background-color: #f27052 !important;
}
body.dark .da-hover-bg-color-dark-primary:hover {
  background-color: #050342 !important;
}
body.dark .da-hover-bg-color-dark-danger:hover {
  background-color: #2e1619 !important;
}
body.dark .da-hover-bg-color-dark-error:hover {
  background-color: #2e1619 !important;
}
body.dark .da-hover-bg-color-dark-warning:hover {
  background-color: #3b3117 !important;
}
body.dark .da-hover-bg-color-dark-success:hover {
  background-color: #18342a !important;
}
body.dark .da-hover-bg-color-dark-secondary:hover {
  background-color: #290034 !important;
}
body.dark .da-hover-bg-color-dark-info:hover {
  background-color: #1e383d !important;
}
body.dark .da-bg-color-dark-0 {
  background-color: #ffffff !important;
}
body.dark .da-bg-color-dark-5 {
  background-color: #f5f5f5 !important;
}
body.dark .da-bg-color-dark-10 {
  background-color: #f0f0f0 !important;
}
body.dark .da-bg-color-dark-15 {
  background-color: #e1e1e1 !important;
}
body.dark .da-bg-color-dark-20 {
  background-color: #d7d7d7 !important;
}
body.dark .da-bg-color-dark-30 {
  background-color: #c2c2c2 !important;
}
body.dark .da-bg-color-dark-35 {
  background-color: #adadad !important;
}
body.dark .da-bg-color-dark-40 {
  background-color: #999999 !important;
}
body.dark .da-bg-color-dark-45 {
  background-color: #858585 !important;
}
body.dark .da-bg-color-dark-50 {
  background-color: #707070 !important;
}
body.dark .da-bg-color-dark-60 {
  background-color: #5c5c5c !important;
}
body.dark .da-bg-color-dark-70 {
  background-color: #474747 !important;
}
body.dark .da-bg-color-dark-80 {
  background-color: #333333 !important;
}
body.dark .da-bg-color-dark-85 {
  background-color: #292929 !important;
}
body.dark .da-bg-color-dark-90 {
  background-color: #1f1f1f !important;
}
body.dark .da-bg-color-dark-100 {
  background-color: #141414 !important;
}
body.dark .da-bg-color-dark-primary-2 {
  background-color: #ee502c !important;
}
body.dark .da-bg-color-dark-primary-1 {
  background-color: #f27052 !important;
}
body.dark .da-bg-color-dark-primary {
  background-color: #050342 !important;
}
body.dark .da-bg-color-dark-danger {
  background-color: #2e1619 !important;
}
body.dark .da-bg-color-dark-error {
  background-color: #2e1619 !important;
}
body.dark .da-bg-color-dark-warning {
  background-color: #3b3117 !important;
}
body.dark .da-bg-color-dark-success {
  background-color: #18342a !important;
}
body.dark .da-bg-color-dark-secondary {
  background-color: #290034 !important;
}
body.dark .da-bg-color-dark-info {
  background-color: #1e383d !important;
}
body.dark .da-hover-bg-dark-0:hover {
  background: #ffffff !important;
}
body.dark .da-hover-bg-dark-5:hover {
  background: #f5f5f5 !important;
}
body.dark .da-hover-bg-dark-10:hover {
  background: #f0f0f0 !important;
}
body.dark .da-hover-bg-dark-15:hover {
  background: #e1e1e1 !important;
}
body.dark .da-hover-bg-dark-20:hover {
  background: #d7d7d7 !important;
}
body.dark .da-hover-bg-dark-30:hover {
  background: #c2c2c2 !important;
}
body.dark .da-hover-bg-dark-35:hover {
  background: #adadad !important;
}
body.dark .da-hover-bg-dark-40:hover {
  background: #999999 !important;
}
body.dark .da-hover-bg-dark-45:hover {
  background: #858585 !important;
}
body.dark .da-hover-bg-dark-50:hover {
  background: #707070 !important;
}
body.dark .da-hover-bg-dark-60:hover {
  background: #5c5c5c !important;
}
body.dark .da-hover-bg-dark-70:hover {
  background: #474747 !important;
}
body.dark .da-hover-bg-dark-80:hover {
  background: #333333 !important;
}
body.dark .da-hover-bg-dark-85:hover {
  background: #292929 !important;
}
body.dark .da-hover-bg-dark-90:hover {
  background: #1f1f1f !important;
}
body.dark .da-hover-bg-dark-100:hover {
  background: #141414 !important;
}
body.dark .da-hover-bg-dark-primary-2:hover {
  background: #ee502c !important;
}
body.dark .da-hover-bg-dark-primary-1:hover {
  background: #f27052 !important;
}
body.dark .da-hover-bg-dark-primary:hover {
  background: #050342 !important;
}
body.dark .da-hover-bg-dark-danger:hover {
  background: #2e1619 !important;
}
body.dark .da-hover-bg-dark-error:hover {
  background: #2e1619 !important;
}
body.dark .da-hover-bg-dark-warning:hover {
  background: #3b3117 !important;
}
body.dark .da-hover-bg-dark-success:hover {
  background: #18342a !important;
}
body.dark .da-hover-bg-dark-secondary:hover {
  background: #290034 !important;
}
body.dark .da-hover-bg-dark-info:hover {
  background: #1e383d !important;
}
body.dark .da-bg-dark-0 {
  background: #ffffff !important;
}
body.dark .da-bg-dark-5 {
  background: #f5f5f5 !important;
}
body.dark .da-bg-dark-10 {
  background: #f0f0f0 !important;
}
body.dark .da-bg-dark-15 {
  background: #e1e1e1 !important;
}
body.dark .da-bg-dark-20 {
  background: #d7d7d7 !important;
}
body.dark .da-bg-dark-30 {
  background: #c2c2c2 !important;
}
body.dark .da-bg-dark-35 {
  background: #adadad !important;
}
body.dark .da-bg-dark-40 {
  background: #999999 !important;
}
body.dark .da-bg-dark-45 {
  background: #858585 !important;
}
body.dark .da-bg-dark-50 {
  background: #707070 !important;
}
body.dark .da-bg-dark-60 {
  background: #5c5c5c !important;
}
body.dark .da-bg-dark-70 {
  background: #474747 !important;
}
body.dark .da-bg-dark-80 {
  background: #333333 !important;
}
body.dark .da-bg-dark-85 {
  background: #292929 !important;
}
body.dark .da-bg-dark-90 {
  background: #1f1f1f !important;
}
body.dark .da-bg-dark-100 {
  background: #141414 !important;
}
body.dark .da-bg-dark-primary-2 {
  background: #ee502c !important;
}
body.dark .da-bg-dark-primary-1 {
  background: #f27052 !important;
}
body.dark .da-bg-dark-primary {
  background: #050342 !important;
}
body.dark .da-bg-dark-danger {
  background: #2e1619 !important;
}
body.dark .da-bg-dark-error {
  background: #2e1619 !important;
}
body.dark .da-bg-dark-warning {
  background: #3b3117 !important;
}
body.dark .da-bg-dark-success {
  background: #18342a !important;
}
body.dark .da-bg-dark-secondary {
  background: #290034 !important;
}
body.dark .da-bg-dark-info {
  background: #1e383d !important;
}
body.dark .da-hover-border-color-dark-0:hover {
  border-color: #ffffff !important;
}
body.dark .da-hover-border-color-dark-5:hover {
  border-color: #f5f5f5 !important;
}
body.dark .da-hover-border-color-dark-10:hover {
  border-color: #f0f0f0 !important;
}
body.dark .da-hover-border-color-dark-15:hover {
  border-color: #e1e1e1 !important;
}
body.dark .da-hover-border-color-dark-20:hover {
  border-color: #d7d7d7 !important;
}
body.dark .da-hover-border-color-dark-30:hover {
  border-color: #c2c2c2 !important;
}
body.dark .da-hover-border-color-dark-35:hover {
  border-color: #adadad !important;
}
body.dark .da-hover-border-color-dark-40:hover {
  border-color: #999999 !important;
}
body.dark .da-hover-border-color-dark-45:hover {
  border-color: #858585 !important;
}
body.dark .da-hover-border-color-dark-50:hover {
  border-color: #707070 !important;
}
body.dark .da-hover-border-color-dark-60:hover {
  border-color: #5c5c5c !important;
}
body.dark .da-hover-border-color-dark-70:hover {
  border-color: #474747 !important;
}
body.dark .da-hover-border-color-dark-80:hover {
  border-color: #333333 !important;
}
body.dark .da-hover-border-color-dark-85:hover {
  border-color: #292929 !important;
}
body.dark .da-hover-border-color-dark-90:hover {
  border-color: #1f1f1f !important;
}
body.dark .da-hover-border-color-dark-100:hover {
  border-color: #141414 !important;
}
body.dark .da-hover-border-color-dark-primary-2:hover {
  border-color: #ee502c !important;
}
body.dark .da-hover-border-color-dark-primary-1:hover {
  border-color: #f27052 !important;
}
body.dark .da-hover-border-color-dark-primary:hover {
  border-color: #050342 !important;
}
body.dark .da-hover-border-color-dark-danger:hover {
  border-color: #2e1619 !important;
}
body.dark .da-hover-border-color-dark-error:hover {
  border-color: #2e1619 !important;
}
body.dark .da-hover-border-color-dark-warning:hover {
  border-color: #3b3117 !important;
}
body.dark .da-hover-border-color-dark-success:hover {
  border-color: #18342a !important;
}
body.dark .da-hover-border-color-dark-secondary:hover {
  border-color: #290034 !important;
}
body.dark .da-hover-border-color-dark-info:hover {
  border-color: #1e383d !important;
}
body.dark .da-border-color-dark-0 {
  border-color: #ffffff !important;
}
body.dark .da-border-color-dark-5 {
  border-color: #f5f5f5 !important;
}
body.dark .da-border-color-dark-10 {
  border-color: #f0f0f0 !important;
}
body.dark .da-border-color-dark-15 {
  border-color: #e1e1e1 !important;
}
body.dark .da-border-color-dark-20 {
  border-color: #d7d7d7 !important;
}
body.dark .da-border-color-dark-30 {
  border-color: #c2c2c2 !important;
}
body.dark .da-border-color-dark-35 {
  border-color: #adadad !important;
}
body.dark .da-border-color-dark-40 {
  border-color: #999999 !important;
}
body.dark .da-border-color-dark-45 {
  border-color: #858585 !important;
}
body.dark .da-border-color-dark-50 {
  border-color: #707070 !important;
}
body.dark .da-border-color-dark-60 {
  border-color: #5c5c5c !important;
}
body.dark .da-border-color-dark-70 {
  border-color: #474747 !important;
}
body.dark .da-border-color-dark-80 {
  border-color: #333333 !important;
}
body.dark .da-border-color-dark-85 {
  border-color: #292929 !important;
}
body.dark .da-border-color-dark-90 {
  border-color: #1f1f1f !important;
}
body.dark .da-border-color-dark-100 {
  border-color: #141414 !important;
}
body.dark .da-border-color-dark-primary-2 {
  border-color: #ee502c !important;
}
body.dark .da-border-color-dark-primary-1 {
  border-color: #f27052 !important;
}
body.dark .da-border-color-dark-primary {
  border-color: #050342 !important;
}
body.dark .da-border-color-dark-danger {
  border-color: #2e1619 !important;
}
body.dark .da-border-color-dark-error {
  border-color: #2e1619 !important;
}
body.dark .da-border-color-dark-warning {
  border-color: #3b3117 !important;
}
body.dark .da-border-color-dark-success {
  border-color: #18342a !important;
}
body.dark .da-border-color-dark-secondary {
  border-color: #290034 !important;
}
body.dark .da-border-color-dark-info {
  border-color: #1e383d !important;
}
body.dark .da-fill-dark-0 path {
  fill: #ffffff !important;
}
body.dark .da-fill-dark-5 path {
  fill: #f5f5f5 !important;
}
body.dark .da-fill-dark-10 path {
  fill: #f0f0f0 !important;
}
body.dark .da-fill-dark-15 path {
  fill: #e1e1e1 !important;
}
body.dark .da-fill-dark-20 path {
  fill: #d7d7d7 !important;
}
body.dark .da-fill-dark-30 path {
  fill: #c2c2c2 !important;
}
body.dark .da-fill-dark-35 path {
  fill: #adadad !important;
}
body.dark .da-fill-dark-40 path {
  fill: #999999 !important;
}
body.dark .da-fill-dark-45 path {
  fill: #858585 !important;
}
body.dark .da-fill-dark-50 path {
  fill: #707070 !important;
}
body.dark .da-fill-dark-60 path {
  fill: #5c5c5c !important;
}
body.dark .da-fill-dark-70 path {
  fill: #474747 !important;
}
body.dark .da-fill-dark-80 path {
  fill: #333333 !important;
}
body.dark .da-fill-dark-85 path {
  fill: #292929 !important;
}
body.dark .da-fill-dark-90 path {
  fill: #1f1f1f !important;
}
body.dark .da-fill-dark-100 path {
  fill: #141414 !important;
}
body.dark .da-fill-dark-primary-2 path {
  fill: #ee502c !important;
}
body.dark .da-fill-dark-primary-1 path {
  fill: #f27052 !important;
}
body.dark .da-fill-dark-primary path {
  fill: #050342 !important;
}
body.dark .da-fill-dark-danger path {
  fill: #2e1619 !important;
}
body.dark .da-fill-dark-error path {
  fill: #2e1619 !important;
}
body.dark .da-fill-dark-warning path {
  fill: #3b3117 !important;
}
body.dark .da-fill-dark-success path {
  fill: #18342a !important;
}
body.dark .da-fill-dark-secondary path {
  fill: #290034 !important;
}
body.dark .da-fill-dark-info path {
  fill: #1e383d !important;
}
body.dark .da-hover-fill-dark-0:hover path {
  fill: #ffffff !important;
}
body.dark .da-hover-fill-dark-5:hover path {
  fill: #f5f5f5 !important;
}
body.dark .da-hover-fill-dark-10:hover path {
  fill: #f0f0f0 !important;
}
body.dark .da-hover-fill-dark-15:hover path {
  fill: #e1e1e1 !important;
}
body.dark .da-hover-fill-dark-20:hover path {
  fill: #d7d7d7 !important;
}
body.dark .da-hover-fill-dark-30:hover path {
  fill: #c2c2c2 !important;
}
body.dark .da-hover-fill-dark-35:hover path {
  fill: #adadad !important;
}
body.dark .da-hover-fill-dark-40:hover path {
  fill: #999999 !important;
}
body.dark .da-hover-fill-dark-45:hover path {
  fill: #858585 !important;
}
body.dark .da-hover-fill-dark-50:hover path {
  fill: #707070 !important;
}
body.dark .da-hover-fill-dark-60:hover path {
  fill: #5c5c5c !important;
}
body.dark .da-hover-fill-dark-70:hover path {
  fill: #474747 !important;
}
body.dark .da-hover-fill-dark-80:hover path {
  fill: #333333 !important;
}
body.dark .da-hover-fill-dark-85:hover path {
  fill: #292929 !important;
}
body.dark .da-hover-fill-dark-90:hover path {
  fill: #1f1f1f !important;
}
body.dark .da-hover-fill-dark-100:hover path {
  fill: #141414 !important;
}
body.dark .da-hover-fill-dark-primary-2:hover path {
  fill: #ee502c !important;
}
body.dark .da-hover-fill-dark-primary-1:hover path {
  fill: #f27052 !important;
}
body.dark .da-hover-fill-dark-primary:hover path {
  fill: #050342 !important;
}
body.dark .da-hover-fill-dark-danger:hover path {
  fill: #2e1619 !important;
}
body.dark .da-hover-fill-dark-error:hover path {
  fill: #2e1619 !important;
}
body.dark .da-hover-fill-dark-warning:hover path {
  fill: #3b3117 !important;
}
body.dark .da-hover-fill-dark-success:hover path {
  fill: #18342a !important;
}
body.dark .da-hover-fill-dark-secondary:hover path {
  fill: #290034 !important;
}
body.dark .da-hover-fill-dark-info:hover path {
  fill: #1e383d !important;
}
body.dark .da-stroke-dark-0 path {
  stroke: #ffffff !important;
}
body.dark .da-stroke-dark-5 path {
  stroke: #f5f5f5 !important;
}
body.dark .da-stroke-dark-10 path {
  stroke: #f0f0f0 !important;
}
body.dark .da-stroke-dark-15 path {
  stroke: #e1e1e1 !important;
}
body.dark .da-stroke-dark-20 path {
  stroke: #d7d7d7 !important;
}
body.dark .da-stroke-dark-30 path {
  stroke: #c2c2c2 !important;
}
body.dark .da-stroke-dark-35 path {
  stroke: #adadad !important;
}
body.dark .da-stroke-dark-40 path {
  stroke: #999999 !important;
}
body.dark .da-stroke-dark-45 path {
  stroke: #858585 !important;
}
body.dark .da-stroke-dark-50 path {
  stroke: #707070 !important;
}
body.dark .da-stroke-dark-60 path {
  stroke: #5c5c5c !important;
}
body.dark .da-stroke-dark-70 path {
  stroke: #474747 !important;
}
body.dark .da-stroke-dark-80 path {
  stroke: #333333 !important;
}
body.dark .da-stroke-dark-85 path {
  stroke: #292929 !important;
}
body.dark .da-stroke-dark-90 path {
  stroke: #1f1f1f !important;
}
body.dark .da-stroke-dark-100 path {
  stroke: #141414 !important;
}
body.dark .da-stroke-dark-primary-2 path {
  stroke: #ee502c !important;
}
body.dark .da-stroke-dark-primary-1 path {
  stroke: #f27052 !important;
}
body.dark .da-stroke-dark-primary path {
  stroke: #050342 !important;
}
body.dark .da-stroke-dark-danger path {
  stroke: #2e1619 !important;
}
body.dark .da-stroke-dark-error path {
  stroke: #2e1619 !important;
}
body.dark .da-stroke-dark-warning path {
  stroke: #3b3117 !important;
}
body.dark .da-stroke-dark-success path {
  stroke: #18342a !important;
}
body.dark .da-stroke-dark-secondary path {
  stroke: #290034 !important;
}
body.dark .da-stroke-dark-info path {
  stroke: #1e383d !important;
}
body.dark .da-hover-stroke-dark-0:hover path {
  stroke: #ffffff !important;
}
body.dark .da-hover-stroke-dark-5:hover path {
  stroke: #f5f5f5 !important;
}
body.dark .da-hover-stroke-dark-10:hover path {
  stroke: #f0f0f0 !important;
}
body.dark .da-hover-stroke-dark-15:hover path {
  stroke: #e1e1e1 !important;
}
body.dark .da-hover-stroke-dark-20:hover path {
  stroke: #d7d7d7 !important;
}
body.dark .da-hover-stroke-dark-30:hover path {
  stroke: #c2c2c2 !important;
}
body.dark .da-hover-stroke-dark-35:hover path {
  stroke: #adadad !important;
}
body.dark .da-hover-stroke-dark-40:hover path {
  stroke: #999999 !important;
}
body.dark .da-hover-stroke-dark-45:hover path {
  stroke: #858585 !important;
}
body.dark .da-hover-stroke-dark-50:hover path {
  stroke: #707070 !important;
}
body.dark .da-hover-stroke-dark-60:hover path {
  stroke: #5c5c5c !important;
}
body.dark .da-hover-stroke-dark-70:hover path {
  stroke: #474747 !important;
}
body.dark .da-hover-stroke-dark-80:hover path {
  stroke: #333333 !important;
}
body.dark .da-hover-stroke-dark-85:hover path {
  stroke: #292929 !important;
}
body.dark .da-hover-stroke-dark-90:hover path {
  stroke: #1f1f1f !important;
}
body.dark .da-hover-stroke-dark-100:hover path {
  stroke: #141414 !important;
}
body.dark .da-hover-stroke-dark-primary-2:hover path {
  stroke: #ee502c !important;
}
body.dark .da-hover-stroke-dark-primary-1:hover path {
  stroke: #f27052 !important;
}
body.dark .da-hover-stroke-dark-primary:hover path {
  stroke: #050342 !important;
}
body.dark .da-hover-stroke-dark-danger:hover path {
  stroke: #2e1619 !important;
}
body.dark .da-hover-stroke-dark-error:hover path {
  stroke: #2e1619 !important;
}
body.dark .da-hover-stroke-dark-warning:hover path {
  stroke: #3b3117 !important;
}
body.dark .da-hover-stroke-dark-success:hover path {
  stroke: #18342a !important;
}
body.dark .da-hover-stroke-dark-secondary:hover path {
  stroke: #290034 !important;
}
body.dark .da-hover-stroke-dark-info:hover path {
  stroke: #1e383d !important;
}
.da-border-radius {
  border-radius: 7px;
}
.da-border-radius-tl {
  border-top-left-radius: 7px;
}
.da-border-radius-tr {
  border-top-right-radius: 7px;
}
.da-border-radius-br {
  border-bottom-right-radius: 7px;
}
.da-border-radius-bl {
  border-bottom-left-radius: 7px;
}
.da-border-radius-b {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.da-border-radius-t {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.da-border-radius-r {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.da-border-radius-l {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
html[dir='rtl'] .da-border-radius-tl {
  border-top-left-radius: 0;
  border-top-right-radius: 7px;
}
html[dir='rtl'] .da-border-radius-tr {
  border-top-right-radius: 0;
  border-top-left-radius: 7px;
}
html[dir='rtl'] .da-border-radius-br {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 7px;
}
html[dir='rtl'] .da-border-radius-bl {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 7px;
}
html[dir='rtl'] .da-border-radius-b {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
html[dir='rtl'] .da-border-radius-t {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}
html[dir='rtl'] .da-border-radius-r {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
html[dir='rtl'] .da-border-radius-l {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.da-border-radius-sm {
  border-radius: 4px;
}
.da-border-radius-sm-tl {
  border-top-left-radius: 4px;
}
.da-border-radius-sm-tr {
  border-top-right-radius: 4px;
}
.da-border-radius-sm-br {
  border-bottom-right-radius: 4px;
}
.da-border-radius-sm-bl {
  border-bottom-left-radius: 4px;
}
.da-border-radius-sm-b {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.da-border-radius-sm-t {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.da-border-radius-sm-r {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.da-border-radius-sm-l {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
html[dir='rtl'] .da-border-radius-sm-tl {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
}
html[dir='rtl'] .da-border-radius-sm-tr {
  border-top-right-radius: 0;
  border-top-left-radius: 4px;
}
html[dir='rtl'] .da-border-radius-sm-br {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
html[dir='rtl'] .da-border-radius-sm-bl {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 4px;
}
html[dir='rtl'] .da-border-radius-sm-b {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
html[dir='rtl'] .da-border-radius-sm-t {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
html[dir='rtl'] .da-border-radius-sm-r {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
html[dir='rtl'] .da-border-radius-sm-l {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.da-border-radius-lg {
  border-radius: 10px;
}
.da-border-radius-lg-tl {
  border-top-left-radius: 10px;
}
.da-border-radius-lg-tr {
  border-top-right-radius: 10px;
}
.da-border-radius-lg-br {
  border-bottom-right-radius: 10px;
}
.da-border-radius-lg-bl {
  border-bottom-left-radius: 10px;
}
.da-border-radius-lg-b {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.da-border-radius-lg-t {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.da-border-radius-lg-r {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.da-border-radius-lg-l {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
html[dir='rtl'] .da-border-radius-lg-tl {
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
}
html[dir='rtl'] .da-border-radius-lg-tr {
  border-top-right-radius: 0;
  border-top-left-radius: 10px;
}
html[dir='rtl'] .da-border-radius-lg-br {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 10px;
}
html[dir='rtl'] .da-border-radius-lg-bl {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
}
html[dir='rtl'] .da-border-radius-lg-b {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
html[dir='rtl'] .da-border-radius-lg-t {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
html[dir='rtl'] .da-border-radius-lg-r {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
html[dir='rtl'] .da-border-radius-lg-l {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.da-border-1 {
  border: 1px solid #dfe6e9;
}
.da-border-top-1 {
  border-top: 1px solid #dfe6e9;
}
.da-border-bottom-1 {
  border-bottom: 1px solid #dfe6e9;
}
.da-border-left-1 {
  border-left: 1px solid #dfe6e9;
}
.da-border-right-1 {
  border-right: 1px solid #dfe6e9;
}
html[dir='rtl'] .da-border-left-1 {
  border-left: none;
  border-right: 1px solid #dfe6e9;
}
html[dir='rtl'] .da-border-right-1 {
  border-right: none;
  border-left: 1px solid #dfe6e9;
}
.da-border-2 {
  border: 2px solid #dfe6e9;
}
.da-border-top-2 {
  border-top: 2px solid #dfe6e9;
}
.da-border-bottom-2 {
  border-bottom: 2px solid #dfe6e9;
}
.da-border-left-2 {
  border-left: 2px solid #dfe6e9;
}
.da-border-right-2 {
  border-right: 2px solid #dfe6e9;
}
html[dir='rtl'] .da-border-left-2 {
  border-left: none;
  border-right: 2px solid #dfe6e9;
}
html[dir='rtl'] .da-border-right-2 {
  border-right: none;
  border-left: 2px solid #dfe6e9;
}
.da-border-3 {
  border: 3px solid #dfe6e9;
}
.da-border-top-3 {
  border-top: 3px solid #dfe6e9;
}
.da-border-bottom-3 {
  border-bottom: 3px solid #dfe6e9;
}
.da-border-left-3 {
  border-left: 3px solid #dfe6e9;
}
.da-border-right-3 {
  border-right: 3px solid #dfe6e9;
}
html[dir='rtl'] .da-border-left-3 {
  border-left: none;
  border-right: 3px solid #dfe6e9;
}
html[dir='rtl'] .da-border-right-3 {
  border-right: none;
  border-left: 3px solid #dfe6e9;
}
.da-border-none {
  border: 0 solid #dfe6e9;
}
.da-border-top-none {
  border-top: 0 solid #dfe6e9;
}
.da-border-bottom-none {
  border-bottom: 0 solid #dfe6e9;
}
.da-border-left-none {
  border-left: 0 solid #dfe6e9;
}
.da-border-right-none {
  border-right: 0 solid #dfe6e9;
}
html[dir='rtl'] .da-border-left-none {
  border-left: none;
  border-right: 0 solid #dfe6e9;
}
html[dir='rtl'] .da-border-right-none {
  border-right: none;
  border-left: 0 solid #dfe6e9;
}
.da-border-radius-full {
  border-radius: 100px;
}
.da-border-dashed {
  border-style: dashed;
}
.da-border-radius-round {
  border-radius: 50%;
}
.da-elevatior {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
}
.da-elevatior-2 {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
::-moz-selection {
  background: #f0e3e0;
  color: #f27052;
}
::selection {
  background: #f0e3e0;
  color: #f27052;
}
body {
  overflow-x: hidden;
}
a:hover {
  color: #f27052;
}
img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  max-width: 100%;
}
.da-img-cover {
  -o-object-fit: cover;
     object-fit: cover;
}
.da-img-contain {
  -o-object-fit: contain;
     object-fit: contain;
}
.da-scrollbar-y-hidden::-webkit-scrollbar {
  width: 0;
}
.da-scrollbar-x-hidden::-webkit-scrollbar {
  height: 0;
}
.da-app-layout-bg {
  position: relative;
}
.da-app-layout-bg:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 270px;
  background: #ffffff;
}
.da-modal-p-24 .ant-modal-body {
  padding: 24px;
}
.da-line-height-normal {
  line-height: normal;
}
.da-overflow-hidden {
  overflow: hidden;
}
.da-overflow-scroll {
  overflow: scroll;
}
.da-d-flex {
  display: flex;
}
.da-flex-wrap {
  flex-wrap: wrap;
}
.da-d-flex-center {
  display: flex !important;
  align-items: center;
}
.da-d-flex-full-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.da-d-flex-between {
  justify-content: space-between;
}
.da-d-block {
  display: block;
}
.da-d-inline {
  display: inline;
}
.da-d-inline-block {
  display: inline-block;
}
.da-d-inline-flex {
  display: inline-flex;
}
.da-primary-shadow {
  box-shadow: 0px 0px 25px rgba(238, 80, 44, 0.702);
}
.da-position-relative {
  position: relative;
}
.da-position-absolute {
  position: absolute;
}
.da-position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.da-position-absolute-top-left {
  position: absolute;
  top: 0;
  left: 0;
}
.da-position-absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}
.da-position-absolute-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.da-position-absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
html[dir='rtl'] .da-position-absolute-top-left {
  right: 0;
  left: auto;
}
html[dir='rtl'] .da-position-absolute-top-right {
  left: 0;
  right: auto;
}
html[dir='rtl'] .da-position-absolute-bottom-left {
  right: 0;
  left: auto;
}
html[dir='rtl'] .da-position-absolute-bottom-right {
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
}
.da-float-left {
  float: left;
}
.da-float-right {
  float: right;
}
.da-float-none {
  float: none;
}
@media screen and (max-width: 575px) {
  .da-float-xs-left {
    float: left;
  }
}
@media screen and (max-width: 575px) {
  .da-float-xs-right {
    float: right;
  }
}
@media screen and (max-width: 575px) {
  .da-float-xs-none {
    float: none;
  }
}
.da-w-auto {
  width: auto !important;
}
.da-w-100 {
  width: 100% !important;
}
@media screen and (max-width: 1599px) {
  .da-text-xl-left {
    text-align: left !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-text-lg-left {
    text-align: left !important;
  }
}
@media screen and (max-width: 991px) {
  .da-text-md-left {
    text-align: left !important;
  }
}
@media screen and (max-width: 767px) {
  .da-text-sm-left {
    text-align: left !important;
  }
}
@media screen and (max-width: 575px) {
  .da-text-xs-left {
    text-align: left !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-text-xl-right {
    text-align: right !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-text-lg-right {
    text-align: right !important;
  }
}
@media screen and (max-width: 991px) {
  .da-text-md-right {
    text-align: right !important;
  }
}
@media screen and (max-width: 767px) {
  .da-text-sm-right {
    text-align: right !important;
  }
}
@media screen and (max-width: 575px) {
  .da-text-xs-right {
    text-align: right !important;
  }
}
@media screen and (max-width: 1599px) {
  .da-text-xl-center {
    text-align: center !important;
  }
}
@media screen and (max-width: 1199px) {
  .da-text-lg-center {
    text-align: center !important;
  }
}
@media screen and (max-width: 991px) {
  .da-text-md-center {
    text-align: center !important;
  }
}
@media screen and (max-width: 767px) {
  .da-text-sm-center {
    text-align: center !important;
  }
}
@media screen and (max-width: 575px) {
  .da-text-xs-center {
    text-align: center !important;
  }
}
.da-text-center {
  text-align: center;
}
.da-text-right {
  text-align: right;
}
.da-text-left {
  text-align: left;
}
html[dir='rtl'] .da-text-right {
  text-align: left;
}
html[dir='rtl'] .da-text-left {
  text-align: right;
}
.da-h-auto {
  height: auto !important;
}
.da-h-100 {
  height: 100% !important;
}
.da-vertical-align-bottom {
  vertical-align: bottom;
}
.da-vertical-align-middle {
  vertical-align: middle !important;
}
.da-align-self-center {
  align-self: center;
}
.da-align-items-center {
  align-items: center;
}
.da-align-items-end {
  align-items: flex-end;
}
.da-z-index {
  z-index: 1;
}
.da-cursor-pointer {
  cursor: pointer;
}
.da-transition {
  transition: 0.4s;
}
html[dir='rtl'] .da-rtl-scale-x-n1 {
  transform: scale(-1, 1);
}
code {
  padding: 4px;
  border-radius: 4px;
  background: #fff0;
  margin: 0 1px;
}
.show-code {
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 20px);
  transition: 0.5s;
}
.show-code-active {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.scroll-to-top > div {
  z-index: 3;
}
html[dir='rtl'] .scroll-to-top > div {
  left: 30px;
  right: auto !important;
}
.da-wish-button svg {
  transition: 0.3s;
}
.da-wish-button:hover svg {
  transform: scale(1.15);
}
.da-color-wrapper-xl {
  height: 60px;
  width: 90px;
}
.da-color-wrapper-sm {
  height: 20px;
  width: 30px;
}
.da-color-wrapper {
  height: 60px;
  width: 60px;
}
.border {
  border: 1px solid #dfe6e9;
}
a.ant-btn {
  padding: 8px 24px !important;
  line-height: inherit;
}
.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
  white-space: initial;
  height: auto;
  min-height: 40px;
}
.ant-btn > .remix-icon {
  line-height: 1;
  font-size: 16px;
}
.ant-btn-text.da-btn-text-bg-none {
  background: none !important;
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #636e72 !important;
  background: #f7fafc !important;
  border-color: transparent !important;
}
.ant-btn:hover {
  color: #ee502c;
  border-color: #ee502c;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffffff;
  background: #ee502c;
  border-color: #ee502c;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent !important;
}
.ant-btn-link.ant-btn {
  min-height: 0;
  min-height: initial;
  padding: 0;
  padding: initial;
  color: inherit;
}
.ant-btn-ghost {
  color: #f27052;
  border-color: #f27052;
}
.ant-btn-circle.ant-btn-sm {
  min-height: 32px;
}
.ant-btn-icon-only.ant-btn-sm {
  min-height: 32px;
}
.ant-btn-round.ant-btn-sm {
  min-height: 32px;
}
.ant-btn-sm {
  min-height: 32px;
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  background-color: #f27052 !important;
  color: #ffffff !important;
}
.da-icon-card:hover {
  box-shadow: 0px 0px 10px rgba(242, 112, 82, 0.349);
}
.ant-input:focus,
.ant-input-focused {
  border-color: #ee502c;
  box-shadow: 0px 0px 10px rgba(242, 112, 82, 0.349);
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  outline: 0;
  box-shadow: 0px 0px 10px rgba(242, 112, 82, 0.349);
}
.ant-input-prefix {
  margin-right: 8px;
  font-size: 16px;
  color: #636e72;
}
.ant-input-suffix {
  margin-left: 8px;
  font-size: 16px;
  color: #636e72;
}
.ant-input-textarea-show-count::after {
  font-size: 10px;
}
.ant-input-affix-wrapper-sm {
  padding: 4px 15px;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #ee502c;
  box-shadow: 0px 0px 10px rgba(242, 112, 82, 0.349);
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  color: #636e72;
}
.ant-form-item-has-warning .ant-input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
  box-shadow: 0px 0px 10px rgba(255, 194, 18, 0.35);
}
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  box-shadow: 0px 0px 10px rgba(255, 0, 34, 0.35);
}
.da-xl-search-button .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding: 0 64px;
}
@media screen and (max-width: 767px) {
  .da-xl-search-button .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    padding: 0 16px;
  }
}
html[dir='rtl'] .ant-input-group > .ant-input:last-child,
html[dir='rtl'] .ant-input-group-addon:last-child {
  border-radius: 7px 0 0 7px;
}
html[dir='rtl'] .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 7px 0 0 7px;
}
.ant-checkbox-inner {
  border-radius: 4px;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  border-radius: 2px;
}
.ant-checkbox-inner::after {
  top: 47%;
  left: 19%;
}
.ant-upload-list {
  margin-top: 16px;
}
.ant-upload-list.ant-upload-list-picture .ant-upload-list-item {
  border-radius: 0;
}
.ant-upload-list.ant-upload-list-picture .ant-upload-list-item-name {
  font-weight: 200;
  color: #f27052;
}
.ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error {
  border-color: #ff455e;
}
.ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff455e;
}
.ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error button .anticon-delete svg {
  fill: #ff455e;
}
.ant-upload-list .ant-upload-list-item .ant-upload-list-item-info .ant-upload-span .ant-upload-list-item-thumbnail svg {
  width: 24px;
  height: 24px;
}
.ant-upload-list .ant-upload-list-item .ant-upload-list-item-info .ant-upload-span .ant-upload-list-item-thumbnail svg path:nth-child(1) {
  fill: #2d3436;
}
.ant-upload-list .ant-upload-list-item .ant-upload-list-item-info .ant-upload-span .ant-upload-list-item-thumbnail svg path:nth-child(2) {
  fill: #ffffff;
  stroke: #2d3436;
}
.ant-upload-list .ant-upload-list-item .ant-upload-list-item-info .ant-upload-span .ant-upload-list-item-thumbnail svg path:nth-child(3) {
  fill: #ffffff;
  stroke: #2d3436;
}
.ant-upload-list .ant-upload-list-item .ant-upload-list-item-info .ant-upload-span .ant-upload-list-item-thumbnail svg path:nth-child(5) {
  fill: #2d3436;
}
.ant-upload-list-picture-container {
  width: 100%;
}
.ant-upload-list-picture-container .ant-upload-list-item .ant-upload-list-item-info .ant-upload-span .ant-upload-list-item-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-upload.ant-upload-select-picture-card {
  background-color: #f0f3f5;
  border-radius: 0;
}
.avatar-uploader .ant-upload.ant-upload-select-picture-card {
  width: 128px;
  height: 128px;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 0.8;
}
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff455e;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  background-color: #636e72;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .ant-btn-icon-only {
  padding: 0;
  width: auto;
  margin-left: 10px;
  background: #fff0 !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon svg {
  fill: #ffffff;
}
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff455e;
}
.ant-upload.ant-upload-drag {
  background-color: #f0f3f5;
  padding: 0 16px;
}
.da-upload-dragger-col span {
  display: block;
}
@media screen and (max-width: 767px) {
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    margin: 16px -4px 0;
  }
  .ant-upload-list .ant-upload-list-picture-card-container {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 8px);
    margin: 0 4px 8px;
  }
  .ant-upload-list .ant-upload.ant-upload-select-picture-card {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 8px);
    margin: 0 4px 8px;
  }
}
.da-slider-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.da-slider-icon-wrapper .ant-slider {
  flex: 1 1;
  margin: 0 14px;
}
.da-slider-icon-wrapper .anticon {
  position: absolute;
  top: -2px;
  width: 16px;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 1;
}
.da-slider-icon-wrapper .anticon:first-child {
  left: 0;
}
.da-slider-icon-wrapper .anticon:last-child {
  right: 0;
}
.da-slider-icon-wrapper .icon-wrapper-active {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-focused {
  border-color: #ee502c;
  box-shadow: 0px 0px 10px rgba(242, 112, 82, 0.349);
}
.ant-picker-time-panel-column::-webkit-scrollbar {
  width: 0;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0px 0px 10px rgba(242, 112, 82, 0.349);
}
.ant-tag-magenta .ant-tag-close-icon {
  color: #c903ff !important;
}
.ant-tag-red .ant-tag-close-icon {
  color: #ff0022 !important;
}
.ant-tag-gold .ant-tag-close-icon {
  color: #ffc212 !important;
}
.ant-tag-cyan .ant-tag-close-icon {
  color: #1be7ff !important;
}
.ant-tag-blue .ant-tag-close-icon {
  color: #f27052 !important;
}
.ant-tag-green .ant-tag-close-icon {
  color: #00f7bf !important;
}
.ant-tag-success .ant-tag-close-icon {
  color: #00f7bf !important;
}
.ant-tag-processing .ant-tag-close-icon {
  color: #f27052 !important;
}
.ant-tag-error .ant-tag-close-icon {
  color: #ff0022 !important;
}
.ant-tag-warning .ant-tag-close-icon {
  color: #ffc212 !important;
}
.ant-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-avatar svg {
  width: 100%;
  height: 60%;
  margin-left: 2px !important;
}
.ant-avatar-group-popover .ant-popover-inner-content .ant-avatar {
  margin: 0 auto;
}
.ant-avatar-group-popover .ant-popover-inner-content .ant-avatar + .ant-avatar {
  margin-top: 10px;
}
.head-example {
  width: 42px;
  height: 42px;
  border-radius: 7px;
  background: #f0e3e0;
  display: inline-block;
  vertical-align: middle;
}
.site-badge-count-4 .ant-badge-count {
  color: #b2bec3;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(242, 112, 82, 0.349);
}
.da-badge-16.ant-badge,
.da-badge-16 .ant-badge-status-dot {
  width: 16px;
  height: 16px;
}
.ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
  transform: none;
  transform: initial;
}
.ant-tag {
  padding: 0 16px;
}
.ant-tag-magenta {
  background: #fdeffc;
  border-color: #c903ff;
  color: #c903ff;
}
.ant-tag-red {
  background: #ffe7ea;
  border-color: #ff0022;
  color: #ff0022;
}
.ant-tag-gold {
  background: #fff9e9;
  border-color: #ffc212;
  color: #ffc212;
}
.ant-tag-cyan {
  background: #eafcff;
  border-color: #1be7ff;
  color: #1be7ff;
}
.ant-tag-blue {
  background: #f0e3e0;
  border-color: #f27052;
  color: #f27052;
}
.ant-tag-green {
  background: #eafff8;
  border-color: #00f7bf;
  color: #00f7bf;
}
.ant-tag-success {
  background: #eafff8;
  border-color: #00f7bf;
  color: #00f7bf;
}
.ant-tag-processing {
  background: #f0e3e0;
  border-color: #f27052;
  color: #f27052;
}
.ant-tag-error {
  background: #ffe7ea;
  border-color: #ff0022;
  color: #ff0022;
}
.ant-tag-warning {
  background: #fff9e9;
  border-color: #ffc212;
  color: #ffc212;
}
.ant-popover-message {
  display: flex;
  align-items: center;
}
.ant-popover-title {
  border: none;
  padding: 8px 16px;
}
.ant-popover-inner-content {
  padding: 16px;
}
.ant-popover-buttons .ant-btn {
  padding: 0 16px;
}
.ant-popover-buttons .ant-btn:nth-child(1) {
  color: #b2bec3;
  border-color: #b2bec3;
  background-color: #f0f3f5;
}
.ant-popover-message .anticon svg {
  width: 16px;
  height: 16px;
  fill: #f27052;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
}
.ant-picker-calendar-full .ant-picker-panel {
  overflow: scroll;
}
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  border: 1px solid #ff0022;
  background-color: #ffe7ea;
  border-radius: 12px;
  overflow: hidden;
  padding-left: 4px;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.events .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.calendar-demo-card {
  max-width: 300px;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  overflow: scroll;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::before,
.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  display: flex;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .da-collapse-arrow {
  transition: 0.4s;
}
.ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-extra .da-collapse-arrow {
  transform: rotate(90deg);
}
@media screen and (max-width: 767px) {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 24px !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 24px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-demo-loadmore-list .ant-list-item-meta {
    flex: 0 0 100%;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 767px) {
  .ant-list-lg .ant-list-item {
    padding: 16px 0 16px;
  }
}
.virtual-table-cell {
  padding: 18px 16px;
}
.da-table-menu .ant-menu {
  margin-top: 0;
  padding: 10px 0;
  border-radius: 8px;
  border: 1px solid #dfe6e9;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.da-table-menu .ant-menu li {
  height: 30px;
  line-height: normal;
  padding: 0px !important;
}
.da-table-menu span.ant-menu-title-content > span {
  padding: 0 14px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.4s;
}
.da-table-menu .ant-menu li {
  background: none !important;
  overflow: hidden;
  color: #636e72;
}
.da-table-menu span.ant-menu-title-content {
  height: 100%;
}
.da-table-menu span.ant-menu-title-content > span:after {
  content: '';
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: 5px;
  border-radius: 2px;
  padding: 8px 0;
  border-right: 2px solid #f27052;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.da-table-menu span.ant-menu-title-content > span.active:after {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.da-table-menu span.ant-menu-title-content > span.active {
  color: #f27052;
  background: #f0e3e0;
}
.da-table-menu span.ant-menu-title-content > span > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.da-table-menu .ant-menu li:after {
  visibility: hidden;
}
@media screen and (max-width: 1199px) {
  .da-table-menu {
    display: none;
  }
}
html[dir='rtl'] .ant-table-filter-trigger {
  margin-left: 0;
  margin-right: 4px;
}
.da-card p {
  margin-bottom: 0;
}
.ant-alert {
  padding: 16px;
}
.ant-alert .ant-alert-icon {
  margin-right: 20px;
}
.ant-alert .ant-alert-close-icon .anticon-close {
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 13px;
  height: 13px;
}
.ant-alert .ant-alert-close-icon .anticon-close svg {
  display: none;
}
.ant-alert-success .ant-alert-content .ant-alert-message,
.ant-alert-success .ant-alert-content .ant-alert-description {
  color: #00f7bf;
}
.ant-alert-success .ant-alert-close-icon .anticon-close {
  background-image: url(/vendor-theme/images/alert/success-close.svg);
}
.ant-alert-info .ant-alert-content .ant-alert-message,
.ant-alert-info .ant-alert-content .ant-alert-description {
  color: #1be7ff;
}
.ant-alert-info .ant-alert-close-icon .anticon-close {
  background-image: url(/vendor-theme/images/alert/info-close.svg);
}
.ant-alert-warning .ant-alert-content .ant-alert-message,
.ant-alert-warning .ant-alert-content .ant-alert-description {
  color: #ffc212;
}
.ant-alert-warning .ant-alert-close-icon .anticon-close {
  background-image: url(/vendor-theme/images/alert/warning-close.svg);
}
.ant-alert-error .ant-alert-content .ant-alert-message,
.ant-alert-error .ant-alert-content .ant-alert-description {
  color: #ff0022;
}
.ant-alert-error .ant-alert-close-icon .anticon-close {
  background-image: url(/vendor-theme/images/alert/error-close.svg);
}
.ant-popover-buttons .ant-btn:nth-child(1) {
  color: #b2bec3;
  border-color: #b2bec3;
  background-color: #f0f3f5;
}
.ant-btn-loading-icon .anticon {
  margin-right: 8px;
}
.da-placement {
  overflow-x: auto;
  overflow-y: hidden;
}
.da-placement .ant-btn {
  text-align: center;
  padding: 0 8px;
  min-width: 82px;
  height: 40px;
}
.ant-message-custom-content .anticon {
  margin-right: 10px;
}
.ant-message-notice-content {
  border-radius: 2px;
}
.ant-message-notice .ant-message-custom-content span:nth-child(2) {
  font-weight: 300;
  color: #636e72;
}
.ant-message-notice .ant-message-loading svg {
  fill: #f27052;
}
.ant-message-notice .ant-message-success svg {
  fill: #00f7bf;
}
.ant-message-notice .ant-message-info svg {
  fill: #1be7ff;
}
.ant-message-notice .ant-message-error svg {
  fill: #ff0022;
}
.ant-message-notice .ant-message-warning svg {
  fill: #ffc212;
}
.ant-message-custom-content.ant-message-rtl .anticon {
  margin-left: 10px;
  margin-right: 0;
}
.ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-modal-close-x svg {
  margin-right: 2px;
}
.popup-modal .ant-modal-body {
  padding-bottom: 24px;
}
html[dir='rtl'] .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-drawer-header-no-title {
  height: 50px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .ant-drawer.da-drawer-mobile .ant-drawer-content-wrapper {
    width: 85% !important;
  }
}
.da-drawer-submit .ant-drawer-content-wrapper {
  max-width: 720px;
}
@media screen and (max-width: 991px) {
  .da-drawer-submit .ant-drawer-content-wrapper {
    max-width: 60%;
  }
}
@media screen and (max-width: 767px) {
  .da-drawer-submit .ant-drawer-content-wrapper {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .da-result-col .da-result-button {
    margin: 0 -20px;
  }
}
.ant-menu-item {
  display: flex;
  align-items: center;
}
.ant-menu-submenu-title {
  display: flex;
  align-items: center;
}
.ant-menu-item-only-child {
  padding-left: 56px;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  text-align: left !important;
}
.ant-menu-vertical .ant-menu-item {
  text-align: center;
}
.ant-menu-submenu-arrow {
  width: 24px;
}
.ant-menu-horizontal .ant-menu-item svg {
  vertical-align: middle;
  margin-top: -2px;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}
.ant-menu-submenu-arrow {
  right: 0;
}
html[dir='rtl'] .ant-menu-inline .ant-menu-item,
html[dir='rtl'] .ant-menu-inline .ant-menu-submenu-title {
  margin-right: 10px;
  margin-left: 0;
}
.ant-dropdown-trigger.ant-dropdown-link {
  display: inline-flex;
  align-items: center;
  color: #f27052;
}
.ant-dropdown-trigger.ant-dropdown-link svg {
  margin-left: 10px;
}
.ant-dropdown-menu {
  padding: 8px 6px;
  border: 1px solid #dfe6e9;
}
.ant-dropdown-menu-submenu-popup {
  border: none;
}
.ant-dropdown-menu-item:hover {
  background-color: #f0e3e0;
}
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f0e3e0;
}
.ant-pagination {
  text-align: center;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #ee502c;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0px 0px 10px rgba(242, 112, 82, 0.349);
}
.ant-pagination-options-quick-jumper input-focused {
  border-color: #ee502c;
  box-shadow: 0px 0px 10px rgba(242, 112, 82, 0.349);
}
.ant-pagination-item {
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ant-pagination-prev {
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ant-pagination-next {
  margin-top: 16px;
  margin-bottom: 16px;
}
.ant-pagination-jump-prev {
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ant-pagination-jump-next {
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ant-pagination-options {
  margin-top: 16px;
  margin-bottom: 16px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ant-pagination.mini .ant-pagination-item {
  margin: 0 4px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  line-height: 28px;
  margin: 0;
  font-size: 14px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 4px;
}
.ant-table-pagination {
  align-items: center;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  border-color: #dfe6e9;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  height: 32px;
  min-width: 32px;
  margin: 0 4px;
}
@media screen and (max-width: 767px) {
  .ant-pagination-item {
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    min-width: 30px;
    height: 30px;
    line-height: 28px;
  }
  .ant-pagination-jump-next {
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ant-pagination-prev {
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ant-pagination-next {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ant-pagination-options {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: 30px;
    height: 30px;
    line-height: 28px;
  }
}
@media screen and (max-width: 767px) {
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    flex: none;
  }
}
.da-grid-row-bg {
  background-image: linear-gradient(90deg, #b2bec3 4.16666667%, transparent 0, transparent 8.33333333%, #b2bec3 0, #b2bec3 12.5%, transparent 0, transparent 16.66666667%, #b2bec3 0, #b2bec3 20.83333333%, transparent 0, transparent 25%, #b2bec3 0, #b2bec3 29.16666667%, transparent 0, transparent 33.33333333%, #b2bec3 0, #b2bec3 37.5%, transparent 0, transparent 41.66666667%, #b2bec3 0, #b2bec3 45.83333333%, transparent 0, transparent 50%, #b2bec3 0, #b2bec3 54.16666667%, transparent 0, transparent 58.33333333%, #b2bec3 0, #b2bec3 62.5%, transparent 0, transparent 66.66666667%, #b2bec3 0, #b2bec3 70.83333333%, transparent 0, transparent 75%, #b2bec3 0, #b2bec3 79.16666667%, transparent 0, transparent 83.33333333%, #b2bec3 0, #b2bec3 87.5%, transparent 0, transparent 91.66666667%, #b2bec3 0, #b2bec3 95.83333333%, transparent 0);
  margin-bottom: 8px;
  overflow: hidden;
}
.da-grid-col {
  border: none;
  color: #ffffff;
  font-size: 18px;
  line-height: 2;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  opacity: 0.75;
  padding: 30px 0px;
}
.da-mark ul > li {
  list-style-type: circle;
  margin-left: 20px;
  padding-left: 4px;
}
.da-lock-screen {
  position: relative;
  min-height: 100vh;
  padding: 150px 30px;
}
.da-lock-screen .da-screen-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}
.da-lock-screen-row {
  max-width: 390px;
  background: rgba(0, 16, 247, 0.3);
  border: 3px solid rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
@media screen and (max-width: 767px) {
  .da-lock-screen-row {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .da-lock-screen {
    padding: 64px 24px;
  }
}
.da-authentication-page {
  position: relative;
  min-height: 100vh;
  margin: 0 !important;
  overflow: hidden;
}
.da-authentication-page .da-logo-item {
  position: absolute;
  top: 0;
  left: 0;
}
.da-authentication-page .da-bg-item {
  transform: translate(0px, -20%);
}
@media screen and (max-width: 991px) {
  .da-authentication-page .da-bg-item {
    margin-top: 120px;
    margin-bottom: 200px !important;
  }
}
@media screen and (max-width: 767px) {
  .da-authentication-page .da-bg-item {
    margin-top: 40px;
    margin-bottom: 70px !important;
  }
}
.da-authentication-page .da-text-item {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media screen and (max-width: 767px) {
  .da-authentication-page .da-text-item {
    position: relative;
    top: 0%;
    left: 0;
    transform: translate(0, 0);
    margin-top: -30%;
  }
}
.da-authentication-page .da-or-line {
  position: relative;
}
.da-authentication-page .da-or-line:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  transform: translate(0, -50%);
  background-color: #dfe6e9;
  z-index: -1;
}
.da-authentication-page .da-other-links a + a {
  position: relative;
  padding-left: 16px;
  margin-left: 16px;
}
.da-authentication-page .da-other-links a + a:before {
  content: '';
  position: absolute;
  top: 49%;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: #636e72;
}
html[dir='rtl'] .da-authentication-page .da-logo-item {
  right: 0;
  left: auto;
}
.da-faq-tabs .ant-tabs-nav {
  margin-bottom: 0;
}
.da-faq-tabs .ant-tabs-nav .ant-tabs-tab-active svg {
  fill: #f27052 !important;
}
.da-faq-tabs .ant-tabs-nav .ant-tabs-tab-active span {
  color: #f27052 !important;
}
.da-faq-tabs .ant-tabs-nav .ant-tabs-tab-active svg,
.da-faq-tabs .ant-tabs-nav .ant-tabs-tab-active span {
  transition: 0.4s;
}
.da-knowledge-basic-card .ant-card-body {
  min-height: 200px;
  align-content: space-between;
  display: block;
}
.da-profile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #dfe6e9;
  min-height: 930px;
}
.da-profile-menu-header .ant-scroll-number {
  bottom: -7px;
  right: 6px;
  top: auto;
  padding: 0 3px;
  height: 16px;
  line-height: 16px;
  z-index: 1;
  transform: translate(0px, 0px);
  box-shadow: none;
}
.da-profile-menu-header .ant-avatar {
  overflow: visible;
  overflow: initial;
}
.da-profile-menu-header .ant-avatar:before {
  content: '';
  position: absolute;
  top: -7px;
  left: -7px;
  right: -7px;
  bottom: -7px;
  background: cconic-gradient(#f27052 68deg, white 36deg 170deg, white 170deg);
  border-radius: 50%;
  pointer-events: none;
}
.da-profile-menu-header .ant-avatar:after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: #ffffff;
  border-radius: 100%;
  pointer-events: none;
}
.da-profile-menu-header .ant-avatar img {
  position: relative;
  z-index: 1;
}
.da-profile-menu-body {
  margin-top: 50px !important;
}
.da-profile-menu-body .is-active .ant-row {
  color: #f27052;
}
.da-profile-menu-body .is-active svg {
  fill: #f27052;
}
@media screen and (max-width: 991px) {
  .da-profile-menu {
    display: none;
  }
}
.da-profile-content-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.da-profile-content-list ul li {
  display: flex;
  align-items: center;
}
.da-profile-content-list ul li > span {
  display: block;
}
.da-profile-content-list ul li > span.listTitle {
  min-width: 200px;
}
@media screen and (max-width: 767px) {
  .da-profile-content-list ul li {
    display: block;
  }
  .da-profile-content-list ul li span.listTitle {
    min-width: 0;
    min-width: initial;
  }
}
.da-profile-notifications {
  max-width: 424px;
}
@media screen and (max-width: 767px) {
  .da-profile-action-btn button {
    width: 100%;
    margin-top: 24px;
  }
}
.da-profile-connect-btn {
  flex: 0 0 360px;
}
.da-profile-connect-btn svg {
  min-width: 14px;
}
@media screen and (max-width: 767px) {
  .da-profile-connect-btn {
    flex: 0 0 100%;
  }
}
.da-profile-mobile-menu-btn {
  display: none;
}
@media screen and (max-width: 991px) {
  .da-profile-mobile-menu-btn {
    display: block;
  }
}
.da-profile-mobile-menu .ant-drawer-header {
  padding: 16px;
  border: none;
}
.da-profile-mobile-menu .ant-drawer-body {
  padding: 0;
}
.da-profile-mobile-menu .da-profile-menu {
  padding: 0 0 35px !important;
  display: block;
  min-height: 0;
  min-height: initial;
}
.da-profile-activity .ant-table-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
html[dir='rtl'] .da-profile-menu {
  border-right: none;
  border-left: 1px solid #dfe6e9;
}
.da-error-content {
  min-height: calc(100vh - 60px);
}
.da-error-content-circle {
  position: absolute;
  top: 40%;
  left: 53%;
  transform: translate(-50%, -50%);
  width: 504px;
  height: 504px;
  border-radius: 100%;
}
@media screen and (max-width: 767px) {
  .da-error-content-circle {
    width: 80vw;
    height: 80vw;
    left: 50%;
  }
}
.da-error-content .da-comingsoon-timer-item {
  min-width: 200px;
}
.da-error-content .da-comingsoon-timer-item span:nth-child(1) {
  font-size: 100px;
  font-weight: 500;
}
@media screen and (max-width: 1199px) {
  .da-error-content .da-comingsoon-timer-item {
    min-width: 16vw;
  }
  .da-error-content .da-comingsoon-timer-item span:nth-child(1) {
    font-size: 8vw;
  }
}
@media screen and (max-width: 767px) {
  .da-error-content .da-comingsoon-timer-item {
    min-width: 18vw;
  }
  .da-error-content .da-comingsoon-timer-item span:nth-child(1) {
    font-size: 11vw;
    margin-bottom: -8px;
  }
  .da-error-content .da-comingsoon-timer-item span:nth-child(2) {
    font-size: 3vw;
  }
}
@media screen and (max-width: 767px) {
  .da-error-content-title {
    font-size: 32px;
  }
}
.da-exchange-card .ant-card-body {
  padding: 0;
}
.da-exchange-card .ant-list-item {
  padding: 16px;
}
.da-exchange-card .ant-list-header {
  padding: 16px 16px 0px;
}
.da-invoice-card p {
  margin: 0;
  color: #636e72;
  line-height: 21px;
}
.da-invoice-card .ant-table-thead > tr > th {
  color: #2d3436;
  font-weight: 500;
  font-size: 12;
  line-height: 18px;
  text-align: left;
  background: none !important;
  border-bottom: none;
  padding: 0px;
}
.da-invoice-card .ant-table-tbody > tr > td {
  border-bottom: none;
  padding: 16px 0px 0px;
}
.da-invoice-card .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #ffffff;
}
@media print {
  body {
    padding: 0;
    margin: 0;
  }
  .ant-layout-sider {
    display: none;
  }
  .ant-layout-header {
    display: none;
  }
  .ant-layout-footer {
    display: none;
  }
  .da-exchange-card {
    display: none;
  }
  .ant-breadcrumb {
    display: none;
  }
  .da-print-none {
    display: none;
  }
  .da-invoice-card {
    border: none;
    padding: 0px;
  }
  .da-print-info {
    max-width: 33%;
  }
  .da-print-checkout {
    max-width: 33%;
  }
}
.da-pricing-item {
  flex: 0 0 19%;
  max-width: 210px;
}
.da-pricing-item-best-button {
  width: 100%;
  height: auto;
  white-space: normal;
  white-space: initial;
  line-height: 1.2;
}
.da-pricing-item-special {
  background-color: #f27052;
  border-color: #f27052 !important;
}
.da-pricing-item-special .da-pricing-item-title,
.da-pricing-item-special .da-pricing-item-price {
  color: #f0e3e0;
}
.da-pricing-item-special .da-pricing-item-subtitle {
  color: #00f7bf !important;
}
.da-pricing-item-special .da-pricing-item-billed,
.da-pricing-item-special .da-pricing-item-list {
  color: #ecaa9b !important;
}
.da-pricing-item-special .da-pricing-item-list svg path {
  fill: #f0e3e0;
}
.da-pricing-item-special button {
  color: #f27052 !important;
  background-color: #f0e3e0 !important;
}
@media screen and (max-width: 1199px) {
  .da-pricing-item {
    flex: 0 0 40%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: normal;
  }
}
@media screen and (max-width: 767px) {
  .da-pricing-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.da-landing-container {
  max-width: 1184px;
  margin: 0 auto;
  padding: 0 32px;
}
@media screen and (max-width: 767px) {
  .da-landing-container {
    padding: 0 16px;
  }
}
.da-landing header .da-landing-header-menu .ant-menu {
  border-bottom: none;
}
.da-landing header .da-landing-header-menu .ant-menu .ant-menu-item:after {
  visibility: hidden;
}
.da-landing header .da-landing-header-mobile-button {
  display: none;
}
@media screen and (max-width: 767px) {
  .da-landing header .da-landing-header-menu {
    display: none !important;
  }
  .da-landing header .da-landing-header-buttons {
    display: none;
  }
  .da-landing header .da-landing-header-mobile-button {
    display: block;
  }
}
.da-landing-hero {
  position: relative;
  overflow: hidden;
}
.da-landing-hero-title {
  max-width: 790px;
  margin: 0 auto;
}
.da-landing-hero-img {
  position: relative;
  max-width: 1100px;
  height: 450px;
  margin: 0 auto;
}
.da-landing-hero-img-container {
  width: 100%;
  height: 100%;
  transform: translate(0px, 10px);
}
.da-landing-hero-img-item {
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  filter: drop-shadow(2px 4px 46px rgba(0, 0, 0, 0.1));
}
.da-landing-hero-img-left {
  position: absolute;
  bottom: 20px;
  left: 6%;
}
.da-landing-hero-img-left .da-landing-hero-img-emoji:nth-child(1) {
  font-size: 40px;
  transform: rotate(28deg);
  margin-bottom: 80px;
  margin-left: 40px;
}
.da-landing-hero-img-left .da-landing-hero-img-emoji:nth-child(2) {
  margin-left: 30px;
  margin-bottom: 0px;
}
.da-landing-hero-img-left .da-landing-hero-img-emoji:nth-child(3) {
  font-size: 40px;
  transform: rotate(28deg);
  margin-bottom: 20px;
  margin-left: -30px;
}
.da-landing-hero-img-right {
  position: absolute;
  bottom: 30px;
  right: 6%;
  text-align: center;
}
.da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(1) {
  font-size: 20px;
  margin-bottom: 60px;
  margin-left: 70px;
}
.da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(2) {
  font-size: 40px;
  margin-bottom: 20px;
  margin-left: -40px;
}
.da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(3) {
  margin-bottom: 30px;
  margin-left: -220px;
}
.da-landing-hero-rectangle {
  border-radius: 50px;
  transform: rotate(-22deg);
  width: 256px;
  height: 256px;
  position: absolute;
  bottom: -100px;
  left: 0;
}
.da-landing-hero-circle {
  width: 282px;
  height: 282px;
  border-radius: 100%;
  position: absolute;
  top: -60px;
  right: -40px;
}
@media screen and (max-width: 1450px) {
  .da-landing-hero-img-left {
    left: 30px;
  }
  .da-landing-hero-img-right {
    right: 30px;
  }
}
@media screen and (max-width: 1199px) {
  .da-landing-hero-img {
    max-width: 80vw;
    height: 360px;
  }
}
@media screen and (max-width: 991px) {
  .da-landing-hero-title {
    max-width: 580px;
  }
  .da-landing-hero-img {
    max-width: 100%;
    height: 42vw;
  }
  .da-landing-hero-img-left,
  .da-landing-hero-img-right {
    transform: scale(0.7);
    transform-origin: bottom;
  }
  .da-landing-hero-img-left {
    left: 0;
  }
  .da-landing-hero-img-right {
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .da-landing-hero-rectangle {
    width: 126px;
    height: 126px;
    bottom: 10px;
    left: 10px;
  }
  .da-landing-hero-circle {
    width: 122px;
    height: 122px;
    right: 10px;
    top: -30px;
  }
  .da-landing-hero-title {
    max-width: 100%;
  }
  .da-landing-hero-title p {
    font-size: 18px;
  }
  .da-landing-hero-img {
    max-width: 100%;
    height: 42vw;
  }
  .da-landing-hero-img-left,
  .da-landing-hero-img-right {
    transform: scale(0.5);
    bottom: 10px;
  }
  .da-landing-hero-img-left .da-landing-hero-img-emoji:nth-child(1) {
    margin-bottom: 0;
  }
  .da-landing-hero-img-left .da-landing-hero-img-emoji:nth-child(3) {
    margin-left: 0px;
  }
  .da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(1) {
    margin-bottom: 20px;
    margin-left: 40px;
  }
  .da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(3) {
    margin-left: 30px;
  }
}
.da-landing-features .slick-slider:before,
.da-landing-features .slick-slider:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 180px;
  background: linear-gradient(to left, #ffffff, #ffffff00);
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .da-landing-features .slick-slider:before,
  .da-landing-features .slick-slider:after {
    width: 50px;
  }
}
.da-landing-features .slick-slider:before {
  left: 0;
  right: auto;
  transform: rotate(180deg);
}
.da-landing-features .slick-slider:after {
  right: 0;
  left: auto;
}
.da-landing-features .slick-list {
  padding-left: 230px;
}
@media screen and (max-width: 1370px) {
  .da-landing-features .slick-list {
    padding-left: 150px;
  }
}
@media screen and (max-width: 1199px) {
  .da-landing-features .slick-list {
    padding-left: 80px;
  }
}
@media screen and (max-width: 767px) {
  .da-landing-features .slick-list {
    padding-left: 24px;
  }
}
.da-landing-content-1 {
  padding-top: 160px;
}
.da-landing-content-1 .da-landing-container {
  max-width: 75vw;
}
.da-landing-content-1-circle {
  width: 612px;
  height: 612px;
  border-radius: 100%;
  margin-left: auto;
}
.da-landing-content-1-img {
  position: absolute;
  right: -152px;
  top: 58%;
  transform: translate(0px, -50%);
}
.da-landing-content-1-img img {
  max-width: 880px;
}
@media screen and (max-width: 1450px) {
  .da-landing-content-1 .da-landing-container {
    max-width: 83vw;
  }
}
@media screen and (max-width: 1199px) {
  .da-landing-content-1-img img {
    max-width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .da-landing-content-1 .da-landing-container {
    max-width: 100%;
  }
  .da-landing-content-1 .da-landing-container > .ant-row {
    justify-content: center;
  }
  .da-landing-content-1-circle {
    width: 500px;
    height: 500px;
  }
  .da-landing-content-1-img {
    right: -40px;
    text-align: right;
  }
  .da-landing-content-1-img img {
    max-width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .da-landing-content-1-circle {
    width: 300px;
    height: 300px;
  }
  .da-landing-content-1 h3 {
    font-size: 18px;
    line-height: normal;
  }
}
.da-landing-content-2 {
  padding-top: 160px;
}
.da-landing-content-2-circle {
  width: 504px;
  height: 504px;
  border-radius: 100%;
}
.da-landing-content-2-circle img {
  max-width: 770px;
  top: 56%;
  left: 63%;
}
@media screen and (max-width: 1199px) {
  .da-landing-content-2-circle {
    width: 304px;
    height: 304px;
    margin: 0 auto 200px;
  }
  .da-landing-content-2-circle img {
    max-width: 600px;
    top: 56%;
    left: 63%;
  }
}
@media screen and (max-width: 767px) {
  .da-landing-content-2-circle {
    margin: 0 auto 130px;
  }
  .da-landing-content-2-circle img {
    max-width: 140%;
    left: 55%;
  }
}
.da-landing-content-2-list {
  max-width: 360px;
}
@media screen and (max-width: 1199px) {
  .da-landing-content-2 .da-landing-container > .ant-row {
    justify-content: center;
  }
}
.da-landing-people-circle {
  width: 542px;
  height: 542px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -60%);
}
@media screen and (max-width: 767px) {
  .da-landing-people-circle {
    width: 342px;
    height: 342px;
  }
}
.da-landing-people-item {
  min-height: 200px;
}
.da-landing-people-item-twitter {
  position: absolute;
  top: 16px;
  right: 20px;
}
.da-landing-people-item-img {
  margin-left: -10px;
  margin-right: 10px;
}
@media screen and (max-width: 991px) {
  .da-landing-people-item-title {
    flex: 0 0 100%;
  }
}
.da-landing-pricing-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 450px;
}
@media screen and (max-width: 767px) {
  .da-landing-pricing-item {
    min-height: 0;
    min-height: initial;
  }
}
.da-landing-footer-circle {
  width: 255px;
  height: 255px;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
}
@media screen and (max-width: 767px) {
  .da-landing-footer-circle {
    width: 200px;
    height: 200px;
  }
  .da-landing-footer-copyright {
    text-align: center;
  }
  .da-landing-footer-copyright > .ant-col {
    flex: 100% 1;
  }
  .da-landing-footer-copyright > .ant-col .ant-row {
    justify-content: center;
  }
}
html[dir='rtl'] .da-landing-hero-img-left {
  left: auto;
  right: 6%;
}
html[dir='rtl'] .da-landing-hero-img-left .da-landing-hero-img-emoji:nth-child(1) {
  margin-right: 40px;
  margin-left: 0px;
}
html[dir='rtl'] .da-landing-hero-img-left .da-landing-hero-img-emoji:nth-child(2) {
  margin-right: 30px;
  margin-left: 0px;
}
html[dir='rtl'] .da-landing-hero-img-left .da-landing-hero-img-emoji:nth-child(3) {
  margin-right: -30px;
  margin-left: 0px;
}
html[dir='rtl'] .da-landing-hero-img-right {
  left: 6%;
  right: auto;
}
html[dir='rtl'] .da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(1) {
  margin-right: 70px;
  margin-left: 0px;
}
html[dir='rtl'] .da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(2) {
  margin-right: -40px;
  margin-left: 0px;
}
html[dir='rtl'] .da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(3) {
  margin-right: -220px;
  margin-left: 0px;
}
@media screen and (max-width: 1450px) {
  html[dir='rtl'] .da-landing-hero-img-left {
    right: 30px;
  }
  html[dir='rtl'] .da-landing-hero-img-right {
    left: 30px;
  }
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-landing-hero-img-left {
    right: 0;
  }
  html[dir='rtl'] .da-landing-hero-img-right {
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  html[dir='rtl'] .da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(1) {
    margin-right: 40px;
  }
  html[dir='rtl'] .da-landing-hero-img-right .da-landing-hero-img-emoji:nth-child(3) {
    margin-right: 30px;
  }
}
html[dir='rtl'] .da-landing-content-1-circle {
  margin-right: auto;
  margin-left: 0;
}
html[dir='rtl'] .da-landing-content-1-img {
  left: -152px;
  right: auto;
  transform: translate(0px, -50%) scale(-1, 1);
}
@media screen and (max-width: 991px) {
  html[dir='rtl'] .da-landing-content-1-img {
    left: -40px;
    right: auto;
    text-align: left;
  }
}
html[dir='rtl'] .da-landing-people-circle {
  left: 0;
  right: auto;
  transform: translate(-50%, -60%);
}
html[dir='rtl'] .da-landing-people-item-twitter {
  left: 20px;
  right: auto;
}
html[dir='rtl'] .da-landing-people-item-img {
  margin-right: -10px;
  margin-left: 10px;
}
html[dir='rtl'] .da-landing-footer-circle {
  right: 0;
  transform: translate(50%, 50%);
}
.da-contact-card .ant-card-body {
  padding: 0;
}
.da-contact-card .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #f0e3e0;
}
.da-contact-card .ant-table-thead > tr > th {
  background: none !important;
  border-bottom: none;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding: 14px 32px 32px;
}
.da-contact-card .ant-table-thead > tr > th:nth-child(2),
.da-contact-card .ant-table-thead > tr > th:nth-child(6) {
  min-width: 200px;
}
.da-contact-card .ant-table-thead > tr > th:before {
  display: none;
}
.da-contact-card .ant-table-tbody > tr > td {
  border-bottom: none;
  padding: 14px 32px;
}
.da-contact-card .ant-table-tbody > tr > td:first-child {
  text-align: center;
}
.da-contact-card .ant-pagination {
  margin: 0 32px;
}
@media screen and (max-width: 767px) {
  .da-contact-card .ant-pagination {
    margin: 0 16px;
  }
}
.da-contact-detail .ant-card-body {
  padding: 0;
  border-radius: 7px;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .da-contact-detail .ant-layout {
    border-radius: 12px;
    overflow: hidden;
  }
  .da-contact-detail .ant-layout .ant-layout-sider {
    flex: none !important;
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }
  .da-contact-detail .ant-layout .ant-layout-sider .da-footer-img {
    display: none;
  }
  .da-contact-detail .ant-layout .ant-layout-content {
    width: 100% !important;
  }
}
.da-calendar .ant-card-body {
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 767px) {
  .da-calendar .fc .fc-view-harness {
    height: auto !important;
    min-height: 800px;
  }
}
.da-calendar .fc .fc-popover {
  z-index: 999;
}
.da-calendar .fc .fc-dayGridMonth-view .fc-scroller-liquid-absolute {
  position: relative;
}
.da-calendar .fc-theme-standard th {
  border-top: none;
  border-left: none;
  border-right: none;
}
.da-calendar .fc-theme-standard .fc-scrollgrid {
  border-top: none;
  border-left: none;
}
.da-calendar .fc tbody .fc-scroller-harness {
  border-left: 1px solid #dfe6e9;
}
.da-calendar .fc-theme-standard td,
.da-calendar .fc-theme-standard th {
  border-color: #dfe6e9;
}
.da-calendar .fc .fc-scrollgrid-section > td {
  border-bottom: none;
}
.da-calendar .fc .fc-cell-shaded,
.da-calendar .fc .fc-day-disabled {
  background: #dfe6e9;
}
.da-calendar .fc .fc-toolbar-title {
  line-height: normal;
  margin-top: -9px;
  margin-left: 14px;
}
.da-calendar .fc .fc-theme-standard td {
  border: 1px solid #dfe6e9;
}
.da-calendar .fc .fc-theme-standard .fc-scrollgrid {
  border: none;
}
.da-calendar .fc .fc-theme-standard th {
  border: none;
  text-align: left;
}
.da-calendar .fc .fc-col-header-cell-cushion {
  color: #2d3436;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
}
.da-calendar .fc .fc-scrollgrid-section-liquid > td {
  box-shadow: none;
}
.da-calendar .fc .fc-scrollgrid-section > * {
  padding: 0;
}
.da-calendar .fc .fc-scrollgrid-section-header > td {
  border: none !important;
}
.da-calendar .fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}
.da-calendar .fc .fc-day-other .fc-daygrid-day-top a {
  color: #b2bec3;
}
.da-calendar .fc .fc-day-other .fc-daygrid-day-frame {
  background: #f0f3f5;
  border: 1px solid #f0f0f0;
}
.da-calendar .fc .fc-daygrid-day-number {
  color: #2d3436;
  padding: 5px;
  display: block;
}
.da-calendar .fc .fc-dayGridMonth-view .fc-daygrid-day.fc-day-today {
  background: none;
  border-bottom: 2px solid #f27052;
}
.da-calendar .fc .fc-daygrid-day-top {
  display: block;
}
.da-calendar .fc .fc-button {
  background: #ffffff;
  border-color: #f27052;
  color: #f27052;
  border-radius: 7px;
  padding: 8px 24px;
  font-size: 15px;
  line-height: normal;
  text-transform: capitalize;
}
.da-calendar .fc .fc-button-primary:not(:disabled):active,
.da-calendar .fc .fc-button-primary:not(:disabled).fc-button-active {
  background: #f0e3e0;
  color: #f27052;
  border-color: #f27052;
}
.da-calendar .fc .fc-button.fc-prev-button,
.da-calendar .fc .fc-button.fc-next-button {
  display: flex;
  align-items: center;
  margin-top: -6px;
  padding: 4px 8px;
  border: none;
  color: #001102;
}
.da-calendar .fc .fc-button .fc-icon {
  vertical-align: baseline;
  vertical-align: initial;
}
.da-calendar .fc .fc-daygrid-event {
  margin-bottom: 8px;
}
.da-calendar .fc .fc-button-primary:hover {
  border-color: #f27052;
  background-color: #f0e3e0;
  color: #f27052;
}
.da-calendar .fc .fc-button-primary:focus {
  box-shadow: none !important;
}
.da-calendar .fc .fc-button.fc-prev-button span {
  margin-left: -3px;
}
.da-calendar .fc .fc-button.fc-next-button span {
  margin-right: -3px;
}
.da-calendar .fc-h-event {
  background: #f0e3e0;
  border-radius: 100px;
  border-color: #f27052;
  font-size: 11px;
  padding: 0px;
  line-height: normal;
}
.da-calendar .fc-h-event.bg-light-travel {
  background-color: #fdeffc;
  border-color: #c903ff;
}
.da-calendar .fc-h-event.bg-light-travel .fc-event-main-frame:before {
  background-color: #c903ff;
}
.da-calendar .fc-h-event.bg-light-social {
  background-color: #eafff8;
  border-color: #00f7bf;
}
.da-calendar .fc-h-event.bg-light-social .fc-event-main-frame:before {
  background-color: #00f7bf;
}
.da-calendar .fc-h-event.bg-light-work {
  background-color: #fff9e9;
  border-color: #ffc212;
}
.da-calendar .fc-h-event.bg-light-work .fc-event-main-frame:before {
  background-color: #ffc212;
}
.da-calendar .fc-h-event.bg-light-important {
  background-color: #ffe7ea;
  border-color: #ff0022;
}
.da-calendar .fc-h-event.bg-light-important .fc-event-main-frame:before {
  background-color: #ff0022;
}
.da-calendar .fc-h-event .fc-event-title {
  color: #636e72;
}
.da-calendar .fc-h-event .fc-event-main-frame {
  position: relative;
  align-items: center;
  height: 16px;
}
.da-calendar .fc-h-event .fc-event-main-frame:before {
  content: '';
  display: block;
  min-width: 10px;
  height: 10px;
  background: #f27052;
  border-radius: 100%;
  margin-right: 6px;
  margin-left: 3px;
}
.da-calendar .fc-h-event .fc-event-title-container {
  line-height: normal;
  margin-top: -2px;
}
.da-calendar .fc-direction-ltr .fc-daygrid-event .fc-event-time {
  color: #636e72;
  line-height: normal;
  margin-top: -2px;
}
.da-calendar .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk > div {
  display: flex;
  align-items: center;
}
.da-calendar .fc-button.fc-next-button {
  margin-left: 5px;
}
.da-calendar .fc-timeGridWeek-view .fc-day-today .fc-col-header-cell-cushion {
  color: #f27052;
}
.da-calendar .fc-media-screen .fc-timegrid-event {
  background: rgba(235, 250, 250, 0.8);
  border: none;
}
.da-calendar .fc-timegrid-event .fc-event-main {
  color: #f27052;
  padding: 16px 8px;
}
.da-calendar .fc-timeGridWeek-view .fc-timegrid-col.fc-day-today {
  background: #f7fafc !important;
}
.da-calendar .fc-timegrid-slot {
  height: 32px;
}
.da-calendar .fc-timegrid-axis-cushion {
  color: #b2bec3;
  text-transform: capitalize;
}
.da-calendar table.fc-col-header {
  border: none !important;
}
.da-calendar .fc-timeGridWeek-view .fc-daygrid-day.fc-day-today {
  border: none !important;
  background: none;
}
.da-calendar .fc-timeGridDay-view .fc-scrollgrid-sync-inner {
  text-align: center;
}
.da-calendar .fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: center;
  padding: 8px 0px;
}
.da-calendar .fc-daygrid-day.fc-day-today {
  background: none !important;
}
.da-calendar .fc .fc-timegrid-col.fc-day-today {
  background: none;
}
@media screen and (max-width: 1199px) {
  .da-calendar .ant-layout-sider {
    flex: 0 0 180px !important;
    max-width: none !important;
    max-width: initial !important;
    min-width: 0 !important;
    min-width: initial !important;
    width: auto !important;
  }
}
@media screen and (max-width: 991px) {
  .da-calendar .ant-layout-sider {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .da-calendar .fc .fc-toolbar {
    flex-wrap: wrap;
    width: 100%;
  }
  .da-calendar .fc .fc-toolbar .fc-toolbar-chunk {
    width: 100%;
  }
  .da-calendar .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk > div {
    width: 100%;
  }
  .da-calendar .fc .fc-toolbar .fc-toolbar-chunk:first-child {
    margin-bottom: 20px;
  }
  .da-calendar .fc .fc-button.fc-prev-button,
  .da-calendar .fc .fc-button.fc-next-button {
    position: absolute;
    top: 22px;
  }
  .da-calendar .fc .fc-button.fc-prev-button {
    right: 40px;
    left: auto;
  }
  .da-calendar .fc .fc-button.fc-next-button {
    right: 0;
  }
  .da-calendar .fc .fc-toolbar-title {
    font-size: 18px;
    margin-top: -4px;
    margin-left: 0;
  }
}
.da-calendar-mobile-menu {
  display: none;
}
.da-calendar-mobile-menu .ant-drawer-header {
  border: none;
  padding: 0;
  height: 40px;
}
.da-calendar-mobile-menu .ant-drawer-close {
  padding: 10px;
}
.da-calendar-mobile-menu .da-calendar-menu-footer {
  display: none;
}
@media screen and (max-width: 991px) {
  .da-calendar-mobile-menu {
    display: block;
  }
}
.da-calendar-mobile-menu-btn {
  display: none;
}
@media screen and (max-width: 991px) {
  .da-calendar-mobile-menu-btn {
    display: block;
  }
}
.da-calendar-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.da-calendar-checkbox-list .ant-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.da-calendar-checkbox-list .ant-checkbox + span {
  position: relative;
  padding-left: 18px;
}
.da-calendar-checkbox-list .ant-checkbox .ant-checkbox-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  border: none !important;
  background: none !important;
  outline: none !important;
}
.da-calendar-checkbox-list .ant-checkbox .ant-checkbox-inner:before {
  content: '';
  position: absolute;
  top: 1px;
  left: -5px;
  right: 0px;
  bottom: -5px;
  border-radius: 100px;
  width: 0;
  transition: all 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}
.da-calendar-checkbox-list .ant-checkbox .ant-checkbox-inner:after {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 100%;
  opacity: 1;
  top: 70%;
  left: 0;
  transform: translate(0%, -50%);
}
.da-calendar-checkbox-list .ant-checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
}
.da-calendar-checkbox-list .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.da-calendar-checkbox-list .ant-checkbox-checked::after {
  display: none;
}
.da-calendar-checkbox-list .ant-checkbox-input:checked ~ .ant-checkbox-inner:before {
  width: 110%;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='none'] ~ .ant-checkbox-inner:before {
  background: #f0e3e0;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='none'] ~ .ant-checkbox-inner:after {
  background: #f27052;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='travel'] ~ .ant-checkbox-inner:before {
  background: #fdeffc;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='travel'] ~ .ant-checkbox-inner:after {
  background: #c903ff;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='social'] ~ .ant-checkbox-inner:before {
  background: #eafff8;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='social'] ~ .ant-checkbox-inner:after {
  background: #00f7bf;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='work'] ~ .ant-checkbox-inner:before {
  background: #fff9e9;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='work'] ~ .ant-checkbox-inner:after {
  background: #ffc212;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='important'] ~ .ant-checkbox-inner:before {
  background: #ffe7ea;
}
.da-calendar-checkbox-list .ant-checkbox-input[data-color='important'] ~ .ant-checkbox-inner:after {
  background: #ff0022;
}
.flatpickr-wrapper {
  width: 100%;
}
.flatpickr-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 15px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  transition: all 0.3s;
  outline: none;
}
.select__control.select__control--is-focused,
.react-select__control.select__control--is-focused,
.select__control.react-select__control--is-focused,
.react-select__control.react-select__control--is-focused {
  box-shadow: none;
  border-color: #f27052;
}
.select__control .select__indicator svg,
.react-select__control .select__indicator svg {
  cursor: pointer;
}
.select__control .select__indicator-separator,
.react-select__control .select__indicator-separator {
  display: none;
}
.select__control .select__single-value,
.react-select__control .select__single-value {
  color: #636e72;
}
.select__control .select__placeholder,
.react-select__control .select__placeholder {
  color: #636e72;
}
.select__menu .select__menu-list .select__option,
.react-select__menu .select__menu-list .select__option,
.select__menu .react-select__menu-list .select__option,
.react-select__menu .react-select__menu-list .select__option,
.select__menu .select__menu-list .react-select__option,
.react-select__menu .select__menu-list .react-select__option,
.select__menu .react-select__menu-list .react-select__option,
.react-select__menu .react-select__menu-list .react-select__option {
  cursor: pointer;
}
.select__menu .select__menu-list .select__option.select__option--is-focused,
.react-select__menu .select__menu-list .select__option.select__option--is-focused,
.select__menu .react-select__menu-list .select__option.select__option--is-focused,
.react-select__menu .react-select__menu-list .select__option.select__option--is-focused,
.select__menu .select__menu-list .react-select__option.select__option--is-focused,
.react-select__menu .select__menu-list .react-select__option.select__option--is-focused,
.select__menu .react-select__menu-list .react-select__option.select__option--is-focused,
.react-select__menu .react-select__menu-list .react-select__option.select__option--is-focused {
  background-color: #f0e3e0;
  color: #f27052;
}
.select__menu .select__menu-list .select__option.select__option--is-selected,
.react-select__menu .select__menu-list .select__option.select__option--is-selected,
.select__menu .react-select__menu-list .select__option.select__option--is-selected,
.react-select__menu .react-select__menu-list .select__option.select__option--is-selected,
.select__menu .select__menu-list .react-select__option.select__option--is-selected,
.react-select__menu .select__menu-list .react-select__option.select__option--is-selected,
.select__menu .react-select__menu-list .react-select__option.select__option--is-selected,
.react-select__menu .react-select__menu-list .react-select__option.select__option--is-selected {
  background-color: #f0e3e0;
  color: #f27052;
}
.select__menu .select__menu-list .select__group .select__group-heading,
.react-select__menu .select__menu-list .select__group .select__group-heading,
.select__menu .react-select__menu-list .select__group .select__group-heading,
.react-select__menu .react-select__menu-list .select__group .select__group-heading {
  margin-bottom: 0.5rem;
  font-weight: bolder;
  font-size: inherit;
}
.select__multi-value,
.react-select__multi-value {
  color: #ffffff;
  border-radius: 3px;
  margin: 0 0.7rem 0 0;
}
.select__multi-value .select__multi-value__label,
.react-select__multi-value .select__multi-value__label {
  color: #ffffff;
  font-size: 0.85rem;
  border-radius: 7px;
  padding: 0.26rem 0.6rem;
}
.select__multi-value .select__multi-value__remove,
.react-select__multi-value .select__multi-value__remove {
  padding-left: 0;
  padding-right: 0.5rem;
}
.select__multi-value .select__multi-value__remove:hover,
.react-select__multi-value .select__multi-value__remove:hover {
  background-color: inherit;
  color: inherit;
}
.select__multi-value .select__multi-value__remove svg,
.react-select__multi-value .select__multi-value__remove svg {
  height: 0.85rem;
  width: 0.85rem;
}
.select__multi-value .select__multi-value__remove svg:hover,
.react-select__multi-value .select__multi-value__remove svg:hover {
  cursor: pointer;
}
.select-borderless .select__control {
  border: 0;
}
.select-borderless .select__control .select__indicators {
  display: none;
}
.dark-layout .select__control .select__indicator svg {
  fill: #f7fafc;
}
.dark-layout .select__control .select__indicator span,
.dark-layout .select__control .select__single-value {
  color: #f7fafc;
}
.dark-layout .select__control .select__multi-value .select__multi-value__label {
  color: #f27052;
}
.dark-layout .select__control .select__multi-value__remove svg {
  fill: #f27052;
}
.dark-layout .select__control .select__multi-value__remove:hover {
  background-color: transparent !important;
}
.dark-layout .select__control.select__control--is-disabled {
  opacity: 0.5;
}
.da-ecommerce-app .slick-track {
  margin: 0;
  display: flex;
  align-items: center;
}
.da-ecommerce-app-sidebar .ant-collapse {
  border: none;
  width: 100%;
}
.da-ecommerce-app-sidebar .ant-collapse .ant-collapse-item {
  border: none;
}
.da-ecommerce-app-sidebar .ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 0 !important;
}
.da-ecommerce-app-sidebar .ant-collapse .ant-collapse-item .ant-collapse-content {
  border: none;
}
.da-ecommerce-app-sidebar .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 0 !important;
}
.da-ecommerce-app-sidebar-mobile {
  display: none;
}
.da-ecommerce-app-sidebar-mobile-btn {
  display: none;
}
@media screen and (max-width: 991px) {
  .da-ecommerce-app-sidebar {
    flex: 0 0 100% !important;
    display: none;
  }
  .da-ecommerce-app-sidebar-mobile {
    display: block;
  }
  .da-ecommerce-app-sidebar-mobile .da-ecommerce-app-sidebar {
    display: block;
    padding: 0 !important;
  }
  .da-ecommerce-app-sidebar-mobile .da-ecommerce-app-sidebar > .ant-row {
    border: none;
    padding: 0 !important;
  }
  .da-ecommerce-app-sidebar-mobile-btn {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .da-ecommerce-app-header-search {
    flex: 0 0 100% !important;
  }
  .da-ecommerce-app-header-select {
    flex: 1 0 !important;
  }
}
.da-ecommerce-app-content .da-eCommerceCardOne img {
  max-height: auto;
}
.da-ecommerce-app-content .da-eCommerceCardOne .ant-card-body {
  padding: 16px;
}
.da-ecommerce-app-content .da-eCommerceCardOne .ant-rate {
  font-size: 15px;
}
.da-ecommerce-app-content .da-eCommerceCardOne .ant-rate .ant-rate-star:not(:last-child) {
  margin-right: 5px;
}
.da-ecommerce-app-content .da-eCommerceCardOne-large .ant-card-body {
  padding: 32px;
  min-height: 270px;
}
@media screen and (max-width: 767px) {
  .da-ecommerce-app-content .da-eCommerceCardOne-large .ant-card-body {
    padding: 16px;
  }
}
.da-ecommerce-app-content .da-eCommerceCardOne-wishlist .ant-card-body {
  padding: 24px;
}
@media screen and (max-width: 767px) {
  .da-ecommerce-app-content .da-eCommerceCardOne-wishlist .ant-card-body {
    padding: 16px;
  }
}
.da-ecommerce-app-content .ant-list-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
}
.da-ecommerce-app-content .ant-pagination {
  text-align: right;
}
.da-ecommerce-app-content .ant-pagination > li {
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  background: #fff0;
}
.da-ecommerce-app-content .ant-pagination > li .ant-pagination-item-link {
  background: #fff0;
}
.da-ecommerce-app-content .ant-pagination > li .ant-select .ant-select-selector {
  height: 32px;
  display: flex;
  align-items: center;
}
.da-ecommerce-app-content .ant-pagination > li .ant-pagination-options-quick-jumper {
  height: 100%;
  line-height: normal;
}
.da-ecommerce-app-content .ant-pagination > li .ant-pagination-options-quick-jumper input {
  height: 100%;
}
@media screen and (max-width: 991px) {
  .da-ecommerce-app-content .ant-pagination {
    text-align: center;
  }
}
.da-ecommerce-app-detail-slider img {
  max-height: 600px;
  padding: 10px;
}
.da-ecommerce-app-detail-slider .slick-slider:nth-child(2) {
  width: 80%;
  margin: 0 auto;
}
.da-ecommerce-app-detail-slider .slick-slider:nth-child(2) .slick-slide .da-slick-slide-item > div {
  width: 75px;
  height: 75px;
}
.da-ecommerce-app-detail-slider .slick-slider:nth-child(2) .slick-slide.slick-current .da-slick-slide-item > div {
  border-color: #636e72 !important;
}
@media screen and (max-width: 767px) {
  .da-ecommerce-app-detail-slider .slick-slider:nth-child(2) {
    width: 100%;
  }
}
.da-ecommerce-app-detail-other-slider .slick-slider {
  margin: 0 -12px;
}
.da-ecommerce-app-detail-other-slider .slick-slider .slick-track {
  width: 100%;
}
.da-ecommerce-app-detail-other-slider .slick-slider .da-other-slide-prev-arrow,
.da-ecommerce-app-detail-other-slider .slick-slider .da-other-slide-next-arrow {
  position: absolute;
  top: -56px;
  right: 12px;
  left: auto;
  bottom: auto;
}
.da-ecommerce-app-detail-other-slider .slick-slider .da-other-slide-prev-arrow {
  right: 59px;
}
@media screen and (max-width: 767px) {
  .da-ecommerce-app-checkout-item-img {
    flex: 0 0 100% !important;
  }
}
@media screen and (max-width: 767px) {
  .da-ecommerce-app-checkout-item-text {
    flex: 0 0 100% !important;
  }
}
@media screen and (max-width: 767px) {
  .da-ecommerce-app-checkout-info .ant-row {
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 767px) {
  .da-ecommerce-app-checkout-title-table {
    display: none;
  }
}
.apexcharts-canvas .apexcharts-tooltip {
  color: #636e72;
}
.apexcharts-canvas .apexcharts-toolbar .apexcharts-menu .apexcharts-menu-item {
  color: #636e72;
  background-color: #f27052;
}
.apexcharts-canvas .apexcharts-xaxistooltip-text {
  font-family: 'Manrope', sans-serif !important;
  color: #636e72 !important;
}
.apexcharts-canvas .apexcharts-text,
.apexcharts-canvas .apexcharts-datalabel {
  font-family: "Dosis", Arial, Helvetica, sans-serif !important;
}
.apexcharts-canvas .apexcharts-tooltip-text {
  font-family: "Dosis", Arial, Helvetica, sans-serif !important;
}
.apexcharts-canvas .da-donut-chart .apexcharts-tooltip {
  color: #ffffff !important;
}
.da-analytics-visiters-chart {
  height: 402px;
}
.da-analytics-visiters-chart .apexcharts-text {
  fill: #b2bec3 !important;
}
.da-chart-text-color .apexcharts-text {
  fill: #b2bec3 !important;
}
.apexcharts-xaxistooltip {
  color: #ffffff !important;
}
.apexcharts-toolbar {
  z-index: 10;
}
.da-heatmap-chart .apexcharts-legend.position-bottom.apexcharts-align-center,
.da-heatmap-chart .apexcharts-legend.position-top.apexcharts-align-center {
  justify-content: space-between !important;
  padding: 0px 50px;
}
html[dir='rtl'] .apexcharts-legend-marker {
  margin-left: 3px;
  margin-right: 0;
}
.da-card-1 {
  min-height: 120px;
}
.da-card-2 {
  min-height: 202px;
}
.da-card-3 {
  min-height: 272px;
}
.da-card-4 {
  min-height: 342px;
}
.da-card-5 {
  min-height: 352px;
}
.da-card-6 {
  min-height: 424px;
}
.da-card-7 {
  min-height: 576px;
}
.da-card-8 {
  min-height: 656px;
}
#market-place-chart {
  min-height: 184px !important;
}
#visit-customer-donut-card {
  min-height: 184px !important;
}
#revenue-column-card {
  min-height: 315px;
}
#revenue-radar-card {
  min-height: 336px;
}
#visiters-line-card {
  min-height: 315px;
  margin-left: -10px;
  margin-right: -10px;
}
#site-trafic-radar-card {
  min-height: 472px;
}
.da-statistic-icon-bg {
  padding: 12px;
  max-height: 48px;
  border-radius: 50%;
  margin-right: 8px;
}
.da-categoriesOfTheWeek .da-img-container {
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 7px;
  margin-right: 8px;
}
.da-eCommerceCardOne img {
  max-height: 200px;
}
.da-eCommerceCardOne .ant-rate.da-rate {
  width: 32px !important;
  height: 32px !important;
  font-size: 16px;
  line-height: 0;
}
.da-eCommerceCardOne-text-overlay {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.da-bestSellingCategories .da-img-container {
  width: 48px;
  height: 48px;
  text-align: center;
  border-radius: 7px;
  margin-right: 8px;
}
.da-welcomeCard {
  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 100%;
}
.da-welcomeCard .da-dot-1 {
  width: 250px;
  height: 250px;
  position: absolute;
  z-index: -1;
  opacity: 0.5;
  top: -58%;
  left: -41%;
  border-radius: 50%;
}
@media screen and (max-width: 1199px) {
  .da-welcomeCard .da-dot-1 {
    left: -20%;
  }
}
@media screen and (max-width: 575px) {
  .da-welcomeCard .da-dot-1 {
    left: -40%;
  }
}
.da-welcomeCard .da-img-1 {
  position: absolute;
  top: 50%;
  transform: translate(30%, -50%);
  right: 0;
}
@media screen and (max-width: 575px) {
  .da-welcomeCard .da-img-1 {
    display: none;
  }
}
.da-welcomeCard .da-img-2 {
  position: absolute;
  top: 50%;
  transform: translate(30%, -50%);
  right: 0;
}
@media screen and (max-width: 575px) {
  .da-welcomeCard .da-img-2 {
    display: none;
  }
}
.da-welcomeCard .da-img-3 {
  position: absolute;
  top: 50%;
  transform: translate(30%, -50%);
  right: 0;
}
@media screen and (max-width: 575px) {
  .da-welcomeCard .da-img-3 {
    display: none;
  }
}
.da-welcomeCard .da-img-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -14%;
}
@media screen and (max-width: 1199px) {
  .da-welcomeCard .da-img-4 {
    right: -10%;
  }
}
@media screen and (max-width: 575px) {
  .da-welcomeCard .da-img-4 {
    display: none;
  }
}
.da-welcomeCard .da-img-5 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -14%;
}
@media screen and (max-width: 1199px) {
  .da-welcomeCard .da-img-5 {
    right: -10%;
  }
}
@media screen and (max-width: 575px) {
  .da-welcomeCard .da-img-5 {
    display: none;
  }
}
.da-upgradePlanCardOne {
  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .da-upgradePlanCardOne-bg {
    background-position: 530px center !important;
  }
  .da-upgradePlanCardOne .ant-col {
    flex: 0 0 100% !important;
  }
}
.da-upgradePlanCardOne .da-dot-1 {
  width: 250px;
  height: 250px;
  position: absolute;
  z-index: -1;
  bottom: -90%;
  left: -20%;
  border-radius: 50%;
}
@media screen and (max-width: 575px) {
  .da-upgradePlanCardOne .da-dot-1 {
    display: none;
  }
}
.da-upgradePlanCardOne .da-dot-2 {
  width: 250px;
  height: 250px;
  position: absolute;
  z-index: -2;
  top: -105%;
  right: -21%;
  border-radius: 50%;
}
@media screen and (max-width: 575px) {
  .da-upgradePlanCardOne .da-dot-2 {
    left: -40%;
  }
}
.da-upgradePlanCardOne .da-dot-3 {
  width: 250px;
  height: 250px;
  position: absolute;
  z-index: -1;
  top: -58%;
  right: -29%;
  border-radius: 50%;
}
@media screen and (max-width: 1599px) {
  .da-upgradePlanCardOne .da-dot-3 {
    right: -41%;
  }
}
@media screen and (max-width: 575px) {
  .da-upgradePlanCardOne .da-dot-3 {
    left: -40%;
    display: none;
  }
}
.da-social-media-logo-wrapper {
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: center;
  border-radius: 7px;
  position: relative;
}
.da-social-media-logo-wrapper .da-social-media-logo {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.da-project-table-card.da-analytics-project-table-card .ant-table-tbody > tr > td {
  padding-top: 8px;
  padding-bottom: 8px;
}
.da-project-table-card .da-img-container {
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 7px;
  margin-right: 8px;
}
.da-project-table-card .ant-table-thead > tr > th {
  text-align: left;
  background: none !important;
  border-bottom: none;
}
.da-project-table-card .ant-table-thead > tr > th:before {
  visibility: hidden;
}
.da-project-table-card .ant-table-tbody > tr > td {
  border-bottom: none;
}
.da-project-table-card .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fff;
}
.da-project-ecommerce-table-card .ant-table-thead > tr > th {
  background: none !important;
  border-bottom: none;
}
.da-project-ecommerce-table-card .ant-table-thead > tr > th:before {
  visibility: hidden;
}
.da-project-ecommerce-table-card .ant-table-thead > tr > th:first-child {
  padding-left: 0;
}
.da-project-ecommerce-table-card .ant-table-tbody > tr > td {
  border-bottom: none;
  padding-top: 15px;
  padding-bottom: 15px;
}
.da-project-ecommerce-table-card .ant-table-tbody > tr > td:first-child {
  padding-left: 0;
}
.da-project-ecommerce-table-card .ant-table-tbody > tr:first-child > td {
  padding-top: 0px;
}
.da-project-ecommerce-table-card .ant-table-tbody > tr:last-child > td {
  border: none;
}
.da-project-ecommerce-table-card .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fff;
}
.da-order-table-card .ant-table-thead tr th {
  background: none !important;
  border: none;
}
.da-order-table-card .ant-table-thead tr th:before {
  visibility: hidden;
}
.da-order-table-card .ant-table-tbody tr:hover > td {
  background: none;
}
.da-order-table-card .ant-table-tbody tr td {
  border: none;
  padding: 7px 16px;
}
.da-order-table-card .ant-table-tbody tr td .da-img-container {
  width: 40px;
  height: 40px;
}
.ant-form-item-with-help .ant-form-item-explain-connected {
  font-size: 0.75em;
}
.ant-form-horizontal .ant-form-item-label > label {
  white-space: break-spaces;
}
.da-analytics-col-2 {
  flex: 0 0 400px;
}
@media screen and (max-width: 1199px) {
  .da-analytics-col-2 {
    flex: 0 0 100%;
  }
}
.da-ecommerce-detail-card img {
  position: absolute;
  bottom: -50px;
  right: -70px;
  max-width: 80%;
}
@media screen and (max-width: 1599px) {
  .da-ecommerce-detail-card img {
    max-width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .da-ecommerce-detail-card img {
    max-width: 85%;
  }
}
@media screen and (max-width: 767px) {
  .da-ecommerce-detail-card img {
    max-width: 100%;
  }
}
.da-dashboard-ecommerce-product-slider .slick-slider {
  width: 100%;
}
.da-dashboard-ecommerce-product-slider-button {
  position: absolute;
  top: -56px;
  right: 16px;
}
.da-dashboard-ecommerce-product-slider-button.prev-button {
  right: 64px;
}
html[dir='rtl'] .da-ecommerce-detail-card img {
  left: -70px;
  right: auto;
  transform: scale(-1, 1);
}
html[dir='rtl'] .da-dashboard-ecommerce-product-slider-button {
  left: 64px;
  right: auto;
}
html[dir='rtl'] .da-dashboard-ecommerce-product-slider-button.prev-button {
  left: 16px;
  right: auto;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-loading .slick-list {
  background: #fff;
}
.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}
.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}
body.dark {
  background: #141414;
}
body.dark a {
  color: #ee502c;
}
body.dark .da-app-layout-bg:before {
  background: #141414;
}
body.dark .ant-divider {
  color: #ffffff;
  border-color: #333333;
}
body.dark .ant-layout {
  background: #1f1f1f;
}
body.dark .da-theme-customise.active {
  box-shadow: -13px 0px 20px rgba(255, 255, 255, 0.03);
}
body.dark .da-theme-customise.active .da-theme-customise-button-bg svg path {
  fill: #1f1f1f;
}
body.dark .da-theme-customise-container-body-item + .da-theme-customise-container-body-item {
  border-color: #474747;
}
body.dark .da-theme-customise-container-body-item-svg {
  border-color: #999999;
}
body.dark .da-theme-customise-container-body-item-svg svg {
  filter: grayscale(0) brightness(0.9);
}
body.dark .da-theme-customise-container-body-item-svg-other {
  border-color: #999999;
}
body.dark .da-theme-customise-container-body-item-svg-other svg {
  filter: grayscale(0) brightness(0.9);
}
body.dark .da-theme-customise-container-body-item-svg-other.active {
  border-color: #c2c2c2;
}
body.dark .da-theme-customise-container-body-item-svg.active {
  border-color: #c2c2c2;
}
body.dark ::-moz-selection {
  background: #f27052;
  color: #ffffff;
}
body.dark ::selection {
  background: #f27052;
  color: #ffffff;
}
body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6,
body.dark .h1,
body.dark .h2,
body.dark .h3,
body.dark .h4,
body.dark .h5,
body.dark .h6 {
  color: #ffffff;
}
body.dark .da-p1-body {
  color: #c2c2c2;
}
body.dark .da-p2-body {
  color: #c2c2c2;
}
body.dark .da-button {
  color: #c2c2c2;
}
body.dark .da-placeholder {
  color: #c2c2c2;
}
body.dark .da-input-description {
  color: #c2c2c2;
}
body.dark .da-input-label {
  color: #c2c2c2;
}
body.dark .da-badge-text {
  color: #c2c2c2;
}
body.dark .ant-layout-header > .ant-row > .ant-col {
  background: #141414;
}
body.dark .ant-layout-header.da-header-horizontal.da-header-full,
body.dark .ant-layout-header.da-header-horizontal.da-header-bg {
  background: #141414;
}
body.dark .ant-layout-header .da-header-search-result {
  background: #141414;
}
body.dark .ant-layout-header .da-header-search-result ul li a {
  color: #d7d7d7;
}
body.dark .ant-layout-header .da-header-search-result ul li:hover {
  background: #333333;
}
body.dark .ant-layout-header .da-header-search-result ul li:hover a {
  color: #ffffff;
}
body.dark .da-basket-dropdown:before {
  border-color: #333333;
  background: #141414;
}
body.dark .ant-btn {
  background: #141414;
  border-color: #333333;
  color: #ffffff;
}
body.dark .ant-btn-text:hover,
body.dark .ant-btn-text:focus {
  color: #d7d7d7 !important;
  background: #292929 !important;
}
body.dark .ant-btn-text {
  background: transparent;
  border-color: transparent !important;
}
body.dark .ant-btn-text.da-btn-text-bg-none {
  background: none !important;
}
body.dark .ant-btn-link {
  color: #ee502c;
  background: transparent;
  border-color: transparent;
}
body.dark .ant-btn-primary {
  color: #ffffff;
  border-color: #f27052;
  background: #f27052;
}
body.dark .ant-btn-primary:hover,
body.dark .ant-btn-primary:focus {
  color: #ffffff !important;
  background: #ee502c;
  border-color: #ee502c;
}
body.dark .ant-btn:hover {
  color: #ee502c;
  border-color: #ee502c;
}
body.dark .ant-btn-link:hover,
body.dark .ant-btn-link:focus {
  color: #ecaa9b;
}
body.dark .ant-btn-background-ghost {
  color: #ee502c !important;
  border-color: #ee502c !important;
}
body.dark .ant-btn-primary.ant-btn-background-ghost:hover {
  border-color: #f27052 !important;
}
body.dark .ant-btn-background-ghost,
body.dark .ant-btn-background-ghost:hover,
body.dark .ant-btn-background-ghost:active,
body.dark .ant-btn-background-ghost:focus {
  background: transparent;
}
body.dark .ant-btn[disabled],
body.dark .ant-btn[disabled]:hover,
body.dark .ant-btn[disabled]:focus,
body.dark .ant-btn[disabled]:active {
  background: #1f1f1f !important;
  border-color: #333333 !important;
  color: #474747 !important;
}
body.dark .ant-btn-text[disabled],
body.dark .ant-btn-text[disabled]:hover,
body.dark .ant-btn-text[disabled]:focus,
body.dark .ant-btn-text[disabled]:active {
  border-color: transparent !important;
  background: transparent !important;
}
body.dark .ant-btn-dangerous {
  color: #ff0022;
  border-color: #ff0022;
}
body.dark .ant-btn-dangerous.ant-btn-primary {
  background: #ff0022;
  border-color: #ff0022;
  color: #ffffff;
}
body.dark .ant-breadcrumb {
  color: #d7d7d7;
}
body.dark .ant-breadcrumb a {
  color: #d7d7d7;
}
body.dark span.ant-breadcrumb-separator {
  color: #999999;
}
body.dark .ant-breadcrumb > span:last-child {
  color: #999999 !important;
}
body.dark .show-code {
  background: #1f1f1f !important;
  color: #ffffff !important;
}
body.dark .show-code code {
  filter: brightness(2.4);
}
body.dark .ant-card {
  background: #141414;
  border-color: #333333 !important;
}
body.dark .ant-layout-sider.ant-layout-sider-dark {
  background: #141414;
}
body.dark .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
body.dark .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
body.dark .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
body.dark .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 2px solid;
}
body.dark .ant-menu-item-selected-in-active {
  background: #141414 !important;
}
body.dark .ant-form-item-label > label {
  color: #ffffff;
}
body.dark .ant-input-password-icon {
  color: #333333;
}
body.dark .ant-input-password-icon:hover {
  color: #ee502c;
}
body.dark .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
body.dark .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
body.dark .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
body.dark .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background: #141414;
}
body.dark .ant-form-item-has-warning :not(.ant-input-disabled).ant-input,
body.dark .ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
body.dark .ant-form-item-has-warning :not(.ant-input-disabled).ant-input:hover,
body.dark .ant-form-item-has-warning :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background: #141414;
}
body.dark .ant-input {
  border-color: #333333;
  background: #141414;
  color: #f0f0f0;
}
body.dark .ant-input :-ms-input-placeholder {
  color: #c2c2c2;
}
body.dark .ant-input ::-moz-placeholder {
  color: #c2c2c2;
}
body.dark .ant-input ::placeholder {
  color: #c2c2c2;
}
body.dark .ant-input-affix-wrapper {
  border-color: #333333;
  background: #141414;
  color: #f0f0f0;
}
body.dark .ant-input-affix-wrapper-disabled {
  background: #1f1f1f;
}
body.dark .ant-input:focus,
body.dark .ant-input-focused {
  border-color: #ee502c !important;
}
body.dark .ant-input-group-addon {
  color: #ffffff;
  border-color: #333333;
  background: #333333;
}
body.dark .ant-input[disabled] {
  background: #1f1f1f;
  border-color: #333333;
  color: #5c5c5c;
}
body.dark .ant-input-number {
  border-color: #333333;
  background: #141414;
  color: #e1e1e1;
}
body.dark .ant-input-number-handler-wrap {
  background: #1f1f1f;
}
body.dark .ant-input-number-handler {
  border-color: #333333;
}
body.dark .ant-input-number-handler svg {
  fill: #e1e1e1;
}
body.dark .ant-input-number-disabled {
  background: #333333;
  color: #5c5c5c;
}
body.dark .ant-menu.ant-menu-dark,
body.dark .ant-menu-dark .ant-menu-sub,
body.dark .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #141414;
  color: #c2c2c2;
}
body.dark .ant-menu-item.ant-menu-item-disabled:hover {
  background: transparent !important;
}
body.dark .ant-dropdown-menu,
body.dark .ant-dropdown-menu .ant-dropdown-menu {
  border-color: #333333 !important;
  background: #141414 !important;
}
body.dark .ant-dropdown-menu .ant-dropdown-menu-item,
body.dark .ant-dropdown-menu .ant-dropdown-menu .ant-dropdown-menu-item,
body.dark .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
body.dark .ant-dropdown-menu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  color: #ffffff;
}
body.dark .ant-dropdown-trigger.ant-dropdown-link {
  color: #ee502c;
}
body.dark .ant-dropdown-menu-title-content > a {
  color: #ffffff;
}
body.dark .ant-dropdown-menu-item:hover {
  background: #1f1f1f;
}
body.dark .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  background: #ff0022;
}
body.dark .ant-dropdown-menu-item-disabled,
body.dark .ant-dropdown-menu-submenu-title-disabled {
  background: none !important;
  color: #474747 !important;
}
body.dark .ant-dropdown-menu-item-disabled a,
body.dark .ant-dropdown-menu-submenu-title-disabled a {
  color: #474747;
}
body.dark .ant-dropdown-menu-item-group-title {
  color: #c2c2c2;
}
body.dark .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
body.dark .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: #474747;
  background: #141414;
}
body.dark .ant-dropdown-menu-submenu-title:hover {
  background: #1f1f1f;
}
body.dark .ant-dropdown-menu-item:hover,
body.dark .ant-dropdown-menu-submenu-title:hover {
  background: #1f1f1f;
}
body.dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
body.dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  color: #ffffff;
}
body.dark .ant-progress-circle-trail {
  stroke: #333333;
}
body.dark .ant-progress-outer .ant-progress-inner {
  background: #333333;
}
body.dark .ant-progress-text[title] {
  color: #c2c2c2;
}
body.dark .ant-typography {
  color: #c2c2c2;
}
body.dark .ant-result-content {
  background: #1f1f1f;
}
body.dark .ant-skeleton-header .ant-skeleton-avatar {
  background-color: #5c5c5c;
}
body.dark .ant-skeleton-content .ant-skeleton-title {
  background-color: #5c5c5c;
}
body.dark .ant-skeleton-content .ant-skeleton-paragraph > li {
  background-color: #5c5c5c;
}
body.dark .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
body.dark .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background-image: linear-gradient(90deg, #5c5c5c 25%, #858585 37%, #5c5c5c 63%);
}
body.dark .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background-image: linear-gradient(90deg, #5c5c5c 25%, #858585 37%, #5c5c5c 63%);
}
body.dark .ant-popover-buttons .ant-btn:nth-child(1) {
  color: #333333;
  border-color: #333333;
  background-color: #1f1f1f;
}
body.dark .ant-popover-inner {
  background: #141414;
  box-shadow: 0 3px 20px 0px rgba(255, 255, 255, 0.06);
}
body.dark .ant-popover-arrow-content {
  background: #141414;
}
body.dark .ant-popover-message {
  color: #ffffff;
}
body.dark .ant-message-notice-content {
  background: #141414;
  box-shadow: 0 3px 20px 0px rgba(255, 255, 255, 0.06);
}
body.dark .ant-message-notice .ant-message-custom-content span:nth-child(2) {
  color: #ffffff;
}
body.dark .ant-notification-notice {
  background: #141414;
  box-shadow: 0 3px 20px 0px rgba(255, 255, 255, 0.06);
}
body.dark .ant-notification-notice-message {
  color: #ffffff;
}
body.dark .ant-notification-notice-description {
  color: #ffffff;
}
body.dark .ant-notification-notice-close {
  color: #c2c2c2;
}
body.dark .ant-modal-title {
  color: #ffffff;
}
body.dark .ant-modal-close {
  color: #c2c2c2;
}
body.dark .ant-modal-mask {
  background: rgba(20, 20, 20, 0.7);
}
body.dark .ant-modal-content {
  background: #141414;
  box-shadow: 0 3px 20px 0px rgba(255, 255, 255, 0.06);
}
body.dark .ant-modal-header {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-modal-footer {
  border-color: #333333;
}
body.dark .ant-drawer-title {
  color: #ffffff;
}
body.dark .ant-drawer-mask {
  background: rgba(20, 20, 20, 0.6);
}
body.dark .ant-drawer-header {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-drawer-content {
  background: #141414;
}
body.dark .ant-drawer-footer {
  border-color: #333333;
}
body.dark .ant-alert-success {
  background: #18342a;
  border-color: #18342a;
}
body.dark .ant-alert-success .ant-alert-content .ant-alert-message,
body.dark .ant-alert-success .ant-alert-content .ant-alert-description {
  color: #00f7bf;
}
body.dark .ant-alert-info {
  background: #1e383d;
  border-color: #1e383d;
}
body.dark .ant-alert-info .ant-alert-content .ant-alert-message,
body.dark .ant-alert-info .ant-alert-content .ant-alert-description {
  color: #1be7ff;
}
body.dark .ant-alert-warning {
  background: #3b3117;
  border-color: #3b3117;
}
body.dark .ant-alert-warning .ant-alert-content .ant-alert-message,
body.dark .ant-alert-warning .ant-alert-content .ant-alert-description {
  color: #ffc212;
}
body.dark .ant-alert-error {
  background: #2e1619;
  border-color: #2e1619;
}
body.dark .ant-alert-error .ant-alert-content .ant-alert-message,
body.dark .ant-alert-error .ant-alert-content .ant-alert-description {
  color: #ff0022;
}
body.dark .ant-timeline.ant-timeline-label .ant-timeline-item-label,
body.dark .ant-timeline-item-content {
  color: #ffffff;
}
body.dark .ant-timeline-item-head {
  background: #141414;
}
body.dark .ant-timeline-item-tail {
  border-color: #333333;
}
body.dark .ant-tag {
  background: #1f1f1f;
  border-color: #333333;
  color: #ffffff;
}
body.dark .ant-tag-close-icon {
  color: #d7d7d7;
}
body.dark .ant-tag-magenta {
  background: #290034;
  border-color: #c903ff;
  color: #c903ff;
}
body.dark .ant-tag-red {
  background: #2e1619;
  border-color: #ff0022;
  color: #ff0022;
}
body.dark .ant-tag-gold {
  background: #3b3117;
  border-color: #ffc212;
  color: #ffc212;
}
body.dark .ant-tag-cyan {
  background: #1e383d;
  border-color: #1be7ff;
  color: #1be7ff;
}
body.dark .ant-tag-blue {
  background: #050342;
  border-color: #ee502c;
  color: #ee502c;
}
body.dark .ant-tag-blue .ant-tag-close-icon {
  color: #ee502c;
}
body.dark .ant-tag-green {
  background: #18342a;
  border-color: #00f7bf;
  color: #00f7bf;
}
body.dark .ant-tag-success {
  background: #18342a;
  border-color: #00f7bf;
  color: #00f7bf;
}
body.dark .ant-tag-processing {
  background: #050342;
  border-color: #ee502c;
  color: #ee502c;
}
body.dark .ant-tag-error {
  background: #2e1619;
  border-color: #ff0022;
  color: #ff0022;
}
body.dark .ant-tag-warning {
  background: #3b3117;
  border-color: #ffc212;
  color: #ffc212;
}
body.dark .ant-tabs {
  color: #ffffff;
}
body.dark .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
body.dark .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
body.dark .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
body.dark .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #ee502c;
}
body.dark .ant-tabs-top > .ant-tabs-nav::before,
body.dark .ant-tabs-bottom > .ant-tabs-nav::before,
body.dark .ant-tabs-top > div > .ant-tabs-nav::before,
body.dark .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-color: #333333;
}
body.dark .ant-tabs-tab:hover {
  color: #ee502c;
}
body.dark .ant-tabs-tab-btn:focus,
body.dark .ant-tabs-tab-remove:focus,
body.dark .ant-tabs-tab-btn:active,
body.dark .ant-tabs-tab-remove:active {
  color: #ee502c;
}
body.dark .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ee502c;
}
body.dark .ant-tabs-ink-bar {
  background: #ee502c;
}
body.dark .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
body.dark .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: #1f1f1f;
  border-color: #333333;
}
body.dark .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
body.dark .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: #141414;
  border-bottom-color: #141414;
}
body.dark .ant-tabs-tab-remove {
  color: #c2c2c2;
}
body.dark .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
body.dark .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  background: #1f1f1f;
  border-color: #333333;
}
body.dark .ant-tabs-left > .ant-tabs-content-holder,
body.dark .ant-tabs-left > div > .ant-tabs-content-holder {
  border-color: #333333;
}
body.dark .da-table-menu .ant-menu {
  border-color: #333333;
}
body.dark .da-table-menu .ant-menu span.ant-menu-title-content > span.active {
  color: #ffffff;
  background: #1f1f1f;
}
body.dark .ant-table {
  background: #141414;
  color: #ffffff;
}
body.dark .ant-table-thead > tr > th {
  background: #1f1f1f;
  color: #ffffff;
  border-color: #333333;
}
body.dark .ant-table-tbody > tr > td {
  border-color: #333333;
}
body.dark .ant-table.ant-table-bordered > .ant-table-title {
  border-color: #333333;
}
body.dark .ant-table-summary > tr > th,
body.dark .ant-table-summary > tr > td {
  border-color: #333333;
}
body.dark .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #474747;
}
body.dark .ant-table-tbody > tr > td a {
  color: #ee502c;
}
body.dark td.ant-table-column-sort {
  background: #5c5c5c;
}
body.dark .ant-table-filter-trigger:hover {
  color: #ffffff;
}
body.dark .ant-table-filter-dropdown-btns {
  border-top-color: #333333;
}
body.dark .ant-table-filter-dropdown {
  background: #141414;
}
body.dark .ant-table-row-expand-icon {
  background: #1f1f1f;
  border-color: #333333;
}
body.dark .ant-table.ant-table-bordered > .ant-table-container {
  border-color: #333333;
}
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-color: #333333;
}
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-color: #333333;
}
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-color: #333333;
}
body.dark .ant-table.ant-table-bordered > .ant-table-footer {
  border-color: #333333;
}
body.dark .ant-table-footer {
  background: #1f1f1f;
  color: #ffffff;
}
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-color: #333333;
}
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
  border-color: #333333;
}
body.dark .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
  border-color: #333333;
}
body.dark .ant-table-cell-fix-left,
body.dark .ant-table-cell-fix-right {
  background: #141414;
}
body.dark .ant-table-sticky-holder {
  background: #141414;
}
body.dark .ant-table-summary {
  background: #141414;
  box-shadow: 0 -1px 0 #333333;
}
body.dark .row-dragging {
  background: #1f1f1f;
  border-color: #333333;
  color: #ffffff;
}
body.dark .ant-popover-inner-content {
  color: #c2c2c2;
}
body.dark .ant-popover-title {
  color: #ffffff;
}
body.dark .ant-list-item-meta-title > a {
  color: #ffffff;
}
body.dark .ant-list-item-meta-description {
  color: #c2c2c2;
}
body.dark .ant-list-split .ant-list-item {
  border-color: #333333 !important;
}
body.dark .ant-list-item {
  color: #c2c2c2;
}
body.dark .ant-list-item-action > li {
  color: #999999;
}
body.dark .ant-list-item-action-split {
  background: #333333;
}
body.dark .ant-list-footer {
  color: #ffffff;
}
body.dark .ant-empty-description {
  color: #c2c2c2;
}
body.dark .ant-empty-img-default-ellipse {
  fill: #333333;
}
body.dark .ant-empty-img-default-path-1 {
  fill: #1f1f1f;
}
body.dark .ant-empty-img-default-path-3 {
  fill: #333333;
}
body.dark .ant-empty-img-default-path-4 {
  fill: #474747;
}
body.dark .ant-empty-img-default-path-5 {
  fill: #333333;
}
body.dark .ant-empty-img-default-g {
  fill: #474747;
}
body.dark .ant-empty-img-simple-g {
  stroke: #5c5c5c;
}
body.dark .ant-empty-img-simple-path {
  fill: #474747;
}
body.dark .ant-empty-img-simple-ellipse {
  fill: #333333;
}
body.dark .ant-comment-content-author-name {
  color: #ffffff;
}
body.dark .ant-comment-content-author-name > * {
  color: #ffffff;
}
body.dark .ant-comment-content-detail p {
  color: #c2c2c2;
}
body.dark .ant-comment-actions > li {
  color: #d7d7d7;
}
body.dark .ant-comment-actions > li > span {
  color: #d7d7d7;
}
body.dark .ant-list-split .ant-list-header {
  border-color: #333333;
  color: #ffffff;
}
body.dark .ant-collapse {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-collapse > .ant-collapse-item {
  border-color: #333333;
}
body.dark .ant-collapse-content {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-collapse-content {
  color: #ffffff;
}
body.dark .ant-card-head {
  color: #ffffff;
  border-color: #333333;
}
body.dark .ant-card-meta-description {
  color: #c2c2c2;
}
body.dark .ant-card-meta-title {
  color: #ffffff;
}
body.dark .da-elevatior {
  box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.08);
}
body.dark .ant-picker-calendar {
  background: #141414;
}
body.dark .ant-picker-calendar-full .ant-picker-panel {
  background: #141414;
}
body.dark .ant-picker-cell {
  color: #707070;
}
body.dark .ant-picker-cell-in-view {
  color: #ffffff;
}
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-color: #333333;
}
body.dark .ant-picker-content th {
  color: #ffffff;
}
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #1f1f1f;
}
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #ee502c !important;
}
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
body.dark .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #ee502c;
}
body.dark .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
body.dark .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #1f1f1f;
}
body.dark .calendar-demo-card {
  border-color: #333333;
}
body.dark .ant-picker-calendar .ant-picker-panel {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-badge {
  color: #ffffff;
}
body.dark .site-badge-count-4 .ant-badge-count {
  color: #5c5c5c;
  background-color: #1f1f1f;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.35);
}
body.dark .ant-badge-status-text {
  color: #ffffff;
}
body.dark .ant-badge-count {
  box-shadow: 0 0 0 1px #141414;
}
body.dark .ant-badge-dot {
  box-shadow: 0 0 0 1px #141414;
}
body.dark .ant-avatar-group .ant-avatar {
  border-color: #141414;
}
body.dark .ant-avatar-transparent {
  background: transparent !important;
}
body.dark .ant-avatar {
  color: #ffffff;
  background: #050342;
}
body.dark .ant-upload-list-item {
  color: #ffffff;
}
body.dark .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: #1f1f1f;
}
body.dark .ant-upload-list-item .ant-upload-list-item-card-actions-btn {
  color: #ffffff;
}
body.dark .ant-upload.ant-upload-select-picture-card {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-upload-list-picture .ant-upload-list-item,
body.dark .ant-upload-list-picture-card .ant-upload-list-item {
  border-color: #333333;
}
body.dark .ant-upload {
  color: #ffffff;
}
body.dark .ant-upload.ant-upload-drag {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-upload.ant-upload-drag p.ant-upload-text {
  color: #ffffff;
}
body.dark .ant-upload.ant-upload-drag p.ant-upload-hint {
  color: #c2c2c2;
}
body.dark .ant-switch {
  color: #ffffff;
  background: #474747;
}
body.dark .ant-switch-checked {
  background-color: #f27052;
}
body.dark .ant-slider-rail {
  background: #333333;
}
body.dark .ant-slider-mark-text-active {
  color: #ffffff;
}
body.dark .ant-slider:hover .ant-slider-rail {
  background: #474747;
}
body.dark .ant-slider-mark-text {
  color: #c2c2c2;
}
body.dark .icon-wrapper-active {
  color: #c2c2c2 !important;
}
body.dark .ant-rate-star.ant-rate-star-zero .ant-rate-star-first,
body.dark .ant-rate-star.ant-rate-star-zero .ant-rate-star-second {
  color: #474747;
}
body.dark .ant-rate-star.ant-rate-star-half .ant-rate-star-second {
  color: #474747;
}
body.dark .ant-radio-wrapper {
  color: #ffffff;
}
body.dark .ant-radio-inner {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-radio-checked .ant-radio-inner {
  border-color: #f27052;
}
body.dark .ant-radio-button-wrapper {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-radio-button-wrapper:not(:first-child)::before {
  background: #333333;
}
body.dark .ant-radio-button-wrapper:first-child {
  border-color: #333333;
}
body.dark .ant-radio-button-wrapper {
  color: #ffffff;
}
body.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #f27052;
  color: #ffffff;
}
body.dark .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #f27052;
  color: #ffffff;
}
body.dark .ant-radio-button-wrapper-disabled {
  color: #474747;
  background: #292929;
  border-color: #292929;
}
body.dark .ant-radio-disabled .ant-radio-inner {
  background: #474747 !important;
  border-color: #474747 !important;
}
body.dark .ant-radio-disabled .ant-radio-inner::after {
  background: #1f1f1f;
}
body.dark .ant-radio-disabled + span {
  color: #474747;
}
body.dark .ant-select {
  color: #ffffff;
}
body.dark .ant-select-item-option-disabled.ant-select-item-option-selected {
  background: #1f1f1f;
}
body.dark .ant-select-dropdown {
  background: #141414;
  border-color: #333333;
  box-shadow: 0 3px 20px 0px rgba(255, 255, 255, 0.06);
}
body.dark .ant-select-item {
  color: #ffffff;
}
body.dark .ant-select-item-option-disabled {
  color: #333333;
}
body.dark .ant-select-clear {
  color: #5c5c5c;
  background: #141414;
}
body.dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #ee502c;
}
body.dark .ant-select-item-group {
  color: #c2c2c2;
}
body.dark .ant-select-multiple .ant-select-selection-item {
  background: #1f1f1f;
  color: #ffffff;
  border-color: #333333;
}
body.dark .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  background: #333333;
  color: #474747;
}
body.dark .ant-select-multiple .ant-select-selection-item-remove {
  color: #c2c2c2;
}
body.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #1f1f1f;
  border-color: #1f1f1f;
  color: #333333;
}
body.dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #292929;
  color: #ee502c;
}
body.dark .ant-select-disabled .ant-select-arrow {
  color: #333333;
}
body.dark .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #292929;
}
body.dark .ant-select:not(.ant-select-customize-input) .ant-select- {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-select-tree {
  background: #1f1f1f;
  color: #ffffff;
}
body.dark .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background: #1f1f1f;
}
body.dark .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background: #1f1f1f;
}
body.dark .ant-cascader-picker {
  background: #141414;
  border-color: #333333;
  color: #ffffff;
}
body.dark .ant-cascader-menu {
  border-color: #333333;
  color: #ffffff;
}
body.dark .ant-cascader-menus {
  background: #1f1f1f;
  box-shadow: 0 3px 20px 0px rgba(255, 255, 255, 0.04);
}
body.dark .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
body.dark .ant-cascader-menu-item-loading-icon {
  color: #ffffff;
}
body.dark .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
body.dark .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: #333333;
}
body.dark .ant-cascader-menu-item:hover {
  background: #333333;
}
body.dark .ant-picker {
  background: #141414;
  border-color: #333333;
  color: #ffffff;
}
body.dark .ant-picker:hover,
body.dark .ant-picker-focused {
  border-color: #ee502c;
}
body.dark .ant-picker-panel-container {
  background: #141414;
  box-shadow: 0 3px 20px 0px rgba(255, 255, 255, 0.04);
}
body.dark .ant-picker-panel .ant-picker-footer {
  border-color: #333333;
}
body.dark .ant-picker-panel {
  border-color: #333333;
}
body.dark .ant-picker-header {
  color: #ffffff;
  border-color: #333333;
}
body.dark .ant-picker-time-panel-column:not(:first-child) {
  border-color: #333333;
}
body.dark .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: #ffffff;
}
body.dark .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #1f1f1f;
}
body.dark .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #333333;
}
body.dark .ant-picker-input > input {
  color: #ffffff;
}
body.dark .ant-picker-clear {
  background: #1f1f1f;
}
body.dark .ant-picker-clear:hover {
  color: #c2c2c2;
}
body.dark .ant-picker-focused .ant-picker-separator {
  color: #ffffff;
}
body.dark .ant-picker.ant-picker-disabled {
  background: #1f1f1f;
  color: #333333;
}
body.dark .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  color: #333333;
}
body.dark .ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #333333;
}
body.dark .ant-picker-input > input[disabled] {
  color: #333333;
}
body.dark .ant-picker-footer-extra:not(:last-child) {
  border-color: #333333;
  color: #ffffff;
}
body.dark .ant-picker-footer-extra {
  color: #ffffff;
}
body.dark .ant-picker-datetime-panel .ant-picker-time-panel {
  border-color: #333333;
}
body.dark .ant-picker-suffix {
  color: #ee502c;
}
body.dark .ant-picker-week-panel-row:hover td {
  background: #1f1f1f;
}
body.dark .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #1f1f1f;
}
body.dark .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
body.dark .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #1f1f1f;
}
body.dark .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
body.dark .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
body.dark .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
body.dark .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
body.dark .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
body.dark .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
body.dark .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #292929;
}
body.dark .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
body.dark .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background: #292929;
}
body.dark .ant-checkbox-wrapper {
  color: #ffffff;
}
body.dark .ant-checkbox-inner {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-checkbox-checked .ant-checkbox-inner {
  background: #f27052;
  border-color: #f27052;
}
body.dark .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  color: #474747;
}
body.dark .ant-checkbox-disabled + span {
  color: #474747;
}
body.dark .ant-checkbox-disabled .ant-checkbox-inner {
  background: #1f1f1f;
  border-color: #333333 !important;
}
body.dark .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #474747;
}
body.dark .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ffffff;
}
body.dark .ant-steps-item-title {
  color: #ffffff;
}
body.dark .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #c2c2c2;
}
body.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ffffff;
}
body.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ffffff;
}
body.dark .ant-steps-item-subtitle {
  color: #c2c2c2;
}
body.dark .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #999999;
}
body.dark .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #999999;
}
body.dark .ant-steps-item-description {
  color: #c2c2c2;
}
body.dark .ant-steps-item-wait .ant-steps-item-icon {
  background: #c2c2c2;
  border-color: #c2c2c2;
}
body.dark .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #333333;
}
body.dark .ant-steps-item-error .ant-steps-item-icon {
  background: #141414;
}
body.dark .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after,
body.dark .ant-steps-item-tail::after,
body.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: #333333;
}
body.dark .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #474747;
  border-color: #474747;
}
body.dark .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
body.dark .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
body.dark .ant-steps-item-title::after,
body.dark .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: #333333;
}
body.dark .ant-pagination-item {
  background: #141414;
  border-color: #333333;
}
body.dark .ant-pagination-item-active {
  border-color: #ee502c;
}
body.dark .ant-pagination-disabled .ant-pagination-item-link,
body.dark .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  background: #141414;
  border-color: #333333;
  color: #333333;
}
body.dark .ant-pagination-prev .ant-pagination-item-link,
body.dark .ant-pagination-next .ant-pagination-item-link {
  background: #141414;
  border-color: #333333;
  color: #ffffff;
}
body.dark .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
body.dark .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #474747;
}
body.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #1f1f1f;
  border-color: #1f1f1f;
}
body.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: #333333;
}
body.dark .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
body.dark .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  border-color: #333333;
}
body.dark .ant-pagination-disabled .ant-pagination-item-link,
body.dark .ant-pagination-disabled:hover .ant-pagination-item-link,
body.dark .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  background: #1f1f1f;
  border-color: #1f1f1f;
  color: #333333;
}
body.dark .ant-pagination-options-quick-jumper {
  color: #ffffff;
}
body.dark .ant-pagination-options-quick-jumper input {
  color: #ffffff;
  border-color: #333333;
  background: #141414;
}
body.dark .ant-pagination-options-quick-jumper input[disabled] {
  background: #1f1f1f;
  border-color: #1f1f1f;
  color: #333333;
}
body.dark .ant-pagination.ant-pagination-disabled .ant-pagination-options-quick-jumper {
  color: #333333;
}
body.dark .ant-pagination-total-text {
  color: #ffffff;
}
body.dark .ant-pagination-simple .ant-pagination-simple-pager input {
  color: #ffffff;
  border-color: #333333;
  background: #141414;
}
body.dark .ant-pagination-disabled .ant-pagination-item-link,
body.dark .ant-pagination-disabled:hover .ant-pagination-item-link {
  border-color: #1f1f1f !important;
  color: #333333;
}
body.dark .ant-pagination-prev:focus-visible .ant-pagination-item-link,
body.dark .ant-pagination-next:focus-visible .ant-pagination-item-link,
body.dark .ant-pagination-prev:hover .ant-pagination-item-link,
body.dark .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #ee502c;
}
body.dark .ant-pagination-simple .ant-pagination-simple-pager {
  color: #c2c2c2;
}
body.dark .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: #474747;
}
body.dark .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  background: #333333;
  color: #474747;
}
body.dark .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent !important;
}
body.dark .ant-pagination-disabled {
  opacity: 0.7;
}
body.dark .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: #c2c2c2;
}
body.dark .da-lock-screen .da-screen-bg {
  filter: grayscale(1) brightness(0.4);
}
body.dark .da-lock-screen-row {
  background: rgba(0, 1, 8, 0.3);
  border: 3px solid rgba(72, 72, 72, 0.3);
}
body.dark .da-authentication-page .da-or-line:before {
  background-color: #333333;
}
body.dark .da-faq-tabs .ant-tabs-nav .ant-tabs-tab-active svg {
  fill: #ee502c !important;
}
body.dark .da-faq-tabs .ant-tabs-nav .ant-tabs-tab-active span {
  color: #ee502c !important;
}
body.dark .da-invoice-card p {
  color: #c2c2c2;
}
body.dark .da-profile-menu {
  border-color: #333333 !important;
}
body.dark .da-profile-menu-header .ant-avatar:after {
  background: #141414;
}
body.dark .da-landing-features .slick-slider:before,
body.dark .da-landing-features .slick-slider:after {
  background: linear-gradient(to left, #1f1f1f, #1f1f1f00);
}
body.dark .da-calendar .fc tbody .fc-scroller-harness {
  border-color: #333333;
}
body.dark .da-calendar .fc-theme-standard td,
body.dark .da-calendar .fc-theme-standard th {
  border-color: #333333;
  color: #ffffff;
}
body.dark .da-calendar .fc .fc-cell-shaded,
body.dark .da-calendar .fc .fc-day-disabled {
  background: #333333;
}
body.dark .da-calendar .fc .fc-theme-standard td {
  border-color: #333333;
}
body.dark .da-calendar .fc .fc-col-header-cell-cushion {
  color: #ffffff;
}
body.dark .da-calendar .fc .fc-day-other .fc-daygrid-day-top a {
  color: #333333;
}
body.dark .da-calendar .fc .fc-day-other .fc-daygrid-day-frame {
  background: #1f1f1f;
  border: none;
}
body.dark .da-calendar .fc .fc-daygrid-day-number {
  color: #ffffff;
}
body.dark .da-calendar .fc .fc-dayGridMonth-view .fc-daygrid-day.fc-day-today {
  border-bottom: 2px solid #f27052;
}
body.dark .da-calendar .fc .fc-button {
  background: #1f1f1f;
}
body.dark .da-calendar .fc .fc-button-primary:not(:disabled):active,
body.dark .da-calendar .fc .fc-button-primary:not(:disabled).fc-button-active {
  background: #050342;
}
body.dark .da-calendar .fc .fc-button.fc-prev-button,
body.dark .da-calendar .fc .fc-button.fc-next-button {
  color: #ffffff;
}
body.dark .da-calendar .fc-h-event {
  background: #050342;
}
body.dark .da-calendar .fc-h-event.bg-light-travel {
  background-color: #290034;
}
body.dark .da-calendar .fc-h-event.bg-light-social {
  background-color: #18342a;
}
body.dark .da-calendar .fc-h-event.bg-light-work {
  background-color: #3b3117;
}
body.dark .da-calendar .fc-h-event.bg-light-important {
  background-color: #2e1619;
}
body.dark .da-calendar .fc-h-event .fc-event-title {
  color: #c2c2c2;
}
body.dark .da-calendar .fc-direction-ltr .fc-daygrid-event .fc-event-time {
  color: #c2c2c2;
}
body.dark .da-calendar .fc-timegrid-event .fc-event-main {
  color: #ee502c;
}
body.dark .da-calendar .fc-media-screen .fc-timegrid-event {
  background: rgba(31, 31, 31, 0.8);
  box-shadow: 0px 0px 0px 1px #333333;
}
body.dark .da-calendar .fc-timeGridWeek-view .fc-timegrid-col.fc-day-today {
  background: #1f1f1f !important;
}
body.dark .da-calendar .fc-timegrid-axis-cushion {
  color: #ffffff;
}
body.dark .da-calendar .fc-theme-standard .fc-popover-header {
  background: #1f1f1f;
  color: #ffffff;
}
body.dark .da-calendar .fc-theme-standard .fc-popover {
  border-color: #141414;
  background: #141414;
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.04);
}
body.dark .da-calendar-checkbox-list .ant-checkbox-input[data-color='none'] ~ .ant-checkbox-inner:before {
  background: #050342;
}
body.dark .da-calendar-checkbox-list .ant-checkbox-input[data-color='travel'] ~ .ant-checkbox-inner:before {
  background: #290034;
}
body.dark .da-calendar-checkbox-list .ant-checkbox-input[data-color='social'] ~ .ant-checkbox-inner:before {
  background: #18342a;
}
body.dark .da-calendar-checkbox-list .ant-checkbox-input[data-color='work'] ~ .ant-checkbox-inner:before {
  background: #3b3117;
}
body.dark .da-calendar-checkbox-list .ant-checkbox-input[data-color='important'] ~ .ant-checkbox-inner:before {
  background: #2e1619;
}
body.dark .flatpickr-input {
  background-color: #141414;
  border-color: #333333;
  color: #ffffff;
}
body.dark .flatpickr-months .flatpickr-month {
  background: #333333;
  fill: #ffffff;
  color: #ffffff;
}
body.dark .flatpickr-months .flatpickr-prev-month,
body.dark .flatpickr-months .flatpickr-next-month {
  color: #ffffff;
  fill: #ffffff;
}
body.dark .flatpickr-current-month .flatpickr-monthDropdown-months {
  background: #333333;
}
body.dark .flatpickr-calendar {
  box-shadow: -13px 0px 20px rgba(255, 255, 255, 0.03);
}
body.dark .flatpickr-days {
  border-color: #474747;
}
body.dark .dayContainer {
  background: #1f1f1f;
}
body.dark .flatpickr-weekdays {
  background: #333333;
}
body.dark span.flatpickr-weekday {
  background: #333333;
  color: #ffffff;
}
body.dark .flatpickr-calendar.hasTime .flatpickr-time {
  border-color: #474747;
}
body.dark .flatpickr-day.inRange,
body.dark .flatpickr-day.prevMonthDay.inRange,
body.dark .flatpickr-day.nextMonthDay.inRange,
body.dark .flatpickr-day.today.inRange,
body.dark .flatpickr-day.prevMonthDay.today.inRange,
body.dark .flatpickr-day.nextMonthDay.today.inRange,
body.dark .flatpickr-day:hover,
body.dark .flatpickr-day.prevMonthDay:hover,
body.dark .flatpickr-day.nextMonthDay:hover,
body.dark .flatpickr-day:focus,
body.dark .flatpickr-day.prevMonthDay:focus,
body.dark .flatpickr-day.nextMonthDay:focus {
  background: #333333;
  border-color: #333333;
  color: #c2c2c2;
}
body.dark .flatpickr-calendar.arrowTop:before {
  border-bottom-color: #333333;
}
body.dark .flatpickr-calendar.arrowTop:after {
  border-bottom-color: #333333;
}
body.dark .flatpickr-time {
  background: #333333;
}
body.dark .flatpickr-time input {
  color: #ffffff;
  background: #333333;
}
body.dark .flatpickr-time .flatpickr-time-separator,
body.dark .flatpickr-time .flatpickr-am-pm {
  color: #ffffff;
}
body.dark .flatpickr-time input:hover,
body.dark .flatpickr-time .flatpickr-am-pm:hover,
body.dark .flatpickr-time input:focus,
body.dark .flatpickr-time .flatpickr-am-pm:focus {
  background: #474747;
}
body.dark .select__value-container.select__value-container--has-value {
  background: #141414;
}
body.dark .css-yk16xz-control {
  border-color: #333333;
  background: #141414;
  min-height: 40px;
  border-radius: 7px;
}
body.dark .select__control .select__single-value,
body.dark .react-select__control .select__single-value {
  color: #ffffff;
}
body.dark .css-1pahdxg-control {
  background: #141414;
}
body.dark .select__menu {
  background: #141414;
}
body.dark .select__option {
  color: #ffffff;
}
body.dark .select__menu .select__menu-list .select__option.select__option--is-focused,
body.dark .react-select__menu .select__menu-list .select__option.select__option--is-focused,
body.dark .select__menu .react-select__menu-list .select__option.select__option--is-focused,
body.dark .react-select__menu .react-select__menu-list .select__option.select__option--is-focused,
body.dark .select__menu .select__menu-list .react-select__option.select__option--is-focused,
body.dark .react-select__menu .select__menu-list .react-select__option.select__option--is-focused,
body.dark .select__menu .react-select__menu-list .react-select__option.select__option--is-focused,
body.dark .react-select__menu .react-select__menu-list .react-select__option.select__option--is-focused {
  background-color: #333333;
  color: #ffffff;
}
body.dark .select__menu .select__menu-list .select__option.select__option--is-selected,
body.dark .react-select__menu .select__menu-list .select__option.select__option--is-selected,
body.dark .select__menu .react-select__menu-list .select__option.select__option--is-selected,
body.dark .react-select__menu .react-select__menu-list .select__option.select__option--is-selected,
body.dark .select__menu .select__menu-list .react-select__option.select__option--is-selected,
body.dark .react-select__menu .select__menu-list .react-select__option.select__option--is-selected,
body.dark .select__menu .react-select__menu-list .react-select__option.select__option--is-selected,
body.dark .react-select__menu .react-select__menu-list .react-select__option.select__option--is-selected {
  background-color: #333333;
  color: #ffffff;
}
body.dark .select__menu .select__menu-list .select__option:active {
  background: #333333;
}
body.dark .apexchartsexpensesxdonutxcard .apexcharts-pie-series path {
  stroke: #141414;
}
body.dark .apexcharts-gridline {
  stroke: #707070;
}
body.dark .apexcharts-yaxis-label,
body.dark .apexcharts-xaxis-label {
  fill: #707070;
}
body.dark .apexcharts-xaxis line {
  stroke: #5c5c5c;
}
body.dark .apexcharts-heatmap-rect {
  stroke: #141414;
}
body.dark .apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #1f1f1f;
}
body.dark .apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: #333333;
}
body.dark .apexcharts-xaxistooltip {
  background: #1f1f1f;
  border-color: #333333;
}
body.dark .apexcharts-canvas .apexcharts-xaxistooltip-text {
  color: #ffffff !important;
}
body.dark path#apexcharts-radialbarTrack-0 {
  stroke: #474747;
}
body.dark .apexcharts-legend-text {
  color: #ffffff !important;
}
body.dark .apexcharts-pie-area {
  stroke: #141414;
}
body.dark .apexcharts-pie .apexcharts-datalabel-value {
  fill: #ffffff;
}
body.dark .apexcharts-pie .apexcharts-datalabel-label {
  fill: #474747;
}
body.dark .apexcharts-radialbar .apexcharts-datalabel-value {
  fill: #ffffff !important;
}
body.dark .apexchartsexpensesxdonutxcard .apexcharts-text:nth-child(1) {
  color: #707070 !important;
}
body.dark .apexchartsexpensesxdonutxcard .apexcharts-text:nth-child(2) {
  fill: #ffffff !important;
}
body.dark .apexcharts-radar-series polygon {
  stroke: #707070;
}
body.dark .apexcharts-radar-series.apexcharts-plot-series {
  stroke: #474747;
}
body.dark .apexcharts-radar-series.apexcharts-plot-series line {
  stroke: #474747;
}
body.dark .apexcharts-canvas .apexcharts-tooltip {
  color: #ffffff;
  background: #141414;
  border-color: #333333;
}
body.dark .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  background: #1f1f1f;
  border-color: #333333;
}
body.dark .da-analytics-visiters-chart .apexcharts-text {
  fill: #707070 !important;
}
body.dark .da-chart-text-color .apexcharts-text {
  fill: #707070 !important;
}

